import { useTranslations, useToast } from "@jugl-web/utils";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { Alert } from "@jugl-web/ui-components/cross-platform";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";

export const ArchiveTaskDialog = ({
  isOpen,
  taskName,
  taskId,
  onClose,
  onSuccess,
}: {
  isOpen: boolean;
  taskName: string;
  taskId: string;
  onClose: () => void;
  onSuccess?: () => void;
}) => {
  const { t } = useTranslations();
  const { tasksApi } = useRestApiProvider();
  const [archiveTask] = tasksApi.useArchiveTaskMutation();
  const { toast } = useToast({ variant: "web" });
  const { entityId } = useEntitySelectedProvider();

  const onConfirm = async () => {
    const response = await archiveTask({ entityId, taskId });
    if (!("error" in response)) {
      toast(
        t({
          id: "feedback.task-was-successfully-archived",
          defaultMessage: "Task was successfully Archived",
        })
      );
      onSuccess?.();
    }
  };
  return (
    <Alert
      isOpen={isOpen}
      title={t({
        id: "tasks-page.archive-task",
        defaultMessage: "Archive Task",
      })}
      content={t(
        {
          id: "tasks-page.do-you-really-want-to-archive-task",
          defaultMessage:
            "Do you really want to archive task <highlightedText>{taskName}</highlightedText>? Task will be stored in Archive for 90 days",
        },
        {
          highlightedText: (text: (string | JSX.Element)[]) => (
            <span className="text-primary-800 font-medium">{text}</span>
          ),
          taskName,
        }
      )}
      buttons={[
        {
          text: t({ id: "common.cancel", defaultMessage: "Cancel" }),
          role: "close",
        },
        {
          text: t({ id: "common.archive", defaultMessage: "Archive" }),
          color: "tertiary",
          onClick: (_, actions) => {
            onConfirm();
            actions.closeAlert();
          },
        },
      ]}
      onRequestClose={onClose}
    />
  );
};
