import React, { useMemo } from "react";
import Lottie from "react-lottie";
import { cx, useTranslations } from "@jugl-web/utils";
import { Button, ButtonProps } from "../../Button";
import errorAnimation from "./assets/error.json";
import noResultsAnimation from "./assets/no-results.json";

type CustomProps = {
  customTitle: string;
  customSubtitle: string;
  customImg?: React.ReactNode;
  className?: string;
  customButton?: Pick<
    ButtonProps,
    | "variant"
    | "color"
    | "isDisabled"
    | "iconStart"
    | "iconEnd"
    | "children"
    | "size"
    | "onClick"
    | "fullWidth"
    | "className"
  > & {
    text: string;
    onClick: () => void;
  };
  onRefetch?: () => void;
};

type EmptyListErrorProps = Partial<CustomProps> & {
  type: "error";
};

type EmptyListNoResultsProps = Partial<CustomProps> & {
  type: "noResults";
};

type EmptyListCustomProps = CustomProps & {
  type: "custom";
};

export type EmptyListContentProps =
  | EmptyListCustomProps
  | EmptyListNoResultsProps
  | EmptyListErrorProps;

export const EmptyListContent: React.FC<EmptyListContentProps> = ({
  type,
  customButton,
  customImg,
  customSubtitle,
  customTitle,
  className,
  onRefetch,
}) => {
  const { t } = useTranslations();

  const { image, title, subtitle, button } = useMemo(() => {
    switch (type) {
      case "noResults":
        return {
          image: (
            <Lottie
              options={{ animationData: noResultsAnimation }}
              height={240}
              width={240}
            />
          ),
          title: t({
            id: "common.no-results",
            defaultMessage: "No results",
          }),
          subtitle: t({
            id: "empty-list-content-component.please-try-something-else",
            defaultMessage: "Please try something else",
          }),
        };
      case "error":
        return {
          image: (
            <Lottie
              options={{ animationData: errorAnimation }}
              height={180}
              width={180}
            />
          ),
          title: t({
            id: "common.something-went-wrong",
            defaultMessage: "Something went wrong",
          }),
          subtitle: t({
            id: "empty-list-content-component.click-button-to-retry",
            defaultMessage: "Click the button to retry",
          }),
          button: (
            <Button fullWidth onClick={onRefetch}>
              {t({
                id: "common.retry",
                defaultMessage: "Retry",
              })}
            </Button>
          ),
        };
      case "custom":
        return {};
      default:
        throw new Error("Invalid EmptyListContent type");
    }
  }, [onRefetch, t, type]);

  return (
    <div
      className={cx(
        "mx-auto flex h-full w-[280px] flex-col items-center justify-center gap-4 text-center",
        className
      )}
    >
      {customImg || image}
      <span className="text-dark font-secondary text-xl font-medium">
        {customTitle || title}
      </span>
      <span className="text-dark text-sm">{customSubtitle || subtitle}</span>
      {customButton ? (
        <Button fullWidth {...customButton}>
          {customButton.text}
        </Button>
      ) : (
        button
      )}
    </div>
  );
};
