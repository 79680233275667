import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import {
  PaymentSummarySideBar,
  PaymentSummarySideBarItem,
} from "@web-src/modules/workspace/components/PaymentSummarySideBar";
import { FC, useMemo, useState } from "react";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { apiUTCToLocalDateTime, priceDisplay, useToast } from "@jugl-web/utils";
import format from "date-fns/format";
import { loadStripe } from "@stripe/stripe-js";
import { useWorkspaceSubscriptionPageProvider } from "../../WorkspaceSubscriptionPageProvider";

export const SeatsUpdateSideBar: FC = () => {
  const { entitiesApi } = useRestApiProvider();
  const { toast } = useToast({ variant: "web" });
  const [updateSeats] = entitiesApi.useUpdateSeatsMutation();
  const [isUpdateSeatsInProgress, setIsUpdateSeatsInProgress] = useState(false);
  const { entityId, subscriptionInfo } = useEntitySelectedProvider();
  const { seatsUpdateState, setSeatsUpdateState } =
    useWorkspaceSubscriptionPageProvider();

  const handleError = () => {
    toast("Failed to upgrade subscription", { variant: "error" });
  };
  const handleSucceeded = () => {
    setSeatsUpdateState(0);
    toast("Subscription updated successfully", { variant: "success" });
  };
  const handleConfirm = async () => {
    if (seatsUpdateState === 0 || !subscriptionInfo.additionalSeats) {
      return;
    }
    try {
      setIsUpdateSeatsInProgress(true);
      const response = await updateSeats({
        entityId,
        data: {
          qty: subscriptionInfo.additionalSeats.purchased + seatsUpdateState,
          price_id: subscriptionInfo.additionalSeats.priceId,
        },
      });
      if ("data" in response && response.data) {
        if (response.data.result === "success") {
          handleSucceeded();
          return;
        }
        if (response.data.result === "action_required") {
          const stripe = await loadStripe(response.data.pub_key);
          if (!stripe) {
            handleError();
            return;
          }
          const nextActionResult = await stripe?.handleNextAction({
            clientSecret: response.data.secret,
          });
          if (
            nextActionResult?.error ||
            nextActionResult?.paymentIntent?.status !== "succeeded"
          ) {
            handleError();
            return;
          }
          handleSucceeded();
        }
      }
    } catch (error) {
      handleError();
    } finally {
      setIsUpdateSeatsInProgress(false);
    }
  };
  const items: PaymentSummarySideBarItem[] = useMemo(() => {
    if (seatsUpdateState === 0 || !subscriptionInfo.additionalSeats) {
      return [];
    }
    return [
      {
        title:
          seatsUpdateState < 0
            ? "Removing Additional seats"
            : "Additional seats",
        summary: [
          `${priceDisplay(
            subscriptionInfo.additionalSeats.price,
            subscriptionInfo.additionalSeats.currency
          )} x 1 seat x 1 ${subscriptionInfo.planInfo?.interval} `,
        ],
        amount: subscriptionInfo.additionalSeats.price * seatsUpdateState,
      },
    ];
  }, [
    seatsUpdateState,
    subscriptionInfo.additionalSeats,
    subscriptionInfo.planInfo,
  ]);

  const nextMonthPaymentInfo: {
    amount: number;
    dateString: string;
    summary: string[];
  } | null = useMemo(() => {
    if (!subscriptionInfo.planInfo) {
      return null;
    }
    const summary = [
      `${subscriptionInfo.planInfo.name}: ${priceDisplay(
        subscriptionInfo.planInfo.price,
        subscriptionInfo.planInfo.currency
      )}`,
    ];
    let seatsTotalPrice = 0;
    if (subscriptionInfo.additionalSeats) {
      const totalAdditionalSeats =
        subscriptionInfo.additionalSeats.purchased + seatsUpdateState;
      if (totalAdditionalSeats > 0) {
        seatsTotalPrice =
          subscriptionInfo.additionalSeats.price * totalAdditionalSeats;
        summary.push(
          `Additional seats: ${priceDisplay(
            subscriptionInfo.additionalSeats.price,
            subscriptionInfo.additionalSeats.currency
          )} x ${totalAdditionalSeats} seats`
        );
      }
    }
    return {
      amount: subscriptionInfo.planInfo.price + seatsTotalPrice,
      dateString: format(
        apiUTCToLocalDateTime(subscriptionInfo.planTill),
        "MMM dd, yyyy"
      ),
      summary,
    };
  }, [
    seatsUpdateState,
    subscriptionInfo.additionalSeats,
    subscriptionInfo.planInfo,
    subscriptionInfo.planTill,
  ]);
  if (!subscriptionInfo.additionalSeats || !subscriptionInfo.planInfo) {
    return null;
  }
  return (
    <PaymentSummarySideBar
      items={items}
      currency={subscriptionInfo.planInfo.currency}
      onConfirm={handleConfirm}
      isSubmitting={isUpdateSeatsInProgress}
      totalLabel="Total for now"
      summaryItem={
        nextMonthPaymentInfo
          ? {
              title: `Then for each ${subscriptionInfo.planInfo.interval}`,
              subtitle: nextMonthPaymentInfo.dateString,
              amount: nextMonthPaymentInfo.amount,
              summary: nextMonthPaymentInfo.summary,
            }
          : undefined
      }
    />
  );
};
