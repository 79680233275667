import { TasksPageLayout } from "@web-src/components/TasksPageLayout";
import { useTranslations, cx } from "@jugl-web/utils";
import { useEffect, useState } from "react";
import { ExpandableSearchBar } from "@jugl-web/ui-components/web/ExpandableSearchBar";
import { PlainButton } from "@jugl-web/ui-components";
import { ControlBarButton } from "@jugl-web/ui-components/web/ControlBarButton";
import { useTaskFiltering } from "@jugl-web/domain-resources/tasks/components/TaskFilteringProvider";
import { useTaskFiltersStateContext } from "@jugl-web/domain-resources/tasks/hooks/useTaskFiltersState";
import { Tab } from "@web-src/modules/common/components/TabsLayout/components/Tab";
import useDebounce from "@web-src/hooks/useDebounce";
import { ReactComponent as FilterIcon } from "./assets/filter.svg";
import { ReactComponent as ResetFiltersIcon } from "./assets/reset-filters.svg";
import { TaskArchiveFiltersDialog } from "../TaskArchiveFiltersDialog";

export const TasksArchiveControlBar = ({
  onUpdate,
}: {
  onUpdate: (resetSearchQuery?: boolean, resetFilterState?: boolean) => void;
}) => {
  const { t } = useTranslations();
  const [isTasksFiltersDialogOpen, setIsTasksFiltersDialogOpen] =
    useState(false);

  const { searchQuery, changeSearchQuery } = useTaskFiltering();
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const { activeFiltersCount, resetFiltersState } =
    useTaskFiltersStateContext();

  useEffect(() => {
    const shouldReset = !debouncedSearchQuery;
    onUpdate(shouldReset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery]);

  return (
    <TasksPageLayout.ControlBar className="border-grey-300 border-[1px] border-x-0 border-t-0 border-solid">
      <div className="flex h-full w-full justify-between gap-1">
        <span className="flex flex-row items-end gap-8">
          <Tab
            title={t({
              id: "tasks-page.archived",
              defaultMessage: "Archived",
            })}
            isSelected
            className="font-secondary"
          />
          <ControlBarButton
            isActive={activeFiltersCount > 0}
            onClick={() => setIsTasksFiltersDialogOpen(true)}
            className="mt-1 self-center"
            startSlot={
              <span className="relative flex items-center justify-center gap-2">
                <FilterIcon className="fill-grey" />
                {(activeFiltersCount && (
                  <div
                    className={cx(
                      "bg-gradients-success absolute -right-0.5 -top-0.5 h-2.5 w-2.5 rounded-full border-2 border-solid border-white"
                    )}
                  />
                )) ||
                  ""}
              </span>
            }
            endSlot={
              activeFiltersCount ? (
                <PlainButton
                  className="hover:bg-grey-300 rounded-full bg-[#EEF2F5] transition-colors"
                  onClick={(event) => {
                    event.stopPropagation();
                    resetFiltersState();
                    onUpdate(false, true);
                  }}
                >
                  <ResetFiltersIcon />
                </PlainButton>
              ) : undefined
            }
          />
        </span>
        <div className="mt-1 flex h-full items-center">
          <ExpandableSearchBar
            color="white"
            size="lg"
            isAlwaysExpanded
            onSearch={(query) => {
              changeSearchQuery(query);
            }}
            defaultValue={searchQuery}
            onReset={() => onUpdate(true)}
          />
        </div>
      </div>
      <TaskArchiveFiltersDialog
        isOpen={isTasksFiltersDialogOpen}
        onDialogClose={() => {
          setIsTasksFiltersDialogOpen(false);
          onUpdate();
        }}
      />
    </TasksPageLayout.ControlBar>
  );
};
