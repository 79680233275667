import { useAppVariant, useTranslations } from "@jugl-web/utils";
import { useCallback, useMemo } from "react";
import { useTaskFields } from "./useTaskFields";

export enum TaskViewType {
  default = "default",
  status = "status",
  date = "date",
  labels = "labels",
  priority = "priority",
  customDropdownField = "customDropdownField",
  templates = "templates",
  assignee = "assignee",
  reportee = "reportee",
  customer = "customer",
}

export type TaskView = {
  id: string;
  title: string;
  isNotSelectableByUser?: boolean;
  customDropdownFieldId?: string;
} & (
  | { type: Exclude<TaskViewType, "customDropdownField"> }
  | { type: TaskViewType.customDropdownField; customDropdownFieldId: string }
);

type TasksViewsHookType = {
  entityId?: string;
  isCustomerMode?: boolean;
};

export const useTasksViews = ({
  entityId,
  isCustomerMode,
}: TasksViewsHookType) => {
  const { customFields } = useTaskFields({ entityId });

  const { isMobile } = useAppVariant();
  const { t } = useTranslations();

  const customDropdownFields = useMemo(
    () => customFields?.filter((item) => item.type === "dropdown"),
    [customFields]
  );

  const defaultViews = useMemo<TaskView[]>(
    () => [
      {
        id: TaskViewType.default,
        title: t({
          id: "tasks-page.default-view",
          defaultMessage: "Default View",
        }),
        type: TaskViewType.default,
      },
      {
        id: TaskViewType.templates,
        title: t({
          id: "tasks-page.task-templates-view",
          defaultMessage: "Task templates",
        }),
        type: TaskViewType.templates,
        isNotSelectableByUser: !isMobile,
      },
      {
        id: TaskViewType.date,
        title: t({
          id: "tasks-page.date-view",
          defaultMessage: "Date View",
        }),
        type: TaskViewType.date,
      },
      {
        id: TaskViewType.labels,
        title: t({
          id: "tasks-page.labels-view",
          defaultMessage: "Labels View",
        }),
        type: TaskViewType.labels,
      },
      {
        id: TaskViewType.priority,
        title: t({
          id: "tasks-page.priority-view",
          defaultMessage: "Priority View",
        }),
        type: TaskViewType.priority,
      },
      {
        id: TaskViewType.status,
        title: t({
          id: "tasks-page.status-view",
          defaultMessage: "Status View",
        }),
        type: TaskViewType.status,
      },
      {
        id: TaskViewType.assignee,
        title: t({
          id: "tasks-page.assignee-view",
          defaultMessage: "Assignee View",
        }),
        type: TaskViewType.assignee,
      },
      {
        id: TaskViewType.reportee,
        title: t({
          id: "tasks-page.reportee-view",
          defaultMessage: "Reportee View",
        }),
        type: TaskViewType.reportee,
        isNotSelectableByUser: true,
      },
      ...(!isCustomerMode
        ? [
            {
              id: TaskViewType.customer,
              title: t({
                id: "tasks-page.customer-view",
                defaultMessage: "Customer View",
              }),
              type: TaskViewType.customer,
            } as TaskView,
          ]
        : []),
    ],
    [isMobile, t, isCustomerMode]
  );

  const views: TaskView[] = useMemo(
    () => [
      ...defaultViews,
      ...customDropdownFields.map((item) => ({
        id: item.id,
        title: item.name,
        type: TaskViewType.customDropdownField,
        customDropdownFieldId: item.id,
      })),
    ],
    [customDropdownFields, defaultViews]
  );

  const accessibleViews = useMemo(
    () => views.filter((view) => !view.isNotSelectableByUser),
    [views]
  );

  const getViewById = useCallback(
    (id: string) => {
      const foundView = views.find((item) => item.id === id);

      if (!foundView) {
        // eslint-disable-next-line no-console
        console.warn(`View with id: "${id}" not found`);
        return views[0];
      }

      return foundView;
    },
    [views]
  );

  return { accessibleViews, getViewById };
};
