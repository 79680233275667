import { useRestApiProvider } from "@jugl-web/rest-api";
import { LoadingAnimation } from "@jugl-web/ui-components";
import { assert, useTranslations } from "@jugl-web/utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { FC } from "react";
import { TaskFormDialog } from "../TaskFormDialog";
import { useTasksPageContext } from "../TasksPageContext";
import { FutureTaskDialogContent } from "./components/FutureTaskDialogContent";

interface FutureTaskDialogProps {
  entityId: string;
  meId: string;
}

export const FutureTaskDialog: FC<FutureTaskDialogProps> = ({
  entityId,
  meId,
}) => {
  const { futureTaskDialogState, closeFutureTaskDialog } =
    useTasksPageContext();

  const { t } = useTranslations();

  const { isOpen, task: futureTask } = futureTaskDialogState;

  const { tasksApi } = useRestApiProvider();

  const { currentData: sourceTask, isLoading } = tasksApi.useGetTaskQuery(
    (() => {
      if (futureTask) {
        assert(!!futureTask._future_task, "Task has to be a future task");
        return { taskId: futureTask._future_task.source_task_id, entityId };
      }

      return skipToken;
    })()
  );

  return (
    <TaskFormDialog
      header={{
        type: "title",
        title: t({
          id: "tasks-page.recurring-task",
          defaultMessage: "Recurring task",
        }),
      }}
      isOpen={isOpen}
      onClose={closeFutureTaskDialog}
    >
      {isLoading || !futureTask || !sourceTask ? (
        <TaskFormDialog.Content className="flex items-center justify-center">
          <LoadingAnimation />
        </TaskFormDialog.Content>
      ) : (
        <FutureTaskDialogContent
          entityId={entityId}
          meId={meId}
          futureTask={futureTask}
          sourceTask={sourceTask}
        />
      )}
    </TaskFormDialog>
  );
};
