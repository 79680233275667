import { useTaskFields } from "@jugl-web/domain-resources/tasks/hooks/useTaskFields";
import { FC, useMemo } from "react";
import { AssigneeFilterSection } from "./components/AssigneeFilterSection";
import { CustomFieldFilterSection } from "./components/CustomFieldFilterSection";
import { CustomerFilterSection } from "./components/CustomerFilterSection";
import { LabelFilterSection } from "./components/LabelFilterSection";
import { PriorityFilterSection } from "./components/PriorityFilterSection";
import { StatusFilterSection } from "./components/StatusFilterSection";
import { TypeFilterSection } from "./components/TypeFilterSection";
import { useTasksPageContext } from "../TasksPageContext";

export type FilterTypes =
  | "type"
  | "label"
  | "priority"
  | "status"
  | "assignee"
  | "customer";

interface TaskFilterSectionsProps {
  entityId: string;
  visibleFilterSections?: FilterTypes[];
  hideCustomFields?: boolean;
}

export const TaskFilterSections: FC<TaskFilterSectionsProps> = ({
  entityId,
  visibleFilterSections,
  hideCustomFields,
}) => {
  const { customFields } = useTaskFields({ entityId });
  const { customerContext } = useTasksPageContext();
  const dropdownCustomFields = useMemo(
    () => customFields.filter((customField) => customField.type === "dropdown"),
    [customFields]
  );
  const filterSections = useMemo(() => {
    if (!visibleFilterSections) {
      return [
        TypeFilterSection,
        LabelFilterSection,
        PriorityFilterSection,
        StatusFilterSection,
        AssigneeFilterSection,
        ...(!customerContext ? [CustomerFilterSection] : []),
      ];
    }
    const filters = {
      type: TypeFilterSection,
      label: LabelFilterSection,
      priority: PriorityFilterSection,
      status: StatusFilterSection,
      assignee: AssigneeFilterSection,
      customer: CustomerFilterSection,
    };
    return visibleFilterSections?.map((filter) => filters[filter]);
  }, [visibleFilterSections, customerContext]);
  return (
    <div className="flex flex-col">
      {filterSections.map((FilterSectionComponent) => (
        <FilterSectionComponent
          key={FilterSectionComponent.name}
          entityId={entityId}
        />
      ))}
      {!hideCustomFields &&
        dropdownCustomFields.map((customField) => (
          <CustomFieldFilterSection
            key={customField.id}
            customField={customField}
            entityId={entityId}
          />
        ))}
    </div>
  );
};
