import { EntityState } from "@reduxjs/toolkit";
import { PatchCollection } from "@reduxjs/toolkit/dist/query/core/buildThunks";
import {
  PaginatedRequestParams,
  PaginatedResponse,
  RtkEmptySplitApi,
} from "../../types";
import { TasksApiTag } from "./tags";
import {
  ChecklistSummary,
  CreateTaskCustomFieldPayload,
  CreateTaskPayload,
  DetailedTask,
  InternalTaskFilters,
  PreviewTask,
  SelfProducedTaskActivity,
  TaskActivity,
  TaskChecklistItem,
  TaskCustomStatus,
  TaskFields,
  TaskFilterSet,
  TaskLabel,
  TasksInitData,
  UpdateTaskCustomFieldPayload,
  UpdateTaskResponse,
} from "./types";
import {
  adaptDetailedTaskToPreviewTask,
  adaptInternalTaskFiltersToTaskFilters,
  adaptTaskCustomFieldsToInternalCustomFields,
  adaptTaskFilterSetToInternalTaskFilterSet,
  getEntityTasksIdTag,
  getTaskIdTag,
  previewTasksAdapter,
  filterTasksWithoutName,
  sortCollectionByOrder,
  stripTaskDescriptionPrefix,
  withPrefixedTaskDescription,
} from "./utils";
import { GuestTaskDataDto } from "./models/GuestTaskData/GuestTaskDataDto";
import { GuestTaskDataModel } from "./models/GuestTaskData";
import { transformGuestDataDtoToModel } from "./models/GuestTaskData/transformGuestTaskDataDtoToModel";

export const addTasksApi = (emptySplitApi: RtkEmptySplitApi) => {
  const apiWithTags = emptySplitApi.enhanceEndpoints({
    addTagTypes: [TasksApiTag.task, TasksApiTag.taskActivity],
  });

  const tasksApi = apiWithTags.injectEndpoints({
    endpoints: (builder) => ({
      // #region tasks
      getTasks: builder.query<EntityState<PreviewTask>, { entityId: string }>({
        query: ({ entityId }) => ({
          url: `/api/auth/entity/${entityId}/v2/task`,
        }),
        transformResponse: (response: PreviewTask[]) =>
          previewTasksAdapter.addMany(
            previewTasksAdapter.getInitialState(),
            filterTasksWithoutName(response)
          ),
        providesTags: (_result, _error, { entityId }) => [
          getEntityTasksIdTag(entityId),
        ],
      }),

      getTeamTasks: builder.query<
        EntityState<PreviewTask>,
        { entityId: string }
      >({
        query: ({ entityId }) => ({
          url: `/api/auth/entity/${entityId}/v2/task`,
          params: { view: "team" },
        }),
        transformResponse: (response: PreviewTask[]) =>
          previewTasksAdapter.addMany(
            previewTasksAdapter.getInitialState(),
            filterTasksWithoutName(response)
          ),
        providesTags: (_result, _error, { entityId }) => [
          getEntityTasksIdTag(entityId),
        ],
      }),

      getTask: builder.query<
        DetailedTask,
        { entityId: string; taskId: string; noCache?: true }
      >({
        query: ({ entityId, taskId }) => ({
          url: `/api/auth/entity/${entityId}/task/${taskId}`,
          silentError: true,
        }),
        transformResponse: (task: DetailedTask) => {
          if (task.due_at && !task.due_at?.endsWith("Z")) {
            task.due_at = `${task.due_at}Z`;
          }

          if (task.desc) {
            task.desc = stripTaskDescriptionPrefix(task.desc);
          }

          return task;
        },
        providesTags: (_result, _error, { taskId }) => [getTaskIdTag(taskId)],
      }),

      markTaskAsViewed: builder.mutation<
        null,
        { entityId: string; taskId: string }
      >({
        query: ({ entityId, taskId }) => ({
          url: `/api/auth/entity/${entityId}/task/${taskId}`,
          params: { user_viewed: true },
          silentError: true,
        }),
      }),

      sendUpdateToCustomer: builder.mutation<
        null,
        { entityId: string; taskId: string }
      >({
        query: ({ entityId, taskId }) => ({
          method: "GET",
          url: `/api/auth/entity/${entityId}/v2/task/share_link/${taskId}`,
        }),
      }),
      invalidateTask: builder.mutation<
        null,
        { entityId: string; taskId: string }
      >({
        queryFn: () => ({ data: null }),
        invalidatesTags: (_result, _error, { taskId }) => [
          getTaskIdTag(taskId),
          { type: TasksApiTag.taskActivity, id: taskId },
        ],
      }),

      createTask: builder.mutation<
        DetailedTask,
        {
          entityId: string;
          task: CreateTaskPayload;
          isInTeamTasksContext?: boolean;
        }
      >({
        query: ({ entityId, task }) => ({
          url: `/api/auth/entity/${entityId}/task`,
          method: "POST",
          data: withPrefixedTaskDescription(task),
        }),
        onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
          try {
            const response = await queryFulfilled;

            if (args.isInTeamTasksContext) {
              const updateTeamTasksCollectionAction =
                tasksApi.util.updateQueryData(
                  "getTeamTasks",
                  { entityId: args.entityId },
                  (state) => {
                    previewTasksAdapter.addOne(
                      state,
                      adaptDetailedTaskToPreviewTask(response.data)
                    );
                  }
                );

              dispatch(updateTeamTasksCollectionAction);
            } else {
              const updateTasksCollectionAction = tasksApi.util.updateQueryData(
                "getTasks",
                { entityId: args.entityId },
                (state) => {
                  previewTasksAdapter.addOne(
                    state,
                    adaptDetailedTaskToPreviewTask(response.data)
                  );
                }
              );

              dispatch(updateTasksCollectionAction);
            }
          } catch {
            // Do nothing
          }
        },
      }),

      updateTask: builder.mutation<
        UpdateTaskResponse,
        {
          entityId: string;
          taskId: string;
          updatedTask: Partial<DetailedTask>;
          isInTeamTasksContext?: boolean;
        }
      >({
        query: ({ entityId, taskId, updatedTask }) => ({
          url: `/api/auth/entity/${entityId}/task/${taskId}`,
          method: "PUT",
          data: withPrefixedTaskDescription(updatedTask),
        }),
        onQueryStarted: (args, { dispatch, queryFulfilled }) => {
          const internalTaskChanges: Partial<DetailedTask> = (() => {
            const internalUpdatedAt = new Date().toISOString().split(".")[0];

            const changes: Partial<DetailedTask> = {
              ...args.updatedTask,
              updated_at: internalUpdatedAt,
            };

            if (args.updatedTask.recurrence === null) {
              return Object.assign<
                Partial<DetailedTask>,
                Partial<DetailedTask>
              >(changes, { recurrences: [] });
            }

            return changes;
          })();

          // Update UI optimistically with self-produced data
          const detailedTaskPatchResult = dispatch(
            tasksApi.util.updateQueryData(
              "getTask",
              { entityId: args.entityId, taskId: args.taskId },
              (taskDraft) => {
                Object.assign(taskDraft, internalTaskChanges);
              }
            )
          );

          let previewTasksPatchResult: PatchCollection;

          if (args.isInTeamTasksContext) {
            previewTasksPatchResult = dispatch(
              tasksApi.util.updateQueryData(
                "getTeamTasks",
                { entityId: args.entityId },
                (state) => {
                  previewTasksAdapter.updateOne(state, {
                    id: args.taskId,
                    changes: internalTaskChanges,
                  });
                }
              )
            );
          } else {
            previewTasksPatchResult = dispatch(
              tasksApi.util.updateQueryData(
                "getTasks",
                { entityId: args.entityId },
                (state) => {
                  previewTasksAdapter.updateOne(state, {
                    id: args.taskId,
                    changes: internalTaskChanges,
                  });
                }
              )
            );
          }

          queryFulfilled
            // Rollback optimistic updates if the request fails
            .catch(() => {
              detailedTaskPatchResult.undo();
              previewTasksPatchResult.undo();
            });
        },
      }),

      deleteTask: builder.mutation<void, { entityId: string; taskId: string }>({
        query: ({ entityId, taskId }) => ({
          url: `/api/auth/entity/${entityId}/task/${taskId}`,
          method: "DELETE",
        }),
      }),

      deleteTaskAttachment: builder.mutation<
        void,
        { entityId: string; taskId: string; attachmentId: string }
      >({
        query: ({ entityId, attachmentId }) => ({
          url: `/api/auth/drive/entity/${entityId}/${attachmentId}`,
          method: "DELETE",
        }),
        onQueryStarted: (args, { dispatch, queryFulfilled }) => {
          const patchResult = dispatch(
            tasksApi.util.updateQueryData(
              "getTask",
              { entityId: args.entityId, taskId: args.taskId },
              (taskDraft) => {
                taskDraft.attachments = taskDraft.attachments.filter(
                  (attachment) => attachment.id !== args.attachmentId
                );
              }
            )
          );

          queryFulfilled.catch(patchResult.undo);
        },
        invalidatesTags: (_result, _error, { taskId }) => [
          { type: TasksApiTag.taskActivity, id: taskId },
        ],
      }),

      renameTaskAttachment: builder.mutation<
        void,
        {
          entityId: string;
          taskId: string;
          attachmentId: string;
          attachmentName: string;
        }
      >({
        query: ({ entityId, taskId, attachmentId, attachmentName }) => ({
          url: `/api/auth/drive/entity/${entityId}/rename`,
          method: "PUT",
          data: {
            name: attachmentName,
            taskId,
            id: attachmentId,
          },
        }),
        onQueryStarted: (args, { dispatch, queryFulfilled }) => {
          const patchResult = dispatch(
            tasksApi.util.updateQueryData(
              "getTask",
              { entityId: args.entityId, taskId: args.taskId },
              (taskDraft) => {
                const foundAttachment = taskDraft.attachments.find(
                  (attachment) => attachment.id === args.attachmentId
                );

                if (foundAttachment) {
                  foundAttachment.name = args.attachmentName;
                }
              }
            )
          );

          queryFulfilled.catch(patchResult.undo);
        },
        invalidatesTags: (_result, _error, { taskId }) => [
          { type: TasksApiTag.taskActivity, id: taskId },
        ],
      }),

      readTask: builder.mutation<null, { entityId: string; taskId: string }>({
        queryFn: () => ({ data: null }),
        onQueryStarted: ({ entityId, taskId }, { dispatch }) => {
          dispatch(
            tasksApi.util.updateQueryData("getTasks", { entityId }, (state) => {
              previewTasksAdapter.updateOne(state, {
                id: taskId,
                changes: { unread: false },
              });
            })
          );
        },
      }),
      // #endregion

      // #region task checklist
      addChecklistItem: builder.mutation<
        {
          checklist_item: TaskChecklistItem;
          activity: SelfProducedTaskActivity;
        },
        {
          entityId: string;
          taskId: string;
          item: Pick<
            TaskChecklistItem,
            "due_at" | "name" | "order" | "user_id"
          >;
        }
      >({
        query: ({ entityId, taskId, item }) => ({
          url: `/api/auth/entity/${entityId}/task/${taskId}/subtask`,
          method: "POST",
          data: item,
        }),
        onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
          try {
            const response = await queryFulfilled;

            dispatch(
              tasksApi.util.updateQueryData(
                "getTask",
                { entityId: args.entityId, taskId: args.taskId },
                (taskDraft) => {
                  taskDraft.checklist.push(response.data.checklist_item);
                }
              )
            );
          } catch {
            // Do nothing
          }
        },
      }),

      updateChecklistItem: builder.mutation<
        {
          checklist_item: TaskChecklistItem;
          activity: SelfProducedTaskActivity;
        },
        {
          entityId: string;
          taskId: string;
          itemId: string;
          attributes: Partial<Omit<TaskChecklistItem, "completed_at" | "id">>;
        }
      >({
        query: ({ entityId, taskId, itemId, attributes }) => ({
          url: `/api/auth/entity/${entityId}/task/${taskId}/subtask/${itemId}`,
          method: "PUT",
          data: attributes,
        }),
        onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
          // First, update the UI optimistically
          const patchResult = dispatch(
            tasksApi.util.updateQueryData(
              "getTask",
              { entityId: args.entityId, taskId: args.taskId },
              (taskDraft) => {
                const itemToUpdate = taskDraft.checklist.find(
                  (item) => item.id === args.itemId
                );

                if (itemToUpdate) {
                  Object.assign(itemToUpdate, args.attributes);
                }
              }
            )
          );

          queryFulfilled
            .then((response) => {
              // Then, update the UI with the actual server data
              dispatch(
                tasksApi.util.updateQueryData(
                  "getTask",
                  { entityId: args.entityId, taskId: args.taskId },
                  (taskDraft) => {
                    const itemToUpdate = taskDraft.checklist.find(
                      (item) => item.id === args.itemId
                    );

                    if (itemToUpdate) {
                      Object.assign<TaskChecklistItem, TaskChecklistItem>(
                        itemToUpdate,
                        response.data.checklist_item
                      );
                    }
                  }
                )
              );
            })
            .catch(patchResult.undo);
        },
      }),

      deleteChecklistItem: builder.mutation<
        { activity: SelfProducedTaskActivity },
        {
          entityId: string;
          taskId: string;
          itemId: string;
        }
      >({
        query: ({ entityId, taskId, itemId }) => ({
          url: `/api/auth/entity/${entityId}/task/${taskId}/subtask/${itemId}`,
          method: "DELETE",
        }),
        onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
          const patchResult = dispatch(
            tasksApi.util.updateQueryData(
              "getTask",
              { entityId: args.entityId, taskId: args.taskId },
              (taskDraft) => {
                const itemIndexToDelete = taskDraft.checklist.findIndex(
                  (item) => item.id === args.itemId
                );

                if (itemIndexToDelete > -1) {
                  taskDraft.checklist.splice(itemIndexToDelete, 1);
                }
              }
            )
          );

          queryFulfilled.catch(patchResult.undo);
        },
      }),
      // #endregion

      // #region task fields
      getTaskFields: builder.query<TaskFields, { entityId: string }>({
        query: ({ entityId }) => ({
          url: `/api/auth/entity/${entityId}/v2/task/init`,
        }),
        transformResponse: (response: TasksInitData) => ({
          customFieldsData: {
            id: response.custom_fields.id,
            items: adaptTaskCustomFieldsToInternalCustomFields(
              sortCollectionByOrder(response.custom_fields.props.value)
            ),
          },
          labels: sortCollectionByOrder(response.labels),
          customStatuses: sortCollectionByOrder(response.custom_statuses),
          filterSets: response.filter_sets.map(
            adaptTaskFilterSetToInternalTaskFilterSet
          ),
        }),
      }),

      createTaskCustomField: builder.mutation<
        TasksInitData["custom_fields"],
        {
          entityId: string;
          customFieldsId: string;
          customField: CreateTaskCustomFieldPayload;
        }
      >({
        query: ({ entityId, customFieldsId, customField }) => ({
          url: `/api/auth/entity/${entityId}/v2/task/custom_fields/${customFieldsId}/append`,
          method: "POST",
          data: { props: { value: [customField] } },
          silentError: true,
        }),
        onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
          try {
            const { data } = await queryFulfilled;

            dispatch(
              tasksApi.util.updateQueryData(
                "getTaskFields",
                { entityId: args.entityId },
                (taskFieldsDraft) => {
                  taskFieldsDraft.customFieldsData.items =
                    adaptTaskCustomFieldsToInternalCustomFields(
                      data.props.value
                    );
                }
              )
            );
          } catch {
            // Do nothing
          }
        },
      }),

      updateTaskCustomFields: builder.mutation<
        TasksInitData["custom_fields"],
        {
          entityId: string;
          customFieldsId: string;
          customFields: UpdateTaskCustomFieldPayload[];
        }
      >({
        query: ({ entityId, customFieldsId, customFields }) => ({
          url: `/api/auth/entity/${entityId}/v2/task/custom_fields/${customFieldsId}`,
          method: "PUT",
          data: { props: { value: customFields } },
        }),
        onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
          const patchResult = dispatch(
            tasksApi.util.updateQueryData(
              "getTaskFields",
              { entityId: args.entityId },
              (taskFieldsDraft) => {
                taskFieldsDraft.customFieldsData.items =
                  adaptTaskCustomFieldsToInternalCustomFields(
                    args.customFields
                  );
              }
            )
          );

          queryFulfilled.catch(patchResult.undo);
        },
      }),

      createTaskLabel: builder.mutation<
        TaskLabel,
        {
          entityId: string;
          label: Pick<TaskLabel, "text" | "color" | "order">;
        }
      >({
        query: ({ entityId, label }) => ({
          url: `/api/auth/entity/${entityId}/labels`,
          method: "POST",
          data: label,
        }),
        onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
          try {
            const { data: newLabel } = await queryFulfilled;

            dispatch(
              tasksApi.util.updateQueryData(
                "getTaskFields",
                { entityId: args.entityId },
                (taskFieldsDraft) => {
                  taskFieldsDraft.labels.push(newLabel);
                }
              )
            );
          } catch {
            // Do nothing
          }
        },
      }),

      updateTaskLabel: builder.mutation<
        TaskLabel,
        { entityId: string; label: TaskLabel }
      >({
        query: ({ entityId, label }) => {
          const { id: labelId, ...labelWithoutId } = label;

          return {
            url: `/api/auth/entity/${entityId}/labels/${labelId}`,
            method: "PUT",
            data: labelWithoutId,
          };
        },
        onQueryStarted: (args, { dispatch, queryFulfilled }) => {
          const patchResult = dispatch(
            tasksApi.util.updateQueryData(
              "getTaskFields",
              { entityId: args.entityId },
              (taskFieldsDraft) => {
                const labelIndexToUpdate = taskFieldsDraft.labels.findIndex(
                  (label) => label.id === args.label.id
                );

                taskFieldsDraft.labels[labelIndexToUpdate] = args.label;
              }
            )
          );

          queryFulfilled.catch(patchResult.undo);
        },
      }),

      updateTaskLabelsInBulk: builder.mutation<
        { data: TaskLabel[] },
        { entityId: string; labels: TaskLabel[] }
      >({
        query: ({ entityId, labels }) => ({
          url: `/api/auth/entity/${entityId}/labels`,
          method: "PUT",
          data: { labels },
        }),
        onQueryStarted: (args, { dispatch, queryFulfilled }) => {
          const patchResult = dispatch(
            tasksApi.util.updateQueryData(
              "getTaskFields",
              { entityId: args.entityId },
              (taskFieldsDraft) => {
                taskFieldsDraft.labels = args.labels;
              }
            )
          );

          queryFulfilled.catch(patchResult.undo);
        },
      }),

      deleteTaskLabel: builder.mutation<
        void,
        { entityId: string; labelId: string }
      >({
        query: ({ entityId, labelId }) => ({
          url: `/api/auth/entity/${entityId}/labels/${labelId}`,
          method: "DELETE",
        }),
        onQueryStarted: (args, { dispatch, queryFulfilled }) => {
          const patchResult = dispatch(
            tasksApi.util.updateQueryData(
              "getTaskFields",
              { entityId: args.entityId },
              (taskFieldsDraft) => {
                const labelIndexToDelete = taskFieldsDraft.labels.findIndex(
                  (label) => label.id === args.labelId
                );

                if (labelIndexToDelete > -1) {
                  taskFieldsDraft.labels.splice(labelIndexToDelete, 1);
                }
              }
            )
          );

          queryFulfilled.catch(patchResult.undo);
        },
      }),

      createTaskCustomStatus: builder.mutation<
        TaskCustomStatus[],
        { entityId: string; customStatus: { text: string; order: number } }
      >({
        query: ({ entityId, customStatus }) => ({
          url: `/api/auth/entity/${entityId}/v2/task/custom_statuses`,
          method: "POST",
          data: { statuses: [customStatus] },
        }),
        onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
          try {
            const { data: customStatuses } = await queryFulfilled;

            dispatch(
              tasksApi.util.updateQueryData(
                "getTaskFields",
                { entityId: args.entityId },
                (taskFieldsDraft) => {
                  taskFieldsDraft.customStatuses = customStatuses;
                }
              )
            );
          } catch {
            // Do nothing
          }
        },
      }),

      updateTaskCustomStatuses: builder.mutation<
        TaskCustomStatus[],
        { entityId: string; customStatuses: TaskCustomStatus[] }
      >({
        query: ({ entityId, customStatuses }) => ({
          url: `/api/auth/entity/${entityId}/v2/task/custom_statuses`,
          method: "PUT",
          data: { statuses: customStatuses },
        }),
        onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
          const patchResult = dispatch(
            tasksApi.util.updateQueryData(
              "getTaskFields",
              { entityId: args.entityId },
              (taskFieldsDraft) => {
                taskFieldsDraft.customStatuses = args.customStatuses;
              }
            )
          );

          queryFulfilled.catch(patchResult.undo);
        },
      }),

      deleteTaskCustomStatus: builder.mutation<
        void,
        { entityId: string; statusId: string }
      >({
        query: ({ entityId, statusId }) => ({
          url: `/api/auth/entity/${entityId}/v2/task/custom_statuses/${statusId}`,
          method: "DELETE",
        }),
        onQueryStarted: (args, { dispatch, queryFulfilled }) => {
          const patchResult = dispatch(
            tasksApi.util.updateQueryData(
              "getTaskFields",
              { entityId: args.entityId },
              (taskFieldsDraft) => {
                const statusIndexToDelete =
                  taskFieldsDraft.customStatuses.findIndex(
                    (status) => status.id === args.statusId
                  );

                if (statusIndexToDelete > -1) {
                  taskFieldsDraft.customStatuses.splice(statusIndexToDelete, 1);
                }
              }
            )
          );

          queryFulfilled.catch(patchResult.undo);
        },
      }),

      createTaskFilterSet: builder.mutation<
        TaskFilterSet,
        { entityId: string; name: string; filters: InternalTaskFilters }
      >({
        query: ({ entityId, name, filters }) => ({
          url: `/api/auth/entity/${entityId}/task_filter_sets`,
          method: "POST",
          data: {
            name,
            ...adaptInternalTaskFiltersToTaskFilters(filters),
          },
        }),
        onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
          try {
            const { data: newFilterSet } = await queryFulfilled;

            dispatch(
              tasksApi.util.updateQueryData(
                "getTaskFields",
                { entityId: args.entityId },
                (taskFieldsDraft) => {
                  taskFieldsDraft.filterSets.push(
                    adaptTaskFilterSetToInternalTaskFilterSet(newFilterSet)
                  );
                }
              )
            );
          } catch {
            // Do nothing
          }
        },
      }),

      updateTaskFilterSet: builder.mutation<
        TaskFilterSet,
        {
          entityId: string;
          filterSetId: string;
          name: string;
          filters: InternalTaskFilters;
        }
      >({
        query: ({ entityId, filterSetId, name, filters }) => ({
          url: `/api/auth/entity/${entityId}/task_filter_sets/${filterSetId}`,
          method: "PUT",
          data: {
            name,
            ...adaptInternalTaskFiltersToTaskFilters(filters),
          },
        }),
        onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
          try {
            const { data: updatedFilterSet } = await queryFulfilled;

            dispatch(
              tasksApi.util.updateQueryData(
                "getTaskFields",
                { entityId: args.entityId },
                (taskFieldsDraft) => {
                  const filterSetIndexToUpdate =
                    taskFieldsDraft.filterSets.findIndex(
                      (filterSet) => filterSet.id === args.filterSetId
                    );

                  if (filterSetIndexToUpdate > -1) {
                    taskFieldsDraft.filterSets[filterSetIndexToUpdate] =
                      adaptTaskFilterSetToInternalTaskFilterSet(
                        updatedFilterSet
                      );
                  }
                }
              )
            );
          } catch {
            // Do nothing
          }
        },
      }),

      deleteTaskFilterSet: builder.mutation<
        void,
        { entityId: string; filterSetId: string }
      >({
        query: ({ entityId, filterSetId }) => ({
          url: `/api/auth/entity/${entityId}/task_filter_sets/${filterSetId}`,
          method: "DELETE",
        }),
        onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
          try {
            await queryFulfilled;

            dispatch(
              tasksApi.util.updateQueryData(
                "getTaskFields",
                { entityId: args.entityId },
                (taskFieldsDraft) => {
                  const filterSetIndexToDelete =
                    taskFieldsDraft.filterSets.findIndex(
                      (filterSet) => filterSet.id === args.filterSetId
                    );

                  if (filterSetIndexToDelete > -1) {
                    taskFieldsDraft.filterSets.splice(
                      filterSetIndexToDelete,
                      1
                    );
                  }
                }
              )
            );
          } catch {
            // Do nothing
          }
        },
      }),
      // #endregion

      // #region task activities
      getTaskActivities: builder.query<
        PaginatedResponse<TaskActivity>,
        {
          entityId: string;
          params: PaginatedRequestParams<{ task_id: string; type: string }>;
        }
      >({
        query: ({ entityId, params }) => ({
          url: `/api/auth/entity/${entityId}/task_activities`,
          params,
          silentError: true,
        }),
        providesTags: (_result, _error, { params }) => [
          { type: TasksApiTag.taskActivity, id: params.task_id },
        ],
      }),
      // #endregion

      getChecklistSummary: builder.query<
        { data: ChecklistSummary[] },
        {
          entityId: string;
          params: {
            // timestamp
            from: string;
            // timestamp
            to: string;
            // list of user_ids separated by comma(,)
            user_id: string;
          };
        }
      >({
        query: ({ entityId, params = {} }) => ({
          url: `api/auth/entity/${entityId}/checklists_summary`,
          params,
        }),
      }),

      getGuestTask: builder.query<GuestTaskDataModel, { taskShortId: string }>({
        query: ({ taskShortId }) => ({
          url: `/api/auth/guest/task/${taskShortId}`,
        }),
        transformResponse: (dto: GuestTaskDataDto) =>
          transformGuestDataDtoToModel(dto),
        providesTags: [TasksApiTag.taskActivity],
      }),
      sendGuestTaskComment: builder.mutation<
        TaskActivity,
        {
          entityId: string;
          taskId: string;
          data: {
            content: string;
          };
        }
      >({
        query: ({ entityId, taskId, data: { content } }) => ({
          url: `/api/auth/entity/${entityId}/guest/task/${taskId}`,
          method: "POST",
          data: {
            activity_name: "guest_comment",
            content,
          },
        }),
        invalidatesTags: [TasksApiTag.taskActivity],
      }),
      updateGuestTaskComment: builder.mutation<
        TaskActivity,
        {
          entityId: string;
          taskId: string;
          commentId: string;
          data: {
            content: string;
          };
        }
      >({
        query: ({ entityId, taskId, commentId, data: { content } }) => ({
          url: `/api/auth/entity/${entityId}/guest/task/${taskId}/${commentId}`,
          method: "PUT",
          data: {
            activity_name: "guest_comment",
            content,
          },
        }),
        invalidatesTags: [TasksApiTag.taskActivity],
      }),
      deleteGuestTaskComment: builder.mutation<
        void,
        {
          entityId: string;
          taskId: string;
          commentId: string;
        }
      >({
        query: ({ entityId, taskId, commentId }) => ({
          url: `/api/auth/entity/${entityId}/guest/task/${taskId}/${commentId}`,
          method: "DELETE",
        }),
        invalidatesTags: [TasksApiTag.taskActivity],
      }),
      sendGuestFeedback: builder.mutation<
        TaskActivity,
        {
          entityId: string;
          taskId: string;
          data: {
            rating: number;
            hashtag: string[];
            feedback: string;
          };
        }
      >({
        query: ({ entityId, taskId, data }) => ({
          url: `/api/auth/entity/${entityId}/guest/task/${taskId}`,
          method: "POST",
          data: {
            activity_name: "guest_feedback",
            content: {
              ...data,
            },
          },
        }),
        invalidatesTags: [TasksApiTag.taskActivity],
      }),
      sendGuestTaskOpenedActivity: builder.mutation<
        TaskActivity,
        {
          entityId: string;
          taskId: string;
        }
      >({
        query: ({ entityId, taskId }) => ({
          url: `/api/auth/entity/${entityId}/guest/task/${taskId}`,
          method: "POST",
          data: {
            activity_name: "guest_opened",
          },
        }),
        invalidatesTags: [TasksApiTag.taskActivity],
      }),
    }),
    overrideExisting: false,
  });

  return tasksApi;
};

export type TasksApi = ReturnType<typeof addTasksApi>;
