import React from "react";
import classNames from "classnames";

import { ReactComponent as CloseIcon } from "./icons/close.svg";

export const Modal: React.FC<{
  title: string;
  onClose: () => void;
  children: React.ReactNode;
  footer?: React.ReactNode;
}> = ({ title, onClose, children, footer }) => (
  <>
    <div
      className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none"
      onClick={onClose}
      onKeyDown={(event) => {
        if (event.key === "Escape") {
          onClose();
        }
      }}
      role="presentation"
    >
      <div
        className="relative my-6 mx-auto  max-w-3xl"
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.stopPropagation()}
        role="presentation"
      >
        <div
          className={classNames(
            "relative flex max-h-[700px] w-full min-w-[400px] flex-col rounded-lg bg-white"
          )}
        >
          <div className="border-grey-200 flex h-[60px] items-center justify-between border-0 border-b border-solid p-5">
            <h3 className="text-xl font-medium">{title}</h3>
            <button
              type="button"
              onClick={onClose}
              className="border-0 bg-inherit"
            >
              <CloseIcon />
            </button>
          </div>
          <div className="m-5">{children}</div>
          {footer && (
            <div className="flex items-center px-5 pb-10">{footer}</div>
          )}
        </div>
      </div>
    </div>
    <div className="fixed inset-0 z-40 bg-black opacity-30" />
  </>
);

export default Modal;
