import { Alert } from "@jugl-web/ui-components";
import { useTranslations } from "@jugl-web/utils";

export const NavigationAlert: React.FC<{
  isOpen: boolean;
  onRequestClose: () => void;
  onConfirm: () => void;
}> = ({ isOpen, onConfirm, onRequestClose }) => {
  const { t } = useTranslations();
  return (
    <Alert
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={t({
        id: "order-form-create-page.discard-changes",
        defaultMessage: "Discard changes",
      })}
      content={t({
        id: "order-form-create-page.discard-changes-message",
        defaultMessage:
          "If you discard changes, entered Form info will not be saved",
      })}
      buttons={[
        {
          text: t({
            id: "common.cancel",
            defaultMessage: "Cancel",
          }),
          role: "close",
        },
        {
          text: t({
            id: "common.discard",
            defaultMessage: "Discard",
          }),
          color: "tertiary",
          onClick: onConfirm,
        },
      ]}
    />
  );
};
