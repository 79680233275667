import { useDroppable } from "@dnd-kit/core";
import { TaskColumnDroppableData } from "@jugl-web/domain-resources/tasks";
import { useTaskCardAdapter } from "@jugl-web/domain-resources/tasks/components/TaskCard";
import { TaskCard } from "@jugl-web/domain-resources/tasks/components/TaskCard/TaskCard";
import { useTaskFiltering } from "@jugl-web/domain-resources/tasks/components/TaskFilteringProvider";
import { TaskViewColumn } from "@jugl-web/domain-resources/tasks/hooks/useTasksViewColumns";
import { UserGeneralProfile } from "@jugl-web/domain-resources/users/components/UserGeneralProfile";
import { PreviewTask } from "@jugl-web/rest-api/tasks";
import { Avatar } from "@jugl-web/ui-components/cross-platform";
import { assert, cx, useTranslations } from "@jugl-web/utils";
import { selectUserId } from "@web-src/features/auth/authSlice";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { TasksColumnLayout } from "../TasksColumnLayout";
import { NewTaskColumnCard } from "./components/NewTaskColumnCard";
import { ReactComponent as EmptyListIcon } from "./icons/empty-list.svg";
import { ReactComponent as PlusIcon } from "./icons/plus.svg";
import { useTasksPageContext } from "../TasksPageContext";

export interface TasksColumnProps {
  column: TaskViewColumn;
  onTaskClick: (task: PreviewTask) => void;
}

export const TasksColumn: FC<TasksColumnProps> = ({ column, onTaskClick }) => {
  const [isNewTaskCardOpen, setOpenNewTaskCard] = useState<boolean>(false);

  const { entityId } = useEntitySelectedProvider();
  const meId = useSelector(selectUserId);
  const { customerContext } = useTasksPageContext();

  const { searchQuery } = useTaskFiltering();
  const { getTaskCardProps } = useTaskCardAdapter({
    entityId,
    customerId: customerContext?.customerId,
  });
  const { t } = useTranslations();

  const { isOver, setNodeRef, active } = useDroppable({
    id: column.id,
    disabled: !column.droppableData,
    data: column.droppableData satisfies TaskColumnDroppableData | undefined,
  });

  const isEmpty = column.items.length === 0;
  const canCreateTask = !!column.createTaskOptions;

  return (
    <TasksColumnLayout
      ref={setNodeRef}
      className={cx(
        isOver && "bg-blue-100",
        column.isOverdue ? "bg-red-100" : undefined
      )}
    >
      <TasksColumnLayout.Header
        onClick={canCreateTask ? () => setOpenNewTaskCard(true) : undefined}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2.5 overflow-hidden">
            {column.title.type === "user" && (
              <UserGeneralProfile
                entityId={entityId}
                userId={column.title.userId}
              >
                {({ safeProfile, profile }) => {
                  assert(
                    column.title.type === "user",
                    'Column title has to be of type "user"'
                  );

                  const isMe = meId === column.title.userId;
                  const isProfileLoaded = !!profile;

                  return (
                    <>
                      <Avatar
                        imageUrl={safeProfile.avatar}
                        username={safeProfile.displayName}
                        size="sm"
                      />
                      <span
                        className="truncate"
                        title={safeProfile.displayName}
                      >
                        {safeProfile.displayName}{" "}
                        {isMe &&
                          isProfileLoaded &&
                          `(${t({ id: "common.you", defaultMessage: "You" })})`}
                      </span>
                    </>
                  );
                }}
              </UserGeneralProfile>
            )}
            {column.title.type === "customer" && (
              <div className="flex items-center gap-2.5 overflow-hidden">
                <Avatar
                  imageUrl={column.title.avatar}
                  username={column.title.name}
                  size="sm"
                />
                <div className="flex flex-col overflow-hidden">
                  <span
                    className="truncate font-medium"
                    title={column.title.name}
                  >
                    {column.title.name}
                  </span>
                  <span
                    className="truncate text-xs font-normal text-[#828282]"
                    title={column.title.company}
                  >
                    {column.title.company ||
                      t({
                        id: "tasks-page.no-company-info",
                        defaultMessage: "No company info",
                      })}
                  </span>
                </div>
              </div>
            )}
            {column.title.type === "text" && (
              <span className="truncate" title={column.title.value}>
                {column.title.value}
              </span>
            )}
          </div>
          {canCreateTask && <PlusIcon className="shrink-0" />}
        </div>
      </TasksColumnLayout.Header>
      {isEmpty && !isNewTaskCardOpen ? (
        <TasksColumnLayout.EmptyMessage>
          <div className="flex items-center gap-2">
            <EmptyListIcon />{" "}
            {t({
              id: "tasks-page.no-tasks-added",
              defaultMessage: "No task added",
            })}
          </div>
        </TasksColumnLayout.EmptyMessage>
      ) : (
        <TasksColumnLayout.List isDragging={!!active}>
          {isNewTaskCardOpen && (
            <NewTaskColumnCard
              column={column}
              onClose={() => setOpenNewTaskCard(false)}
            />
          )}
          {column.items.map((task) => (
            <TaskCard
              key={task.id}
              className="shrink-0"
              highlightedText={searchQuery}
              onClick={() => onTaskClick(task)}
              columnId={column.id}
              draggable
              {...getTaskCardProps(task)}
            />
          ))}
        </TasksColumnLayout.List>
      )}
    </TasksColumnLayout>
  );
};
