import {
  isTaskOverdue,
  TaskDueDateState,
} from "@jugl-web/domain-resources/tasks";
import { DueDatePicker } from "@jugl-web/domain-resources/tasks/components/DueDatePicker";
import { TaskDefaultStatus } from "@jugl-web/rest-api/tasks";
import { Popover } from "@jugl-web/ui-components/cross-platform";
import { cx, isoToLocalDate } from "@jugl-web/utils";
import { useFormattedDate } from "@jugl-web/utils/hooks/useFormattedDate";
import { FC } from "react";
import { TaskTableCellComponentProps } from "../../types";
import { TaskTableLayout } from "../TaskTableLayout";

export const DueDateCell: FC<TaskTableCellComponentProps> = ({
  task,
  isFutureTask,
  permissions,
  cellProps,
  onUpdate,
}) => {
  const { formatDateTimeLabel } = useFormattedDate();

  const isOverdue = isTaskOverdue(task);
  const isEditable = permissions.canEditDueDate && !isFutureTask;

  const adaptedDueDateState: TaskDueDateState | null = task.due_at
    ? {
        date: isoToLocalDate(task.due_at),
        reminderExecutionTime: task.reminder
          ? isoToLocalDate(task.reminder.next_execution)
          : undefined,
      }
    : null;

  return (
    <Popover
      placement="bottom"
      renderTrigger={({ Trigger, triggerRef, isOpen, onClose }) => (
        <Trigger
          ref={triggerRef}
          as={TaskTableLayout.Cell}
          isFocused={isOpen}
          isHoverable
          isDisabled={!isEditable}
          className="justify-between gap-2"
          {...cellProps}
        >
          {adaptedDueDateState ? (
            <TaskTableLayout.CellText
              className={cx(isOverdue && "text-tertiary-800")}
            >
              {formatDateTimeLabel(adaptedDueDateState.date)}
            </TaskTableLayout.CellText>
          ) : (
            <TaskTableLayout.AppearingAddValueCellText
              isStatic={isOpen}
              isHidden={!isEditable}
            />
          )}
          {adaptedDueDateState && isEditable && (
            <TaskTableLayout.ClearCellValueButton
              isAppearing={!isOpen}
              onClick={(event) => {
                event.stopPropagation();
                onUpdate({ due_at: null, reminder: null });
                onClose();
              }}
            />
          )}
        </Trigger>
      )}
      className="w-[375px] px-4 py-6"
    >
      {({ onClose }) => (
        <DueDatePicker
          initialState={adaptedDueDateState}
          withReminder={task.status !== TaskDefaultStatus.completed}
          onSubmit={(state) => {
            if (!state) {
              onUpdate({ due_at: null, reminder: null });
            } else {
              onUpdate({
                due_at: state.date.toISOString(),
                reminder: state.reminderExecutionTime
                  ? {
                      next_execution: state.reminderExecutionTime.toISOString(),
                    }
                  : null,
              });
            }

            onClose();
          }}
          onClose={onClose}
        />
      )}
    </Popover>
  );
};
