import { useRestApiProvider } from "@jugl-web/rest-api";
import { ArchivedTask, DetailedTask } from "@jugl-web/rest-api/tasks";
import {
  Collapse,
  LoadingAnimation,
} from "@jugl-web/ui-components/cross-platform";
import { cx } from "@jugl-web/utils";
import { FC, useState } from "react";
import { useInView } from "react-intersection-observer";
import { TABLE_DIMENSIONS } from "@web-src/features/tasks/TaskTable/consts";
import { TaskDetailsRows } from "@web-src/features/tasks/TaskTable/components/TaskDetailsRows";
import { TaskTableLayout } from "@web-src/features/tasks/TaskTable/components/TaskTableLayout";
import { useTaskPermissions } from "@jugl-web/domain-resources/tasks/hooks/useTaskPermissions";
import { ReactComponent as ChevronRightSmallIcon } from "../../assets/chevron.svg";

export interface TaskRowProps {
  task: ArchivedTask;
  entityId: string;
  meId: string;
  columns: {
    id: string;
    className?: string;
    grow: boolean;
    width: number;
    content: (
      task: ArchivedTask,
      isExpanded: boolean,
      canRestoreRemoveTask: boolean,
      detailedTask?: DetailedTask
    ) => React.ReactNode;
  }[];
}

export const TaskRow: FC<TaskRowProps> = ({
  task: archivedTask,
  entityId,
  meId,
  columns,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const [rowRef, isRowInView] = useInView({
    threshold: 0.1,
    rootMargin: "300px 0px",
  });

  const { tasksApi } = useRestApiProvider();

  const { data: detailedTask, isLoading } =
    tasksApi.useGetArchivedDetailedTaskQuery(
      {
        entityId,
        taskId: archivedTask.id,
      },
      { skip: !isExpanded }
    );

  const areTaskDetailsVisible = Boolean(isExpanded && detailedTask);
  const permissions = useTaskPermissions({
    task: detailedTask,
    meId,
  });
  const noPermissionObj = useTaskPermissions({});

  return (
    <>
      <TaskTableLayout.Row
        ref={rowRef}
        style={{
          minHeight: TABLE_DIMENSIONS.TABLE_TASK_ROW_HEIGHT,
          gap: 0,
          marginTop: 0,
        }}
        className={cx(areTaskDetailsVisible && "[&>div]:bg-grey-100 gap-0")}
      >
        {isRowInView ? (
          <>
            <TaskTableLayout.Cell
              width={TABLE_DIMENSIONS.TABLE_OUTERMOST_COLUMN_WIDTH}
              isDisabled={isLoading}
              className="text-dark-800 hover:bg-grey-100 hover:border-grey-400 flex items-center justify-center border-0 border-t-[1px] border-solid border-[#EEF2F5] px-4 text-sm hover:border-[1px]"
              onClick={() => setIsExpanded((s) => !s)}
            >
              {isLoading ? (
                <LoadingAnimation size="sm" />
              ) : (
                <ChevronRightSmallIcon
                  className={cx("transition", isExpanded && "rotate-90")}
                />
              )}
            </TaskTableLayout.Cell>

            {columns.map((column) => (
              <TaskTableLayout.Cell
                key={column.id}
                width={column.width}
                shouldGrow={column.grow}
                className={cx(
                  "text-dark-800 hover:bg-grey-100 hover:border-grey-400 flex items-center border-[1px] border-t-[1px] border-b-0 border-solid border-[#EEF2F5] px-4 text-sm hover:border-[1px]",
                  {
                    "p-[2px]": column.id === "label" || column.id === "status",
                  },
                  column.className
                )}
              >
                {column.content(
                  archivedTask,
                  isExpanded,
                  permissions.canRestoreTask,
                  detailedTask
                )}
              </TaskTableLayout.Cell>
            ))}
          </>
        ) : (
          <TaskTableLayout.Cell shouldGrow />
        )}
      </TaskTableLayout.Row>
      <Collapse isOpen={areTaskDetailsVisible} className="flex flex-col">
        {detailedTask && (
          <TaskDetailsRows
            task={detailedTask}
            // Passing all false permissions so we can't edit anything
            permissions={noPermissionObj}
            isFutureTask={false}
            entityId={entityId}
            showEmptyContentIndicator
          />
        )}
      </Collapse>
    </>
  );
};
