import { TaskViewColumn } from "@jugl-web/domain-resources/tasks/hooks/useTasksViewColumns";
import { Collapse } from "@jugl-web/ui-components/cross-platform";
import { cx } from "@jugl-web/utils";
import { FC } from "react";
import { ReactComponent as ChevronRightIcon } from "./assets/chevron-right.svg";
import { ManageColumnsButtonCell } from "./components/ManageColumnsButtonCell/ManageColumnsButtonCell";
import { NewTaskRow } from "./components/NewTaskRow";
import { TaskRow } from "./components/TaskRow";
import { TaskTableLayout } from "./components/TaskTableLayout";
import { TaskTableTitle } from "./components/TaskTableTitle";
import { TABLE_DIMENSIONS, tableColorSetToClasses } from "./consts";
import { useTaskTableColumns } from "./hooks/useTaskTableColumns";

interface TaskTableProps {
  entityId: string;
  meId: string;
  viewColumn: TaskViewColumn;
  isExpanded: boolean;
  onToggleExpand: () => void;
}

export const TaskTable: FC<TaskTableProps> = ({
  entityId,
  meId,
  viewColumn,
  isExpanded,
  onToggleExpand,
}) => {
  const { columns } = useTaskTableColumns();

  return (
    <div
      className={cx(
        "shrink-0 overflow-hidden rounded-xl border-l-4 border-t-0 border-b-0 border-r-0 border-solid bg-[#EEF2F5]",
        tableColorSetToClasses[viewColumn.tableView.tableColorSet].border
      )}
      style={{ boxShadow: "0px 0px 32px 0px rgba(0, 0, 0, 0.03)" }}
    >
      <TaskTableLayout.Row
        height={TABLE_DIMENSIONS.TABLE_TITLE_ROW_HEIGHT}
        isFirst
        onClick={onToggleExpand}
      >
        <TaskTableLayout.Cell
          width={TABLE_DIMENSIONS.TABLE_OUTERMOST_COLUMN_WIDTH}
          className="justify-center px-0"
        >
          <ChevronRightIcon
            className={cx("transition", isExpanded && "rotate-90")}
          />
        </TaskTableLayout.Cell>
        <TaskTableLayout.Cell shouldGrow>
          <div className="flex items-center gap-3.5">
            <TaskTableTitle
              entityId={entityId}
              meId={meId}
              viewColumn={viewColumn}
            />
            <span className="text-grey-600 font-secondary text-sm font-medium leading-4 tracking-[0.14px]">
              {viewColumn.items.length}
            </span>
          </div>
        </TaskTableLayout.Cell>
      </TaskTableLayout.Row>
      <Collapse isOpen={isExpanded} className="flex flex-col">
        <TaskTableLayout.Row
          height={TABLE_DIMENSIONS.TABLE_HEADINGS_ROW_HEIGHT}
          isHoverable={false}
        >
          <ManageColumnsButtonCell side="left" />
          {columns.map((column, index) => (
            <TaskTableLayout.Cell
              key={column.id}
              width={column.width}
              shouldGrow={index === columns.length - 1}
            >
              <TaskTableLayout.CellLabel>
                {column.title}
              </TaskTableLayout.CellLabel>
            </TaskTableLayout.Cell>
          ))}
          <ManageColumnsButtonCell side="right" />
        </TaskTableLayout.Row>
        {viewColumn.items.map((task) => (
          <TaskRow key={task.id} task={task} entityId={entityId} meId={meId} />
        ))}
        {viewColumn.createTaskOptions && (
          <NewTaskRow
            entityId={entityId}
            initialState={viewColumn.createTaskOptions.initialState}
          />
        )}
      </Collapse>
    </div>
  );
};
