import { useEffect, useContext, MutableRefObject } from "react";
import { UNSAFE_NavigationContext } from "react-router-dom";

export const useBlockNavigation = (
  showNavigationAlert: (state: boolean) => void,
  confirmNavigation: MutableRefObject<(() => void) | null>,
  skipNavigationAlert: MutableRefObject<boolean>
) => {
  const { navigator } = useContext(UNSAFE_NavigationContext);

  useEffect(() => {
    const originalPush = navigator.push;

    navigator.push = (...args) => {
      if (!skipNavigationAlert.current) {
        showNavigationAlert(true);
        confirmNavigation.current = () => {
          showNavigationAlert(false);
          originalPush(...args);
        };
      } else {
        skipNavigationAlert.current = false;
        originalPush(...args);
      }
    };

    return () => {
      navigator.push = originalPush;
    };
  }, [navigator, showNavigationAlert, confirmNavigation, skipNavigationAlert]);
};
