import {
  DatePicker,
  DatePickerProps,
} from "@jugl-web/ui-components/cross-platform/DatePicker";
import { Popover } from "@jugl-web/ui-components/cross-platform/Popover";
import { MobileDrawer } from "@jugl-web/ui-components/mobile/MobileDrawer";
import { ResourcePickerPopoverHeader } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { assert, useAppVariant } from "@jugl-web/utils";
import { isValidDate } from "@jugl-web/utils/date-time/isValidDate";
import { FC, useState } from "react";
import { CustomFieldProps } from "../../types";
import { getCustomFieldButtonId } from "../../utils";
import {
  TaskCustomFieldPropertyButton,
  TaskCustomFieldPropertyButtonProps,
} from "../TaskCustomFieldPropertyButton";

export const CustomDateField: FC<CustomFieldProps> = ({
  field,
  rawValue,
  isReadonly,
  onChange,
  onFinishEditing,
}) => {
  assert(field.type === "date", "Field should be of type date");

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { isMobile } = useAppVariant();

  const initialDate = (() => {
    if (rawValue && isValidDate(rawValue)) {
      return new Date(rawValue);
    }

    return undefined;
  })();

  const commonButtonProps: TaskCustomFieldPropertyButtonProps = {
    isDisabled: isReadonly,
    id: getCustomFieldButtonId(field.id),
    field,
    rawValue,
  };

  const commonDatePickerProps: DatePickerProps = {
    initialDate,
    isDateRemovable: true,
    onSubmit: (date) => {
      onChange?.(date ? date.toISOString() : "");
    },
  };

  if (isMobile) {
    return (
      <>
        <TaskCustomFieldPropertyButton
          onClick={() => setIsDialogOpen(true)}
          {...commonButtonProps}
        />
        <MobileDrawer
          isOpen={isDialogOpen}
          header={{ title: field.name }}
          animateHeight
          onClose={() => {
            setIsDialogOpen(false);
            onFinishEditing();
          }}
        >
          <MobileDrawer.Content>
            <DatePicker
              onClose={() => {
                setIsDialogOpen(false);
                onFinishEditing();
              }}
              {...commonDatePickerProps}
              showTimePicker
            />
          </MobileDrawer.Content>
        </MobileDrawer>
      </>
    );
  }

  return (
    <Popover
      key={field.id}
      placement="bottom-start"
      className="w-[375px]"
      renderTrigger={({ Trigger, triggerRef }) => (
        <Trigger
          ref={triggerRef}
          as={TaskCustomFieldPropertyButton}
          {...commonButtonProps}
        />
      )}
      onUnmount={onFinishEditing}
    >
      {({ onClose }) => (
        <>
          <ResourcePickerPopoverHeader title={field.name} onClose={onClose} />
          <DatePicker
            className="p-4"
            onClose={onClose}
            {...commonDatePickerProps}
            showTimePicker
          />
        </>
      )}
    </Popover>
  );
};
