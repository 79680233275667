import React, { useState } from "react";
import { FormField } from "@jugl-web/rest-api";
import { TextField } from "@jugl-web/ui-components/cross-platform/TextField";
import {
  PhoneInput,
  validatePhoneNumber,
} from "@jugl-web/ui-components/cross-platform/PhoneInput";
import { FormControlLabel } from "@jugl-web/ui-components/cross-platform";
import { SelectField } from "@jugl-web/ui-components/cross-platform/SelectField";
import { defineMessage, MessageDescriptor } from "react-intl";
import {
  Controller,
  UseFormRegister,
  FieldValues,
  Control,
  FieldError,
  UseFormResetField,
} from "react-hook-form";
import {
  convertSnakeCaseToReadableString,
  cx,
  useTranslations,
} from "@jugl-web/utils";

export const customerFieldDescriptorsById: Record<string, MessageDescriptor> = {
  first_name: defineMessage({
    id: "customers-page.first-name",
    defaultMessage: "First name",
  }),
  last_name: defineMessage({
    id: "customers-page.last-name",
    defaultMessage: "Last name",
  }),
  position: defineMessage({
    id: "customers-page.position",
    defaultMessage: "Position",
  }),
  phone_number: defineMessage({
    id: "customers-page.phone-number",
    defaultMessage: "Phone number",
  }),
  state: defineMessage({
    id: "customers-page.state",
    defaultMessage: "State",
  }),
  country: defineMessage({
    id: "customers-page.country",
    defaultMessage: "Country",
  }),
  city: defineMessage({
    id: "customers-page.city",
    defaultMessage: "City",
  }),
  zip_code: defineMessage({
    id: "customers-page.zip-code",
    defaultMessage: "ZIP code",
  }),
  street: defineMessage({
    id: "customers-page.street",
    defaultMessage: "Street",
  }),
  company_name: defineMessage({
    id: "customers-page.company-name",
    defaultMessage: "Company name",
  }),
  industry: defineMessage({
    id: "customers-page.industry",
    defaultMessage: "Industry",
  }),
  website: defineMessage({
    id: "customers-page.website",
    defaultMessage: "Website",
  }),
  email: defineMessage({
    id: "customers-page.email",
    defaultMessage: "Email",
  }),
};

const CustomerFormField: React.FC<{
  section: "personal" | "company";
  field: FormField;
  control: Control<FieldValues>;
  register: UseFormRegister<FieldValues>;
  value: string;
  invalid?: boolean;
  error?: FieldError;
  variant?: "task-dialog" | "customer-table";
  resetField: UseFormResetField<FieldValues>;
}> = ({
  section,
  field,
  register,
  control,
  value,
  invalid,
  error,
  resetField,
  variant = "customer-table",
}) => {
  const [focusState, setFocusState] = useState<{ [key: string]: boolean }>({});
  const { t } = useTranslations();
  const convertPhoneStringToObject = (str: string) => {
    if (!str) {
      return { iso: "IN", code: "+91", phone: "" };
    }
    const [iso, code, phone] = str.split(",");
    return { iso, code, phone };
  };
  if (field.section === section) {
    const normalizedFieldName = convertSnakeCaseToReadableString(field.name);
    switch (field.type) {
      case "email":
      case "text":
      case "url":
        return (
          <div>
            <TextField
              variant="underline"
              placeholder={t({
                id: "common.enter",
                defaultMessage: "Enter",
              })}
              isRequired={field.required}
              label={
                customerFieldDescriptorsById[field.name]
                  ? t(customerFieldDescriptorsById[field.name])
                  : normalizedFieldName
              }
              labelClassName={
                variant === "task-dialog" ? "text-xs text-grey-600 ml-0" : ""
              }
              inputClassName={cx({
                "px-0 border-dark-100 placeholder:text-grey-700 pt-1 pb-2.5":
                  variant === "task-dialog",
                "border-tertiary border-[1px] border-x-0 border-t-0 border-solid":
                  invalid,
              })}
              className={cx("capitalize")}
              isFullWidth
              {...register(field.id, {
                required: field.required,
                minLength: 2,
                pattern: {
                  value: /.*[^ ].*/,
                  message: t({
                    id: "feedback.only-spaces-are-forbidden",
                    defaultMessage: "Field can't contain only spaces",
                  }),
                },
              })}
              errorMessage={error?.message}
            />
          </div>
        );
      case "dropdown":
        return (
          <SelectField
            label={
              customerFieldDescriptorsById[field.name]
                ? t(customerFieldDescriptorsById[field.name])
                : normalizedFieldName
            }
            isRequired={field.required}
            isFullWidth
            {...register(field.id, { required: field.required })}
          >
            <option />
            {field?.value?.split(",").map((option: string) => (
              <option>{option}</option>
            ))}
          </SelectField>
        );
      case "mobile":
        return (
          <div
            className={cx("border-x-0 border-t-0 border-b-[1px] border-solid", {
              "border-black/20": variant === "customer-table",
              "border-dark-100 transition-colors": variant === "task-dialog",
              "border-primary":
                variant === "task-dialog" && focusState[field.id],
              "border-tertiary border-solid": invalid,
            })}
          >
            <FormControlLabel
              className={cx(
                "capitalize",
                variant === "task-dialog"
                  ? "text-grey-600 text-xs"
                  : "pl-2 capitalize"
              )}
              isRequired={field.required}
            >
              {customerFieldDescriptorsById[field.name]
                ? t(customerFieldDescriptorsById[field.name])
                : normalizedFieldName}
            </FormControlLabel>
            <Controller
              control={control}
              {...register(field.id, {
                required: field.required,
                validate: {
                  check: (inputValue) => {
                    if (convertPhoneStringToObject(inputValue || "").phone) {
                      return validatePhoneNumber(inputValue);
                    }
                    return true;
                  },
                },
              })}
              render={({ field: controlledField }) => (
                <PhoneInput
                  value={convertPhoneStringToObject(value || "")}
                  inputClassName={
                    variant === "task-dialog" ? "placeholder-grey-700" : ""
                  }
                  placeholder={t({
                    id: "common.enter",
                    defaultMessage: "Enter",
                  })}
                  listButtonClassName={variant === "task-dialog" ? "pl-0" : ""}
                  onChange={({
                    iso,
                    phone,
                    code,
                  }: {
                    iso: string;
                    phone: string;
                    code: string;
                  }) => {
                    const parsedPhone = phone.replace(/[^0-9]/g, "");
                    if (!parsedPhone) {
                      controlledField.onChange(null);
                      resetField(field.id);
                    }
                    controlledField.onChange(`${iso},${code},${phone}`);
                  }}
                  onFocus={() => {
                    setFocusState({ ...focusState, [field.id]: true });
                  }}
                  onBlur={() => {
                    setFocusState({ ...focusState, [field.id]: false });
                  }}
                />
              )}
            />
          </div>
        );
      default:
        return <></>;
    }
  }
  return <></>;
};

export default CustomerFormField;
