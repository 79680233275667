import {
  getSpecificOrderCompletionState,
  taskChecklistItemAdapters,
} from "@jugl-web/domain-resources/tasks";
import { useTaskMentions } from "@jugl-web/domain-resources/tasks/hooks/useTaskMentions";
import { TaskPermissions } from "@jugl-web/domain-resources/tasks/hooks/useTaskPermissions";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { DetailedTask, TaskChecklistItem } from "@jugl-web/rest-api/tasks";
import { useToast, useTranslations } from "@jugl-web/utils";
import { OpenConfirmationDialogFn } from "@jugl-web/utils/hooks/useConfirmationDialogState";
import { FC, Fragment } from "react";
import { useInView } from "react-intersection-observer";
import { ReactComponent as BinIcon } from "../../assets/bin.svg";
import { TABLE_DIMENSIONS } from "../../consts";
import { useTaskTableColumns } from "../../hooks/useTaskTableColumns";
import { TaskTableLayout } from "../TaskTableLayout";

interface TaskChecklistItemRowProps {
  task: DetailedTask;
  permissions: TaskPermissions;
  isFutureTask: boolean;
  item: TaskChecklistItem;
  index: number;
  entityId: string;
  meId: string;
  onOpenDeleteConfirmationDialog: OpenConfirmationDialogFn;
}

export const TaskChecklistItemRow: FC<TaskChecklistItemRowProps> = ({
  task,
  permissions,
  isFutureTask,
  item,
  index,
  entityId,
  meId,
  onOpenDeleteConfirmationDialog,
}) => {
  const { columns } = useTaskTableColumns();

  const { mentions } = useTaskMentions({
    entityId,
    meId,
    taskAssigneeIds: task.assignees,
    taskCreatorId: task.created_by,
  });

  const { tasksApi } = useRestApiProvider();
  const [updateChecklistItem] = tasksApi.useUpdateChecklistItemMutation();
  const [deleteChecklistItem] = tasksApi.useDeleteChecklistItemMutation();

  const [rowRef, isRowInView] = useInView({
    threshold: 0.1,
    rootMargin: "300px 0px",
  });

  const { toast } = useToast({ variant: "web" });
  const { t } = useTranslations();

  const isEditable = permissions.canManageChecklistItem && !isFutureTask;

  return (
    <TaskTableLayout.Row
      ref={rowRef}
      style={{
        minHeight: TABLE_DIMENSIONS.TABLE_TASK_CHECKLIST_ITEM_ROW_HEIGHT,
      }}
    >
      {isRowInView ? (
        <>
          <TaskTableLayout.Cell
            width={TABLE_DIMENSIONS.TABLE_OUTERMOST_COLUMN_WIDTH}
            isHoverable
            isDisabled={!isEditable}
            className="justify-center px-0"
            onClick={() =>
              onOpenDeleteConfirmationDialog({
                onConfirm: () => {
                  deleteChecklistItem({
                    entityId,
                    taskId: task.id,
                    itemId: item.id,
                  });
                  toast(
                    t({
                      id: "feedback.task-checklist-item-removed",
                      defaultMessage: "Checklist item has been removed",
                    })
                  );
                },
              })
            }
          >
            {isEditable && <BinIcon className="hidden group-hover:block" />}
          </TaskTableLayout.Cell>
          {columns.map((column, columnIndex) => {
            const isFirstColumn = columnIndex === 0;

            const previousItem = task.checklist[index - 1];
            const nextItem = task.checklist[index + 1];

            const specificOrderCompletionState =
              getSpecificOrderCompletionState({
                isCompleteInSpecificOrder: task.has_chklist_chk,
                item: taskChecklistItemAdapters.toInternalModel(item),
                previousItem: previousItem
                  ? taskChecklistItemAdapters.toInternalModel(previousItem)
                  : undefined,
                nextItem: nextItem
                  ? taskChecklistItemAdapters.toInternalModel(nextItem)
                  : undefined,
              });

            return (
              <Fragment key={column.id}>
                {column.checklistCellRenderer({
                  task,
                  item,
                  index,
                  specificOrderCompletionState,
                  mentions,
                  entityId,
                  permissions,
                  isFutureTask,
                  cellProps: {
                    width: column.width,
                    hasMarginLine: isFirstColumn,
                    shouldGrow: false,
                  },
                  onUpdate: (attributes) =>
                    updateChecklistItem({
                      entityId,
                      taskId: task.id,
                      itemId: item.id,
                      attributes,
                    }),
                })}
              </Fragment>
            );
          })}
        </>
      ) : (
        <TaskTableLayout.Cell shouldGrow />
      )}
    </TaskTableLayout.Row>
  );
};
