import {
  Dialog,
  Button,
  LoadingSpinner,
  PlainButton,
} from "@jugl-web/ui-components";
import { useTranslations, useToast, cx } from "@jugl-web/utils";
import { Customer, FormField, useRestApiProvider } from "@jugl-web/rest-api";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useMemo, useCallback, useEffect } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import CustomerFormField from "@web-src/modules/customers/pages/CustomersPage/components/CustomerFormField/CustomerFormField";
import { useForm, FieldValues, FieldError } from "react-hook-form";
import { ReactComponent as CloseIcon } from "@jugl-web/ui-components/web/DrawerHeader/assets/close.svg";
import { processNewCustomerData } from "@web-src/modules/customers/utils/processNewCustomerData";

type CustomerFormValues = { [key: string]: string };

export const AddNewCustomerDialog: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (customer: Customer) => void;
}> = ({ isOpen, onClose, onSuccess }) => {
  const {
    formState,
    control,
    reset,
    watch,
    handleSubmit,
    register,
    getFieldState,
    resetField,
  } = useForm<CustomerFormValues>({
    mode: "all",
    shouldUnregister: true,
    resetOptions: {
      keepSubmitCount: false,
    },
  });

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen, reset]);
  const { customersApi, customersFormFieldApi } = useRestApiProvider();
  const [addNewCustomer, { isLoading: isCreatingNewCustomer }] =
    customersApi.useAddCustomerMutation();

  const formParams = watch();

  const { toast } = useToast({ variant: "web" });
  const { entity } = useEntitySelectedProvider();
  const { t } = useTranslations();
  const { data: formFields, isLoading } =
    customersFormFieldApi.useFormFieldsQuery(
      isOpen
        ? {
            entityId: entity.id,
          }
        : skipToken
    );

  const isFieldInvalid = useCallback(
    (id: string) => {
      const state = getFieldState(id);
      return !formParams[id] || state.invalid;
    },
    [formParams, getFieldState]
  );

  const fieldsData: { [key: string]: FormField | undefined } = useMemo(() => {
    if (!formFields?.data) return {};
    return {
      firstName: {
        ...formFields.data.filter(
          (field) =>
            field.name === "first_name" &&
            field.is_cust_field === false &&
            field.section === "personal"
        )[0],
      },
      email: {
        ...formFields.data.filter(
          (field) =>
            field.name === "email" &&
            field.is_cust_field === false &&
            field.section === "personal"
        )[0],
      },
      mobile: {
        ...formFields.data.filter(
          (field) =>
            field.name === "phone_number" &&
            field.is_cust_field === false &&
            field.section === "personal"
        )[0],
      },
    };
  }, [formFields]);

  const fieldValues = useMemo(() => {
    const firstNameValue =
      fieldsData.firstName?.id && formParams[fieldsData.firstName.id];
    const emailValue = fieldsData.email?.id && formParams[fieldsData.email.id];
    const mobileValue =
      fieldsData.mobile?.id && formParams[fieldsData.mobile.id];
    return {
      firstName: firstNameValue,
      email: emailValue,
      mobile: mobileValue,
    };
  }, [fieldsData, formParams]);

  const shouldShowAddPhoneOrMail = useMemo(() => {
    const { email, mobile } = fieldValues;
    const [, , phoneNumber]: string[] = mobile?.split(",") || [];

    if (formState.submitCount && !email && !phoneNumber) {
      return true;
    }
    return false;
  }, [fieldValues, formState.submitCount]);

  const onSubmit = async (data: FieldValues) => {
    if (entity && data && formFields?.data) {
      const newCustomer = processNewCustomerData(formFields?.data, data);
      const response = await addNewCustomer({
        entityId: entity.id,
        fields: { fields: newCustomer },
      });

      if (response && "data" in response) {
        toast(
          t({
            id: "feedback.customer-added",
            defaultMessage: "Customer has been added",
          })
        );
        handleClose();
        onSuccess?.(response.data.data);
      }
    }
  };
  const handleClose = () => {
    onClose();
  };

  if (!isOpen) return null;
  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      size="auto"
      className="w-[560px]"
    >
      <div
        className={cx("flex h-[511px] w-full items-center justify-center", {
          hidden: !isLoading,
        })}
      >
        <LoadingSpinner />
      </div>
      <div
        className={cx("w-[560px]", {
          hidden: isLoading,
        })}
      >
        <header
          className="border-dark-100 relative z-10 flex shrink-0 items-center justify-between border-[1px] border-x-0 border-t-0 border-solid px-6"
          style={{
            height: 60,
          }}
        >
          <div className="flex items-center gap-2.5">
            <div className="flex items-center gap-2">
              <span className="text-dark max-w-[250px] truncate text-base font-semibold leading-4 tracking-[0.16px]">
                {t({
                  id: "tasks-details-page.add-new-customer",
                  defaultMessage: "Add New Customer",
                })}
              </span>
            </div>
          </div>
          <PlainButton onClick={handleClose}>
            <CloseIcon />
          </PlainButton>
        </header>

        <div className="mt-12 flex w-[560px] flex-col gap-10 px-14">
          {Object.keys(fieldsData).map((key) => {
            const field = fieldsData[key] as FormField;
            const { email, mobile } = fieldValues;
            const [, , phoneNumber]: string[] = mobile?.split(",") || [];

            if (!field) return null;
            field.required = true;
            if (email && field.type === "mobile") {
              field.required = false;
            }
            if (phoneNumber && !email && field.type === "email") {
              field.required = false;
            }
            return (
              <div>
                <CustomerFormField
                  key={field.id}
                  field={field}
                  section="personal"
                  register={register}
                  resetField={resetField}
                  control={control}
                  value={formParams[field.id]}
                  invalid={
                    (formState.submitCount && isFieldInvalid(field.id)) || false
                  }
                  error={formState.errors[field.id] as FieldError}
                  variant="task-dialog"
                />
                {field.type !== "text" && shouldShowAddPhoneOrMail && (
                  <span className="text-gradients-danger text-xs">
                    {t({
                      id: "tasks-details-page.add-phone-or-mail-to-proceed",
                      defaultMessage: "Add phone number or email to proceed",
                    })}
                  </span>
                )}
              </div>
            );
          })}
        </div>
        <div className="my-12 flex w-full items-center justify-center">
          <Button
            className="w-[300px]"
            onClick={handleSubmit(onSubmit)}
            isDisabled={isCreatingNewCustomer}
          >
            {t({
              id: "tasks-details-page.add-customer",
              defaultMessage: "Add Customer",
            })}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
