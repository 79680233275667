import { Alert } from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import Lottie from "react-lottie";
import dangerAnimation from "../../assets/danger-animation.json";

interface RecurrenceRemovalWarningDialogProps {
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

export const RecurrenceRemovalWarningDialog: FC<
  RecurrenceRemovalWarningDialogProps
> = ({ isOpen, onConfirm, onClose }) => {
  const { t } = useTranslations();

  return (
    <Alert
      isOpen={isOpen}
      img={
        <Lottie
          options={{ animationData: dangerAnimation }}
          height={70}
          width={70}
        />
      }
      title={t({
        id: "common.confirm-action",
        defaultMessage: "Confirm action",
      })}
      content={t({
        id: "tasks-page.remove-recurring-settings-warning",
        defaultMessage:
          "If you remove recurring settings from this task, all future recurring tasks will be deleted",
      })}
      buttons={[
        {
          text: t({ id: "common.cancel", defaultMessage: "Cancel" }),
          role: "close",
        },
        {
          text: t({ id: "common.confirm", defaultMessage: "Confirm" }),
          color: "primary",
          onClick: (_, actions) => {
            onConfirm();
            actions.closeAlert();
          },
        },
      ]}
      onRequestClose={onClose}
    />
  );
};
