import React, { useMemo } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useTranslations } from "@jugl-web/utils";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { OnboardingProgressBar } from "@web-src/modules/preferences/components/OnboardingProgressBar";
import { useOnboarding } from "@web-src/modules/preferences/providers";
import { ReactComponent as PeopleIcon } from "./assets/people.svg";
import { ReactComponent as EducationIcon } from "./assets/education.svg";
import { ReactComponent as LocationPinIcon } from "./assets/location-pin.svg";
import { ReactComponent as UserLocationLineIcon } from "./assets/user-location-line.svg";
import { ReactComponent as TrophyLineIcon } from "./assets/trophy-line.svg";
import { ReactComponent as CertificateIcon } from "./assets/certificate.svg";
import { ReactComponent as AwardIcon } from "./assets/award.svg";
import { ReactComponent as ServicesIcon } from "./assets/services.svg";
import { ReactComponent as WorkExperienceIcon } from "./assets/work-experience.svg";
import SideBar from "./components/SideBar";
import { EditProfileTiles, SettingsRoutes } from "../SettingsPage/types";
import EditEducationForm from "./components/EditEducationForm";
import CurrentCityForm from "./components/CurrentCityForm";
import ExperienceForm from "./components/ExperienceForm";
import ServiceForm from "./components/ServiceForm";
import AchievementsForm from "./components/AchievementsForm";
import CertificationsForm from "./components/CertificationsForm";
import AwardsForm from "./components/AwardsForm";
import BirthPlaceForm from "./components/BirthPlaceForm";
import PersonalInfoForm from "./components/PersonalInfoForm";
import { UserAccountSettingsLayout } from "../../components/UserAccountSettingsLayout";

export type EditProfileSection =
  | "personal-info"
  | "achievements"
  | "awards"
  | "birth-place"
  | "certifications"
  | "current-city"
  | "education"
  | "experience"
  | "service";

export const EditProfilePage: React.FC = () => {
  const { generateUrl } = useNavigation();
  const { section } = useParams<{
    section: EditProfileSection;
  }>();
  const { isOnboardingActive } = useOnboarding();

  const { t } = useTranslations();
  const listElements: {
    id: string;
    title: string;
    icon: React.ReactNode;
    link: string;
    color: "green" | "pink" | "purple";
  }[] = useMemo(
    () => [
      {
        id: "personal-info",
        title: t({
          id: "edit-profile-page.personal-info",
          defaultMessage: "Personal Info",
        }),
        icon: <PeopleIcon />,
        color: "green",
        link: `${SettingsRoutes.EditProfile}/${EditProfileTiles.PersonalInfo}`,
      },
      {
        id: "education",
        title: t({
          id: "edit-profile-page.education",
          defaultMessage: "Education",
        }),
        icon: <EducationIcon />,
        color: "green",
        link: `${SettingsRoutes.EditProfile}/${EditProfileTiles.Education}`,
      },
      {
        id: "current-city",
        title: t({
          id: "edit-profile-page.current-city",
          defaultMessage: "Current City",
        }),
        icon: <LocationPinIcon />,
        color: "green",
        link: `${SettingsRoutes.EditProfile}/${EditProfileTiles.CurrentCity}`,
      },
      {
        id: "birth-place",
        title: t({
          id: "edit-profile-page.birth-place",
          defaultMessage: "Native/Birth Place",
        }),
        icon: <UserLocationLineIcon />,
        color: "green",
        link: `${SettingsRoutes.EditProfile}/${EditProfileTiles.BirthPlace}`,
      },
      {
        id: "awards",
        title: t({
          id: "edit-profile-page.awards",
          defaultMessage: "Awards",
        }),
        icon: <TrophyLineIcon />,
        color: "pink",
        link: `${SettingsRoutes.EditProfile}/${EditProfileTiles.Awards}`,
      },
      {
        id: "certifications",
        title: t({
          id: "edit-profile-page.certifications",
          defaultMessage: "Certifications",
        }),
        icon: <CertificateIcon />,
        color: "pink",
        link: `${SettingsRoutes.EditProfile}/${EditProfileTiles.Certifications}`,
      },
      {
        id: "achievements",
        title: t({
          id: "edit-profile-page.achievements",
          defaultMessage: "Achievements",
        }),
        icon: <AwardIcon />,
        color: "pink",
        link: `${SettingsRoutes.EditProfile}/${EditProfileTiles.Achievements}`,
      },
      {
        id: "service",
        title: t({
          id: "edit-profile-page.service",
          defaultMessage: "Service",
        }),
        icon: <ServicesIcon />,
        color: "pink",
        link: `${SettingsRoutes.EditProfile}/${EditProfileTiles.Service}`,
      },
      {
        id: "experience",
        title: t({
          id: "edit-profile-page.experience",
          defaultMessage: "Experience",
        }),
        icon: <WorkExperienceIcon />,
        color: "purple",
        link: `${SettingsRoutes.EditProfile}/${EditProfileTiles.Experience}`,
      },
    ],
    [t]
  );

  const $content = useMemo(() => {
    switch (section) {
      case "personal-info":
        return <PersonalInfoForm />;
      case "achievements":
        return <AchievementsForm />;
      case "awards":
        return <AwardsForm />;
      case "certifications":
        return <CertificationsForm />;
      case "current-city":
        return <CurrentCityForm />;
      case "education":
        return <EditEducationForm />;
      case "experience":
        return <ExperienceForm />;
      case "service":
        return <ServiceForm />;
      case "birth-place":
        return <BirthPlaceForm />;
      default:
        return (
          <Navigate
            to={generateUrl("settingsEditProfile", {
              section: "personal-info",
            })}
          />
        );
    }
  }, [section, generateUrl]);

  return (
    <UserAccountSettingsLayout selectedTab="profile-info">
      <div className="flex h-full flex-col">
        <div className="flex overflow-y-auto">
          <SideBar listElements={listElements} />
          {$content}
        </div>
        {isOnboardingActive && <OnboardingProgressBar />}
      </div>
    </UserAccountSettingsLayout>
  );
};
