import { ModuleNotification, useRestApiProvider } from "@jugl-web/rest-api";
import {
  NotificationItem,
  NotificationItemEntry,
  NotificationItemProps,
} from "@jugl-web/ui-components/cross-platform/NotificationItem";
import { useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import Lottie from "react-lottie";
import { useModuleLogParser } from "../../../../hooks/useModuleLogParser";
import { isDriveLog, isTaskLog } from "../../../../utils";
import { ReactComponent as ListIcon } from "../../assets/list.svg";
import loadingAnimationData from "../../assets/loading-animation.json";
import { ModuleToLogMap, OnNotificationClickFn } from "../../types";
import { EmptyState } from "../EmptyState";
import { LoadingSkeleton } from "../LoadingSkeleton";
import externalAvatarImg from "./assets/external-avatar-img.png";

export interface ModuleNotificationsListProps<
  TModule extends keyof ModuleToLogMap,
  TLog extends ModuleToLogMap[TModule]
> {
  entityId: string;
  module: TModule;
  onNotificationClick: OnNotificationClickFn<TLog>;
}

type AdaptedNotification = Omit<NotificationItemProps, "className"> & {
  id: string;
};

const NOTIFICATIONS_PER_PAGE = 10;

export const ModuleNotificationsList = <
  TModule extends keyof ModuleToLogMap,
  TLog extends ModuleToLogMap[TModule]
>({
  entityId,
  module,
  onNotificationClick,
}: ModuleNotificationsListProps<TModule, TLog>) => {
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const [notifications, setNotifications] = useState<
    ModuleNotification<TLog>[]
  >([]);

  const { moduleNotificationsApi } = useRestApiProvider();

  const [getModuleNotifications, { isFetching, isError }] =
    moduleNotificationsApi.useLazyGetModuleNotificationsQuery();

  const [markNotificationAsRead] =
    moduleNotificationsApi.useMarkNotificationAsReadMutation();

  const { ref: loadingSpinnerRef, entry } = useInView();

  useEffect(() => {
    const loadMoreNotifications = async () => {
      const response = await getModuleNotifications(
        {
          entityId,
          module,
          page,
          page_size: NOTIFICATIONS_PER_PAGE,
        },
        false
      );

      setIsInitialized(true);

      if (response.data) {
        const paginatedResponse = response.data;

        setHasMore(
          paginatedResponse.page_number < paginatedResponse.total_pages
        );

        setNotifications((prevCombinedNotifications) => [
          ...prevCombinedNotifications,
          ...(paginatedResponse.data as ModuleNotification<TLog>[]),
        ]);
      }
    };

    loadMoreNotifications();
  }, [entityId, getModuleNotifications, module, page]);

  useEffect(() => {
    if (entry?.isIntersecting && hasMore && !isFetching) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [entry?.isIntersecting, hasMore, isFetching]);

  const { parseModuleLog } = useModuleLogParser({ entityId });

  // TODO: Do the transformation when setting the state
  const adaptedNotifications = useMemo(
    () =>
      notifications.reduce<AdaptedNotification[]>(
        (validNotifications, notification) => {
          const hasAnyUnreadLog = notification.logs.some((log) => log.unread);

          const entries = notification.logs.reduce<NotificationItemEntry[]>(
            (validEntries, log) => {
              try {
                const parsedLog = parseModuleLog(log);

                return [
                  ...validEntries,
                  {
                    id: parsedLog.id,
                    image: {
                      type: "avatar",
                      username: parsedLog.user.name,
                      imageUrl: parsedLog.user.isExternalClient
                        ? externalAvatarImg
                        : parsedLog.user.avatarUrl,
                    },
                    message: parsedLog.message,
                    date: parsedLog.date,
                  },
                ];
              } catch (error) {
                // eslint-disable-next-line no-console
                console.warn(`Couldn't parse the log`, { log, error });
                return validEntries;
              }
            },
            []
          );

          if (entries.length === 0) {
            return validNotifications;
          }

          const notificationLog = notification.logs[0];
          let contextualDetails;
          if (isTaskLog(notificationLog)) {
            contextualDetails = {
              title: notificationLog.task.title,
              icon: <ListIcon />,
            };
          } else if (isDriveLog(notificationLog)) {
            contextualDetails = {
              title: notificationLog.drive.name,
              icon: <ListIcon />,
            };
          } else {
            contextualDetails = undefined;
          }

          const adaptedNotification: AdaptedNotification = {
            id: notification.id,
            entries,
            isRead: !hasAnyUnreadLog,
            contextualDetails,
            onClick: () => {
              onNotificationClick(notification.logs[0] as TLog);

              if (hasAnyUnreadLog) {
                markNotificationAsRead({
                  entityId,
                  logIds: notification.logs.map((log) => log.id),
                });
              }
            },
          };

          return [...validNotifications, adaptedNotification];
        },
        []
      ),
    [
      notifications,
      parseModuleLog,
      onNotificationClick,
      markNotificationAsRead,
      entityId,
    ]
  );

  const isEmpty = adaptedNotifications.length === 0;

  if (isError || !isInitialized) {
    return <LoadingSkeleton />;
  }

  if (isEmpty) {
    return <EmptyState module={module} />;
  }

  return (
    <div className="animate-fade-in flex flex-col gap-[18px]">
      {adaptedNotifications.map((notification) => (
        <NotificationItem key={notification.id} {...notification} />
      ))}
      {hasMore && (
        <div ref={loadingSpinnerRef}>
          <Lottie
            options={{ animationData: loadingAnimationData }}
            height={32}
            width={32}
          />
        </div>
      )}
    </div>
  );
};
