import {
  TaskRecurrence,
  TaskRecurrenceEndAt,
  TaskRecurrenceInterval,
  TaskRecurrenceIntervalEvery,
} from "@jugl-web/rest-api/tasks";
import {
  Button,
  InteractiveContainer,
  ListBoxItem,
  PlainButton,
} from "@jugl-web/ui-components/cross-platform";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { assert, cx, omit, useTranslations } from "@jugl-web/utils";
import { useFormattedDate } from "@jugl-web/utils/hooks/useFormattedDate";
import { eachDayOfInterval, endOfDay, endOfWeek, startOfWeek } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { isEqual } from "lodash";
import { Dispatch, FC, FocusEvent, SetStateAction, useMemo } from "react";
import { ReactComponent as ArrowSmallIcon } from "../../assets/arrow-small.svg";
import { ReactComponent as ArrowIcon } from "../../assets/arrow.svg";
import {
  RecurrenceListOptionDetails,
  useTaskRecurrence,
} from "../../hooks/useTaskRecurrence";
import { RecurrenceEndsAtListOption, RecurrenceListOption } from "../../types";
import { getDayOfWeek, getWeekdayOrdinalInGivenMonth } from "../../utils";
import { EndAtCounterInput } from "../EndAtCounterInput";
import { EndAtDatePicker } from "../EndAtDatePicker";

interface RecurrenceCustomSettingsProps {
  state: TaskRecurrence;
  onChange: Dispatch<SetStateAction<TaskRecurrence>>;
  onSubmit: () => void;
  onGoBack: () => void;
  onClose: () => void;
}

// TODO: Re-visit, divide into smaller components
export const RecurrenceCustomSettings: FC<RecurrenceCustomSettingsProps> = ({
  state,
  onChange,
  onSubmit,
  onGoBack,
  onClose,
}) => {
  const { getRecurrenceListOptionIdToDetailsMap } = useTaskRecurrence();

  const { t } = useTranslations();
  const { localeAwareFormat } = useFormattedDate();

  const weekdays = useMemo(() => {
    const now = new Date();

    return eachDayOfInterval({
      start: startOfWeek(now, { weekStartsOn: 1 }),
      end: endOfWeek(now, { weekStartsOn: 1 }),
    }).map((date, index) => ({
      number: index + 1,
      label: localeAwareFormat(date, "iiiii"),
    }));
  }, [localeAwareFormat]);

  const intervalEveryListItems = useMemo<ListBoxItem<{ label: string }>[]>(
    () => [
      {
        id: TaskRecurrenceIntervalEvery.day,
        value: {
          label: t({
            id: "tasks-page.day",
            defaultMessage: "Day",
          }),
        },
      },
      {
        id: TaskRecurrenceIntervalEvery.week,
        value: {
          label: t({
            id: "tasks-page.week",
            defaultMessage: "Week",
          }),
        },
      },
      {
        id: TaskRecurrenceIntervalEvery.month,
        value: {
          label: t({
            id: "tasks-page.month",
            defaultMessage: "Month",
          }),
        },
      },
      {
        id: TaskRecurrenceIntervalEvery.year,
        value: {
          label: t({
            id: "tasks-page.year",
            defaultMessage: "Year",
          }),
        },
      },
    ],
    [t]
  );

  const selectedIntervalEveryItemId = useMemo(
    () =>
      intervalEveryListItems.find(
        (option) => option.id === state.interval.every
      ) || intervalEveryListItems[0],
    [intervalEveryListItems, state.interval.every]
  );

  const intervalEveryBasedOptionItems = useMemo<
    ListBoxItem<RecurrenceListOptionDetails>[]
  >(() => {
    const recurrenceListOptionIdToDetailsMap =
      getRecurrenceListOptionIdToDetailsMap(new Date());

    if (state.interval.every === TaskRecurrenceIntervalEvery.month) {
      return [
        {
          id: RecurrenceListOption.monthlyOnDay,
          value:
            recurrenceListOptionIdToDetailsMap[
              RecurrenceListOption.monthlyOnDay
            ],
        },
        {
          id: RecurrenceListOption.monthlyOnWeekday,
          value:
            recurrenceListOptionIdToDetailsMap[
              RecurrenceListOption.monthlyOnWeekday
            ],
        },
      ];
    }

    if (state.interval.every === TaskRecurrenceIntervalEvery.year) {
      return [
        {
          id: RecurrenceListOption.yearlyOnDate,
          value:
            recurrenceListOptionIdToDetailsMap[
              RecurrenceListOption.yearlyOnDate
            ],
        },
      ];
    }

    return [];
  }, [getRecurrenceListOptionIdToDetailsMap, state.interval.every]);

  const selectedIntervalEveryBasedOption = useMemo<
    ListBoxItem<RecurrenceListOptionDetails> | undefined
  >(() => {
    const matchingListOption = intervalEveryBasedOptionItems.find((option) => {
      if (!option.value.interval) {
        return false;
      }

      return isEqual(
        omit(state.interval, "freq"),
        omit(option.value.interval, "freq")
      );
    });

    return matchingListOption;
  }, [intervalEveryBasedOptionItems, state.interval]);

  const endsAtListItems = useMemo<
    ListBoxItem<{ label: string; endsAt: TaskRecurrenceEndAt }>[]
  >(
    () => [
      {
        id: RecurrenceEndsAtListOption.never,
        value: {
          label: t({
            id: "tasks-page.recurrence-ends-at-list-option-never",
            defaultMessage: "Never",
          }),
          endsAt: null,
        },
      },
      {
        id: RecurrenceEndsAtListOption.endsOnDate,
        value: {
          label: t({
            id: "tasks-page.recurrence-ends-at-list-option-ends-on",
            defaultMessage: "Ends on...",
          }),
          endsAt: {
            date: zonedTimeToUtc(endOfDay(new Date()), state.tz).toISOString(),
          },
        },
      },
      {
        id: RecurrenceEndsAtListOption.afterOccurrences,
        value: {
          label: t({
            id: "tasks-page.recurrence-ends-at-list-option-after",
            defaultMessage: "After...",
          }),
          endsAt: { counter: 10 },
        },
      },
    ],
    [state.tz, t]
  );

  const selectedEndsAtItemId = useMemo(() => {
    if (!state.end_at) {
      return RecurrenceEndsAtListOption.never;
    }

    if ("date" in state.end_at) {
      return RecurrenceEndsAtListOption.endsOnDate;
    }

    return RecurrenceEndsAtListOption.afterOccurrences;
  }, [state.end_at]);

  const selectedEndsAtItem = useMemo(
    () => endsAtListItems.find((item) => item.id === selectedEndsAtItemId),
    [endsAtListItems, selectedEndsAtItemId]
  );

  const updateInterval = (interval: TaskRecurrenceInterval) => {
    onChange({ ...state, interval });
  };

  const handleFrequencyInputBlur = (
    event: FocusEvent<HTMLInputElement>
  ): void => {
    if (!event.target.validity.valid) {
      event.target.value = state.interval.freq.toString();
      return;
    }

    const updatedFreq = Number(event.target.value);

    updateInterval({
      ...state.interval,
      freq: updatedFreq,
    });

    event.target.value = updatedFreq.toString(); // removes leading zeros
  };

  const handleIntervalEveryChange = (
    intervalEvery: TaskRecurrenceIntervalEvery
  ) => {
    const now = new Date();
    let interval: TaskRecurrenceInterval;

    if (intervalEvery === TaskRecurrenceIntervalEvery.day) {
      interval = {
        ...state.interval,
        every: intervalEvery,
      };
    } else if (intervalEvery === TaskRecurrenceIntervalEvery.week) {
      interval = {
        ...state.interval,
        every: intervalEvery,
        on: [getDayOfWeek(now)],
      };
    } else if (intervalEvery === TaskRecurrenceIntervalEvery.month) {
      interval = {
        ...state.interval,
        every: intervalEvery,
        on: {
          week: getWeekdayOrdinalInGivenMonth(now),
          day: getDayOfWeek(now),
        },
      };
    } else {
      interval = {
        ...state.interval,
        every: intervalEvery,
        on: {
          month: now.getMonth() + 1,
          date: now.getDate(),
        },
      };
    }

    updateInterval(interval);
  };

  const handleIntervalOnChange = (day: number) => {
    assert(state.interval.every === "week");

    const isDaySelected = state.interval.on.includes(day);

    updateInterval({
      ...state.interval,
      on: isDaySelected
        ? state.interval.on.filter((selectedDay) => selectedDay !== day)
        : [...state.interval.on, day],
    });
  };

  const handleEndsAtChange = (endsAt: TaskRecurrenceEndAt) => {
    onChange({ ...state, end_at: endsAt });
  };

  return (
    <>
      <div className="flex h-9 items-center gap-2">
        <PlainButton
          className="flex h-9 w-9 items-center justify-center"
          onClick={onGoBack}
        >
          <ArrowIcon />
        </PlainButton>
        <span className="text-sm font-semibold text-[#383838]">
          {t({
            id: "tasks-page.custom-settings",
            defaultMessage: "Custom settings",
          })}
        </span>
      </div>
      <div className="bg-dark-100 mb-6 mt-1.5 h-px" />
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-2">
          <span className="text-sm text-[#828282]">
            {t({
              id: "tasks-page.recurrence-repeat-every",
              defaultMessage: "Repeat every:",
            })}
          </span>
          <div className="flex gap-2">
            <input
              type="number"
              className="text-dark bg-grey-100 h-10 w-[92px] shrink-0 rounded-lg border-none p-4 text-center text-sm outline-none transition-colors [appearance:textfield] focus:bg-[#F5F6F8] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
              min={1}
              max={100}
              required
              defaultValue={state.interval.freq}
              onBlur={handleFrequencyInputBlur}
            />
            <ResourcePickerPopover
              items={intervalEveryListItems}
              placement="bottom"
              selectionBehavior={{ canToggle: false, mode: "single" }}
              renderTrigger={({ Trigger, triggerRef, isOpen }) => (
                <Trigger
                  ref={triggerRef}
                  as={InteractiveContainer}
                  className="bg-grey-100 flex h-10 w-full min-w-[176px] items-center justify-between rounded-lg p-4 outline-none"
                >
                  <span className="text-dark text-sm">
                    {selectedIntervalEveryItemId.value.label}
                  </span>
                  <ArrowSmallIcon
                    className={cx(isOpen && "rotate-180 transition-transform")}
                  />
                </Trigger>
              )}
              defaultSelectedIds={[state.interval.every]}
              renderLabel={(item) => item.value.label}
              itemSize="sm"
              spaceBetweenItems="compact"
              onSelect={({ item, onClose: closePopover }) => {
                handleIntervalEveryChange(
                  item.id as TaskRecurrenceIntervalEvery
                );
                closePopover();
              }}
              className="w-[176px]"
            />
          </div>
          {state.interval.every === TaskRecurrenceIntervalEvery.week && (
            <div className="mt-2.5 flex items-center gap-3">
              {weekdays.map((day) => {
                assert(state.interval.every === "week");

                const isSelected = state.interval.on.includes(day.number);

                return (
                  <InteractiveContainer
                    key={day.number}
                    className={cx(
                      "flex h-10 w-10 items-center justify-center rounded-full text-sm font-medium transition-colors",
                      isSelected
                        ? "bg-primary-800 text-white"
                        : "bg-grey-100 text-[#4F4F4F]"
                    )}
                    isDisabled={isSelected && state.interval.on.length === 1}
                    onClick={() => handleIntervalOnChange(day.number)}
                  >
                    {day.label}
                  </InteractiveContainer>
                );
              })}
            </div>
          )}
          {(state.interval.every === TaskRecurrenceIntervalEvery.month ||
            state.interval.every === TaskRecurrenceIntervalEvery.year) && (
            <ResourcePickerPopover
              items={intervalEveryBasedOptionItems}
              placement="bottom"
              selectionBehavior={{ canToggle: false, mode: "single" }}
              renderTrigger={({ Trigger, triggerRef, isOpen }) => (
                <Trigger
                  ref={triggerRef}
                  as={InteractiveContainer}
                  className="bg-grey-100 flex h-10 w-full min-w-[276px] items-center justify-between rounded-lg p-4 outline-none"
                >
                  <span className="text-dark text-sm">
                    {selectedIntervalEveryBasedOption?.value.label || "-"}
                  </span>
                  <ArrowSmallIcon
                    className={cx(isOpen && "rotate-180 transition-transform")}
                  />
                </Trigger>
              )}
              defaultSelectedIds={
                selectedIntervalEveryBasedOption
                  ? [selectedIntervalEveryBasedOption.id]
                  : undefined
              }
              renderLabel={(item) => item.value.label}
              itemSize="sm"
              spaceBetweenItems="compact"
              onSelect={({ item, onClose: closePopover }) => {
                if (item.value.interval) {
                  updateInterval(item.value.interval);
                }
                closePopover();
              }}
              className="w-fit min-w-[276px]"
            />
          )}
        </div>
        <div className="flex flex-col gap-2">
          <span className="text-sm text-[#828282]">
            {t({
              id: "tasks-page.recurrence-ends",
              defaultMessage: "Ends:",
            })}
          </span>
          <ResourcePickerPopover
            items={endsAtListItems}
            placement="bottom"
            selectionBehavior={{ mode: "single", canToggle: false }}
            renderTrigger={({ Trigger, triggerRef, isOpen }) => (
              <Trigger
                ref={triggerRef}
                as={InteractiveContainer}
                className="bg-grey-100 flex h-10 w-full min-w-[276px] items-center justify-between rounded-lg p-4 outline-none"
              >
                <span className="text-dark text-sm">
                  {selectedEndsAtItem?.value.label || "-"}
                </span>
                <ArrowSmallIcon
                  className={cx(isOpen && "rotate-180 transition-transform")}
                />
              </Trigger>
            )}
            renderLabel={(item) => item.value.label}
            defaultSelectedIds={[selectedEndsAtItemId]}
            itemSize="sm"
            spaceBetweenItems="compact"
            onSelect={({ item, onClose: closePopover }) => {
              handleEndsAtChange(item.value.endsAt);
              closePopover();
            }}
            className="w-fit min-w-[276px]"
          />
          {state.end_at && "date" in state.end_at && (
            <EndAtDatePicker
              endAtDate={new Date(state.end_at.date)}
              onChange={(date) => {
                handleEndsAtChange({
                  date: zonedTimeToUtc(endOfDay(date), state.tz).toISOString(),
                });
              }}
            />
          )}
          {state.end_at && "counter" in state.end_at && (
            <EndAtCounterInput
              counter={state.end_at.counter}
              onChange={(counter) => handleEndsAtChange({ counter })}
            />
          )}
        </div>
        <div className="flex gap-4 pb-2">
          <Button
            fullWidth
            color="grey"
            onClick={() => {
              onClose();
            }}
          >
            {t({
              id: "common.cancel",
              defaultMessage: "Cancel",
            })}
          </Button>
          <Button
            fullWidth
            onClick={() => {
              onSubmit();
              onClose();
            }}
          >
            {t({
              id: "common.save",
              defaultMessage: "Save",
            })}
          </Button>
        </div>
      </div>
    </>
  );
};
