import { EntityInvitationModel, useRestApiProvider } from "@jugl-web/rest-api";
import { FC, useState } from "react";
import {
  Alert,
  Avatar,
  Button,
  PageLoaderFull,
} from "@jugl-web/ui-components/cross-platform";
import { useToast, useTranslations } from "@jugl-web/utils";
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";
import { ReactComponent as TrashIcon } from "./assets/trash-icon.svg";
import { ListItemCard } from "../ListItemCard";
import alertAnimation from "./assets/alert.json";
import { ExpiredInvitationAlert } from "../ExpiredInvitationAlert/ExpiredInvitationAlert";
import { AcceptInvitationAlert } from "../AcceptInvitationAlert";

type EntityInvitationCardProps = {
  invitation: EntityInvitationModel;
};

export const EntityInvitationCard: FC<EntityInvitationCardProps> = (props) => {
  const {
    invitation: { entityName, entityImg, inviterAvatar, inviterName, id },
  } = props;
  const { t } = useTranslations();
  const [rejectInvitationAlertIsOpen, setRejectInvitationAlertIsOpen] =
    useState(false);
  const [acceptInvitationAlertIsOpen, setAcceptInvitationAlertIsOpen] =
    useState(false);
  const [expiredInvitationAlertIsOpen, setExpiredInvitationAlertIsOpen] =
    useState(false);

  const { entitiesApi } = useRestApiProvider();
  const [respondToInvitation, { isLoading }] =
    entitiesApi.useRespondToEntityInviteMutation();
  const { toast } = useToast({ variant: "web" });
  const navigate = useNavigate();

  const handleAcceptInvitationClick = async () => {
    const response = await respondToInvitation({
      inviteId: id,
      action: "accept",
    });
    if ("error" in response && response.error) {
      if ("status" in response.error && response.error.status === 404) {
        toast(
          t({
            id: "feedback.workspace-invitation-not-found",
            defaultMessage: "Invitation not found",
          }),
          { variant: "error" }
        );
      } else {
        toast(
          t({
            id: "feedback.failed-to-accept-workspace-invitation",
            defaultMessage: "Failed to accept invitation",
          }),
          { variant: "error" }
        );
      }
      return;
    }
    const {
      invitation: { entityId },
    } = props;
    setAcceptInvitationAlertIsOpen(false);
    navigate(`/${entityId}/tasks?showWelcomeAlert=true`);
  };

  const handleRemoveInvitation = async () => {
    const response = await respondToInvitation({
      inviteId: id,
      action: "decline",
    });
    if ("error" in response && response.error) {
      return;
    }
    toast(
      t({
        id: "feedback.successfully-declined-workspace-invitation",
        defaultMessage: "Invitation declined successfully",
      })
    );

    setRejectInvitationAlertIsOpen(false);
  };

  return (
    <>
      <PageLoaderFull isActive={isLoading} isTransparent />
      <ListItemCard
        title={entityName}
        avatarSrc={entityImg}
        subTitle={`Invitation from ${inviterName}`}
        subAvatarSrc={inviterAvatar}
        subAvatarTitle={inviterName}
        rightContent={
          <div className="flex items-center gap-4">
            <Button
              size="small"
              onClick={() => setAcceptInvitationAlertIsOpen(true)}
            >
              Join
            </Button>
            <Button
              size="small"
              onClick={() => setRejectInvitationAlertIsOpen(true)}
              color="grey"
              className="px-2"
            >
              <TrashIcon />
            </Button>
          </div>
        }
      />
      <ExpiredInvitationAlert
        isOpen={expiredInvitationAlertIsOpen}
        onClose={() => setExpiredInvitationAlertIsOpen(false)}
      />
      <AcceptInvitationAlert
        isOpen={acceptInvitationAlertIsOpen}
        onClose={() => setAcceptInvitationAlertIsOpen(false)}
        inviterAvatarUrl={inviterAvatar}
        inviterName={inviterName}
        entityAvatar={entityImg}
        entityName={entityName}
        onAccept={handleAcceptInvitationClick}
        isAcceptingLinkInvitation={isLoading}
      />

      <Alert
        isOpen={rejectInvitationAlertIsOpen}
        onRequestClose={() => setRejectInvitationAlertIsOpen(false)}
        img={
          <div className="relative grid h-[150px] w-[150px]">
            <Avatar imageUrl={entityImg} username={entityName} size="5xl" />
            <div className="absolute bottom-0 right-0 flex h-[60px] w-[60px] items-center justify-center rounded-full bg-white">
              <Lottie
                options={{ animationData: alertAnimation }}
                height={40}
                width={40}
              />
            </div>
          </div>
        }
        header="Confirm Action"
        title="Reject Invitation"
        content={
          <span>
            You are trying to reject invitation to the Workspace{" "}
            <span className="text-primary-800 font-semibold">{entityName}</span>
            . This action can&apos;t be undone
          </span>
        }
        buttons={[
          {
            text: "Reject Invitation",
            color: "tertiary",
            onClick: () => handleRemoveInvitation(),
          },
        ]}
      />
    </>
  );
};
