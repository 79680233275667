import React from "react";
import { ReactComponent as ArrowIcon } from "./icons/arrow-left.svg";
import { ReactComponent as Exit } from "./icons/close.svg";

export interface MainSideBarProps {
  children: React.ReactNode;
  footer?: React.ReactNode;
  hasBackButton?: boolean;
  onRequestClose?: () => void;
  title: string;
}

export const MainSideBar: React.FC<MainSideBarProps> = ({
  children,
  footer,
  onRequestClose,
  hasBackButton,
  title,
}) => (
  <>
    <div
      className="fixed inset-0 z-50 flex justify-end outline-none focus:outline-none"
      onClick={onRequestClose}
      onKeyDown={(event) => {
        if (event.key === "Escape") {
          onRequestClose?.();
        }
      }}
      role="presentation"
    >
      <div
        className="flex h-screen w-[400px] flex-col overflow-x-clip border-l bg-white"
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.stopPropagation()}
        role="presentation"
      >
        {title && (
          <div className="w-[calc(100% - 64px)] flex h-[80px] flex-row items-center justify-between border-0 border-b border-solid border-gray-200 px-8">
            <div className="flex flex-row items-center justify-center overflow-hidden whitespace-nowrap">
              {hasBackButton && (
                //  TODO: change to IconButton ??
                <button
                  onClick={onRequestClose}
                  className="mr-4 flex items-center border-0 bg-inherit"
                  type="button"
                >
                  <ArrowIcon />
                </button>
              )}
              <h4 className="text-dark overflow-hidden text-ellipsis text-xl font-bold">
                {title}
              </h4>
            </div>
            <button
              onClick={onRequestClose}
              className="flex items-center border-0 bg-inherit"
              type="button"
            >
              <Exit />
            </button>
          </div>
        )}
        <div className="flex-1 overflow-y-auto overflow-x-hidden">
          {children}
        </div>
        {footer && (
          <div className="flex flex-row justify-between space-x-[10px] px-8 py-7">
            {footer}
          </div>
        )}
      </div>
    </div>
    <div className="bg-grey-background fixed inset-0 z-40 opacity-[0.4]" />
  </>
);

export default MainSideBar;
