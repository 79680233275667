import {
  addCustomerApi,
  addCustomerFormFieldApi,
  addDriveApi,
  addLocationApi,
  addModuleNotificationsApi,
  addSupersetApi,
  addTasksApi,
  addUsersApi,
  addWorkspacesApi,
  baseQuery,
  addEntitiesApi,
  addEntitySpacesApi,
  addReportsApi,
  addLinksApi,
} from "@jugl-web/rest-api";
import { addTasksCommentsApi } from "@jugl-web/rest-api/tasks-comments";
import { addTasksTemplatesApi } from "@jugl-web/rest-api/tasks-templates";
import { createApi } from "@reduxjs/toolkit/query/react";
import { environment } from "@web-src/environments/environment";
import api from "@web-src/features/api/api";
import Geocode from "react-geocode";
import { addAuthApi } from "@jugl-web/rest-api/auth/authApi";
import { addOrdersApi } from "@jugl-web/rest-api/orders";

Geocode.setApiKey(environment.geocodingKey);

export const baseQueryObj = baseQuery(api);

export const emptySplitApi = createApi({
  baseQuery: baseQuery(api),
  endpoints: (build) => ({
    openGraph: build.query({
      query: (url) => ({
        url: `https://opengraph.io/api/1.1/site/${encodeURIComponent(
          url
        )}?app_id=3c6c16c9-66db-465d-b3e0-ae987f90cda4`,
        silentError: true,
      }),
    }),
  }),
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
emptySplitApi._baseQuery = baseQueryObj;

export const locationApi = addLocationApi(emptySplitApi);

export const tasksApi = addTasksApi(emptySplitApi);

export const tasksCommentsApi = addTasksCommentsApi(emptySplitApi);

export const tasksTemplatesApi = addTasksTemplatesApi(emptySplitApi);

export const usersApi = addUsersApi(emptySplitApi);

export const workspacesApi = addWorkspacesApi(emptySplitApi);

export const supersetApi = addSupersetApi(emptySplitApi);

export const { useOpenGraphQuery } = emptySplitApi;

export const customerFormFieldApi = addCustomerFormFieldApi(emptySplitApi);

export const customerApi = addCustomerApi(emptySplitApi);

export const driveApi = addDriveApi(emptySplitApi);

export const moduleNotificationsApi = addModuleNotificationsApi(emptySplitApi);

export const authApi = addAuthApi(emptySplitApi);

export const entitiesApi = addEntitiesApi(emptySplitApi);

export const entitySpacesApi = addEntitySpacesApi(emptySplitApi);

export const reportsApi = addReportsApi(emptySplitApi);

export const linksApi = addLinksApi(emptySplitApi);

export const ordersApi = addOrdersApi(emptySplitApi);
