import { HookOutOfContextError } from "@jugl-web/utils";
import { useLocalStorage } from "@jugl-web/utils/hooks/useStorage";
import { TASK_VIEW_ID_BY_ENTITY_ID_KEY } from "@jugl-web/utils/storage";
import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { TaskView, TaskViewType, useTasksViews } from "../hooks/useTasksViews";

interface TaskViewsContextValue {
  accessibleViews: TaskView[];
  selectedView: TaskView;
  selectView: (viewId: string) => void;
}

const TaskViewsContext = createContext<TaskViewsContextValue | null>(null);

interface TaskViewsProviderProps {
  children: ReactNode;
  entityId: string;
  isCustomerMode?: boolean;
}

type TaskViewIdByEntityId = Record<string, string>;

export const TaskViewsProvider: FC<TaskViewsProviderProps> = ({
  children,
  entityId,
  isCustomerMode,
}) => {
  const { accessibleViews, getViewById } = useTasksViews({
    entityId,
    isCustomerMode,
  });

  const [taskViewIdByEntityId, setTaskViewIdByEntityId] =
    useLocalStorage<TaskViewIdByEntityId>(TASK_VIEW_ID_BY_ENTITY_ID_KEY, {});

  const selectedView = useMemo(
    () => getViewById(taskViewIdByEntityId[entityId] || TaskViewType.default),
    [entityId, getViewById, taskViewIdByEntityId]
  );

  const selectView = useCallback(
    (viewId: string) => {
      setTaskViewIdByEntityId((previousState) => ({
        ...previousState,
        [entityId]: viewId,
      }));
    },
    [entityId, setTaskViewIdByEntityId]
  );

  const contextValue: TaskViewsContextValue = useMemo(
    () => ({ accessibleViews, selectedView, selectView }),
    [accessibleViews, selectedView, selectView]
  );

  return (
    <TaskViewsContext.Provider value={contextValue}>
      {children}
    </TaskViewsContext.Provider>
  );
};

export const useTaskViewsContext = () => {
  const context = useContext(TaskViewsContext);

  if (!context) {
    throw new HookOutOfContextError("useTaskViewsContext", "TaskViewsContext");
  }

  return context;
};
