export enum RecurrenceEndsAtListOption {
  never = "never",
  endsOnDate = "endsOnDate",
  afterOccurrences = "afterOccurrences",
}

export enum RecurrenceListOption {
  none = "none",
  daily = "daily",
  weeklyOnDay = "weeklyOnDay",
  monthlyOnDay = "monthlyOnDate",
  monthlyOnWeekday = "monthlyOnWeekday",
  yearlyOnDate = "yearlyOnDate",
  customSettings = "customSettings",
}
