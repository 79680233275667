import { BaseTask, TaskDefaultStatus } from "@jugl-web/rest-api/tasks";
import { isoToLocalDate } from "@jugl-web/utils/date-time";
import add from "date-fns/add";
import differenceInDays from "date-fns/differenceInDays";
import endOfDay from "date-fns/endOfDay";
import isAfter from "date-fns/isAfter";
import truncate from "lodash/truncate";
import { TEMPLATE_FOLDER_PRESETS } from "./consts";

const MAX_TASK_NAME_LENGTH = 100;

export const getDueDateBasedOnDays = (dueDateInDays: number) =>
  endOfDay(add(new Date(), { days: dueDateInDays - 1 }));

export const getDueDateInDays = (dueDate: Date) =>
  differenceInDays(dueDate, new Date()) + 1;

export interface TransformedActivity {
  custom_fields?: {
    name: string;
    value: string;
    isRemoved?: boolean;
  };
}

export const shortenTaskTitle = (
  title: string,
  maxLength: number = MAX_TASK_NAME_LENGTH
) =>
  truncate(title, {
    length: maxLength,
    omission: "…",
  });

export const isTaskOverdue = <TTask extends BaseTask>(task: TTask) =>
  !!(
    task.due_at &&
    isAfter(new Date(), isoToLocalDate(task.due_at)) &&
    task.status !== TaskDefaultStatus.completed
  );

export const getRandomFolderPreset = () =>
  TEMPLATE_FOLDER_PRESETS[
    Math.floor(Math.random() * TEMPLATE_FOLDER_PRESETS.length)
  ];
