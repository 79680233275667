import { InternalTaskCustomField } from "@jugl-web/rest-api/tasks";
import {
  TaskPropertyButton,
  TaskPropertyButtonProps,
} from "@jugl-web/ui-components/cross-platform/tasks/TaskPropertyButton";
import { cx } from "@jugl-web/utils";
import { forwardRef } from "react";
import { useTaskCustomFieldsDetails } from "../../../../hooks/useTaskCustomFieldsDetails";
import { useFormattedCustomFieldValue } from "../../../../hooks/useFormattedCustomFieldValue";

export interface TaskCustomFieldPropertyButtonProps
  extends TaskPropertyButtonProps {
  field: InternalTaskCustomField;
  rawValue: string;
}

export const TaskCustomFieldPropertyButton = forwardRef<
  HTMLButtonElement,
  TaskCustomFieldPropertyButtonProps
>(({ field, rawValue, ...props }, ref) => {
  const { customFieldTypeToDetails } = useTaskCustomFieldsDetails();
  const { formatCustomFieldValue } = useFormattedCustomFieldValue();

  const hasValue = !!rawValue;
  const Icon = customFieldTypeToDetails[field.type].primaryIcon;

  return (
    <TaskPropertyButton
      ref={ref}
      className={cx(
        "max-w-[250px]",
        hasValue && "bg-secondary-50 text-secondary-600"
      )}
      startIcon={
        <Icon className={cx(hasValue ? "text-secondary-600" : "text-grey")} />
      }
      {...props}
    >
      {hasValue
        ? `${field.name}: ${formatCustomFieldValue(field, rawValue)}`
        : field.name}
    </TaskPropertyButton>
  );
});
