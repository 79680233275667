import { useTranslations } from "@jugl-web/utils";
import { FC, FocusEvent } from "react";

interface EndAtCounterInputProps {
  counter: number;
  onChange: (counter: number) => void;
}

export const EndAtCounterInput: FC<EndAtCounterInputProps> = ({
  counter,
  onChange,
}) => {
  const { t } = useTranslations();

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (!event.target.validity.valid) {
      event.target.value = counter.toString();
      return;
    }

    const updatedCounter = Number(event.target.value);

    onChange(updatedCounter);
    event.target.value = updatedCounter.toString(); // removes leading zeros
  };

  return (
    <div className="bg-grey-100 flex h-10 w-full items-center gap-4 rounded-lg p-4">
      <input
        type="number"
        min={1}
        max={100}
        required
        className="text-dark w-8 border-none bg-transparent text-center text-sm outline-none [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
        defaultValue={counter}
        onBlur={handleBlur}
      />
      <span className="text-sm text-[#828282]">
        {t({
          id: "tasks-page.occurrences",
          defaultMessage: "occurrences",
        })}
      </span>
    </div>
  );
};
