import { cx } from "@jugl-web/utils";
import { forwardRef } from "react";

interface BackdropProps {
  isVisible?: boolean;
}

export const Backdrop = forwardRef<HTMLDivElement, BackdropProps>(
  ({ isVisible = true }, ref) => (
    <div
      ref={ref}
      className={cx("fixed inset-0 z-50", isVisible && "backdrop-blur-[1px]")}
      style={{
        backgroundColor: isVisible ? "rgba(14, 14, 14, 0.66)" : undefined,
      }}
    />
  )
);
