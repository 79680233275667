import { useRestApiProvider, InvitationLinkResponse } from "@jugl-web/rest-api";
import { FC, useEffect, useState } from "react";
import {
  useToast,
  useTranslations,
  apiUTCToLocalDateTime,
} from "@jugl-web/utils";
import { useNavigate } from "react-router-dom";
import { ENTITY_LINK_INVITATION } from "@jugl-web/utils/storage";
import { useSessionStorage } from "@jugl-web/utils/hooks/useStorage";
import { differenceInMinutes } from "date-fns";
import { ExpiredInvitationAlert } from "../ExpiredInvitationAlert";
import { AcceptInvitationAlert } from "../AcceptInvitationAlert";
import { EntityAlreadyAddedAlert } from "../EntityAlreadyAddedAlert";
import { useEntitySelectPageProvider } from "../../EntitySelectPageProvider";

const EXPIRATION_MINUTES = 120;

export const EntityLinkInvitationAlert: FC = () => {
  const {
    entitiesState: { entities, isLoading: isEntitiesLoading },
  } = useEntitySelectPageProvider();

  const [acceptInvitationAlertIsOpen, setAcceptInvitationAlertIsOpen] =
    useState(false);
  const [expiredInvitationAlertIsOpen, setExpiredInvitationAlertIsOpen] =
    useState(false);
  const [entityAlreadyAddedAlertIsOpen, setEntityAlreadyAddedAlertIsOpen] =
    useState(false);

  const { t } = useTranslations();
  const { entitiesApi } = useRestApiProvider();
  const { toast } = useToast({ variant: "web" });
  const navigate = useNavigate();

  const [entityLinkInvitation] =
    useSessionStorage<InvitationLinkResponse | null>(
      ENTITY_LINK_INVITATION,
      null
    );

  const [acceptLinkInvitation, { isLoading: isAcceptingLinkInvitation }] =
    entitiesApi.useAcceptEntityInvitationByLinkMutation();

  const handleAcceptInvitationClick = async () => {
    if (!entityLinkInvitation) return;
    const response = await acceptLinkInvitation({
      inviteId: entityLinkInvitation.id,
      entityId: entityLinkInvitation.entity.id,
    });
    if ("error" in response && response.error) {
      toast(
        t({
          id: "feedback.failed-to-accept-workspace-invitation",
          defaultMessage: "Failed to accept invitation",
        }),
        { variant: "error" }
      );
      return;
    }

    setAcceptInvitationAlertIsOpen(false);
    navigate(`/${entityLinkInvitation.entity.id}/tasks?showWelcomeAlert=true`);
  };

  useEffect(() => {
    if (!entityLinkInvitation || isEntitiesLoading) return;
    sessionStorage.removeItem(ENTITY_LINK_INVITATION);
    const isEntityAlreadyAdded = entities?.some(
      (entity) => entity.id === entityLinkInvitation.entity.id
    );
    if (isEntityAlreadyAdded) {
      setEntityAlreadyAddedAlertIsOpen(true);
      return;
    }
    const date = new Date(
      apiUTCToLocalDateTime(entityLinkInvitation.valid_till)
    );
    const now = new Date();
    const isLinkExpired = differenceInMinutes(now, date) > EXPIRATION_MINUTES;
    if (isLinkExpired) {
      setExpiredInvitationAlertIsOpen(true);
    } else {
      setAcceptInvitationAlertIsOpen(true);
    }
  }, [entityLinkInvitation, entities, isEntitiesLoading]);

  if (!entityLinkInvitation) {
    return <></>;
  }

  return (
    <>
      <EntityAlreadyAddedAlert
        isOpen={entityAlreadyAddedAlertIsOpen}
        onClose={() => setEntityAlreadyAddedAlertIsOpen(false)}
        entityAvatar={entityLinkInvitation.entity.img}
        entityName={entityLinkInvitation.entity.name}
      />
      <ExpiredInvitationAlert
        isOpen={expiredInvitationAlertIsOpen}
        onClose={() => setExpiredInvitationAlertIsOpen(false)}
      />
      <AcceptInvitationAlert
        isOpen={acceptInvitationAlertIsOpen}
        onClose={() => setAcceptInvitationAlertIsOpen(false)}
        inviterAvatarUrl={entityLinkInvitation.created_by.img}
        inviterName={entityLinkInvitation.created_by.name}
        entityAvatar={entityLinkInvitation.entity.img}
        entityName={entityLinkInvitation.entity.name}
        onAccept={handleAcceptInvitationClick}
        isAcceptingLinkInvitation={isAcceptingLinkInvitation}
      />
    </>
  );
};
