import { ScreenTransitionWrapper } from "@jugl-web/ui-components/cross-platform/ScreenTransitionWrapper";
import {
  BottomCenteredDrawer,
  BottomCenteredDrawerProps,
} from "@jugl-web/ui-components/web/BottomCenteredDrawer";
import {
  Breadcrumb,
  DRAWER_HEADER_HEIGHT_PX,
} from "@jugl-web/ui-components/web/DrawerHeader";
import { ExpandableSearchBar } from "@jugl-web/ui-components/web/ExpandableSearchBar";
import { useTranslations } from "@jugl-web/utils";
import {
  Screen,
  ScreenTransitionManagerProvider,
  useScreenTransitionManager,
} from "@jugl-web/utils/utils/ScreenTransitionManager";
import { FC, useEffect, useMemo, useState } from "react";
import { ManageTaskFieldsHintDialog } from "./ManageTaskFieldsHintDialog";
import { EditCustomFieldScreen } from "./screens/EditCustomFieldScreen";
import { EntryScreen } from "./screens/EntryScreen";
import { LabelsScreen } from "./screens/LabelsScreen";
import { NewCustomDropdownFieldValuesScreen } from "../NewCustomDropdownFieldValuesScreen";
import { NewCustomFieldScreen } from "../NewCustomFieldScreen/NewCustomFieldScreen";
import { StatusesScreen } from "./screens/StatusesScreen";
import { ManageTaskFieldsDialogScreenToParametersMap } from "./types";

const InnerManageTaskFieldsDialog: FC<ManageTaskFieldsDialogProps> = ({
  isOpen,
  entityId,
  initialScreen,
  onClose,
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const { screen, transitionTo, renderContent, transitionRequest$ } =
    useScreenTransitionManager<ManageTaskFieldsDialogScreenToParametersMap>();

  const { t } = useTranslations();

  const breadcrumbs = useMemo<Breadcrumb[]>(() => {
    const entryBreadcrumb: Breadcrumb = {
      id: "entry",
      title: t({
        id: "tasks-page.manage-task-fields",
        defaultMessage: "Manage task fields",
      }),
      onClick:
        screen.name !== "entry"
          ? () => transitionTo({ name: "entry" })
          : undefined,
    };

    return renderContent({
      entry: [entryBreadcrumb],
      labels: [
        entryBreadcrumb,
        {
          id: "labels",
          title: t({
            id: "tasks-page.labels",
            defaultMessage: "Labels",
          }),
        },
      ],
      statuses: [
        entryBreadcrumb,
        {
          id: "statuses",
          title: t({
            id: "tasks-page.statuses",
            defaultMessage: "Statuses",
          }),
        },
      ],
      newCustomField: [
        entryBreadcrumb,
        {
          id: "newCustomField",
          title: t({
            id: "tasks-page.new-custom-field",
            defaultMessage: "New custom field",
          }),
        },
      ],
      newCustomDropdownFieldValues: [
        entryBreadcrumb,
        {
          id: "newCustomField",
          title: t({
            id: "tasks-page.new-custom-field",
            defaultMessage: "New custom field",
          }),
        },
      ],
      editCustomField: [
        entryBreadcrumb,
        {
          id: "editCustomField",
          title: t({
            id: "tasks-page.edit-custom-field",
            defaultMessage: "Edit custom field",
          }),
        },
      ],
    });
  }, [renderContent, screen.name, transitionTo, t]);

  const header = useMemo<BottomCenteredDrawerProps["header"]>(() => {
    const navigationSlots = (() => {
      if (screen.name === "labels" || screen.name === "statuses") {
        return [
          <ExpandableSearchBar
            onSearch={setSearchQuery}
            color="grey"
            size="md"
          />,
        ];
      }

      return undefined;
    })();

    return {
      type: "breadcrumbs",
      breadcrumbs,
      navigationSlots,
    };
  }, [breadcrumbs, screen.name]);

  const content = useMemo<JSX.Element>(
    () =>
      renderContent({
        entry: <EntryScreen entityId={entityId} />,
        labels: <LabelsScreen entityId={entityId} searchQuery={searchQuery} />,
        statuses: (
          <StatusesScreen entityId={entityId} searchQuery={searchQuery} />
        ),
        newCustomField: (parameters) => (
          <NewCustomFieldScreen
            entityId={entityId}
            onAfterCreateTaskField={() => transitionTo({ name: "entry" })}
            onCancel={() => transitionTo({ name: "entry" })}
            {...parameters}
          />
        ),
        newCustomDropdownFieldValues: (parameters) => (
          <NewCustomDropdownFieldValuesScreen
            entityId={entityId}
            onAfterCreateTaskField={() =>
              transitionTo({ name: "entry" }, { force: true })
            }
            {...parameters}
          />
        ),
        editCustomField: (parameters) => (
          <EditCustomFieldScreen entityId={entityId} {...parameters} />
        ),
      }),
    [entityId, renderContent, searchQuery, transitionTo]
  );

  const handleSafeClose = () => {
    if (transitionRequest$.observed) {
      transitionRequest$.next({ resolve: onClose });
    } else {
      onClose();
    }
  };

  const handleDrawerTransitionEnd = () => {
    if (!isOpen) {
      transitionTo(initialScreen, { force: true });
    }
  };

  useEffect(() => {
    transitionTo(initialScreen, { force: true });
  }, [initialScreen, transitionTo]);

  useEffect(() => {
    setSearchQuery("");
  }, [screen]);

  return (
    <BottomCenteredDrawer
      isOpen={isOpen}
      header={header}
      onClose={handleSafeClose}
      onTransitionEnd={handleDrawerTransitionEnd}
    >
      <ScreenTransitionWrapper
        screenName={screen.name}
        style={{ height: `calc(100% - ${DRAWER_HEADER_HEIGHT_PX}px)` }}
      >
        {content}
      </ScreenTransitionWrapper>
      <ManageTaskFieldsHintDialog />
    </BottomCenteredDrawer>
  );
};

interface ManageTaskFieldsDialogProps {
  isOpen: boolean;
  entityId: string;
  initialScreen: Screen<ManageTaskFieldsDialogScreenToParametersMap>;
  onClose: () => void;
}

export const ManageTaskFieldsDialog: FC<ManageTaskFieldsDialogProps> = (
  props
) => {
  const { initialScreen } = props;

  return (
    <ScreenTransitionManagerProvider initialScreen={initialScreen}>
      <InnerManageTaskFieldsDialog {...props} />
    </ScreenTransitionManagerProvider>
  );
};
