export const secondsToTime = (seconds: number) => {
  let firstCharacterIdx = 15;

  if (seconds >= 3600) {
    firstCharacterIdx = 11;
  }

  if (seconds >= 600) {
    firstCharacterIdx = 14;
  }

  return new Date(seconds * 1000).toISOString().slice(firstCharacterIdx, 19);
};
