import getDate from "date-fns/getDate";
import getDay from "date-fns/getDay";

/**
 * Returns the ordinal of the weekday in the month like 1st (Monday), 2nd (Monday), etc.
 */
export const getWeekdayOrdinalInGivenMonth = (date: Date) => {
  const dayOfMonth = getDate(date);

  return Math.ceil(dayOfMonth / 7);
};

/**
 * Returns the day of the week as an ordinal number (1-7) where 1 is Monday and 7 is Sunday.
 */
export const getDayOfWeek = (date: Date) => {
  const day = getDay(date);
  return day === 0 ? 7 : day;
};
