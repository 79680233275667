import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { usePopper } from "react-popper";
import { useTranslations } from "@jugl-web/utils";
import { ConfirmationPopup } from "@jugl-web/ui-components/cross-platform";
import UserProfileName from "@web-src/features/users/components/UserProfileName";
import { ReactComponent as MicrophoneOffIcon } from "./assets/microphone-off.svg";
import { ReactComponent as CallCutIcon } from "./assets/call-cut.svg";
import { ReactComponent as WhiteMicrophoneOffIcon } from "./assets/white-microphone-off.svg";
import { ReactComponent as MicrophoneOnIcon } from "./assets/microphone-on.svg";
import { ReactComponent as SettingsOffIcon } from "./assets/settings-off.svg";
import { ReactComponent as ScreenShareOnIcon } from "./assets/share-screen.svg";
import { ReactComponent as ScreenShareOffIcon } from "./assets/share-screen-off.svg";
import { ReactComponent as VideoOffIcon } from "./assets/video-off.svg";
import { ReactComponent as VideoOnIcon } from "./assets/video-on.svg";
import { ReactComponent as WhiteVideoOffIcon } from "./assets/white-video-off.svg";
import { ReactComponent as MinimizeIcon } from "./assets/minimize.svg";
import { ReactComponent as MaximizeIcon } from "./assets/maximize.svg";
import ActiveCallDeviceSelector from "./components/ActiveCallDeviceSelector";
import { ToggleButton } from "../ToggleButton";
import { ReactComponent as WarningIcon } from "./assets/warning.svg";

type OptionsToHide =
  | "toggleVideo"
  | "toggleMic"
  | "toggleScreenShare"
  | "toggleSettings"
  | "toggleMinimize"
  | "endCall";

const ControlPanel: React.FC<{
  toggleVideo: () => void;
  toggleMic: () => void;
  toggleScreenShare: () => void;
  toggleMinimize: () => void;
  endCall: () => void;
  isVideoOn: boolean;
  isMicOn: boolean;
  isScreenShared: boolean;
  isMinimized: boolean;
  optionsToHide?: OptionsToHide[];
  buttonsClassName?: string;
  isOnBg?: string;
  isOffBg?: string;
  buttonTextVariant?: "dark";
  screenSharingId?: string;
  primaryButtons?: boolean;
  disableMediaControl?: boolean;
}> = ({
  toggleMic,
  toggleScreenShare,
  toggleVideo,
  toggleMinimize,
  endCall,
  isVideoOn,
  isMicOn,
  isScreenShared,
  isMinimized,
  optionsToHide,
  buttonsClassName,
  isOnBg,
  isOffBg,
  buttonTextVariant,
  screenSharingId,
  primaryButtons,
  disableMediaControl,
}) => {
  const [isSettingsOpen, setSettingsOpen] = useState<boolean>(false);
  const [isConfirmScreenShareOpen, setConfirmScreenShareOpen] =
    useState<boolean>(false);
  const { t } = useTranslations();
  const [settingsBtnRef, setSettingsBtnRef] =
    useState<HTMLButtonElement | null>();
  const [settingsPopoverRef, setSettingsPopoverRef] =
    useState<HTMLElement | null>();
  const handleToggleSettings = () => {
    setSettingsOpen(!settingsPopoverRef);
  };
  const handleEndCall = () => {
    endCall();
  };
  const { styles, attributes, forceUpdate } = usePopper(
    settingsBtnRef,
    settingsPopoverRef,
    {
      placement: "auto",
      modifiers: [
        {
          name: "preventOverflow",
        },
        {
          name: "offset",
          options: {
            offset: [0, -50],
          },
        },
      ],
    }
  );

  useEffect(() => {
    if (settingsPopoverRef && settingsBtnRef && forceUpdate) {
      forceUpdate();
    }
  }, [forceUpdate, settingsBtnRef, settingsPopoverRef, isSettingsOpen]);
  return (
    <>
      {Boolean(settingsPopoverRef) && (
        <div className="bg-dark/30 fixed inset-0 z-40 backdrop-blur-[1px]" />
      )}
      <ToggleButton
        disabled={disableMediaControl}
        onClick={toggleMic}
        isOn={isMicOn}
        onIcon={primaryButtons ? WhiteMicrophoneOffIcon : MicrophoneOffIcon}
        offIcon={MicrophoneOnIcon}
        textVariant={buttonTextVariant}
        hide={optionsToHide?.includes("toggleMic")}
        text={
          isMicOn
            ? t({ id: "call-control-panel-mute", defaultMessage: "Mute" })
            : t({
                id: "call-control-panel-start-mic",
                defaultMessage: "Start Mic",
              })
        }
        className={!isMicOn ? isOnBg : isOffBg}
      />
      <ToggleButton
        disabled={disableMediaControl}
        onClick={toggleVideo}
        isOn={isVideoOn}
        onIcon={primaryButtons ? WhiteVideoOffIcon : VideoOffIcon}
        offIcon={VideoOnIcon}
        hide={optionsToHide?.includes("toggleVideo")}
        textVariant={buttonTextVariant}
        text={
          isVideoOn
            ? t({
                id: "call-control-panel-stop-video",
                defaultMessage: "Stop Video",
              })
            : t({
                id: "call-control-panel-start-video",
                defaultMessage: "Start Video",
              })
        }
        className={!isVideoOn ? isOnBg : isOffBg}
      />

      {!optionsToHide?.includes("toggleSettings") && (
        <Popover className="relative">
          <PopoverButton
            className="border-0 bg-transparent"
            ref={setSettingsBtnRef}
            onClick={handleToggleSettings}
          >
            <ToggleButton
              textVariant={buttonTextVariant}
              isOn={!settingsPopoverRef}
              onIcon={SettingsOffIcon}
              offIcon={SettingsOffIcon}
              noFill
              text={t({
                id: "call-control-panel-settings",
                defaultMessage: "Settings",
              })}
              className={settingsPopoverRef ? isOnBg : isOffBg}
            />
          </PopoverButton>
          <PopoverPanel
            className="absolute z-50 min-w-[325px]"
            ref={setSettingsPopoverRef}
            style={styles.popper}
            {...attributes.popper}
          >
            <ActiveCallDeviceSelector
              isOpen={isSettingsOpen}
              onClose={() => settingsBtnRef?.click()}
            />
          </PopoverPanel>
        </Popover>
      )}
      <ToggleButton
        textVariant={buttonTextVariant}
        onClick={toggleMinimize}
        isOn={!isMinimized}
        onIcon={MaximizeIcon}
        offIcon={MinimizeIcon}
        hide={optionsToHide?.includes("toggleMinimize")}
        noBgChange
        className={buttonsClassName}
      />
      <ToggleButton
        textVariant={buttonTextVariant}
        onClick={() => {
          if (screenSharingId && !isScreenShared) {
            setConfirmScreenShareOpen(true);
          } else {
            toggleScreenShare();
          }
        }}
        isOn={!isScreenShared}
        onIcon={ScreenShareOffIcon}
        offIcon={ScreenShareOnIcon}
        hide={optionsToHide?.includes("toggleScreenShare")}
        text={
          isScreenShared
            ? t({
                id: "call-control-panel-stop-screen-share",
                defaultMessage: "Stop Share",
              })
            : t({
                id: "call-control-panel-start-screen-share",
                defaultMessage: "Share",
              })
        }
      />
      <ToggleButton
        textVariant={buttonTextVariant}
        onClick={handleEndCall}
        isOn={false}
        onIcon={CallCutIcon}
        offIcon={CallCutIcon}
        className="bg-gradients-danger h-[56px] w-[56px] rounded-full hover:brightness-90"
        text={t({
          id: "call-control-panel-end-call",
          defaultMessage: "End Call",
        })}
        hide={optionsToHide?.includes("endCall")}
      />
      <ConfirmationPopup
        variant="web"
        title={t({
          id: "call-conference.start-sharing-confrimation-modal-title",
          defaultMessage: "Screen Share",
        })}
        message={
          <div>
            <WarningIcon />{" "}
            {t(
              {
                id: "call-conference.start-sharing-confirmation-modal-description",
                defaultMessage:
                  "Only one meeting member can screen share at a time. Your screen sharing will replace {user}'s demo.",
              },
              {
                user: <UserProfileName userId={screenSharingId} />,
              }
            )}
          </div>
        }
        isOpen={isConfirmScreenShareOpen}
        hasCancelButton
        buttons={[
          {
            color: "primary",
            label: t({
              id: "call-conference.start-sharing-confrimation-modal-confirm-button",
              defaultMessage: "Start sharing",
            }),
            onClick: () => {
              toggleScreenShare();
              setConfirmScreenShareOpen(false);
            },
          },
        ]}
        onRequestClose={() => setConfirmScreenShareOpen(false)}
      />
    </>
  );
};

export default ControlPanel;
