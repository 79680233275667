import { Menu, PlainButton } from "@jugl-web/ui-components";
import { useTranslations } from "@jugl-web/utils";
import { PreviewTask } from "@jugl-web/rest-api/tasks";
import { useState } from "react";
import { ReactComponent as MenuIcon } from "./assets/menu.svg";
import { ReactComponent as ArchiveIcon } from "./assets/archive.svg";
import { ArchiveTaskDialog } from "./ArchiveTaskDialog";

export const TaskMenu = ({
  task,
  onToggle,
}: {
  task: PreviewTask;
  onToggle: (isOpen: boolean) => void;
}) => {
  const { t } = useTranslations();
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);

  return (
    <>
      <Menu
        placement="bottom-end"
        renderTrigger={({ Trigger, triggerRef, isOpen }) => {
          onToggle(isOpen);
          return (
            <Trigger
              as={PlainButton}
              ref={triggerRef}
              className="flex h-[34px] w-[34px] items-center justify-center rounded-lg"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <MenuIcon />
            </Trigger>
          );
        }}
        onSelect={(_item, _event, close) => close()}
        sections={[
          [
            {
              id: "archive-task",
              label: t({
                id: "tasks-page.archive-task",
                defaultMessage: "Archive Task",
              }),
              onSelect: () => setIsArchiveDialogOpen(true),
              icon: <ArchiveIcon />,
            },
          ],
        ]}
      />
      <ArchiveTaskDialog
        taskId={task.id}
        taskName={task.name}
        isOpen={isArchiveDialogOpen}
        onClose={() => setIsArchiveDialogOpen(false)}
      />
    </>
  );
};
