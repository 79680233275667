import { TaskCustomFieldType } from "@jugl-web/rest-api/tasks";
import { useTranslations } from "@jugl-web/utils";
import { ReactComponent as TextIcon } from "./assets/text.svg";
import { ReactComponent as DateIcon } from "./assets/date.svg";
import { ReactComponent as DropdownIcon } from "./assets/dropdown.svg";
import { ReactComponent as NumberIcon } from "./assets/number.svg";
import { ReactComponent as CustomDateFieldBlankIcon } from "./assets/custom-date-field-blank.svg";
import { ReactComponent as CustomDropdownFieldBlankIcon } from "./assets/custom-dropdown-field-blank.svg";
import { ReactComponent as CustomNumberFieldBlankIcon } from "./assets/custom-number-field-blank.svg";
import { ReactComponent as CustomTextFieldBlankIcon } from "./assets/custom-text-field-blank.svg";

interface CustomFieldTypeDetails {
  color: string;
  primaryIcon: React.FC<React.SVGProps<SVGSVGElement>>;
  secondaryIcon: React.FC<React.SVGProps<SVGSVGElement>>;
  shortLabel: string;
  fullLabel: string;
}
export const useTaskCustomFieldsDetails = () => {
  const { t } = useTranslations();

  const customFieldTypeToDetails: Record<
    TaskCustomFieldType,
    CustomFieldTypeDetails
  > = {
    text: {
      color: "#EBFFF5",
      primaryIcon: CustomTextFieldBlankIcon,
      shortLabel: t({
        id: "tasks-page.text",
        defaultMessage: "Text",
      }),
      secondaryIcon: TextIcon,
      fullLabel: t({
        id: "tasks-page.text-field",
        defaultMessage: "Text field",
      }),
    },
    date: {
      color: "#FFF7EB",
      primaryIcon: CustomDateFieldBlankIcon,
      shortLabel: t({
        id: "tasks-page.date",
        defaultMessage: "Date",
      }),
      secondaryIcon: DateIcon,
      fullLabel: t({
        id: "tasks-page.date-field",
        defaultMessage: "Date field",
      }),
    },
    dropdown: {
      color: "#E3F2FD",
      primaryIcon: CustomDropdownFieldBlankIcon,
      shortLabel: t({
        id: "tasks-page.dropdown",
        defaultMessage: "Dropdown",
      }),
      secondaryIcon: DropdownIcon,
      fullLabel: t({
        id: "tasks-page.dropdown-field",
        defaultMessage: "Dropdown field",
      }),
    },
    number: {
      color: "#FDEBFF",
      primaryIcon: CustomNumberFieldBlankIcon,
      shortLabel: t({
        id: "tasks-page.number",
        defaultMessage: "Number",
      }),
      secondaryIcon: NumberIcon,
      fullLabel: t({
        id: "tasks-page.numeric-field",
        defaultMessage: "Numeric field",
      }),
    },
  };

  return {
    customFieldTypeToDetails,
  };
};
