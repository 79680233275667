import { ModuleNotificationsDialog } from "@jugl-web/domain-resources/module-notifications/components/ModuleNotificationsDialog";
import { useModuleNotificationsUnreadIndicator } from "@jugl-web/domain-resources/module-notifications/hooks/useModuleNotificationsUnreadIndicator";
import { ManageTaskFieldsDialog } from "@jugl-web/domain-resources/tasks/components/ManageTaskFieldsDialog";
import { ManageTaskFieldsDialogScreenToParametersMap } from "@jugl-web/domain-resources/tasks/components/ManageTaskFieldsDialog/types";
import { EntityParticipantRole } from "@jugl-web/rest-api/entities";
import { MultiSectionLayout } from "@jugl-web/ui-components/cross-platform/content/MultiSectionLayout";
import {
  HeaderBreadcrumbs,
  HeaderBreadcrumbsItem,
} from "@jugl-web/ui-components/web/HeaderBreadcrumbs";
import { cx } from "@jugl-web/utils";
import { Screen } from "@jugl-web/utils/utils/ScreenTransitionManager";
import { ManageTaskFieldsTopBarButton } from "@web-src/components/ManageTaskFieldsTopBarButton";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { FC, ReactNode, useMemo, useState } from "react";
import { useFCM } from "@web-src/modules/notifications/providers/FCMProvider";
import { OnboardingProgressBar } from "@web-src/modules/preferences/components/OnboardingProgressBar";
import { useOnboarding } from "@web-src/modules/preferences/providers";
import { NotificationsButton } from "../NotificationsButton";

interface TasksPageLayoutProps {
  children: ReactNode;
  breadcrumbs: HeaderBreadcrumbsItem[];
  topBarRightContent?: ReactNode;
  hideNotifications?: boolean;
  hideRightContent?: boolean;
  className?: string;
}

export const TasksPageLayout = ({
  children,
  breadcrumbs,
  topBarRightContent,
  hideNotifications,
  hideRightContent,
  className,
}: TasksPageLayoutProps) => {
  const [isManageFieldsDialogOpen, setIsManageFieldsDialogOpen] =
    useState(false);

  const [isNotificationsDialogOpen, setIsNotificationsDialogOpen] =
    useState(false);

  const initialManageFieldsDialogScreen = useMemo<
    Screen<ManageTaskFieldsDialogScreenToParametersMap>
  >(() => ({ name: "entry" }), []);

  const { entity } = useEntitySelectedProvider();
  const { isOnboardingActive } = useOnboarding();
  const { navigateToPage } = useNavigation();
  const { logEvent } = useFCM();
  const {
    isUnread: hasUnreadNotifications,
    markAsRead: markNotificationsAsRead,
  } = useModuleNotificationsUnreadIndicator({
    entityId: entity.id,
    module: "task",
  });

  const isAdmin = entity.role === EntityParticipantRole.admin;

  const handleTaskClick = (taskId: string) =>
    navigateToPage("tasksDetails", { taskId });

  return (
    <>
      <MultiSectionLayout
        header={
          <HeaderBreadcrumbs
            items={breadcrumbs}
            rightContent={
              <div
                className={cx("flex items-center gap-6", {
                  hidden: hideRightContent,
                })}
              >
                {topBarRightContent}
                {isAdmin && (
                  <ManageTaskFieldsTopBarButton
                    onClick={() => setIsManageFieldsDialogOpen(true)}
                  />
                )}
                {!hideNotifications && (
                  <NotificationsButton
                    hasUnreadIndicator={hasUnreadNotifications}
                    onClick={() => {
                      setIsNotificationsDialogOpen(true);
                      markNotificationsAsRead();
                    }}
                  />
                )}
              </div>
            }
            disableShadow
          />
        }
      >
        <div className={cx("flex h-full flex-col bg-gray-100", className)}>
          {children}
          {isOnboardingActive && <OnboardingProgressBar />}
        </div>
      </MultiSectionLayout>
      <ManageTaskFieldsDialog
        isOpen={isManageFieldsDialogOpen}
        entityId={entity.id}
        initialScreen={initialManageFieldsDialogScreen}
        onClose={() => setIsManageFieldsDialogOpen(false)}
      />
      <ModuleNotificationsDialog
        isOpen={isNotificationsDialogOpen}
        entityId={entity.id}
        module="task"
        onNotificationClick={(log) => {
          handleTaskClick(log.task.id);
          setIsNotificationsDialogOpen(false);
          logEvent("action_task_notification");
        }}
        onClose={() => setIsNotificationsDialogOpen(false)}
      />
    </>
  );
};

TasksPageLayout.ControlBar = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => (
  <div
    className={cx(
      "flex h-[72px] shrink-0 items-center justify-between gap-4 bg-[#EEF2F5] px-8",
      className
    )}
  >
    {children}
  </div>
);

export const TASKS_PAGE_LAYOUT_CONTENT_CONTAINER_ID =
  "tasks-page-layout-content-container";

type ContentBackgroundColor = "white" | "grey";

interface ContentProps {
  children: ReactNode;
  backgroundColor?: ContentBackgroundColor;
  hasGradient?: boolean;
  className?: string;
}

const backgroundColorToClasses: Record<ContentBackgroundColor, string> = {
  white: "bg-white",
  grey: "bg-[#EEF2F5]",
};

TasksPageLayout.Content = (({
  children,
  backgroundColor = "white",
  hasGradient = false,
  className,
}) => (
  <div
    className={cx(
      "relative h-full overflow-x-auto",
      backgroundColorToClasses[backgroundColor],
      className
    )}
  >
    <div
      className={cx(
        "pointer-events-none absolute inset-0 transition-opacity duration-300",
        hasGradient ? "opacity-100" : "opacity-0"
      )}
      style={{
        background: "linear-gradient(180deg, #DCE5E9 12.64%, #B2D2E4 100%)",
      }}
    />
    <div
      id={TASKS_PAGE_LAYOUT_CONTENT_CONTAINER_ID}
      className="relative flex h-full flex-col overflow-x-auto"
    >
      {children}
    </div>
  </div>
)) as FC<ContentProps>;
