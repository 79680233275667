import {
  PaginatedRequestParams,
  PaginatedResponse,
  RtkEmptySplitApi,
} from "../../types";
import { CustomerApiTags } from "./tags";
import { Customer, NewCustomer } from "./types";

export const addCustomerApi = (emptySplitApi: RtkEmptySplitApi) => {
  const apiWithTags = emptySplitApi.enhanceEndpoints({
    addTagTypes: [CustomerApiTags.customer, CustomerApiTags.customersList],
  });
  const customerApi = apiWithTags.injectEndpoints({
    overrideExisting: false,
    endpoints: (build) => ({
      customer: build.query<
        { data: Customer },
        {
          entityId: string;
          customerId: string;
        }
      >({
        query: ({ entityId, customerId }) => ({
          url: `/api/auth/entity/${entityId}/customers/${customerId}`,
        }),
        providesTags: [CustomerApiTags.customer],
      }),
      customersList: build.query<
        PaginatedResponse<Customer>,
        {
          entityId: string;
          params: PaginatedRequestParams<{
            search_term?: string;
          }>;
        }
      >({
        query: ({ entityId, params }) => ({
          url: `/api/auth/entity/${entityId}/customers`,
          params,
        }),
        providesTags: [CustomerApiTags.customersList],
      }),
      addCustomer: build.mutation<
        { data: Customer },
        {
          entityId: string;
          fields: NewCustomer;
          logoFile?: File;
        }
      >({
        query: ({ entityId, fields, logoFile }) => {
          const formData = new FormData();
          formData.set("fields", JSON.stringify(fields));
          if (logoFile) formData.set("logo_file", logoFile);

          return {
            url: `/api/auth/entity/${entityId}/customers`,
            data: formData,
            method: "POST",
            headers: { "Content-Type": "multipart/form-data" },
          };
        },
        invalidatesTags: [CustomerApiTags.customersList],
      }),
      updateCustomer: build.mutation<
        { data: Customer },
        {
          entityId: string;
          fields: NewCustomer;
          logoFile?: File;
          customerId: string;
        }
      >({
        query: ({ entityId, customerId, fields, logoFile }) => {
          const binData = new FormData();
          binData.append("fields", JSON.stringify(fields));
          if (logoFile) binData.set("logo_file", logoFile);

          return {
            url: `/api/auth/entity/${entityId}/customers/${customerId}`,
            data: binData,
            method: "PATCH",
          };
        },
        invalidatesTags: [
          CustomerApiTags.customer,
          CustomerApiTags.customersList,
        ],
      }),
      deleteCustomer: build.mutation<
        void,
        {
          entityId: string;
          customerId: string;
        }
      >({
        query: ({ entityId, customerId }) => ({
          url: `/api/auth/entity/${entityId}/customers/${customerId}`,
          method: "DELETE",
        }),
        invalidatesTags: [CustomerApiTags.customersList],
      }),
    }),
  });

  return customerApi;
};

export type CustomerApi = ReturnType<typeof addCustomerApi>;
