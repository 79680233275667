import { useCallback, useState } from "react";

export const useRerender = () => {
  const [, setCounter] = useState(0);

  return useCallback(() => {
    setTimeout(() => {
      setCounter((c) => c + 1);
    }, 1);
  }, []);
};
