import { EntityParticipantRole } from "@jugl-web/rest-api";
import { SubscriptionPlanModuleId } from "@jugl-web/rest-api/entities/models/common-types/SubscriptionPlanModuleId";
import { useTranslations } from "@jugl-web/utils";
import {
  getStorageItemWithFallback,
  saveItemToStorage,
  SIDEBAR_EXPANDED_KEY,
} from "@jugl-web/utils/storage";
import { useIntercom } from "@web-src/components/IntercomProvider";
import { useUnreadIndicators } from "@web-src/components/UnreadIndicatorsProvider";
import { useUserAuth } from "@web-src/modules/auth/providers/UserAuthProvider";
import { isAppInDevMode } from "@web-src/modules/common/utils/isAppInDevMode";
import { useCallback, useMemo, useState } from "react";
import { useFCM } from "@web-src/modules/notifications/providers/FCMProvider";
import useEntity from "../../hooks/useEntity";
import { TabRoute } from "../../types";
import { ReactComponent as CallsIcon } from "./icons/calls.svg";
import { ReactComponent as ChatsIcon } from "./icons/chats.svg";
import { ReactComponent as ClientsIcon } from "./icons/clients.svg";
import { ReactComponent as DriveIcon } from "./icons/drive.svg";
import { ReactComponent as HelpIcon } from "./icons/help.svg";
import { ReactComponent as LogoutIcon } from "./icons/logout.svg";
import { ReactComponent as MoreIcon } from "./icons/more.svg";
import { ReactComponent as PeopleIcon } from "./icons/people.svg";
import { ReactComponent as ReportsIcon } from "./icons/reports.svg";
import { ReactComponent as ScheduleIcon } from "./icons/schedule.svg";
import { ReactComponent as SettingsIcon } from "./icons/settings.svg";
import { ReactComponent as TasksIcon } from "./icons/tasks.svg";
import { ReactComponent as WorkspaceIcon } from "./icons/workspace.svg";
import { ReactComponent as OrderFormsIcon } from "./icons/order-forms.svg";
import { NavigationItem } from "./types";

export const useHomeSideBar = () => {
  const [isSideBarExpanded, setIsSideBarExpanded] = useState(() =>
    getStorageItemWithFallback(SIDEBAR_EXPANDED_KEY, true)
  );

  const { active } = useEntity();
  const { t } = useTranslations();
  const { logout } = useUserAuth();
  const { logEvent } = useFCM();
  const { moduleUnreadIndicators } = useUnreadIndicators();
  const { toggle: toggleIntercomWidget } = useIntercom();

  const activeTab = window.location.pathname.split("/")[2] || "";
  const activePath = window.location.pathname.split("/").slice(2).join("/");
  const updateIsSideBarExpanded = useCallback((isExpanded: boolean) => {
    setIsSideBarExpanded(isExpanded);
    saveItemToStorage(SIDEBAR_EXPANDED_KEY, isExpanded);
  }, []);

  const navigationItems = useMemo<NavigationItem[]>(
    () => [
      {
        type: "link",
        icon: ChatsIcon,
        title: t({
          id: "home-sidebar-component.chats",
          defaultMessage: "Chats",
        }),
        active: activeTab === "chats",
        unreadCount: moduleUnreadIndicators?.chat,
        href: `/${active?.id}/chats`,
        onClick: () => {
          logEvent("navigation_chat");
        },
      },
      {
        type: "link",
        icon: TasksIcon,
        title: t({
          id: "home-sidebar-component.tasks",
          defaultMessage: "Tasks",
        }),
        active: activeTab === "tasks",
        unreadCount: moduleUnreadIndicators?.task,
        href: `/${active?.id}/tasks`,
        onClick: () => {
          logEvent("navigation_task");
        },
      },
      {
        type: "link",
        icon: PeopleIcon,
        title: t({
          id: "home-sidebar-component.people",
          defaultMessage: "People",
        }),
        active: activeTab === "people",
        unreadCount: moduleUnreadIndicators?.people,
        href: `/${active?.id}/people`,
      },
      {
        type: "link",
        icon: CallsIcon,
        title: t({
          id: "home-sidebar-component.calls",
          defaultMessage: "Calls",
        }),
        active: activeTab === "calls",
        unreadCount: moduleUnreadIndicators?.call,
        href: `/${active?.id}/calls`,
      },
      {
        type: "link",
        icon: DriveIcon,
        title: t({
          id: "home-sidebar-component.drive",
          defaultMessage: "Drive",
        }),
        active: activeTab === "drive",
        unreadCount: moduleUnreadIndicators?.drive,
        href: `/${active?.id}/drive`,
      },
      {
        type: "link",
        icon: ScheduleIcon,
        title: t({
          id: "home-sidebar-component.schedule",
          defaultMessage: "Schedule",
        }),
        active: activeTab === "schedule",
        requiredRole: [EntityParticipantRole.admin],
        href: `/${active?.id}/schedule`,
        onClick: () => {
          logEvent("navigation_schedule");
        },
      },
      {
        type: "link",
        icon: ClientsIcon,
        title: t({
          id: "home-sidebar-component.Customers",
          defaultMessage: "Customers",
        }),
        active: activeTab === "Customers",
        unreadCount: moduleUnreadIndicators?.customer,
        requiredRole: [EntityParticipantRole.admin],
        href: `/${active?.id}/customers`,
        subscriptionModuleRequired: SubscriptionPlanModuleId.clients,
      },
      {
        type: "link",
        icon: OrderFormsIcon,
        title: t({
          id: "home-sidebar-component.order-forms",
          defaultMessage: "Order Forms",
        }),
        active: activeTab === "orders",
        requiredRole: [EntityParticipantRole.admin],
        href: `/${active?.id}/orders/forms`,
      },
      {
        type: "link",
        icon: WorkspaceIcon,
        title: t({
          id: "home-sidebar-component.workspace",
          defaultMessage: "Workspace",
        }),
        active: activeTab.includes("workspace"),
        requiredRole: [EntityParticipantRole.admin],
        href: `/${active?.id}/workspace/settings`,
      },
      {
        type: "accordion",
        icon: HelpIcon,
        title: t({
          id: "home-sidebar-component.get-help",
          defaultMessage: "Get help",
        }),
        items: [
          {
            type: "button",
            title: t({
              id: "home-sidebar-component.help-center",
              defaultMessage: "Help Center",
            }),
            onClick: () => window.open("https://help.jugl.com", "_blank"),
          },
          {
            type: "button",
            title: t({
              id: "home-sidebar-component.support-chat",
              defaultMessage: "Support Chat",
            }),
            onClick: () => toggleIntercomWidget(),
          },
        ],
      },
      {
        type: "accordion",
        icon: ReportsIcon,
        title: t({
          id: "home-sidebar-component.reports",
          defaultMessage: "Reports",
        }),
        items: [
          {
            type: "link",
            title: t({
              id: "home-sidebar-component.tasks",
              defaultMessage: "Tasks",
            }),
            active: activePath === "reports/tasks",
            href: `/${active?.id}/reports/tasks`,
            subscriptionModuleRequired: SubscriptionPlanModuleId.reports,
          },
          {
            type: "link",
            title: t({
              id: "home-sidebar-component.time-clock",
              defaultMessage: "Time Clock",
            }),
            active: activePath === "reports/timeclock",
            href: `/${active?.id}/reports/timeclock`,
            subscriptionModuleRequired: SubscriptionPlanModuleId.reports,
          },
          {
            type: "link",
            title: t({
              id: "home-sidebar-component.scheduled-reports",
              defaultMessage: "Scheduled Reports",
            }),
            active:
              activeTab === TabRoute.scheduledReports ||
              activeTab === TabRoute.scheduledReportsForm,
            requiredRole: [EntityParticipantRole.admin],
            href: `/${active?.id}/scheduled-reports`,
            subscriptionModuleRequired: SubscriptionPlanModuleId.reports,
          },
        ],
      },
      {
        type: "accordion",
        icon: MoreIcon,
        title: t({
          id: "home-sidebar-component.more",
          defaultMessage: "More",
        }),
        items: [
          {
            type: "link",
            icon: SettingsIcon,
            title: t({
              id: "home-sidebar-component.settings",
              defaultMessage: "Settings",
            }),
            active: activeTab === "settings",
            href: `/${active?.id}/settings`,
          },
          {
            type: "button",
            icon: LogoutIcon,
            title: t({
              id: "common.log-out",
              defaultMessage: "Log out",
            }),
            onClick: () => logout(true),
          },
        ],
      },
      ...(isAppInDevMode()
        ? [
            {
              type: "accordion",
              title: "⚙️ Debug",
              items: [
                {
                  type: "link",
                  title: "User settings",
                  active: activePath === "debug/user-settings",
                  href: `/${active?.id}/debug/user-settings`,
                },
                {
                  type: "link",
                  title: "Subscription",
                  active: activePath === "debug/subscription",
                  href: `/${active?.id}/debug/subscription`,
                },
                {
                  type: "link",
                  title: "Mobile app",
                  active: activePath === "debug/mobile-app",
                  href: `/${active?.id}/debug/mobile-app`,
                },
              ],
            } as NavigationItem,
          ]
        : []),
    ],
    [
      t,
      activeTab,
      moduleUnreadIndicators?.chat,
      moduleUnreadIndicators?.task,
      moduleUnreadIndicators?.people,
      moduleUnreadIndicators?.call,
      moduleUnreadIndicators?.drive,
      moduleUnreadIndicators?.customer,
      active?.id,
      activePath,
      logEvent,
      toggleIntercomWidget,
      logout,
    ]
  );

  return {
    navigationItems,
    isSideBarExpanded,
    setIsSideBarExpanded,
    updateIsSideBarExpanded,
  };
};
