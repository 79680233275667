import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import { BottomCenteredDrawer } from "@jugl-web/ui-components/web/BottomCenteredDrawer";
import { TaskFilterSections } from "@web-src/features/tasks/TaskFilterSections";
import { FC } from "react";
import { useTranslations } from "@jugl-web/utils";
import { useTaskFiltersStateContext } from "@jugl-web/domain-resources/tasks/hooks/useTaskFiltersState";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useTaskFiltering } from "@jugl-web/domain-resources/tasks/components/TaskFilteringProvider";

export const TaskArchiveFiltersDialog: FC<{
  isOpen: boolean;
  onDialogClose: () => void;
}> = ({ isOpen, onDialogClose }) => {
  const { t } = useTranslations();
  const { entity } = useEntitySelectedProvider();

  const { activeFiltersCount, resetFiltersState } =
    useTaskFiltersStateContext();

  const { changeFiltering } = useTaskFiltering();

  const handleSubmit = () => {
    changeFiltering({ type: "filter-set", filterSetId: "archive-page" });
    onDialogClose();
  };

  return (
    <BottomCenteredDrawer
      isOpen={isOpen}
      header={{
        type: "title",
        title: t({
          id: "common.filters",
          defaultMessage: "Filters",
        }),
      }}
      onClose={onDialogClose}
    >
      <BottomCenteredDrawer.Content className="py-4">
        <TaskFilterSections
          entityId={entity.id}
          visibleFilterSections={["assignee", "label"]}
          hideCustomFields
        />
      </BottomCenteredDrawer.Content>
      <BottomCenteredDrawer.Actions className="flex items-center justify-center gap-3.5">
        <Button
          variant="contained"
          color="grey"
          className="h-10 w-[200px]"
          onClick={() =>
            activeFiltersCount ? resetFiltersState() : onDialogClose()
          }
        >
          {activeFiltersCount > 0
            ? t({ id: "common.clear-all", defaultMessage: "Clear all" })
            : t({ id: "common.cancel", defaultMessage: "Cancel" })}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="h-10 w-[200px]"
          isDisabled={activeFiltersCount === 0}
          onClick={handleSubmit}
        >
          {t({ id: "common.apply", defaultMessage: "Apply" })}
        </Button>
      </BottomCenteredDrawer.Actions>
    </BottomCenteredDrawer>
  );
};
