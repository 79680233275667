import {
  DEFAULT_DEAL_MIRROR_PLAN_NAME,
  getDealMirrorPromoCodePrice,
} from "@jugl-web/rest-api";
import { SubscriptionPlanModel } from "@jugl-web/rest-api/entities/models/SubscriptionPlan/SubscriptionPlanModel";
import { SubscriptionIntervalType } from "@jugl-web/rest-api/entities/models/common-types/SubscriptionIntervalType";
import {
  DataLoadingWrapper,
  MultiSectionLayout,
} from "@jugl-web/ui-components/cross-platform";
import { cx } from "@jugl-web/utils";
import { useIntercom } from "@web-src/components/IntercomProvider";
import { DealMirrorPromoCodeInfo } from "@jugl-web/rest-api/entities/models/common-types/PromoCodeInfo";
import { FC, useCallback, useMemo } from "react";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import {
  SelectSubscriptionFormProvider,
  useSelectSubscriptionFormProvider,
} from "./SelectSubscriptionFormProvider";
import { PlanItem } from "./components/PlanItem";
import { SummarySideBar } from "./components/SummarySideBar/SummarySideBar";

const SelectSubscriptionFormContent: FC = () => {
  const {
    updateAdditionalSeats,
    selectedPlan,
    selectPlan,
    selectSubscriptionInterval,
    selectedSubscriptionInterval,
    plans,
    plansIsError,
    plansIsLoading,
    plansRefetch,
    isDealMirrorMode,
    appliedPromoCodes,
    defaultCurrency,
  } = useSelectSubscriptionFormProvider();
  const { toggle: toggleIntercomWidget } = useIntercom();

  const { subscriptionInfo } = useEntitySelectedProvider();

  const subscriptionIntervals: {
    label: string;
    value: SubscriptionIntervalType;
    annotation?: string;
  }[] = useMemo(
    () => [
      {
        label: "Monthly",
        value: "month",
      },
      { label: "Yearly", value: "year", annotation: "save 20%" },
    ],
    []
  );

  const visiblePlans = useMemo(() => {
    let result =
      plans?.filter(
        (item) => item.intervalType === selectedSubscriptionInterval
      ) || [];
    if (subscriptionInfo.isDealMirror) {
      result = result.filter((item) => item.planType !== "basic");
    }
    return result;
  }, [plans, selectedSubscriptionInterval, subscriptionInfo]);

  const handleSelectPlan = useCallback(
    (plan: SubscriptionPlanModel) => {
      if (plan.planType === "enterprise") {
        toggleIntercomWidget();
        return;
      }
      selectPlan(plan);
    },
    [selectPlan, toggleIntercomWidget]
  );

  const getDealMirrorPlanDetails = (): Partial<SubscriptionPlanModel> => {
    const dealMirrorPromoCode = appliedPromoCodes?.find(
      (promo) => promo.type === "deal_mirror"
    ) as DealMirrorPromoCodeInfo;
    const result: Partial<SubscriptionPlanModel> = {
      name: DEFAULT_DEAL_MIRROR_PLAN_NAME,
      seatsCount: dealMirrorPromoCode.seats,
      currency: selectedPlan?.currency || defaultCurrency,
    };
    if (dealMirrorPromoCode && selectedPlan) {
      const promoPrice = getDealMirrorPromoCodePrice(
        dealMirrorPromoCode,
        selectedPlan.currency
      );
      if (promoPrice) {
        result.price = promoPrice;
      }
    }
    return result;
  };

  return (
    <DataLoadingWrapper
      className="h-full"
      isLoading={plansIsLoading}
      isError={plansIsError}
      onRetry={plansRefetch}
    >
      <MultiSectionLayout className="relative" rightSide={<SummarySideBar />}>
        <div className={cx("relative px-8 py-12")}>
          <div className="flex items-center justify-center">
            <div
              className={cx(
                "flex h-11 items-center rounded-[22px] bg-[#EDF2F4] px-1",
                { "opacity-50": isDealMirrorMode }
              )}
            >
              {subscriptionIntervals.map((item) => {
                const isSelected = item.value === selectedSubscriptionInterval;
                return (
                  <div
                    key={item.value}
                    onClick={() =>
                      isDealMirrorMode
                        ? null
                        : selectSubscriptionInterval(item.value)
                    }
                    className={cx(
                      "flex h-9 cursor-pointer items-center justify-center rounded-full px-8 transition-all",
                      {
                        "shadow-[0px 2px 16px 0px rgba(0, 0, 0, 0.06) bg-white":
                          isSelected,
                      }
                    )}
                  >
                    <span className={cx({ "font-medium": isSelected })}>
                      {item.label}
                    </span>
                    {item.annotation ? (
                      <div className="ml-2 rounded bg-white px-2 py-0.5 text-xs font-semibold text-red-500">
                        {item.annotation}
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="mt-8 flex justify-center gap-2.5">
            {visiblePlans.map((plan) => (
              <div
                key={plan.id}
                className={cx({
                  "opacity-50":
                    isDealMirrorMode && selectedPlan?.id !== plan.id,
                })}
              >
                <PlanItem
                  plan={{
                    ...plan,
                    ...(isDealMirrorMode && selectedPlan?.id === plan.id
                      ? getDealMirrorPlanDetails()
                      : {}),
                  }}
                  onSelect={isDealMirrorMode ? () => null : handleSelectPlan}
                  onRequestAdditionalSeats={updateAdditionalSeats}
                  isSelected={selectedPlan?.id === plan.id}
                  isDealMirror={
                    isDealMirrorMode && selectedPlan?.id === plan.id
                  }
                />
              </div>
            ))}
          </div>
        </div>
      </MultiSectionLayout>
    </DataLoadingWrapper>
  );
};

export const SelectSubscriptionForm: FC = () => (
  <SelectSubscriptionFormProvider>
    <SelectSubscriptionFormContent />
  </SelectSubscriptionFormProvider>
);
