// TODO: Divide types into DTOs, models, and payloads
export interface TaskAssignee {
  first_name: string;
  img: string;
  last_name: string;
  nickname: string;
  status: string;
  user_id: string;
}

export interface TaskChecklistItem {
  completed_at: string | null;
  due_at: string | null;
  id: string;
  is_completed: boolean;
  name: string;
  order: number;
  user_id: string | null;
}

export type TaskChecklistSummaryItem = {
  id: string;
  due_at: string | null;
  name: string;
  completed_at: string;
  task_id: string;
  task_name: string;
};

export type ChecklistSummary = {
  checklists: TaskChecklistSummaryItem[];
  user_id: string;
};

export type TaskLabel = {
  id: string;
  text: string;
  color: string;
  order: number;
};

export enum TaskPriority {
  none = "none",
  low = "low",
  medium = "medium",
  high = "high",
}

export enum TaskDefaultStatus {
  notStarted = "not-started",
  inProgress = "in-progress",
  completed = "completed",
  reopened = "reopened",
}

export interface TaskCustomStatus {
  id: string;
  order: number;
  text: string;
}

export enum TaskType {
  voice = "voice_task",
  overdue = "overdue",
  recurring = "recurring",
  template = "template",
}

export enum TaskSortBy {
  DueAt = "due_at",
}

export type TaskAttachment = {
  content_type: string;
  id: string;
  name: string;
  preview_available: boolean;
  preview_url: string;
  size: number;
  status: "valid" | "invalid";
  stream_url: string;
  updated_at: string;
};

export type TaskCustomer = {
  id: string;
  logo: string | null;
  fields: { id: string; name: string; value: string }[];
};

export type TaskCreator = {
  first_name: string;
  img: string | null;
  last_name: string;
  nickname: string;
  // TODO: import status from user
  status: unknown;
  user_id: string;
};

export interface TaskRecurrence {
  end_at: TaskRecurrenceEndAt;
  interval: TaskRecurrenceInterval;
  tz: string;
  enabled?: boolean;
}

export enum TaskRecurrenceIntervalEvery {
  day = "day",
  week = "week",
  month = "month",
  year = "year",
}

export type TaskRecurrenceInterval =
  | {
      every: TaskRecurrenceIntervalEvery.day;
      freq: number;
    }
  | {
      every: TaskRecurrenceIntervalEvery.week;
      freq: number;
      on: number[];
    }
  | {
      every: TaskRecurrenceIntervalEvery.month;
      freq: number;
      on: { date: number } | { week: number; day: number };
    }
  | {
      every: TaskRecurrenceIntervalEvery.year;
      freq: number;
      on: { month: number; date: number };
    };

export type TaskRecurrenceEndAt = null | { date: string } | { counter: number };

export interface BaseTask {
  assignees: string[];
  can_assignee_edit: boolean;
  chklist_overdue: boolean;
  created_at: string;
  created_by: string;
  cust_id: string | null;
  custom_fields: Record<string, string> | null;
  due_at: string | null;
  duration: number;
  entity_id: string;
  id: string;
  is_self: boolean;
  label_id: string | null;
  name: string;
  priority: TaskPriority;
  recurrence: TaskRecurrence | null;
  recurrences: string[];
  reminder: TaskReminder | null;
  short_url: string;
  status: TaskDefaultStatus | string;
  unread: boolean;
  updated_at: string;
  order_id: string | null;
}

export interface PreviewTask extends BaseTask {
  chklist_completed: number;
  chklist_total: number;
  /**
   * Internal field to determine if the task is a future occurrence of a recurring task.
   */
  _future_task?: {
    source_task_id: string;
    recurrence_date: Date;
  };
}

export interface TaskReminder {
  next_execution: string;
}

export interface DetailedTask extends BaseTask {
  attachments: TaskAttachment[];
  checklist: TaskChecklistItem[];
  completed_on: string | null;
  desc: string | null;
  has_chklist_chk: boolean;
  type: string | null;
  _is_deleted?: boolean;
}

export type TaskCustomFieldType = "text" | "number" | "date" | "dropdown";

export type TaskCustomField = {
  field: string;
  field_type: TaskCustomFieldType;
  id: string;
  order: number;
  show_in_card?: boolean;
  placeholder?: string;
  values?: null | Record<string, unknown>[];
};

export interface TaskFilters {
  assignee: (string | null)[] | null;
  custom_fields: Record<string, (string | null)[]> | null;
  customer: (string | null)[] | null;
  label: (string | null)[] | null;
  overdue: true | null;
  priority: TaskPriority[] | null;
  recurring: true | null;
  status: (TaskDefaultStatus | string)[] | null;
}

export interface TaskFilterSet extends TaskFilters {
  id: string;
  name: string;
  updated_at: string;
}

export interface TasksInitData {
  custom_fields: {
    entity_id: string;
    id: string;
    props: {
      value: TaskCustomField[];
    };
    type: "task_fields";
  };
  custom_statuses: TaskCustomStatus[];
  filter_sets: TaskFilterSet[];
  labels: TaskLabel[];
}

export type ExpectedTaskCustomDropdownFieldValue = {
  id: string;
  value: string;
  order: number;
};

export type InternalTaskCustomField = {
  id: string;
  name: string;
  type: TaskCustomFieldType;
  order: number;
  isShownInCard: boolean;
  values?: ExpectedTaskCustomDropdownFieldValue[];
};

export interface InternalTaskFilters {
  assignees: (string | null)[];
  customers: (string | null)[];
  customFields: Record<string, (string | null)[]>;
  isOverdue: true | null;
  isRecurring: true | null;
  labels: (string | null)[];
  priorities: TaskPriority[];
  statuses: (TaskDefaultStatus | string)[];
}

export interface InternalTaskFilterSet {
  id: string;
  name: string;
  filters: InternalTaskFilters;
  updatedAt: string;
}

export interface TaskFields {
  customFieldsData: {
    id: string;
    items: InternalTaskCustomField[];
  };
  labels: TaskLabel[];
  customStatuses: TaskCustomStatus[];
  filterSets: InternalTaskFilterSet[];
}

type CreatedActionChanges = { title: string; time: string };

export type UpdatedActionChanges = Partial<
  Pick<
    DetailedTask,
    | "name"
    | "desc"
    | "priority"
    | "due_at"
    | "status"
    | "duration"
    | "can_assignee_edit"
    | "has_chklist_chk"
  >
> & {
  assignee_added?: {
    first_name: string;
    last_name: string;
    user_id: string;
  }[];
  assignee_removed?: {
    first_name: string;
    last_name: string;
    user_id: string;
  }[];
  checklist_updated?: {
    id: string;
    c_name: string;
    name?: string;
    is_completed?: boolean;
    completed_at?: string;
  }[];
  checklist_rearranged?: [];
  checklist_deleted?: {
    id: string;
    c_name: string;
  }[];
  checklist_added?: {
    id: string;
    c_name: string;
  }[];
  custom_fields?: DetailedTask["custom_fields"];
  label?: Omit<TaskLabel, "order">;
  old_custom_fields?: DetailedTask["custom_fields"];
  attachment_added?: {
    file_name: string;
  };
  attachment_deleted?: {
    file_name: string;
  };
  attachment_renamed?: {
    file_name: string;
    old_file_name: string;
  };
  customer?: "updated";
  reminder?: "updated";
  recurrence?: "updated";
};

type DeletedActionChanges = CreatedActionChanges;

type TaskActivityTaskInfo = {
  id: string;
  title: string;
};
export interface TaskActivity {
  action_by: {
    user_id: string;
    first_name: string;
    last_name: string;
    img: string | null;
    updated_at: string;
    logo?: string;
  };
  action_details:
    | {
        action: "created";
        changes: CreatedActionChanges;
        task: TaskActivityTaskInfo;
      }
    | {
        action: "updated";
        changes: UpdatedActionChanges;
        task: TaskActivityTaskInfo;
      }
    | {
        action: "deleted";
        changes: DeletedActionChanges;
        task: TaskActivityTaskInfo;
      }
    | { action: "viewed" }
    | {
        action: "commented";
        changes: { content: string };
        task: TaskActivityTaskInfo;
      }
    | {
        action: "downloaded";
        file_name: string;
      }
    | {
        action: "guest_downloaded";
        file_name: string;
      }
    | {
        action: "guest_comment";
        changes: { content: string };
        task: TaskActivityTaskInfo;
      }
    | {
        action: "guest_opened";
        changes: { content: string };
        task: TaskActivityTaskInfo;
      }
    | {
        action: "guest_comment_reply";
        changes: { content: string };
        task: TaskActivityTaskInfo;
      }
    | {
        action: "guest_feedback";
        changes: {
          content: {
            feedback: string;
            hashtag: string[];
            rating: number;
          };
        };
        task: TaskActivityTaskInfo;
      }
    | {
        action: "restored";
        changes: {
          time: string;
        };
        task: TaskActivityTaskInfo;
      }
    | {
        action: "archived";
        changes: {
          time: string;
        };
        task: TaskActivityTaskInfo;
      };
  id: string;
  inserted_at: string;
  task_id: string;
  updated_at: string;
}

export interface SelfProducedTaskActivity {
  action_by: string;
  action_details: TaskActivity["action_details"];
  inserted_at: string;
}

export interface UpdateTaskResponse {
  task: DetailedTask;
  activity: SelfProducedTaskActivity | Record<string, never>;
}

export type CreateTaskPayload = Pick<
  DetailedTask,
  | "assignees"
  | "can_assignee_edit"
  | "checklist"
  | "cust_id"
  | "custom_fields"
  | "desc"
  | "due_at"
  | "has_chklist_chk"
  | "label_id"
  | "name"
  | "priority"
  | "recurrence"
  | "reminder"
  | "status"
> & {
  as_individual_tasks?: boolean;
  is_self?: boolean;
};

export type CreateTaskCustomFieldPayload = Pick<
  TaskCustomField,
  "field" | "field_type" | "show_in_card" | "order"
> & { values?: ExpectedTaskCustomDropdownFieldValue[] };

export type UpdateTaskCustomFieldPayload = CreateTaskCustomFieldPayload &
  Pick<TaskCustomField, "id">;

export type ArchivedTask = {
  assignees: BaseTask["assignees"];
  due_at: BaseTask["due_at"];
  id: BaseTask["id"];
  inserted_at: string;
  name: BaseTask["name"];
  label_id: BaseTask["label_id"];
  cust_id: string;
  status: BaseTask["status"];
  // order_id: BaseTask["order_id"];
};
