import { assert, cx } from "@jugl-web/utils";
import escape from "lodash/escape";
import { ElementType, FC, KeyboardEvent, useRef } from "react";

export interface EditableTextProps {
  value: string;
  as?: ElementType;
  isDisabled?: boolean;
  className?: string;
  onChange: (value: string) => void;
  validator?: (value: string) => boolean;
}

export const EditableText: FC<EditableTextProps> = ({
  value,
  as: Component = "span",
  isDisabled = false,
  className,
  onChange,
  validator,
}) => {
  const ref = useRef<HTMLElement | null>(null);

  const handleBlur = () => {
    assert(!!ref.current);

    const newValue = ref.current.innerText;
    const isValid = validator ? validator?.(newValue) : true;
    const hasChanged = newValue !== value;

    if (isValid && hasChanged) {
      onChange(newValue);
    } else {
      ref.current.innerText = value;
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    assert(!!ref.current);

    if (event.key === "Escape") {
      ref.current.blur();
    }
  };

  return (
    <Component
      ref={ref}
      contentEditable={!isDisabled}
      className={cx(
        !isDisabled && "cursor-pointer whitespace-pre-wrap",
        className
      )}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: escape(value) }}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={isDisabled ? -1 : 0}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
    />
  );
};
