import { PageNavParams } from "@web-src/modules/navigation/types";
import { ModulePagesConfig } from "@web-src/modules/navigation/types/ModulePagesConfigs";
import { SubscriptionPlanModuleId } from "@jugl-web/rest-api/entities/models/common-types/SubscriptionPlanModuleId";
import CustomersPage from "./CustomersPage";

export type CustomersPagesNavigationParams = {
  customersCustomers: PageNavParams;
};

export const customersPagesNavigationConfig: ModulePagesConfig<CustomersPagesNavigationParams> =
  {
    customersCustomers: {
      element: <CustomersPage />,
      path: "customers",
      isPrivate: true,
      subscriptionModuleRequired: SubscriptionPlanModuleId.clients,
      isAdminRequired: true,
    },
  };
