import { TASK_COLUMNS_IDS } from "@jugl-web/domain-resources/tasks";
import { useTaskFiltering } from "@jugl-web/domain-resources/tasks/components/TaskFilteringProvider";
import { useTaskSorting } from "@jugl-web/domain-resources/tasks/components/TaskSortingProvider";
import { useTasks } from "@jugl-web/domain-resources/tasks/hooks/useTasks";
import { useTasksPageContext } from "@web-src/features/tasks/TasksPageContext";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { endOfDay } from "date-fns";
import { FC } from "react";
import { useWeekdays } from "../../hooks/useWeekdays";
import { CalendarViewLayout } from "../CalendarViewLayout";
import { CalendarWeekColumnHeader } from "../CalendarWeekColumnHeader";
import { CalendarWeekColumnList } from "../CalendarWeekColumnList";

export const CalendarWeekView: FC = () => {
  const { taskListMode } = useTasksPageContext();

  const { searchQuery, filters } = useTaskFiltering();
  const { sorting } = useTaskSorting();

  const { entityId } = useEntitySelectedProvider();

  const { selectTasksByDueDateInterval } = useTasks({
    entityId,
    mode: taskListMode,
    searchQuery,
    filters,
    sorting,
    showFuture: true,
    forceShowCompleted: true,
  });

  const weekdays = useWeekdays();

  return (
    <div className="animate-fade-in flex w-full items-center gap-0.5">
      {weekdays.map((weekday) => (
        <CalendarViewLayout.Column
          key={weekday.date.valueOf()}
          className="group min-w-[207px]"
        >
          <CalendarWeekColumnHeader date={weekday.date} />
          <CalendarWeekColumnList
            tasks={selectTasksByDueDateInterval(
              weekday.date,
              endOfDay(weekday.date)
            )}
            droppableConfig={{
              id: TASK_COLUMNS_IDS.DATE_VIEW(weekday.date),
              data: {
                type: "dueDate",
                value: endOfDay(weekday.date).toISOString(),
              },
            }}
            newTaskConfig={{
              initialState: {
                dueDate: { date: endOfDay(weekday.date) },
              },
              taskPropertiesPanelConfigOverrides: {
                dueDate: { isDateEditable: false },
              },
            }}
          />
        </CalendarViewLayout.Column>
      ))}
    </div>
  );
};
