import { Text } from "@jugl-web/ui-components/cross-platform/Text";
import useEntity from "@web-src/features/app/hooks/useEntity";
import React, { useMemo } from "react";
import { getEntityUserDisplayName } from "@web-src/features/entities/utils";
import { useTranslations, cx } from "@jugl-web/utils";
import { UID } from "agora-rtc-react";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { ReactComponent as MutedMic } from "./assets/muted-mic.svg";
import { parseUserId } from "../../../../utils";
import { CallAvatar } from "../CallAvatar";

const UserCallWindow: React.FC<{
  isAudioMuted: boolean;
  isSpeaking: boolean;
  userId: UID | string;
  playerId?: string;
  isVideoMuted?: boolean;
  isCall?: boolean;
  isRinging?: boolean;
  duration?: string;
  containerClassName?: string;
  profileContainerClassName?: string;
  showAvatarOnly?: boolean;
  minimized?: boolean;
  screenSharingId?: string;
  isFullscreen?: boolean;
  gridAvatarSize?: number;
}> = ({
  isAudioMuted,
  isSpeaking,
  userId,
  playerId,
  isVideoMuted,
  isCall,
  isRinging,
  duration,
  containerClassName,
  profileContainerClassName,
  showAvatarOnly,
  minimized,
  screenSharingId,
  isFullscreen,
  gridAvatarSize,
}) => {
  const { participantsMap } = useEntity();
  const { t } = useTranslations();
  const { me } = useMe();
  const parsedUserId = useMemo(() => parseUserId(userId as string), [userId]);
  const isScreenSharer = useMemo(
    () => userId === screenSharingId,
    [userId, screenSharingId]
  );
  const isLocalUserScreenSharing = useMemo(
    () => me?.id === parseUserId(screenSharingId || ""),
    [me?.id, screenSharingId]
  );
  const avatar = useMemo(() => {
    if (participantsMap[parsedUserId]) {
      return participantsMap[parsedUserId]?.profile?.img;
    }
    return "";
  }, [parsedUserId, participantsMap]);
  const name = useMemo(() => {
    if (playerId === "localplayer") {
      return t({ id: "common.you", defaultMessage: "You" });
    }
    if (participantsMap[parsedUserId]) {
      return getEntityUserDisplayName(participantsMap[parsedUserId]);
    }
    return userId;
  }, [parsedUserId, participantsMap, playerId, userId, t]);

  const bg = useMemo(
    () => ({
      backgroundImage: `url(${avatar})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    }),
    [avatar]
  );

  const dynamicBg = avatar && isVideoMuted ? bg : {};

  if (showAvatarOnly) {
    return (
      <CallAvatar userId={parsedUserId} isSpeaking={isSpeaking} size={80} />
    );
  }
  return (
    <div
      className={cx(
        "relative box-border h-full w-full overflow-hidden rounded-2xl border-2 border-solid border-transparent transition-all duration-500",
        {
          "border-primary-500": isSpeaking && !isCall,
        },
        containerClassName
      )}
    >
      <div
        style={dynamicBg}
        className={cx(
          "absolute bottom-0 left-0 top-0 right-0 z-0",
          {
            "blur-xl brightness-50": isVideoMuted && avatar,
          },
          { "bg-user-call-1": !avatar && isVideoMuted }
        )}
      />
      <div
        className={cx("absolute top-0 left-0 z-10 h-full w-full", {
          hidden: isVideoMuted,
        })}
        id={playerId}
      />
      <div
        className={cx(
          "absolute flex h-full w-full  place-items-center justify-center",
          {
            hidden: !isCall,
            "top-[40px] left-[32px] gap-6":
              playerId !== "localplayer" &&
              !(isCall && !isVideoMuted && duration),
            "top-[16px] left-[16px]":
              playerId !== "localplayer" && isCall && !isVideoMuted && duration,
            "top-4 left-4 gap-4": playerId,
          },
          profileContainerClassName
        )}
      >
        {isVideoMuted && (!isRinging || minimized) && (
          <div className="relative">
            <CallAvatar
              userId={parsedUserId}
              isSpeaking={isSpeaking}
              size={130}
              showMutedIcon={isAudioMuted && isCall && isVideoMuted}
            />
          </div>
        )}
      </div>
      <div
        className={cx({
          hidden: !isScreenSharer,
          "absolute top-4 left-4 z-50 flex flex-row items-center gap-4":
            isScreenSharer && !isFullscreen && isLocalUserScreenSharing,
        })}
      >
        <CallAvatar userId={parsedUserId} size={32} isSpeaking={false} />
        <Text
          variant="body2"
          className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap font-bold text-white"
        >
          {name}
        </Text>
      </div>
      <div
        className={cx(
          "absolute flex h-full w-full  place-items-center justify-center",
          {
            hidden: (isCall && !isRinging) || (isCall && minimized),
          }
        )}
      >
        <div className={cx("flex flex-col place-items-center justify-center")}>
          <CallAvatar
            userId={parsedUserId}
            isSpeaking={isSpeaking}
            size={gridAvatarSize || 80}
          />
        </div>
      </div>

      <div
        className={cx(
          "absolute bottom-5 left-6 right-6 z-20 flex flex-row items-center gap-4 rounded",
          {
            hidden: isScreenSharer || isRinging,
            "bottom-1": screenSharingId,
          }
        )}
      >
        <Text
          variant="body2"
          className={cx(
            "z-20 truncate rounded-sm font-medium text-white shadow-sm",
            { hidden: isCall }
          )}
        >
          {name}
        </Text>
        <div
          className={cx("cursor-default", {
            hidden:
              (isCall && !isAudioMuted) ||
              minimized ||
              !isAudioMuted ||
              (isCall && isVideoMuted),
          })}
        >
          <MutedMic />
        </div>
      </div>
    </div>
  );
};

export default UserCallWindow;
