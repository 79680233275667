import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  EmptyListContent,
  HeaderBreadcrumbs,
  LoadingAnimation,
  MultiSectionLayout,
  PageLoaderFull,
} from "@jugl-web/ui-components";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { useToast, useTranslations, useUniqueId } from "@jugl-web/utils";
import { DndContext, MouseSensor, useSensor, useSensors } from "@dnd-kit/core";
import {
  OrderFormCustomField,
  OrderFormValues,
} from "@jugl-web/domain-resources/orders";
import { Subject } from "rxjs";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useSelector } from "react-redux";
import { selectUserId } from "@web-src/features/auth/authSlice";
import {
  TaskFormState,
  TaskFormStateProvider,
  useTaskFormState,
} from "@jugl-web/domain-resources/tasks/hooks/useTaskFormState";
import { useRestApiProvider } from "@jugl-web/rest-api";
import {
  getDueDateBasedOnDays,
  getDueDateInDays,
  taskChecklistItemAdapters,
} from "@jugl-web/domain-resources/tasks";
import { useMatch, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useTaskFields } from "@jugl-web/domain-resources/tasks/hooks/useTaskFields";
import {
  InternalTaskCustomField,
  stripTaskDescriptionPrefix,
} from "@jugl-web/rest-api/tasks";
import { CreateOrderFormPayload } from "@jugl-web/rest-api/orders/types";
import { TASK_ORDER_EMAIL_ID } from "@jugl-web/utils/consts";
import { FormComponentSelectSection } from "./components/FormComponentSelectSection";
import { OrderFormStep, OrderSteps } from "./components/OrderSteps";
import { OrderTaskDetails } from "./components/OrderTaskDetails";
import { OrderFormPrefixField } from "./components/OrderFormPrefixField";
import { urlToFile } from "./utils/urlToFile";
import { OrderForm } from "./components/OrderForm";
import { useBlockNavigation } from "./hooks/useBlockNavigation";
import { NavigationAlert } from "./components/NavigationAlert";

export const OrderFormCreatePage: React.FC = () => {
  const { generateUrl } = useNavigation();
  const { t } = useTranslations();
  const { toast } = useToast({ variant: "web" });
  const { entity } = useEntitySelectedProvider();
  const meId = useSelector(selectUserId);
  const [orderFormStep, setOrderFormStep] = useState(OrderFormStep.form);
  const { navigateToPage } = useNavigation();
  const { ordersApi } = useRestApiProvider();
  const [createOrderForm, { isLoading }] =
    ordersApi.useCreateOrderFormMutation();
  const [updateOrderForm, { isLoading: isUpdateFormLoading }] =
    ordersApi.useUpdateOrderFormMutation();
  ordersApi.useCreateOrderFormMutation();
  const { formId } = useParams<{ formId: string }>();
  const isEditing = !!useMatch("/:entityId/orders/forms/edit/:formId");
  const [isOrderFormFetched, setIsOrderFormFetched] = useState(false);
  const { formContext, resetFormState } = useTaskFormState();
  const { customFields: taskCustomFields } = useTaskFields({
    entityId: entity.id,
  });
  const confirmNavigation = useRef<(() => void) | null>(null);
  const skipNavigationAlert = useRef(false);
  const [isNavigationAlertOpen, setIsNavigationAlertOpen] = useState(false);

  const emailCustomField: OrderFormCustomField = useMemo(
    () => ({
      id: TASK_ORDER_EMAIL_ID,
      isRequired: true,
      name: t({
        id: "order-form-create-page.client-email",
        defaultMessage: "Client Email",
      }),
      order: 0,
      type: "text",
      isShownInCard: false,
    }),
    [t]
  );

  useBlockNavigation(
    setIsNavigationAlertOpen,
    confirmNavigation,
    skipNavigationAlert
  );

  const { data, isError, refetch } = ordersApi.useGetOrderFormQuery(
    formId && isEditing
      ? {
          entityId: entity.id,
          formId,
        }
      : skipToken
  );

  const uniqueId = useUniqueId();
  const [prefix, setPrefix] = useState(uniqueId.slice(0, 4).toUpperCase());
  const [formValues, setFormValues] = useState<OrderFormValues>({
    description: "",
    image: null,
    message: t({
      id: "order-form-create-page.thanks-for-submitting-form",
      defaultMessage: "Thanks for submitting the form!",
    }),
    title: t({
      id: "order-form-create-page.untitled-form",
      defaultMessage: "Untitled Form",
    }),
  });

  const [customFields, setCustomFields] = useState<OrderFormCustomField[]>([
    emailCustomField,
  ]);

  const adjustInitialValues = useCallback(async () => {
    if (!data) return;
    setFormValues({
      description: data.form_desc || "",
      message: data.msg,
      title: data.title,
      image: data.banner_img ? await urlToFile(data.banner_img) : null,
    });
    setCustomFields(
      data.fields
        .filter(
          (field) =>
            field.id === TASK_ORDER_EMAIL_ID ||
            taskCustomFields.some((el) => el.id === field.id)
        )
        .map((field) => {
          if (field.id === TASK_ORDER_EMAIL_ID) {
            return emailCustomField;
          }

          const matchingItem = taskCustomFields.find(
            (el) => el.id === field.id
          ) as InternalTaskCustomField;

          return {
            ...matchingItem,
            isRequired: field.required,
            order: field.order,
          };
        })
        .sort((a, b) => a.order - b.order)
    );
    resetFormState({
      title: data.name,
      assigneeIds: data.assignees,
      checklistItems: data.checklist.map(
        taskChecklistItemAdapters.toInternalModel
      ),
      dueDate: data.due_in
        ? { date: getDueDateBasedOnDays(data.due_in) }
        : null,
      priority: data.priority,
      description: data.desc ? stripTaskDescriptionPrefix(data.desc) : "",
      allowAssigneesEdit: data.can_assignee_edit,
      completeChecklistInOrder: data.has_chklist_chk,
      labelId: data.label_id,
    });
    setPrefix(data.prefix);
    setIsOrderFormFetched(true);
  }, [data, taskCustomFields, resetFormState, emailCustomField]);

  useEffect(() => {
    if (data && !isOrderFormFetched) {
      adjustInitialValues();
    }
  }, [data, adjustInitialValues, isOrderFormFetched]);

  const completeStep$ = useMemo(() => new Subject<void>(), []);

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });
  const sensors = useSensors(mouseSensor);

  const handleSubmit = useCallback(
    async (taskFormState: TaskFormState) => {
      if (isEditing && !formId) return;
      const { title, description, message, image } = formValues;
      const {
        assigneeIds,
        checklistItems,
        priority,
        dueDate,
        title: name,
      } = taskFormState;

      const payload: {
        opts: CreateOrderFormPayload;
        banner?: File;
      } = {
        opts: {
          title,
          form_desc: description,
          msg: message,
          prefix,
          type: "apt",
          services: [],
          fields: customFields.map((customField, idx) => ({
            id: customField.id,
            order: idx + 1,
            required: customField.isRequired,
          })),
          name,
          due_in: dueDate ? getDueDateInDays(dueDate.date) : null,
          priority,
          checklist: checklistItems.map(
            taskChecklistItemAdapters.toBackendModel
          ),
          assignees: assigneeIds,
          desc: taskFormState.description,
          label_id: taskFormState.labelId,
          can_assignee_edit: taskFormState.allowAssigneesEdit,
          has_chklist_chk: taskFormState.completeChecklistInOrder,
        },
        banner: image || undefined,
      };

      const response = isEditing
        ? await updateOrderForm({
            entityId: entity.id,
            formId: formId || "",
            ...payload,
          })
        : await createOrderForm({
            entityId: entity.id,
            ...payload,
          });

      if ("data" in response) {
        toast(
          isEditing
            ? t({
                id: "order-form-create-page.form-was-updated",
                defaultMessage: "Form was updated",
              })
            : t({
                id: "new-form-was-created",
                defaultMessage: "New Form was created",
              })
        );
        skipNavigationAlert.current = true;
        navigateToPage("orderForms");
        return;
      }
      if ("error" in response) {
        if (
          /* eslint-disable @typescript-eslint/no-explicit-any */
          (response.error as any).data?.errors?.[0]?.message ===
          "prefix_already_exists"
        ) {
          toast(
            t({
              id: "order-form-create-page.form-id-already-exist",
              defaultMessage: "Form Id already exist",
            }),
            { variant: "error" }
          );
          return;
        }
        toast(
          t({
            id: "feedback.something-went-wrong",
            defaultMessage: "Something went wrong",
          }),
          { variant: "error" }
        );
      }
    },
    [
      formId,
      updateOrderForm,
      formValues,
      navigateToPage,
      createOrderForm,
      entity,
      customFields,
      prefix,
      toast,
      t,
      isEditing,
    ]
  );

  return (
    <>
      <MultiSectionLayout
        header={
          <HeaderBreadcrumbs
            items={[
              {
                title: t({
                  id: "order-form-create-page.order-forms",
                  defaultMessage: "Order Forms",
                }),
                href: generateUrl("orderForms"),
              },
              {
                title: isEditing
                  ? t({
                      id: "order-form-create-page.edit-form",
                      defaultMessage: "Edit Form",
                    })
                  : t({
                      id: "order-form-create-page.form-builder",
                      defaultMessage: "Form Builder",
                    }),
              },
            ]}
          />
        }
      >
        {isError ? (
          <div className="bg-grey-200 h-full w-full">
            <EmptyListContent
              type="error"
              customSubtitle={t({
                id: "order-form-create-page.couldnt-load-page",
                defaultMessage: "Couldn't load the page you're trying to open",
              })}
              onRefetch={refetch}
            />
          </div>
        ) : isEditing && !isOrderFormFetched ? (
          <div className="flex h-full w-full items-center justify-center">
            <LoadingAnimation />
          </div>
        ) : (
          <>
            <PageLoaderFull
              isActive={isLoading || isUpdateFormLoading}
              isTransparent
            />
            <div className="bg-grey-200 jugl__custom-scrollbar flex h-full items-start gap-16 overflow-x-auto p-8 pb-0">
              {orderFormStep === OrderFormStep.form ? (
                <DndContext sensors={sensors}>
                  <FormComponentSelectSection customFields={customFields} />
                  <OrderForm
                    customFields={customFields}
                    onCustomFieldsChange={setCustomFields}
                    completeStep$={completeStep$}
                    onSubmit={(values) => {
                      setFormValues(values);
                      setOrderFormStep(OrderFormStep.taskDetails);
                    }}
                    formValues={formValues}
                    entityId={entity.id}
                  />
                </DndContext>
              ) : (
                <TaskFormStateProvider context={formContext}>
                  <OrderTaskDetails
                    entityId={entity.id}
                    meId={meId || ""}
                    completeStep$={completeStep$}
                    prefix={prefix}
                    onSubmit={handleSubmit}
                  />
                </TaskFormStateProvider>
              )}
              <div className="flex flex-col gap-16">
                <OrderFormPrefixField
                  orderFormPrefix={prefix}
                  onChange={setPrefix}
                />
                <OrderSteps
                  completeStep$={completeStep$}
                  orderFormStep={orderFormStep}
                  setOrderFormStep={setOrderFormStep}
                  isEditing={isEditing}
                />
              </div>
            </div>
          </>
        )}
      </MultiSectionLayout>
      <NavigationAlert
        isOpen={isNavigationAlertOpen}
        onRequestClose={() => {
          setIsNavigationAlertOpen(false);
          confirmNavigation.current = null;
        }}
        onConfirm={() => confirmNavigation.current?.()}
      />
    </>
  );
};
