import { Button, ListBoxItem } from "@jugl-web/ui-components/cross-platform";
import {
  DatePicker,
  DatePickerHandle,
} from "@jugl-web/ui-components/cross-platform/DatePicker";
import { Tooltip } from "@jugl-web/ui-components/cross-platform/Tooltip";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { cx, useTranslations } from "@jugl-web/utils";
import { useFormattedDate } from "@jugl-web/utils/hooks/useFormattedDate";
import { endOfDay } from "date-fns";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import {
  TaskReminderOptionId,
  TaskReminderOptionWithId,
  useTaskReminders,
} from "../../hooks/useTaskReminders";
import { TaskDueDateState } from "../../types";
import { ReactComponent as ArrowDownIcon } from "./assets/arrow-down.svg";
import { ReactComponent as InfoIcon } from "./assets/info.svg";
import { DueTimeInput } from "./components/DueTimeInput";

export interface DueDatePickerProps {
  initialState?: TaskDueDateState | null;
  isDateEditable?: boolean;
  withReminder?: boolean;
  onSubmit: (state: TaskDueDateState | null) => void;
  onClose?: () => void;
}

export const DueDatePicker: FC<DueDatePickerProps> = ({
  initialState,
  isDateEditable = true,
  withReminder = false,
  onSubmit,
  onClose,
}) => {
  const {
    getReminderOptionById,
    isReminderMatchingDueDate,
    getDueDateMatchingReminderOptions,
    findReminderOptionByNextExecutionTime,
    getNextExecutionTime,
  } = useTaskReminders();

  const [selectedDate, setSelectedDate] = useState<Date | null>(
    () => initialState?.date || null
  );

  const [selectedReminderId, setSelectedReminderId] =
    useState<TaskReminderOptionId>(() => {
      if (initialState?.reminderExecutionTime) {
        return (
          findReminderOptionByNextExecutionTime(
            initialState.reminderExecutionTime,
            initialState.date
          )?.id || "none"
        );
      }

      return "none";
    });

  const selectedReminderOption = getReminderOptionById(selectedReminderId);

  const datePickerRef = useRef<DatePickerHandle | null>(null);

  const { t } = useTranslations();
  const { localeAwareFormat } = useFormattedDate();

  const reminderOptionsAsListItems = useMemo<
    ListBoxItem<TaskReminderOptionWithId>[]
  >(() => {
    if (!selectedDate) {
      return [];
    }

    const dueDateMatchingReminderOptions =
      getDueDateMatchingReminderOptions(selectedDate);

    if (
      dueDateMatchingReminderOptions.length === 1 &&
      dueDateMatchingReminderOptions[0].id === "none"
    ) {
      return [];
    }

    return dueDateMatchingReminderOptions.map((option) => ({
      id: option.id,
      value: option,
    }));
  }, [getDueDateMatchingReminderOptions, selectedDate]);

  const shouldShowReminderOptions =
    withReminder && reminderOptionsAsListItems.length > 0;

  const handleDaySelect = (date: Date) => {
    setSelectedDate((previousDate) => {
      if (previousDate) {
        const updatedDate = new Date(date);

        updatedDate.setHours(
          previousDate.getHours(),
          previousDate.getMinutes()
        );

        return updatedDate;
      }

      return date;
    });
  };

  const handleSubmit = () => {
    if (selectedDate) {
      onSubmit({
        date: selectedDate,
        timezone: "",
        reminderExecutionTime:
          selectedReminderId !== "none"
            ? getNextExecutionTime(selectedDate, selectedReminderId)
            : undefined,
      });
    } else {
      onSubmit(null);
    }

    onClose?.();
  };

  const handleClear = () => {
    if (!selectedDate) {
      return;
    }

    if (isDateEditable) {
      datePickerRef.current?.reset();
      setSelectedDate(null);
      return;
    }

    setSelectedDate(endOfDay(selectedDate));
  };

  // Clear reminder if the selected date is not matching the reminder
  useEffect(() => {
    if (
      selectedDate &&
      !isReminderMatchingDueDate(selectedReminderId, selectedDate)
    ) {
      setSelectedReminderId("none");
    }
  }, [isReminderMatchingDueDate, selectedDate, selectedReminderId]);

  return (
    <div className="flex flex-col gap-8">
      {isDateEditable && (
        <DatePicker
          ref={datePickerRef}
          initialDate={selectedDate || undefined}
          dateTransformation="endOfDay"
          onDaySelect={handleDaySelect}
        />
      )}
      <div className="flex flex-col gap-2">
        <span className="font-secondary text-dark text-sm font-semibold">
          {t({ id: "tasks-page.due-to", defaultMessage: "Due to" })}
        </span>
        <div className="flex items-center gap-2">
          <div
            className={cx(
              "flex h-10 shrink-0 cursor-default items-center rounded-md px-4 transition-colors",
              selectedDate
                ? "bg-primary-50 text-dark-800"
                : "bg-grey-200 text-dark-600"
            )}
          >
            {selectedDate
              ? localeAwareFormat(selectedDate, "dd/MM/yyyy")
              : t({
                  id: "tasks-page.no-due-date",
                  defaultMessage: "No due date",
                })}
          </div>
          <DueTimeInput
            selectedDate={selectedDate}
            onDateChange={setSelectedDate}
          />
        </div>
      </div>
      {shouldShowReminderOptions && (
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-1">
            <span className="font-secondary text-dark text-sm font-semibold">
              {t({
                id: "tasks-page.send-reminder",
                defaultMessage: "Send reminder",
              })}
            </span>
            <Tooltip
              placement="right"
              renderTrigger={({ props, ref }) => (
                <InfoIcon ref={ref} className="cursor-help" {...props} />
              )}
              className="w-[224px]"
            >
              {t({
                id: "tasks-page.task-reminder-info",
                defaultMessage:
                  "Reminder will be sent to all assignees at the selected time before the due date 📩",
              })}
            </Tooltip>
          </div>
          <ResourcePickerPopover
            placement="bottom"
            items={reminderOptionsAsListItems}
            selectionBehavior={{ mode: "single", canToggle: false }}
            onSelect={({ item, onClose: onReminderOptionsPopoverClose }) => {
              setSelectedReminderId(item.id as TaskReminderOptionId);
              onReminderOptionsPopoverClose();
            }}
            defaultSelectedIds={[selectedReminderId]}
            renderTrigger={({ Trigger, triggerRef, isOpen }) => (
              <Trigger
                ref={triggerRef}
                className="bg-grey-100 hover:bg-grey-200 flex h-10 cursor-pointer items-center justify-between rounded-md border-0 px-4 transition-colors"
              >
                <span className="text-dark-800 text-base">
                  {selectedReminderOption.label}
                </span>
                <ArrowDownIcon
                  className={cx(
                    "shrink-0 transition-transform",
                    isOpen && "rotate-180"
                  )}
                />
              </Trigger>
            )}
            renderLabel={(item) => item.value.label}
          />
        </div>
      )}
      <div className="flex items-center gap-3">
        <Button
          fullWidth
          variant="contained"
          color="grey"
          className="h-10"
          onClick={handleClear}
        >
          {t({ id: "common.clear", defaultMessage: "Clear" })}
        </Button>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className="h-10"
          onClick={handleSubmit}
        >
          {t({ id: "common.save", defaultMessage: "Save" })}
        </Button>
      </div>
    </div>
  );
};
