import React, { memo, useCallback, useEffect, useMemo, useRef } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { withTransientProps } from "@web-src/utils/mui";
import { selectUserId } from "@web-src/features/auth/authSlice";
import { useTranslations, useToast } from "@jugl-web/utils";
import {
  ChatItem,
  ChatMessage,
  ChatMessageActionType,
} from "@web-src/features/chats/types";
import useOnScreen from "@web-src/hooks/useOnScreen";
import { isoToTimeStr } from "@web-src/utils/datetime";
import UserProfileName from "@web-src/features/users/components/UserProfileName";
import UserProfileAvatar from "@web-src/features/users/components/UserProfileAvatar";
import { cx } from "@jugl-web/utils/cx";
import { SvgIcon } from "@mui/material";
import { Checkbox } from "@jugl-web/ui-components/cross-platform";
import { ReactComponent as ForwardedIncomingIcon } from "./images/forwarded-icon-incoming.svg";
import { ReactComponent as ForwardedOutgoingIcon } from "./images/forwarded-icon.svg";
import { ReactComponent as HandleIncomingIcon } from "./images/handle-incoming.svg";
import { ReactComponent as HandleOutgoingIcon } from "./images/handle-outgoing.svg";
import ChatMessageStatusIcon from "./ChatMessageStatusIcon";
import {
  getMessageBodyStr,
  getMessageChatId,
  isChatMessageEmojiOnly,
  isMessagesDebugMode,
  tokenizeMessagePayload,
} from "../../utils";
import ChatMessageAttachment from "./ChatMessageAttachment";
import ChatMessageGroupInfo from "./ChatMessageGroupInfo";
import ChatMessageDebugInfo from "./ChatMessageDebugInfo";
import ChatMessageContextMenu, {
  ChatBubbleMenuAction,
} from "./ChatMessageContextMenu";
import useMessageActions from "../../hooks/useMessageActions";
import ChatMessageReplyMessageBubble from "./ChatMessageReplyMessageBubble";
import useSendMessageReaction from "../../hooks/useSendMessageReaction";
import ChatMessageBubbleBody from "./ChatMessageBubbleBody";
import ChatMessageReactions from "./ChatMessageReactions";
import ChatMessageCall from "./ChatMessageCall";
import { setOpenedUserProfile } from "../../chatsSlice";
import { useChatMessageProvider } from "../../providers/ChatMessageProvider";
import { DigestBubble } from "../DigestBubble";

const isDebugMode = isMessagesDebugMode();

const ChatMessageBubble: React.FC<{
  onMessageShow?: (message: ChatMessage) => void;
  onReactionsClick?: (message: ChatMessage) => void;
  message: ChatMessage;
  chatType?: ChatItem["type"];
  isFirstUnread?: boolean;
  grouped?: {
    isFirst: boolean;
    isLast: boolean;
  };
  preview?: boolean;
  disableMessageActions?: boolean;
  isSelected?: boolean;
  showCheckbox?: boolean;
  onSelect?: (selected: boolean, message: ChatMessage) => void;
  isSearchedMessage?: boolean;
}> = ({
  onMessageShow,
  onReactionsClick,
  message,
  chatType,
  grouped,
  isFirstUnread,
  preview,
  disableMessageActions,
  isSelected,
  showCheckbox,
  onSelect,
  isSearchedMessage,
}) => {
  const dispatch = useDispatch();
  const sendMessageReaction = useSendMessageReaction();
  const { attachmentString, downloadAttachmentInProgress } =
    useChatMessageProvider();
  const meId = useSelector(selectUserId);
  const isOutgoing = useMemo(() => message.from === meId, [message.from, meId]);
  const { openMessageInfo, setMessageAction } = useMessageActions(
    getMessageChatId(message, meId)
  );
  const $message = useRef<HTMLElement | null>(null);
  const { toast } = useToast({ variant: "web" });
  const { t } = useTranslations();

  const isOnScreen = useOnScreen($message);

  const timeStr = useMemo(
    () => (message?.timestamp ? isoToTimeStr(message?.timestamp) : ""),
    [message]
  );

  const handleContextMenuClick = useCallback(
    (action: ChatBubbleMenuAction, value?: string) => {
      switch (action) {
        case ChatBubbleMenuAction.messageInfo:
          openMessageInfo(message);
          break;
        case ChatBubbleMenuAction.copy:
          {
            const messageBodyString = getMessageBodyStr(message);
            if (messageBodyString) {
              navigator.clipboard.writeText(messageBodyString);
              toast(
                t({
                  id: "chats-page.message-copied",
                  defaultMessage: "Message copied to your clipboard",
                })
              );
            }
          }
          break;
        case ChatBubbleMenuAction.edit:
          setMessageAction({ message, type: ChatMessageActionType.edit });
          break;
        case ChatBubbleMenuAction.forward:
          setMessageAction({ message, type: ChatMessageActionType.forward });
          onSelect?.(true, message);
          break;
        case ChatBubbleMenuAction.reply:
          setMessageAction({ message, type: ChatMessageActionType.reply });
          break;
        case ChatBubbleMenuAction.delete: {
          setMessageAction({ message, type: ChatMessageActionType.delete });
          onSelect?.(true, message);
          break;
        }
        case ChatBubbleMenuAction.reaction:
          if (value) {
            sendMessageReaction({
              msgId: message.msg_id,
              reaction: value,
              action: message.reactions?.find(
                (item) => item.user_id === meId && item.reaction === value
              )
                ? "remove"
                : "add",
            });
          }
          break;
        default:
      }
    },
    [
      t,
      onSelect,
      toast,
      meId,
      message,
      openMessageInfo,
      sendMessageReaction,
      setMessageAction,
    ]
  );

  useEffect(() => {
    if (message && !message.is_read_by_self && isOnScreen && !isDebugMode) {
      onMessageShow?.(message);
    }
  }, [
    isOnScreen,
    onMessageShow,
    message.msg_receipt_id,
    message.is_read_by_self,
    message,
  ]);
  const bodyTokens = useMemo(() => tokenizeMessagePayload(message), [message]);

  const handleAvatarClick = useCallback(
    (userId: string) => {
      dispatch(setOpenedUserProfile(userId));
    },
    [dispatch]
  );

  const uploadAttachmentInProgress = useMemo(() => {
    const attachment = message.payload.attachments?.[0];
    if (attachment && attachment._progress !== undefined) {
      return true;
    }
    return false;
  }, [message]);

  const isEmojiOnly = useMemo(() => isChatMessageEmojiOnly(message), [message]);

  const shouldShowCheckbox = useMemo(
    () =>
      showCheckbox &&
      !preview &&
      !message?.deleted &&
      !disableMessageActions &&
      message.payload.type !== "group_info",
    [
      showCheckbox,
      preview,
      message.deleted,
      disableMessageActions,
      message.payload.type,
    ]
  );

  const extractedMyReactions = useMemo(
    () =>
      message.reactions
        ?.filter((reactionItem) => reactionItem.user_id === meId)
        .map((reactionItem) => reactionItem.reaction) || [],
    [message.reactions, meId]
  );

  return (
    <>
      {isFirstUnread && !isOutgoing && (
        <div className="flex w-full items-center justify-center gap-[6px] py-[36px]">
          <div className="bg-primary-50 h-[1px] w-[100px]" />
          <div className="text-primary-500 bg-primary-50 flex h-[22px] items-center justify-center whitespace-nowrap rounded-[34px] px-[8px] text-[12px] font-[500]">
            {t({
              id: "chats-page.unread-messages",
              defaultMessage: "Unread messages",
            })}
          </div>
          <div className="bg-primary-50 h-[1px] w-[100px]" />
        </div>
      )}

      <Wrapper
        $hasReactions={!!message?.reactions?.length && !message.deleted}
        $showCheckbox={shouldShowCheckbox}
        $outgoing={isOutgoing}
        ref={$message}
        $preview={preview}
        $isSelected={isSelected}
        $isSearchedMessage={isSearchedMessage}
      >
        {shouldShowCheckbox && (
          <div
            className={cx(
              "absolute top-0 left-0 z-10 h-full w-full bg-transparent",
              shouldShowCheckbox && "cursor-pointer"
            )}
            onClick={(e) => {
              e.stopPropagation();
              onSelect?.(!isSelected, message);
            }}
          >
            <Checkbox
              isChecked={isSelected}
              readOnly
              className="absolute left-10 top-1/2 -translate-y-1/2"
            />
          </div>
        )}
        {message.payload.type === "group_info" ? (
          <ChatMessageGroupInfo message={message} />
        ) : message.payload.type === "digest" ? (
          <div className="my-2 flex flex-col items-center gap-4">
            {message.payload.digest?.map((digest) => (
              <DigestBubble
                title={digest.digest}
                date={message.timestamp}
                type={digest.module}
              />
            ))}
          </div>
        ) : message.payload.type === "task_reminder" ? (
          <>
            {message.payload.task_reminder ? (
              <div className="my-2">
                <DigestBubble
                  title={message.payload.task_reminder.name}
                  date={message.timestamp}
                  type="task_reminder"
                  reminder={message.payload.task_reminder}
                />
              </div>
            ) : null}
          </>
        ) : (
          <Bubble
            $isFirst={grouped?.isFirst}
            $isLast={grouped?.isLast}
            $outgoing={isOutgoing}
          >
            {!preview &&
              !message?.deleted &&
              !disableMessageActions &&
              !showCheckbox &&
              !uploadAttachmentInProgress && (
                <ChatMessageContextMenu
                  onClick={handleContextMenuClick}
                  isOutgoing={isOutgoing}
                  isHaveBodyContent={!!getMessageBodyStr(message)}
                  myReactions={extractedMyReactions}
                  downloadAttachmentInProgress={downloadAttachmentInProgress}
                />
              )}
            {!isOutgoing &&
              chatType === "muc" &&
              (!grouped || grouped?.isLast) && (
                <UserProfileAvatar
                  userId={message.from}
                  wrapperClassName="absolute bottom-0 left-[-26px] w-[24px] h-[24px] rounded-[12px] overflow-hidden border-[2px] border-solid border-white shadow flex justify-center items-center bg-gray-100 cursor-pointer"
                  className="h-full w-full"
                  lettersClassName="text-[8px] font-bold"
                  onClick={handleAvatarClick.bind(null, message.from)}
                />
              )}
            {message.payload?.forwarded ? (
              <Stack direction="row" alignItems="center" sx={{ opacity: 0.7 }}>
                <SvgIcon
                  viewBox="0 0 16 16"
                  sx={{
                    width: "16px",
                    height: "16px",
                    mr: "2px",
                  }}
                  component={
                    isOutgoing ? ForwardedOutgoingIcon : ForwardedIncomingIcon
                  }
                />
                <Typography
                  color={isOutgoing ? "grey.100" : "grey.900"}
                  variant="body"
                  fontSize="12px"
                  fontWeight="500"
                >
                  {t({
                    id: "chats-page.forwarded",
                    defaultMessage: "Forwarded",
                  })}
                </Typography>
              </Stack>
            ) : null}
            {!isOutgoing &&
              chatType === "muc" &&
              (!grouped ||
                grouped?.isFirst ||
                message?.payload?.reply_attributes_map) && (
                <SenderName>
                  <UserProfileName userId={message.from} />
                </SenderName>
              )}
            {message.deleted ? (
              <span
                className={cx(
                  "text-s italic",
                  isOutgoing ? "text-gray-100" : "text-gray-400"
                )}
              >
                {t({
                  id: "chats-page.owner-deleted-message",
                  defaultMessage: "Message deleted by owner",
                })}
              </span>
            ) : (
              <>
                {message?.payload?.reply_attributes_map ? (
                  <div className="mb-[8px]">
                    <ChatMessageReplyMessageBubble message={message} />
                  </div>
                ) : null}
                {message?.payload?.attachments && (
                  <div className="mb-[8px]">
                    <ChatMessageAttachment />
                  </div>
                )}
                {message?.payload?.conference?.channel && (
                  <ChatMessageCall
                    conference={message?.payload?.conference}
                    isOutgoing={isOutgoing}
                  />
                )}
                <BodyText
                  $outgoing={isOutgoing}
                  sx={{ fontSize: isEmojiOnly ? "32px" : undefined }}
                >
                  <ChatMessageBubbleBody
                    tokens={bodyTokens}
                    outgoing={isOutgoing}
                  />
                </BodyText>
              </>
            )}
            {isDebugMode && (
              <ChatMessageDebugInfo
                message={message}
                onReadClick={onMessageShow}
              />
            )}
            {(!grouped || grouped?.isLast) && (
              <BubbleHandle $outgoing={isOutgoing}>
                {isOutgoing ? (
                  <HandleOutgoingIcon
                    style={{
                      display: "block",
                    }}
                  />
                ) : (
                  <HandleIncomingIcon
                    style={{
                      display: "block",
                    }}
                  />
                )}
              </BubbleHandle>
            )}
            <BottomInfo direction="row">
              {!!message?.reactions?.length && !preview && !message.deleted && (
                <div className="relative -bottom-[16px] mr-[16px]">
                  <ChatMessageReactions
                    message={message}
                    onClick={onReactionsClick?.bind?.(null, message)}
                  />
                </div>
              )}
              {attachmentString && (
                <div
                  className={cx(
                    attachmentString.className,
                    "absolute left-2 whitespace-nowrap text-xs"
                  )}
                >
                  {attachmentString.text}
                </div>
              )}
              <div className="flex w-full items-center justify-end">
                {timeStr && (
                  <Typography
                    variant="body"
                    fontSize="11px"
                    color={isOutgoing ? "grey.100" : "grey.900"}
                  >
                    {message?.edited && (
                      <span>
                        {t({
                          id: "chats-page.edited",
                          defaultMessage: "Edited",
                        })}{" "}
                        •{" "}
                      </span>
                    )}
                    {timeStr}
                  </Typography>
                )}
                {isOutgoing && <ChatMessageStatusIcon message={message} />}
              </div>
            </BottomInfo>
          </Bubble>
        )}
      </Wrapper>
    </>
  );
};

const Wrapper = styled(
  Box,
  withTransientProps
)<{
  $outgoing?: boolean;
  $preview?: boolean;
  $hasReactions?: boolean;
  $showCheckbox?: boolean;
  $isSelected?: boolean;
  $isSearchedMessage?: boolean;
}>(
  ({
    $preview,
    $hasReactions,
    $showCheckbox,
    $isSelected,
    $isSearchedMessage,
  }) => ({
    backgroundColor:
      $showCheckbox && $isSelected
        ? "#F1F5FA"
        : $isSearchedMessage
        ? "#E3F2FD"
        : "white",
    "&:hover": {
      backgroundColor:
        $showCheckbox && !$isSelected
          ? "#F8F9FA"
          : $isSelected
          ? "#F1F5FA"
          : "white",
    },
    position: "relative",
    padding: $preview
      ? "0px"
      : `2px 56px ${$hasReactions ? "16px" : "2px"} ${
          $showCheckbox ? "100px" : "56px"
        }`,
    maxWidth: "100%",
    ".bubbleMenuButton": {
      opacity: 0,
      transition: "opacity 0.2s",
    },
    "&:hover .bubbleMenuButton": {
      opacity: 100,
    },
  })
);

const BubbleHandle = styled(
  Box,
  withTransientProps
)<{ $outgoing: boolean }>(({ $outgoing }) => ({
  position: "absolute",
  height: "10px",
  bottom: 0,
  left: $outgoing ? undefined : "-10px",
  right: $outgoing ? "-10px" : 0,
}));

const SenderName = styled(Box)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "18px",
  color: theme.palette.primary.main,
  marginBottom: "2px",
}));

const BodyText = styled(
  Stack,
  withTransientProps
)<{ $outgoing?: boolean }>(({ $outgoing, theme }) => ({
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "130%",
  whiteSpace: "pre-wrap",
  wordWrap: "break-word",
  [`a`]: {
    color: $outgoing ? theme.palette.grey[100] : theme.palette.primary.main,
  },
}));

const BottomInfo = styled(Stack)(() => ({
  alignItems: "center",
  justifyContent: "space-between",
}));

const Bubble = styled(
  Stack,
  withTransientProps
)<{
  $outgoing?: boolean;
  $deleted?: boolean;
  $isFirst?: boolean;
  $isLast?: boolean;
}>(({ $outgoing, $deleted, theme, $isFirst }) => ({
  position: "relative",
  maxWidth: "min(520px, 100%)",
  width: "max-content",
  padding: "8px 8px 6px 8px",
  backgroundColor:
    $outgoing && !$deleted
      ? theme.palette.juglMessages.chatBubbleSender
      : theme.palette.juglMessages.chatBubbleReceiver,
  borderRadius:
    $outgoing && !$deleted
      ? `${$isFirst === undefined || $isFirst ? "8px 8px" : "8px 0px"} 0px 8px`
      : `${$isFirst === undefined || $isFirst ? "8px 8px" : "0px 8px"} 8px 0px`,
  marginLeft: $outgoing ? "auto" : undefined,
  boxShadow:
    $outgoing || $deleted ? undefined : "0px 1px 4px rgba(0, 0, 0, 0.17)",
  color: $deleted
    ? theme.palette.juglDark[500]
    : $outgoing
    ? "white"
    : theme.palette.dark.A100,
}));

export default memo(ChatMessageBubble);
