import { assert, useTranslations } from "@jugl-web/utils";
import { useCallback, useMemo } from "react";

export type TaskReminderOptionId =
  | "none"
  | "30-minutes-before"
  | "1-hour-before"
  | "2-hours-before"
  | "1-day-before"
  | "2-days-before";

export interface TaskReminderOption {
  secondsBefore: number;
  label: string;
}

export type TaskReminderOptionWithId = TaskReminderOption & {
  id: TaskReminderOptionId;
};

// 1-minute buffer to compensate for backend scheduler time margin
const BACKEND_SCHEDULER_TIME_MARGIN_MS = 60 * 1 * 1000;

export const useTaskReminders = () => {
  const { t } = useTranslations();

  const taskReminderOptionById = useMemo(
    () =>
      new Map<TaskReminderOptionId, TaskReminderOption>([
        [
          "none",
          {
            secondsBefore: 0,
            label: t({
              id: "tasks-page.reminder-none",
              defaultMessage: "None",
            }),
          },
        ],
        [
          "30-minutes-before",
          {
            secondsBefore: 60 * 30,
            label: t(
              {
                id: "tasks-page.reminder-x-minutes-before",
                defaultMessage: "{minutes} minutes before",
              },
              { minutes: 30 }
            ),
          },
        ],
        [
          "1-hour-before",
          {
            secondsBefore: 60 * 60,
            label: t(
              {
                id: "tasks-page.reminder-x-hours-before",
                defaultMessage:
                  "{hours} {hours, plural, one {hour} other {hours}} before",
              },
              { hours: 1 }
            ),
          },
        ],
        [
          "2-hours-before",
          {
            secondsBefore: 60 * 60 * 2,
            label: t(
              {
                id: "tasks-page.reminder-x-hours-before",
                defaultMessage:
                  "{hours} {hours, plural, one {hour} other {hours}} before",
              },
              { hours: 2 }
            ),
          },
        ],
        [
          "1-day-before",
          {
            secondsBefore: 60 * 60 * 24,
            label: t(
              {
                id: "tasks-page.reminder-x-days-before",
                defaultMessage:
                  "{days} {days, plural, one {day} other {days}} before",
              },
              { days: 1 }
            ),
          },
        ],
        [
          "2-days-before",
          {
            secondsBefore: 60 * 60 * 24 * 2,
            label: t(
              {
                id: "tasks-page.reminder-x-days-before",
                defaultMessage:
                  "{days} {days, plural, one {day} other {days}} before",
              },
              { days: 2 }
            ),
          },
        ],
      ]),
    [t]
  );

  const getNextExecutionTime = useCallback(
    (dueDate: Date, reminderId: TaskReminderOptionId) => {
      const reminderOption = taskReminderOptionById.get(reminderId);

      assert(!!reminderOption, "Reminder option must be defined");

      return new Date(
        dueDate.getTime() -
          reminderOption.secondsBefore * 1000 -
          BACKEND_SCHEDULER_TIME_MARGIN_MS
      );
    },
    [taskReminderOptionById]
  );

  const findReminderOptionByNextExecutionTime = useCallback(
    (
      nextExecutionDate: Date,
      dueDate: Date
    ): TaskReminderOptionWithId | undefined => {
      const secondsBefore = Math.floor(
        (dueDate.getTime() - nextExecutionDate.getTime()) / 1000
      );

      const reminderOptionEntry = Array.from(taskReminderOptionById).find(
        ([, option]) => {
          // we seek for the reminder option with the precision of backend scheduler time margin
          const timeBufferSec = BACKEND_SCHEDULER_TIME_MARGIN_MS / 1000;

          return (
            secondsBefore >= option.secondsBefore - timeBufferSec &&
            secondsBefore <= option.secondsBefore + timeBufferSec
          );
        }
      );

      if (reminderOptionEntry) {
        const [id, option] = reminderOptionEntry;
        return { id, ...option };
      }

      return undefined;
    },
    [taskReminderOptionById]
  );

  const getReminderOptionById = useCallback(
    (id: TaskReminderOptionId): TaskReminderOption => {
      const option = taskReminderOptionById.get(id);

      assert(!!option, "Reminder option must be defined");

      return option;
    },
    [taskReminderOptionById]
  );

  const isReminderMatchingDueDate = useCallback(
    (reminderId: TaskReminderOptionId, dueDate: Date) => {
      const option = taskReminderOptionById.get(reminderId);

      assert(!!option, "Reminder option must be defined");

      // 5-minutes buffer to avoid creating reminders in the past
      const timeBufferMs = 60 * 5 * 1000;

      const timeToDueDateMs = dueDate.getTime() - new Date().getTime();

      return timeToDueDateMs >= option.secondsBefore * 1000 + timeBufferMs;
    },
    [taskReminderOptionById]
  );

  const getDueDateMatchingReminderOptions = useCallback(
    (dueDate: Date) =>
      Array.from(taskReminderOptionById).reduce<TaskReminderOptionWithId[]>(
        (acc, [id, option]) => {
          if (!isReminderMatchingDueDate(id, dueDate)) {
            return acc;
          }

          return [...acc, { id, ...option }];
        },
        []
      ),
    [isReminderMatchingDueDate, taskReminderOptionById]
  );

  return {
    getReminderOptionById,
    getNextExecutionTime,
    findReminderOptionByNextExecutionTime,
    isReminderMatchingDueDate,
    getDueDateMatchingReminderOptions,
  };
};
