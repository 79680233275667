import {
  TaskModuleLog,
  TaskModuleLogAction,
  TaskModuleLogChanges,
} from "@jugl-web/rest-api";
import { stripTaskDescriptionPrefix } from "@jugl-web/rest-api/tasks";
import { convertMillisecondsToTimeComponents } from "@jugl-web/ui-components/cross-platform/tasks/TimeSpentPicker/utils";
import {
  addSearchParamsToURL,
  isoToLocalDate,
  joinReactNodes,
  trimHTML,
  useTranslations,
} from "@jugl-web/utils";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import { Linkify } from "@jugl-web/utils/utils/Linkify";
import { Mentionify } from "@jugl-web/utils/utils/Mentionify";
import { joinReactNodesInHumanReadableForm } from "@jugl-web/utils/utils/joinReactNodes";
import format from "date-fns/format";
import { ReactNode, useCallback, useMemo } from "react";
import { useTaskPriorities } from "../../tasks/hooks/useTaskPriorities";
import { useTaskStatuses } from "../../tasks/hooks/useTaskStatuses";

type SupportedTaskModuleLogChanges = Required<TaskModuleLogChanges>;

type ChangeKeyToMessageRendererMap = {
  [Key in keyof SupportedTaskModuleLogChanges]: (
    arg: SupportedTaskModuleLogChanges[Key]
  ) => ReactNode;
};

interface UseTaskLogParserOptions {
  entityId: string | undefined;
}

export const useTaskLogParser = ({ entityId }: UseTaskLogParserOptions) => {
  const { getStatusDetailsById } = useTaskStatuses({ entityId });
  const { getPriorityDetailsById } = useTaskPriorities();

  const { t } = useTranslations();
  const { dateLocale } = useLanguage();

  const taskCreatedMessageRenderer = useCallback(
    (username: string) =>
      t(
        {
          id: "tasks-page.user-created-task-activity",
          defaultMessage: "{username} created this task",
        },
        {
          username: <b>{username}</b>,
        }
      ),
    [t]
  );

  const taskCreatedByExternalUserMessageRenderer = useCallback(
    () =>
      t(
        {
          id: "tasks-page.external-user-created-task-activity",
          defaultMessage:
            "{username} created this task by submitting the order form",
        },
        {
          username: (
            <b>
              {t({
                id: "common.external-client",
                defaultMessage: "External client",
              })}
            </b>
          ),
        }
      ),
    [t]
  );

  const taskUpdatedMessageRenderer = useCallback(
    (username: string, changesMessage: ReactNode) =>
      joinReactNodes([<b>{username}</b>, changesMessage], " "),
    []
  );

  const taskDeletedMessageRenderer = useCallback(
    (username: string) =>
      t(
        {
          id: "tasks-page.user-removed-task-activity",
          defaultMessage: "{username} removed this task",
        },
        {
          username: <b>{username}</b>,
        }
      ),
    [t]
  );

  const taskCommentedMessageRenderer = useCallback(
    (username: string) =>
      t(
        {
          id: "tasks-page.user-commented-task-activity",
          defaultMessage: "{username} commented on this task",
        },
        {
          username: <b>{username}</b>,
        }
      ),
    [t]
  );

  const taskViewedMessageRenderer = useCallback(
    (username: string) =>
      t(
        {
          id: "tasks-page.user-viewed-task-activity",
          defaultMessage: "{username} viewed this task",
        },
        {
          username: <b>{username}</b>,
        }
      ),
    [t]
  );

  // Fallback message if there is no valid change message
  const fallbackUpdatedMessageRenderer = useCallback(
    () =>
      t({
        id: "tasks-page.updated-task-activity",
        defaultMessage: "updated this task",
      }),
    [t]
  );

  const changeKeyToMessageRendererMap: ChangeKeyToMessageRendererMap = useMemo(
    () => ({
      name: (name) =>
        t(
          {
            id: "tasks-page.updated-task-name-activity",
            defaultMessage: "updated task name to {name}",
          },
          { name: <b>{name}</b> }
        ),
      desc: (description) =>
        description
          ? t(
              {
                id: "tasks-page.updated-task-description-activity",
                defaultMessage: "updated task description to {description}",
              },
              {
                description: (
                  <b>{trimHTML(stripTaskDescriptionPrefix(description))}</b>
                ),
              }
            )
          : t({
              id: "tasks-page.removed-task-description-activity",
              defaultMessage: "removed task description",
            }),
      priority: (priority) =>
        t(
          {
            id: "tasks-page.updated-task-priority-activity",
            defaultMessage: "updated task priority to {priority}",
          },
          { priority: <b>{getPriorityDetailsById(priority).shortLabel}</b> }
        ),
      label: (label) =>
        label
          ? t(
              {
                id: "tasks-page.updated-task-label-activity",
                defaultMessage: "updated task label to {label}",
              },
              { label: <b>{label.text}</b> }
            )
          : t({
              id: "tasks-page.removed-task-label-activity",
              defaultMessage: "removed task label",
            }),
      due_at: (dueDate) =>
        dueDate
          ? t(
              {
                id: "tasks-page.updated-due-date-activity",
                defaultMessage: "updated due date to {dueDate}",
              },
              {
                dueDate: (
                  <b>
                    {format(isoToLocalDate(dueDate), "MMM dd, hh:mm a", {
                      locale: dateLocale,
                    })}
                  </b>
                ),
              }
            )
          : t({
              id: "tasks-page.removed-due-date-activity",
              defaultMessage: "removed due date",
            }),
      status: (statusId) =>
        t(
          {
            id: "tasks-page.updated-task-status-activity",
            defaultMessage: "updated task status to {status}",
          },
          { status: <b>{getStatusDetailsById(statusId).label}</b> }
        ),
      duration: (duration) => {
        const { hours, minutes } =
          convertMillisecondsToTimeComponents(duration);

        return t(
          {
            id: "tasks-page.updated-time-spent-activity",
            defaultMessage:
              "updated time spent on a task to <b>{hours}h {minutes}min</b>",
          },
          {
            hours,
            minutes,
            b: (text: (string | JSX.Element)[]) => <b>{text}</b>,
          }
        );
      },
      assignee_added: (assignees) =>
        t(
          {
            id: "tasks-page.assigned-users-activity",
            defaultMessage: "assigned {users} to the task",
          },
          {
            users: joinReactNodesInHumanReadableForm(
              assignees.map((assignee) => (
                <b>
                  {assignee.first_name} {assignee.last_name}
                </b>
              ))
            ),
          }
        ),
      assignee_removed: (assignees) =>
        t(
          {
            id: "tasks-page.unassigned-users-activity",
            defaultMessage: "unassigned {users} from the task",
          },
          {
            users: joinReactNodesInHumanReadableForm(
              assignees.map((assignee) => (
                <b>
                  {assignee.first_name} {assignee.last_name}
                </b>
              ))
            ),
          }
        ),
      checklist_added: (items) =>
        t(
          {
            id: "tasks-page.added-checklist-items-activity",
            defaultMessage:
              "added {count} checklist {count, plural, one {item} other {items}}: {items}",
          },
          {
            count: items.length,
            items: joinReactNodesInHumanReadableForm(
              items.map((item) => (
                <Linkify>
                  <Mentionify>
                    <b>{item.c_name}</b>
                  </Mentionify>
                </Linkify>
              ))
            ),
          }
        ),
      checklist_updated: (items) =>
        t(
          {
            id: "tasks-page.updated-checklist-items-activity",
            defaultMessage:
              "updated {count} checklist {count, plural, one {item} other {items}}: {items}",
          },
          {
            count: items.length,
            items: joinReactNodesInHumanReadableForm(
              items.map((item) => (
                <Linkify>
                  <Mentionify>
                    <b>{item.name || item.c_name}</b>
                  </Mentionify>
                </Linkify>
              ))
            ),
          }
        ),
      checklist_rearranged: () =>
        t({
          id: "tasks-page.rearranged-checklist-items-activity",
          defaultMessage: "has rearranged checklist items",
        }),
      checklist_deleted: (items) =>
        t(
          {
            id: "tasks-page.removed-checklist-items-activity",
            defaultMessage:
              "removed {count} checklist {count, plural, one {item} other {items}}: {items}",
          },
          {
            count: items.length,
            items: joinReactNodesInHumanReadableForm(
              items.map((item) => (
                <Linkify>
                  <Mentionify>
                    <b>{item.c_name}</b>
                  </Mentionify>
                </Linkify>
              ))
            ),
          }
        ),
      attachment_added: (attachment) =>
        t(
          {
            id: "tasks-page.added-attachment-activity",
            defaultMessage: "added attachment {name}",
          },
          {
            name: <b>{attachment.file_name}</b>,
          }
        ),
      attachment_deleted: (attachment) =>
        t(
          {
            id: "tasks-page.removed-attachment-activity",
            defaultMessage: "removed attachment {name}",
          },
          {
            name: <b>{attachment.file_name}</b>,
          }
        ),
      attachment_renamed: (attachment) =>
        t(
          {
            id: "tasks-page.renamed-attachment-activity",
            defaultMessage: "renamed attachment {oldName} to {newName}",
          },
          {
            oldName: <b>{attachment.old_file_name}</b>,
            newName: <b>{attachment.file_name}</b>,
          }
        ),
      customer: () =>
        t({
          id: "tasks-page.updated-task-customer-activity",
          defaultMessage: "updated task customer",
        }),
      reminder: () =>
        t({
          id: "tasks-page.updated-task-reminder-activity",
          defaultMessage: "updated task reminder",
        }),
      recurrence: () =>
        t({
          id: "tasks-page.updated-task-recurrence-settings-activity",
          defaultMessage: "updated task recurrence settings",
        }),
      can_assignee_edit: (canAssigneeEdit) =>
        canAssigneeEdit
          ? t({
              id: "tasks-page.updated-task-editable-activity",
              defaultMessage: "updated task to be editable by assignees",
            })
          : t({
              id: "tasks-page.updated-task-not-editable-activity",
              defaultMessage: "updated task to be not editable by assignees",
            }),
      has_chklist_chk: (isInSpecificOrder) =>
        isInSpecificOrder
          ? t({
              id: "tasks-page.updated-checklist-specific-order-activity",
              defaultMessage:
                "updated checklist to be completed in specific order",
            })
          : t({
              id: "tasks-page.updated-checklist-any-order-activity",
              defaultMessage: "updated checklist to be completed in any order",
            }),
      custom_fields: () => "has updated custom field",
    }),
    [dateLocale, getPriorityDetailsById, getStatusDetailsById, t]
  );

  const taskChangeRenderer = useCallback(
    <
      TKey extends keyof SupportedTaskModuleLogChanges,
      TValue extends SupportedTaskModuleLogChanges[TKey]
    >(
      key: TKey,
      value: TValue
    ): ReactNode | undefined => changeKeyToMessageRendererMap[key]?.(value),
    [changeKeyToMessageRendererMap]
  );

  const extractMessageFromChanges = useCallback(
    (changes: TaskModuleLogChanges) => {
      const changeMessages = Object.keys(changes).reduce<ReactNode[]>(
        (acc, key) => {
          const changeKey = key as keyof SupportedTaskModuleLogChanges;

          const changeValue = changes[
            changeKey
          ] as SupportedTaskModuleLogChanges[keyof SupportedTaskModuleLogChanges];

          let changeMessage: ReactNode;

          try {
            changeMessage = taskChangeRenderer(changeKey, changeValue);
          } catch {
            // Do nothing
          }

          if (!changeMessage) {
            // eslint-disable-next-line no-console
            console.warn(
              "Couldn't parse the following task module log change",
              { changeKey, changeValue }
            );

            return acc;
          }

          return [...acc, changeMessage];
        },
        []
      );

      if (changeMessages.length === 0) {
        return fallbackUpdatedMessageRenderer();
      }

      return joinReactNodesInHumanReadableForm(changeMessages);
    },

    [fallbackUpdatedMessageRenderer, taskChangeRenderer]
  );

  const taskMessageRenderer = useCallback(
    (
      username: string,
      action: TaskModuleLogAction,
      changes: TaskModuleLogChanges,
      isExternalClient?: boolean
    ) => {
      if (action === "created") {
        if (isExternalClient) {
          return taskCreatedByExternalUserMessageRenderer();
        }
        return taskCreatedMessageRenderer(username);
      }

      if (action === "updated") {
        return taskUpdatedMessageRenderer(
          username,
          extractMessageFromChanges(changes)
        );
      }

      if (action === "deleted") {
        return taskDeletedMessageRenderer(username);
      }

      if (action === "commented") {
        return taskCommentedMessageRenderer(username);
      }

      if (action === "viewed") {
        return taskViewedMessageRenderer(username);
      }

      throw new Error(`Unsupported task log action: ${action}`);
    },
    [
      taskCreatedMessageRenderer,
      taskCreatedByExternalUserMessageRenderer,
      taskUpdatedMessageRenderer,
      extractMessageFromChanges,
      taskDeletedMessageRenderer,
      taskCommentedMessageRenderer,
      taskViewedMessageRenderer,
    ]
  );

  const parseTaskLog = useCallback(
    (log: TaskModuleLog) => {
      const isExternalClient = !log.action_by;
      const username = [
        log.action_by?.first_name,
        log.action_by?.last_name,
      ].join(" ");

      return {
        id: log.id,
        user: {
          name: username,
          isExternalClient,
          avatarUrl: log.action_by?.img
            ? addSearchParamsToURL(log.action_by?.img, {
                t: log.action_by.updated_at,
              })
            : null,
        },
        message: taskMessageRenderer(
          username,
          log.action,
          log.changes,
          isExternalClient
        ),
        date: new Date(isoToLocalDate(log.inserted_at)),
      };
    },
    [taskMessageRenderer]
  );

  return { parseTaskLog };
};
