import {
  PaginatedRequestParams,
  PaginatedResponse,
  RtkEmptySplitApi,
} from "../../types";
import { withPrefixedTaskDescription, TaskCustomField } from "../tasks";
import {
  OrderListItemDto,
  OrderListItemModel,
  transformOrderListItemDtoToModel,
} from "./models/OrderListItem";
import { OrdersApiTag } from "./tags";
import { CreateOrderFormPayload, OrderForm } from "./types";

export const addOrdersApi = (emptySplitApi: RtkEmptySplitApi) => {
  const apiWithTags = emptySplitApi.enhanceEndpoints({
    addTagTypes: [OrdersApiTag.orders],
  });

  return apiWithTags.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
      getOrders: builder.query<
        PaginatedResponse<OrderListItemModel>,
        PaginatedRequestParams<{ entityId: string }>
      >({
        query: ({ entityId }) => ({
          url: `/api/auth/${entityId}/order/form`,
        }),
        transformResponse: (response: PaginatedResponse<OrderListItemDto>) => ({
          ...response,
          data: response.data.map(transformOrderListItemDtoToModel),
        }),
        providesTags: [OrdersApiTag.orders],
      }),
      createOrderForm: builder.mutation<
        OrderListItemModel,
        {
          entityId: string;
          opts: CreateOrderFormPayload;
          banner?: File;
        }
      >({
        query: ({ entityId, opts, banner }) => {
          const dataForm = new FormData();
          dataForm.set(
            "opts",
            JSON.stringify(withPrefixedTaskDescription(opts))
          );
          if (banner) {
            dataForm.set("banner_img_file", banner);
          }

          return {
            url: `/api/auth/${entityId}/order/form`,
            method: "POST",
            data: dataForm,
            silentError: true,
          };
        },
        transformResponse: (response: OrderListItemDto) =>
          transformOrderListItemDtoToModel(response),
        invalidatesTags: [OrdersApiTag.orders],
      }),
      getOrderForm: builder.query<
        OrderForm,
        {
          entityId: string;
          formId: string;
        }
      >({
        query: ({ entityId, formId }) => ({
          url: `/api/auth/${entityId}/order/form/${formId}`,
        }),
        providesTags: [OrdersApiTag.orders],
      }),
      deleteOrderForm: builder.mutation<
        void,
        { entityId: string; formId: string }
      >({
        query: ({ entityId, formId }) => ({
          url: `/api/auth/${entityId}/order/form/${formId}`,
          method: "DELETE",
          silentError: true,
        }),
        invalidatesTags: [OrdersApiTag.orders],
      }),
      updateOrderForm: builder.mutation<
        void,
        {
          entityId: string;
          formId: string;
          opts: Partial<CreateOrderFormPayload>;
          banner?: File;
        }
      >({
        query: ({ entityId, formId, opts, banner }) => {
          const dataForm = new FormData();
          dataForm.set(
            "opts",
            JSON.stringify(withPrefixedTaskDescription(opts))
          );
          if (banner) {
            dataForm.set("banner_img_file", banner);
          }

          return {
            url: `/api/auth/${entityId}/order/form/${formId}`,
            method: "PUT",
            data: dataForm,
            silentError: true,
          };
        },
        invalidatesTags: [OrdersApiTag.orders],
      }),
      guestOrderForm: builder.query<any, { entityId: string; formId: string }>({
        query: ({ entityId, formId }) => ({
          url: `/api/guest/${entityId}/order/form/${formId}`,
        }),
      }),
      guestInit: builder.query<
        {
          custom_fields: { props: { value: TaskCustomField[] } };
          entity: { id: string; name: string };
        },
        { entityId: string }
      >({
        query: ({ entityId }) => ({
          url: `/api/guest/${entityId}/order/init`,
        }),
      }),
      guestSubmitOrder: builder.mutation<
        any,
        {
          entityId: string;
          formId: string;
          data: {
            name: string;
            priority: string;
            due_at: string | null;
            fields: Array<{ [key: string]: string }>;
            custom_fields: { [key: string]: string };
            can_assignee_edit?: boolean;
            has_chklist_chk?: boolean;
          };
        }
      >({
        query: ({ entityId, formId, data }) => ({
          url: `/api/guest/${entityId}/order/form/${formId}/submit`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        }),
      }),
    }),
  });
};

export type OrdersApi = ReturnType<typeof addOrdersApi>;
