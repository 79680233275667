import { PageNavParams } from "@web-src/modules/navigation/types";
import { ModulePagesConfig } from "@web-src/modules/navigation/types/ModulePagesConfigs";
import TasksPage from "@web-src/pages/Tasks";
import TaskDetailsPage from "@web-src/pages/TaskDetails";
import TaskTemplatesPage from "@web-src/pages/TaskTemplates";
import TaskTemplatesFoldersPage from "@web-src/pages/TaskTemplateFolders";
import { TasksArchive } from "@web-src/pages/TasksArchive";

export type TasksPagesNavigationParams = {
  tasksTasks: PageNavParams;
  tasksTemplates: PageNavParams;
  tasksTemplatesFolder: PageNavParams<{ folderId: string }>;
  tasksDetails: PageNavParams<{ taskId: string }>;
  tasksArchive: PageNavParams;
};

export const tasksPagesNavigationConfig: ModulePagesConfig<TasksPagesNavigationParams> =
  {
    tasksTasks: {
      element: <TasksPage />,
      path: "tasks",
      isPrivate: true,
    },
    tasksTemplates: {
      element: <TaskTemplatesFoldersPage />,
      path: "tasks/templates",
      isPrivate: true,
    },
    tasksTemplatesFolder: {
      element: <TaskTemplatesPage />,
      path: "tasks/templates/:folderId",
      isPrivate: true,
    },
    tasksDetails: {
      element: <TaskDetailsPage />,
      path: "tasks/details/:taskId",
      isPrivate: true,
    },
    tasksArchive: {
      element: <TasksArchive />,
      path: "tasks/archive",
      isPrivate: true,
    },
  };
