import React, { useContext, useState, useRef, useLayoutEffect } from "react";
import { cx } from "@jugl-web/utils";
import { CallsContext } from "../../../../providers";
import ControlPanel from "../ControlPanel";
import { ReactComponent as DragIcon } from "./assets/drag.svg";
import { ToggleButton } from "../ToggleButton";
import { ReactComponent as MaximizeIcon } from "./assets/maximize.svg";
import { ReactComponent as CloseIcon } from "./assets/close.svg";

export const MinimizedCall: React.FC<{
  toggleMinimized: () => void;
  handleEndCall: () => void;
  toggleVideo?: () => void;
  children: React.ReactNode;
  containerClassName?: string;
  hideControls?: boolean;
  showCloseButton?: boolean;
}> = ({
  toggleMinimized,
  handleEndCall,
  toggleVideo,
  children,
  containerClassName,
  hideControls,
  showCloseButton,
}) => {
  const { activeCall, activeCallProps, callStage } = useContext(CallsContext);
  const [draggable, setDraggable] = useState(false);
  const [position, setPosition] = useState({
    x: window.innerWidth - 480,
    y: 80,
  });
  const isCall = activeCallProps?.type === "call";
  const containerRef = useRef<HTMLDivElement>(null);
  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    const container = containerRef?.current;
    if (container) {
      container.style.top = `${e.clientY - 20}px`;
      container.style.left = `${e.clientX - 380}px`;
    }
  };

  useLayoutEffect(() => {
    const container = containerRef.current;
    if (container) {
      const rect = container.getBoundingClientRect();
      setPosition({
        x: rect.left,
        y: rect.top,
      });
    }
  }, []);

  return (
    <div
      ref={containerRef}
      style={{
        top: `${position.y}px`,
        left: `${position.x}px`,
      }}
      className={cx(
        "fixed z-40 h-[260px] w-[400px] rounded-2xl bg-[#000000]/75",
        containerClassName
      )}
      draggable={draggable}
      onDragEnd={handleMouseMove}
    >
      <ToggleButton
        isOn={false}
        onIcon={MaximizeIcon}
        offIcon={MaximizeIcon}
        noBgChange
        className="absolute top-6 right-6 z-40 h-[40px] w-[40px] cursor-pointer drop-shadow-lg"
        onClick={toggleMinimized}
        hidden={hideControls}
      />
      <ToggleButton
        onMouseDown={() => setDraggable(true)}
        onMouseUp={() => setDraggable(false)}
        onIcon={DragIcon}
        offIcon={DragIcon}
        isOn={false}
        noBgChange
        className="absolute bottom-6 right-6 z-40 h-[40px] w-[40px] cursor-pointer drop-shadow-lg"
        hidden={hideControls || !isCall}
      />
      <ToggleButton
        isOn={false}
        onIcon={CloseIcon}
        offIcon={CloseIcon}
        noBgChange
        className="absolute top-6 right-6 z-40 h-[40px] w-[40px] cursor-pointer drop-shadow-lg"
        onClick={handleEndCall}
        hidden={!showCloseButton}
      />
      {children}
      <div
        className={cx(
          "absolute bottom-10 left-1/2 z-50 flex -translate-x-1/2 transform flex-row items-center gap-6",
          {
            hidden: hideControls,
          }
        )}
      >
        {activeCall && (
          <ControlPanel
            isVideoOn={activeCall?.videoEnabled}
            isMicOn={activeCall?.audioEnabled}
            isScreenShared={activeCall?.screenSharingEnabled}
            toggleVideo={toggleVideo || activeCall?.toggleVideo}
            toggleMic={activeCall?.toggleAudio}
            toggleScreenShare={activeCall?.toggleScreenShare}
            endCall={handleEndCall}
            optionsToHide={
              isCall
                ? ["toggleSettings", "toggleMinimize", "toggleScreenShare"]
                : ["toggleSettings", "toggleMinimize"]
            }
            isMinimized
            toggleMinimize={toggleMinimized}
            disableMediaControl={callStage === "onhold"}
          />
        )}
      </div>
    </div>
  );
};
