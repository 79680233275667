import { usePaginationUpdate } from "@jugl-web/utils";
import useLiveConversationReceipts from "@web-src/features/chats/hooks/useLiveConversationReceipts";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  EntityModel,
  EntityParticipantRole,
  useRestApiProvider,
} from "@jugl-web/rest-api";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { setEntity } from "../appSlice";

export const useSelectEntity = () => {
  const { navigateToPage } = useNavigation();
  const dispatch = useDispatch();
  const { entitiesApi } = useRestApiProvider();

  const { reset: resetLiveConversationReceipts } =
    useLiveConversationReceipts();

  const { resetAll: resetAllPagination } = usePaginationUpdate();

  const [markEntityAsRead] = entitiesApi.useMarkEntityAsReadMutation();

  return useCallback(
    (entity: EntityModel) => {
      // Resets all state related to the previous entity (if any)
      resetLiveConversationReceipts?.();
      resetAllPagination();

      // Marks entity as read if it has any unread updates
      if (entity.unread) {
        markEntityAsRead({ entityId: entity.id });
      }

      // Sets the new entity in the store
      dispatch(setEntity(entity));

      // Navigates to the chats tab of the new entity
      navigateToPage(
        entity.role === EntityParticipantRole.employee
          ? "tasksTasks"
          : "chatsChats",
        undefined,
        { entityId: entity.id }
      );
    },
    [
      dispatch,
      markEntityAsRead,
      navigateToPage,
      resetAllPagination,
      resetLiveConversationReceipts,
    ]
  );
};
