import {
  DatePicker,
  DatePickerProps,
} from "@jugl-web/ui-components/cross-platform/DatePicker";
import { Popover } from "@jugl-web/ui-components/cross-platform/Popover";
import { MobileDrawer } from "@jugl-web/ui-components/mobile";
import { cx, useAppVariant, useTranslations } from "@jugl-web/utils";
import { useFormattedDate } from "@jugl-web/utils/hooks/useFormattedDate";
import { FC, useState } from "react";
import { getDueDateInDays } from "../../utils";
import {
  TaskChecklistItemButton,
  TaskChecklistItemButtonProps,
} from "./TaskChecklistItemButton";
import { ReactComponent as CalendarBlankIcon } from "./icons/calendar-blank.svg";
import { TaskChecklistItemMode } from "./types";

export interface DueDatePickerButtonProps {
  itemMode: TaskChecklistItemMode;
  dueDate: Date | null;
  displayAs: "date" | "days";
  isDisabled?: boolean;
  onDueDateChange: (dueDate: Date | null) => void;
}

export const DueDatePickerButton: FC<DueDatePickerButtonProps> = ({
  itemMode,
  dueDate,
  isDisabled,
  displayAs,
  onDueDateChange,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { isMobile } = useAppVariant();

  const { t } = useTranslations();
  const { formatDateLabel } = useFormattedDate();

  const commonButtonProps: TaskChecklistItemButtonProps = {
    itemMode,
    isDisabled,
    children: (
      <div className="flex max-w-[150px] items-center  gap-1.5 truncate">
        <CalendarBlankIcon
          className={cx("shrink-0", dueDate ? "text-primary" : "text-grey")}
        />
        {dueDate ? (
          <span className="truncate">
            {displayAs === "date"
              ? formatDateLabel(dueDate, isMobile ? "concise" : "regular")
              : t(
                  {
                    id: "tasks-page.due-date-in-days",
                    defaultMessage:
                      "{days} {days, plural, one {day} other {days}}",
                  },
                  {
                    days: getDueDateInDays(dueDate),
                  }
                )}
          </span>
        ) : (
          <span className="@lg:block hidden truncate">
            {t({
              id: "tasks-page.set-due-date",
              defaultMessage: "Set due date",
            })}
          </span>
        )}
      </div>
    ),
  };

  const commonDatePickerProps: DatePickerProps = {
    initialDate: dueDate || undefined,
    isDateRemovable: true,
    removeDateLabel: t({
      id: "tasks-page.remove-due-date",
      defaultMessage: "Remove due date",
    }),
    dateTransformation: "endOfDay",
    onSubmit: onDueDateChange,
  };

  if (isMobile) {
    return (
      <>
        <TaskChecklistItemButton
          onClick={(event) => {
            event.stopPropagation();
            setIsDialogOpen(true);
          }}
          {...commonButtonProps}
        />
        <MobileDrawer
          isOpen={isDialogOpen}
          header={{
            title: t({
              id: "tasks-page.select-due-date",
              defaultMessage: "Select due date",
            }),
          }}
          animateHeight
          onClose={() => setIsDialogOpen(false)}
        >
          <MobileDrawer.Content>
            <DatePicker
              onClose={() => setIsDialogOpen(false)}
              {...commonDatePickerProps}
            />
          </MobileDrawer.Content>
        </MobileDrawer>
      </>
    );
  }

  return (
    <Popover
      className="w-[375px]"
      placement="bottom-start"
      renderTrigger={({ Trigger, triggerRef }) => (
        <Trigger
          ref={triggerRef}
          as={TaskChecklistItemButton}
          {...commonButtonProps}
        />
      )}
    >
      {({ onClose }) => (
        <DatePicker
          className="p-4"
          onClose={onClose}
          {...commonDatePickerProps}
        />
      )}
    </Popover>
  );
};
