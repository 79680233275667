import { HookOutOfContextError } from "@jugl-web/utils";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { GlobalUserPreferences } from "@jugl-web/rest-api/users/models/common-types/GlobalUserPreferences";
import { useEntityProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useLocation } from "react-router-dom";
import { entityPagesNavigationConfig } from "@web-src/modules/entities/pages/navigation-config";
import {
  GetStartedDialog,
  GetStartedDialogHandle,
} from "../components/GetStartedDialog";
import { OnboardingBeginningDialog } from "../components/OnboardingBeginningDialog/OnboardingBeginningDialog";
import { GetStartedDialogStep } from "../types";
import { useOnboardingSteps } from "../hooks/useOnboardingSteps";

interface OnboardingProviderProps {
  children: React.ReactNode;
}

type OnboardingStatusSteps = Omit<
  GlobalUserPreferences,
  "welcome" | "onBoardingFinish"
>;

const ONBOARDING_THRESHOLD = "2024-09-04";

const OnboardingContext = createContext<{
  onboardingStatus?: OnboardingStatusSteps;
  isOnboardingFinished: boolean;
  isOnboardingActive: boolean;
  openedSpotlight?: GetStartedDialogStep;
  openSpotlight: (type?: GetStartedDialogStep) => void;
  openDialog: () => void;
  completeOnboardingStep: (type: GetStartedDialogStep) => void;
} | null>(null);

export const OnboardingProvider = ({ children }: OnboardingProviderProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isOnboardingBeginningDialogOpen, setIsOnboardingBeginningDialogOpen] =
    useState(false);
  const location = useLocation();
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const dialogRef = useRef<GetStartedDialogHandle>(null);
  const { entity, subscriptionInfo, isVerifyBillingAlertVisible } =
    useEntityProvider();
  const hasActiveSubscription =
    !!subscriptionInfo && subscriptionInfo.planIsActive;
  const [openedSpotlight, setOpenedSpotlight] =
    useState<GetStartedDialogStep>();
  const { steps } = useOnboardingSteps();

  const { usersApi } = useRestApiProvider();
  const [updateGlobalPreferences] =
    usersApi.useUpdateGlobalPreferencesMutation();
  const { data, error, refetch } = usersApi.useGetGlobalPreferencesQuery(
    undefined,
    { skip: !entity?.id }
  );

  const { data: userProfile } = usersApi.useGetUserProfileQuery(undefined, {
    skip: !entity?.id,
  });

  const isOnboardingAvailable = !!(
    userProfile &&
    userProfile.general.inserted_at &&
    userProfile.general.inserted_at.split("T")[0] >= ONBOARDING_THRESHOLD
  );

  const isDuringEmailVerification = location.pathname.includes(
    entityPagesNavigationConfig.entityVerifyEmail.path
  );

  useEffect(() => {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    if ((error as any)?.status === 404) {
      updateGlobalPreferences({
        data: {
          onboarding_steps: {},
        },
      }).then(() => {
        refetch();
      });
    }
  }, [error, updateGlobalPreferences, refetch]);

  useEffect(() => {
    // Opens a welcome modal if the user is in an entity with a subscription,
    // has not started or completed the onboarding process,
    // and email verification is not performed.
    if (
      data &&
      entity?.id &&
      hasActiveSubscription &&
      !data.welcome &&
      !data.onBoardingFinish &&
      !isDuringEmailVerification &&
      !isVerifyBillingAlertVisible &&
      isOnboardingAvailable
    ) {
      setIsOnboardingBeginningDialogOpen(true);
    }
  }, [
    data,
    entity,
    hasActiveSubscription,
    isVerifyBillingAlertVisible,
    isDuringEmailVerification,
    isOnboardingAvailable,
  ]);

  const isOnboardingActive = useMemo(() => {
    if (!isOnboardingAvailable) {
      return false;
    }
    if (data?.welcome && !data.onBoardingFinish) {
      return true;
    }
    return false;
  }, [data?.onBoardingFinish, data?.welcome, isOnboardingAvailable]);

  const isOnboardingFinished = useMemo(
    () => !isOnboardingAvailable || !!data?.onBoardingFinish,
    [data, isOnboardingAvailable]
  );

  const openSpotlight = useCallback((type?: GetStartedDialogStep) => {
    setOpenedSpotlight(type);
  }, []);

  const getNextIncompleteStepIndex = useCallback(
    (key?: GetStartedDialogStep) => {
      let startIndex = 0;

      if (key) {
        startIndex = steps.findIndex((step) => step.id === key);

        if (startIndex === -1) {
          startIndex = 0;
        } else {
          startIndex = (startIndex + 1) % steps.length;
        }
      }

      const forwardIndex = steps
        .slice(startIndex)
        .findIndex((step) => !step.isCompleted);

      if (forwardIndex !== -1) {
        return startIndex + forwardIndex;
      }

      const backwardIndex = steps
        .slice(0, startIndex - 1 < 0 ? steps.length : startIndex - 1)
        .findIndex((step) => !step.isCompleted);

      return backwardIndex !== -1 ? backwardIndex : steps.length - 1;
    },
    [steps]
  );

  const completeOnboardingStep = useCallback(
    async (key: GetStartedDialogStep) => {
      if (!data || data.onBoardingFinish || data[key]) return;
      const status: Partial<GlobalUserPreferences> = {};
      status[key] = true;
      const response = await updateGlobalPreferences({
        data: {
          onboarding_steps: {
            ...data,
            ...status,
          },
        },
      });
      if (response && "data" in response) {
        setActiveStepIndex(getNextIncompleteStepIndex(key));
        dialogRef.current?.markStepAsCompleted();
        setIsDialogOpen(true);
      }
    },
    [data, updateGlobalPreferences, getNextIncompleteStepIndex]
  );

  const setStepIndex = useCallback((stepIndex: number) => {
    setActiveStepIndex(stepIndex);
  }, []);

  const openDialog = useCallback(() => {
    setActiveStepIndex(getNextIncompleteStepIndex());
    setIsDialogOpen(true);
  }, [getNextIncompleteStepIndex]);

  const closeOnboardingBeginningDialog = useCallback(async () => {
    setIsOnboardingBeginningDialogOpen(false);
    const response = await updateGlobalPreferences({
      data: {
        onboarding_steps: {
          ...data,
          welcome: true,
        },
      },
    });
    if (response && "data" in response) {
      openDialog();
    }
  }, [updateGlobalPreferences, data, openDialog]);

  const finishOnboarding = useCallback(() => {
    updateGlobalPreferences({
      data: {
        onboarding_steps: {
          ...data,
          onBoardingFinish: true,
        },
      },
    });
  }, [updateGlobalPreferences, data]);

  const contextValue = useMemo(
    () => ({
      onboardingStatus: data,
      isOnboardingActive,
      isOnboardingFinished,
      openedSpotlight: isDialogOpen ? undefined : openedSpotlight,
      openSpotlight,
      openDialog,
      completeOnboardingStep,
    }),
    [
      data,
      isOnboardingFinished,
      isOnboardingActive,
      completeOnboardingStep,
      openDialog,
      openedSpotlight,
      openSpotlight,
      isDialogOpen,
    ]
  );

  return (
    <OnboardingContext.Provider value={contextValue}>
      <OnboardingBeginningDialog
        isOpen={isOnboardingBeginningDialogOpen}
        onRequestClose={closeOnboardingBeginningDialog}
      />
      <GetStartedDialog
        isOpen={isDialogOpen}
        ref={dialogRef}
        isOnboardingFinished={isOnboardingFinished}
        activeStepIndex={activeStepIndex}
        openSpotlight={openSpotlight}
        onFinishOnboarding={finishOnboarding}
        setStepIndex={setStepIndex}
        onRequestClose={() => setIsDialogOpen(false)}
      />
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboarding = () => {
  const context = useContext(OnboardingContext);

  if (!context) {
    throw new HookOutOfContextError("useOnboarding", "OnboardingContext");
  }

  return context;
};
