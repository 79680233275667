import { MobileDrawer } from "@jugl-web/ui-components/mobile/MobileDrawer";
import { useTranslations } from "@jugl-web/utils";
import { FC, useRef } from "react";
import { ManageTaskLabelForm } from "./ManageTaskLabelForm";
import { ManageTaskLabelDialogProps } from "./types";

export const ManageTaskLabelDialog: FC<ManageTaskLabelDialogProps> = ({
  isOpen,
  entityId,
  editingLabel,
  onClose,
}) => {
  const isEditing = !!editingLabel;
  const { t } = useTranslations();
  const nameInputRef = useRef<HTMLInputElement | null>(null);

  return (
    <MobileDrawer
      isOpen={isOpen}
      header={{
        title: isEditing
          ? t({
              id: "tasks-page.edit-label",
              defaultMessage: "Edit label",
            })
          : t({
              id: "tasks-page.add-label",
              defaultMessage: "Add label",
            }),
      }}
      initialFocus={nameInputRef}
      onClose={onClose}
    >
      <MobileDrawer.Content className="px-0">
        <ManageTaskLabelForm
          entityId={entityId}
          editingLabel={editingLabel}
          nameInputRef={nameInputRef}
          onClose={onClose}
        />
      </MobileDrawer.Content>
    </MobileDrawer>
  );
};
