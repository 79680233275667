import { cx } from "@jugl-web/utils";
import React, { PropsWithChildren } from "react";

export const FormGroup: React.FC<
  PropsWithChildren<{
    label: string;
    htmlFor: string;
    className?: string;
    isRequired?: boolean;
  }>
> = ({ label, htmlFor, isRequired, className, children }) => (
  <>
    <label
      className={cx(
        "text-dark-600 font-secondary leading-2 text-xs",
        className
      )}
      htmlFor={htmlFor}
    >
      {label}
      {isRequired && <span className="text-gradients-danger"> *</span>}
    </label>
    {children}
  </>
);
