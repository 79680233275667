import React from "react";
import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import { ReactComponent as PlayIcon } from "./assets/play.svg";
import { ReactComponent as RocketIcon } from "./assets/rocket.svg";
import { useOnboarding } from "../../providers";
import { useOnboardingSteps } from "../../hooks/useOnboardingSteps";

export const OnboardingProgressBar: React.FC = () => {
  const { openDialog } = useOnboarding();
  const { completedSteps, steps } = useOnboardingSteps();
  const { t } = useTranslations();

  return (
    <InteractiveContainer
      className="flex h-[76px] w-full items-center justify-center gap-4 bg-white drop-shadow-[0_4px_16px_rgba(0,0,0,0.16)]"
      onClick={() => openDialog()}
    >
      <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-[#F8E7FF]">
        <RocketIcon />
      </div>
      <span className="font-semibold text-[#383838]">
        {t({
          id: "onboarding-progress-bar-component.get-started",
          defaultMessage: "Get Started 💫",
        })}
      </span>
      <span className="text-sm text-[#828282]">
        {t(
          {
            id: "onboarding-progress-bar-component.onboarding-completed-steps",
            defaultMessage: "{completedSteps}/{stepsCount} Steps completed",
          },
          {
            completedSteps,
            stepsCount: steps.length,
          }
        )}
      </span>
      <PlayIcon />
    </InteractiveContainer>
  );
};
