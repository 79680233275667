import { TaskRecurrence } from "@jugl-web/rest-api/tasks";
import { Alert } from "@jugl-web/ui-components/cross-platform";
import {
  assert,
  getImagePathForSharedLibrary,
  useTranslations,
} from "@jugl-web/utils";
import { FC } from "react";
import overdueImage from "../../assets/overdue.gif";

interface RecurrencePausedWarningDialogProps {
  isOpen: boolean;
  recurrence: TaskRecurrence | null;
  onRecurrenceChange: (recurrence: TaskRecurrence | null) => void;
  onClose: () => void;
}

export const RecurrencePausedWarningDialog: FC<
  RecurrencePausedWarningDialogProps
> = ({ isOpen, recurrence, onRecurrenceChange, onClose }) => {
  const { t } = useTranslations();

  return (
    <Alert
      isOpen={isOpen}
      header={t({
        id: "tasks-page.recurring-task",
        defaultMessage: "Recurring task",
      })}
      img={
        <img
          src={getImagePathForSharedLibrary(overdueImage)}
          alt=""
          className="h-[180xp] w-[180px]"
        />
      }
      title={t({
        id: "tasks-page.recurring-task-issue",
        defaultMessage: "Recurring task issue",
      })}
      content={t(
        {
          id: "tasks-page.recurring-task-paused-warning",
          defaultMessage:
            "This recurring task has been overdue for {count} times in a row. Do you want to keep the recurring settings on?",
        },
        { count: 5 }
      )}
      isCloseButtonVisible
      buttonsAlignment="vertical"
      buttons={[
        {
          variant: "contained",
          color: "primary",
          text: t({
            id: "tasks-page.keep-recurring-settings-button-label",
            defaultMessage: "Yes, keep it",
          }),
          onClick: (_, actions) => {
            assert(!!recurrence, "State must be defined at this point");
            onRecurrenceChange({ ...recurrence, enabled: true });
            actions.closeAlert();
          },
        },
        {
          variant: "text",
          color: "primary",
          text: t({
            id: "tasks-page.remove-recurring-settings-button-label",
            defaultMessage: "Remove recurring settings",
          }),
          onClick: (_, actions) => {
            onRecurrenceChange(null);
            actions.closeAlert();
          },
        },
      ]}
      onRequestClose={onClose}
    />
  );
};
