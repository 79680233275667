/* eslint-disable import/no-import-module-exports */
import isEqual from "lodash/isEqual";
import { useCallback, useEffect, useRef, useState } from "react";
import { WaveSurferParams } from "wavesurfer.js/types/params";
import { secondsToTime } from "../date-time/secondsToTime";
import { useMemoCompare } from "./useMemoCompare";

interface WaveSurferModule {
  module: typeof import("wavesurfer.js");
}

export const useWaveSurfer = (url: string, params: WaveSurferParams) => {
  const [waveSurfer, setWaveSurfer] = useState<WaveSurfer>();
  const [duration, setDuration] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [playing, setPlaying] = useState<boolean>(false);
  const waveSurferRef = useRef<WaveSurfer | null>(null);
  const memorizedParams = useMemoCompare(params, isEqual);
  const memorizedUrl = useMemoCompare(url, isEqual);

  const [waveSurferModule, setWaveSurferModule] = useState<WaveSurferModule>();

  useEffect(() => {
    import("wavesurfer.js").then((module) => {
      setWaveSurferModule({ module: module.default });
    });
  }, []);

  useEffect(() => {
    if (!waveSurferModule) {
      return undefined;
    }

    if (waveSurferRef.current) {
      waveSurferRef.current?.destroy();
    }

    const waveSurferObj = waveSurferModule.module.create(memorizedParams);
    waveSurferRef.current = waveSurferObj;
    waveSurferObj.load(memorizedUrl);
    setWaveSurfer(waveSurferObj);

    const readyCallback = () => {
      setDuration(waveSurferObj.getDuration());
    };
    waveSurferObj.on("ready", readyCallback);

    const interactionCallback = () => {
      setTimeout(() => {
        setCurrentTime(waveSurferObj.getCurrentTime());
      }, 100);
    };
    waveSurferObj.on("interaction", interactionCallback);

    const playCallback = () => {
      setPlaying(true);
    };
    waveSurferObj.on("play", playCallback);

    const pauseCallback = () => {
      setPlaying(false);
    };
    waveSurferObj.on("pause", pauseCallback);

    const audioProcessCallback = () => {
      setCurrentTime(waveSurferObj.getCurrentTime());
    };
    waveSurferObj.on("audioprocess", audioProcessCallback);

    const finishCallback = () => {
      waveSurferObj.setCurrentTime(0);
      setCurrentTime(0);
    };
    waveSurferObj.on("finish", finishCallback);

    setPlaying(false);

    return () => waveSurferObj.destroy();
  }, [waveSurferModule, memorizedParams, memorizedUrl]);

  const play = useCallback(() => {
    waveSurfer?.play();
  }, [waveSurfer]);

  const pause = useCallback(() => {
    waveSurfer?.pause();
  }, [waveSurfer]);

  return {
    waveSurfer,
    duration,
    durationStr: secondsToTime(duration),
    currentTime,
    currentTimeStr: secondsToTime(currentTime),
    playing,
    play,
    pause,
  };
};
