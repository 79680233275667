import { useRestApiProvider } from "@jugl-web/rest-api";
import { TaskActivity, UpdatedActionChanges } from "@jugl-web/rest-api/tasks";
import { User } from "@jugl-web/rest-api/users";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import format from "date-fns/format";
import { useCallback, useEffect, useState } from "react";
import { TransformedActivity } from "../../utils";
import { useTaskActivityParser } from "../useTaskActivityParser";
import { useTaskFields } from "../useTaskFields";
import { PaginatedParsedActivity, ValidTaskActivity } from "./types";
import {
  adaptSelfProducedTaskActivityToTaskActivity,
  isSelfProducedTaskActivity,
  isValidActivity,
} from "./utils";

interface UseTaskActivitiesOptions {
  meUser?: User;
  entityId?: string;
  taskId?: string;
  initialFilters?: string[];
}

const PAGE_SIZE = 20;

export const useTaskActivities = ({
  meUser,
  entityId,
  taskId,
  initialFilters = [],
}: UseTaskActivitiesOptions) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [activitiesFilters, setActivitiesFilters] =
    useState<string[]>(initialFilters);
  const [combinedActivities, setCombinedActivities] = useState<
    PaginatedParsedActivity[]
  >([]);
  const { parseTaskActivity } = useTaskActivityParser({ entityId, meUser });
  const { dateLocale } = useLanguage();

  const { tasksApi } = useRestApiProvider();

  const hasRequiredParams = entityId && taskId;
  const { getCustomFieldById } = useTaskFields({ entityId });

  const {
    data: taskActivitiesResponse,
    isFetching,
    isLoading,
  } = tasksApi.useGetTaskActivitiesQuery(
    hasRequiredParams
      ? {
          entityId,
          params: {
            type: activitiesFilters.length
              ? activitiesFilters.join(",")
              : "non_guest",
            task_id: taskId,
            page_size: PAGE_SIZE,
            page: currentPage,
          },
        }
      : skipToken,
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [activitiesFilters]);

  const transformCustomFieldActivity = useCallback(
    (activity: TaskActivity) => {
      const transformedActivity: TransformedActivity = {};
      if (
        activity.action_details.action === "updated" &&
        activity.action_details.changes.custom_fields &&
        activity.action_details.changes.old_custom_fields
      ) {
        const changes = activity.action_details.changes as UpdatedActionChanges;
        const [changedField] =
          (changes.custom_fields &&
            Object.entries(changes.custom_fields).filter(
              ([customKey, customValue]) =>
                changes?.old_custom_fields?.[customKey] !== customValue
            )) ||
          [];
        const removedKeys =
          (!changedField &&
            changes?.old_custom_fields &&
            changes?.custom_fields &&
            Object.keys(changes.old_custom_fields).filter(
              (key) => !(key in (changes.custom_fields || {}))
            )) ||
          [];
        const fieldInfo = getCustomFieldById(
          changedField?.[0] || removedKeys[0]
        );
        const removedValue = changes.old_custom_fields?.[removedKeys[0]];
        let value = changedField?.[1] || removedValue || "";
        if (fieldInfo?.type === "date" && value) {
          value = format(new Date(value), "MMM dd yyyy", {
            locale: dateLocale,
          });
        }
        if (fieldInfo?.type === "dropdown") {
          value =
            fieldInfo?.values?.find((option) => option.id === changedField[1])
              ?.value || "";
        }
        transformedActivity.custom_fields = {
          name: fieldInfo?.name || "",
          value,
          isRemoved: removedKeys.length > 0,
        };
      }
      return transformedActivity;
    },
    [getCustomFieldById, dateLocale]
  );

  const loadMore = useCallback(() => {
    if (
      taskActivitiesResponse &&
      currentPage < taskActivitiesResponse.total_pages &&
      !isFetching
    ) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }, [currentPage, isFetching, taskActivitiesResponse]);

  /**
   * Accepts task activity from the dedicated endpoint or self-produced
   * activity that is received after updating a task (including empty object
   * which might be received as a result of an update request without any changes)
   */
  const addActivity = useCallback(
    (activity: ValidTaskActivity | Record<string, unknown>) => {
      if (!isValidActivity(activity)) {
        return;
      }

      if (isSelfProducedTaskActivity(activity)) {
        if (!taskId || !meUser) {
          return;
        }

        activity = adaptSelfProducedTaskActivityToTaskActivity({
          selfProducedActivity: activity,
          taskId,
          meUser,
        });
      }

      const activityType = activity.action_details.action;

      const matchActivityTypeWithItsFilterType: Record<
        TaskActivity["action_details"]["action"],
        "updates" | "actions" | "comments" | "guest"
      > = {
        created: "updates",
        deleted: "updates",
        viewed: "actions",
        commented: "comments",
        downloaded: "actions",
        updated: "updates",
        guest_comment: "guest",
        guest_downloaded: "guest",
        guest_comment_reply: "guest",
        guest_feedback: "guest",
        guest_opened: "guest",
      };

      if (
        activitiesFilters.length &&
        !activitiesFilters.includes(
          matchActivityTypeWithItsFilterType[activityType]
        )
      ) {
        return;
      }

      const transformedActivity = transformCustomFieldActivity(activity);
      const parsedActivity = parseTaskActivity(activity, transformedActivity);

      if (parsedActivity) {
        setCombinedActivities((prevActivities) => [
          ...prevActivities,
          { id: parsedActivity.id, data: parsedActivity },
        ]);
      }
    },
    [
      activitiesFilters,
      meUser,
      parseTaskActivity,
      taskId,
      transformCustomFieldActivity,
    ]
  );

  const modifyActivity = useCallback(
    (activity: TaskActivity, actionType: "delete" | "update") => {
      if (actionType === "delete") {
        setCombinedActivities((prevActivities) =>
          prevActivities.filter((item) => item.data.raw.id !== activity.id)
        );
      }
      if (actionType === "update") {
        const parsedActivity = parseTaskActivity(activity);
        if (parsedActivity) {
          setCombinedActivities((prevActivities) => {
            prevActivities.forEach((item) => {
              if (item.data.raw.id === activity.id) {
                item.data = parsedActivity;
              }
            });
            return [...prevActivities];
          });
        }
      }
    },
    [parseTaskActivity]
  );

  useEffect(() => {
    if (!taskActivitiesResponse) {
      return;
    }

    const transformedActivities = taskActivitiesResponse.data.reduce<
      PaginatedParsedActivity[]
    >((acc, activity) => {
      const transformedActivity = transformCustomFieldActivity(activity);
      const parsedActivity = parseTaskActivity(activity, transformedActivity);
      const isViewByMe =
        activity.action_details.action === "viewed" &&
        activity.action_by.user_id === meUser?.id;
      if (parsedActivity && !isViewByMe) {
        return [...acc, { id: parsedActivity.id, data: parsedActivity }];
      }

      return acc;
    }, []);

    const shouldResetInternalState = taskActivitiesResponse.page_number === 1;

    if (shouldResetInternalState) {
      setCombinedActivities(transformedActivities.reverse());
      setCurrentPage(1);
      return;
    }

    setCombinedActivities((prevActivities) => [
      ...transformedActivities.reverse(),
      ...prevActivities,
    ]);
  }, [
    getCustomFieldById,
    meUser,
    parseTaskActivity,
    taskActivitiesResponse,
    taskId,
    transformCustomFieldActivity,
  ]);

  return {
    activities: combinedActivities,
    totalCount: taskActivitiesResponse?.total_entries || 0,
    isLoading: isFetching,
    activitiesFilters,
    isInitialLoading: currentPage === 1 && (isLoading || isFetching),
    setActivitiesFilters,
    loadMore,
    addActivity,
    modifyActivity,
  };
};
