import { Button, InteractiveContainer } from "@jugl-web/ui-components";
import { cx, useTranslations } from "@jugl-web/utils";
import React, { useMemo } from "react";
import { SpotlightTooltip } from "@jugl-web/ui-components/cross-platform/SpotlightTooltip";
import { useSpotlight } from "@jugl-web/utils/hooks/useSpotlight";
import { Subject } from "rxjs";
import { CREATE_ORDER_FORM_SPOTLIGHT_KEY } from "@jugl-web/utils/storage";
import { ReactComponent as CheckIcon } from "./assets/check.svg";

export enum OrderFormStep {
  form = "form",
  taskDetails = "taskDetails",
}

export const OrderSteps: React.FC<{
  orderFormStep: OrderFormStep;
  setOrderFormStep: (orderFormStep: OrderFormStep) => void;
  completeStep$: Subject<void>;
  isEditing?: boolean;
}> = ({ orderFormStep, completeStep$, isEditing, setOrderFormStep }) => {
  const { t } = useTranslations();

  const steps = useMemo(
    () => [
      {
        id: OrderFormStep.form,
        title: t({
          id: "order-form-create-page.create-form",
          defaultMessage: "Create Form",
        }),
        desc: t({
          id: "order-form-create-page.create-custom-form-for-client-message",
          defaultMessage:
            "Create custom form for your Clients. Select already existing inputs or create new ones to add it to your Form ✨",
        }),
        buttonText: t({
          id: "common.done",
          defaultMessage: "Done",
        }),
        onClick: () => setOrderFormStep(OrderFormStep.form),
      },
      {
        id: OrderFormStep.taskDetails,
        title: t({
          id: "order-form-create-page.set-task-details",
          defaultMessage: "Set Task Details",
        }),
        desc: t({
          id: "order-form-create-page.set-custom-form-details-message",
          defaultMessage:
            "When Client will Submit the Form, New Task will be created. Here You can set Task details manually or select Task Template ✨",
        }),
        buttonText: isEditing
          ? t({
              id: "common.save-changes",
              defaultMessage: "Save changes",
            })
          : t({
              id: "order-form-create-page.create-form",
              defaultMessage: "Create Form",
            }),
      },
    ],
    [t, isEditing, setOrderFormStep]
  );

  const { isActive: isSpotlightActive, markAsSeen: markSpotlightAsSeen } =
    useSpotlight({
      id: CREATE_ORDER_FORM_SPOTLIGHT_KEY,
      delay: 200,
    });

  return (
    <div className="flex shrink-0 flex-col gap-6">
      {steps.map((step, idx) => {
        const isCurrentStep = step.id === orderFormStep;
        const isFirstStepFinished =
          idx === 0 && orderFormStep === OrderFormStep.taskDetails;

        return (
          <InteractiveContainer
            className="w-[340px] rounded-2xl bg-white p-4"
            key={step.id}
            onClick={step.onClick}
            isDisabled={!step.onClick || step.id === orderFormStep}
          >
            <div className="flex items-center gap-2">
              <div
                className={cx(
                  "bg-grey-400 font-secondary flex h-6 w-6 items-center justify-center rounded-full text-xs font-semibold text-white transition-colors",
                  isCurrentStep && "bg-primary text-white",
                  isFirstStepFinished && "bg-secondary-400"
                )}
              >
                {isFirstStepFinished ? <CheckIcon /> : idx + 1}
              </div>
              <span
                className={cx(
                  "font-secondary font-medium leading-[140%]",
                  isCurrentStep ? "text-dark" : "text-[#828282]"
                )}
              >
                {step.title}
              </span>
            </div>
            <SpotlightTooltip
              isOpen={isSpotlightActive && step.id === OrderFormStep.form}
              placement="bottom"
              onDismiss={markSpotlightAsSeen}
              tip={t({
                id: "order-form-create-page.two-steps-to-create-form-hint",
                defaultMessage:
                  "Only 2 steps to create a Form, lets start here! 😍",
              })}
              renderTrigger={({ ref, props }) => (
                <div
                  className={cx(
                    "font-secondary mt-2 text-xs leading-[140%]",
                    isCurrentStep ? "text-dark-700" : "text-dark-500"
                  )}
                  ref={ref}
                  {...props}
                >
                  {step.desc}
                </div>
              )}
            />
            {isCurrentStep && (
              <Button
                size="small"
                className="mt-3"
                onClick={(e) => {
                  e.stopPropagation();
                  if (isSpotlightActive) {
                    markSpotlightAsSeen();
                  }
                  completeStep$.next();
                }}
              >
                {step.buttonText}
              </Button>
            )}
          </InteractiveContainer>
        );
      })}
    </div>
  );
};
