import React, { HTMLProps, useMemo, useState, Ref } from "react";
import classNames from "classnames";

import { ReactComponent as EyeIcon } from "./icons/eye.svg";
import { ReactComponent as EyeOffIcon } from "./icons/eye-off.svg";

const PASS_KEY = "password";
const TEXT_KEY = "text";

enum UnderlineInputElement {
  input = "input",
  textarea = "textarea",
}

interface UnderlineInputProps
  extends HTMLProps<HTMLInputElement | HTMLTextAreaElement> {
  label: string;
  error?: string;
  required?: boolean;
  className?: string;
  multiline?: boolean;
  rows?: number;
}

export const UnderlineInput = React.forwardRef<
  Ref<HTMLInputElement | HTMLTextAreaElement>,
  UnderlineInputProps
>(
  (
    {
      label,
      error,
      required,
      multiline,
      className,
      rows = 3,
      type,
      ...inputProps
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const handlePassword = () => setShowPassword(!showPassword);

    const filled = useMemo(() => !!inputProps?.value, [inputProps]);
    const componentClasses = classNames(
      "placeholder:text-grey-1000 disabled:bg-grey-200 disabled:text-grey-1000 w-full border-0 border-b-2 bg-inherit p-[10px] text-base font-medium placeholder:font-normal placeholder:capitalize focus:shadow-none focus:outline-0 focus:ring-0",
      error
        ? "focus:border-gradients-danger border-gradients-danger"
        : "focus:border-primary-500  border-gray-400"
    );

    const Component = React.createElement(
      multiline ? UnderlineInputElement.textarea : UnderlineInputElement.input,
      multiline
        ? {
            ...inputProps,
            rows,
            ref: ref as Ref<HTMLTextAreaElement>,
            className: componentClasses,
          }
        : {
            ...inputProps,
            ref: ref as Ref<HTMLInputElement>,
            type: showPassword ? TEXT_KEY : type,
            className: componentClasses,
          }
    );

    return (
      <div className={classNames("w-full", className ?? "")}>
        <div className="flex">
          <h5 className="m-0 overflow-hidden text-ellipsis whitespace-nowrap py-[2px] pl-2 text-xs font-normal uppercase">
            {label}
          </h5>
          {required && (
            <h5 className="text-gradients-danger m-0 ml-1 text-xs">*</h5>
          )}
        </div>
        <div className="fill-grey-400 focus-within:fill-grey-1000 relative block">
          {Component}
          {type === PASS_KEY && (
            <button
              type="button"
              className="absolute right-1 top-[10px] border-none bg-inherit"
              onClick={handlePassword}
            >
              {showPassword ? (
                <EyeOffIcon
                  className={classNames(
                    filled ? "fill-grey-1000" : "fill-grey-400"
                  )}
                />
              ) : (
                <EyeIcon
                  className={classNames(
                    filled ? "fill-grey-1000" : "fill-grey-400"
                  )}
                />
              )}
            </button>
          )}
        </div>
        {!!error && (
          <h6 className="text-gradients-danger mt-[2px] overflow-hidden text-ellipsis whitespace-nowrap text-sm font-normal">
            {error}
          </h6>
        )}
      </div>
    );
  }
);

export default UnderlineInput;
