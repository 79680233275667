import { HTMLAttributes, PropsWithChildren, forwardRef, useMemo } from "react";
import { cx } from "@jugl-web/utils";
import { LoadingAnimation, LoadingAnimationProps } from "../LoadingAnimation";
import { InteractiveContainer } from "../../InteractiveContainer";
import { EmptyListContent, EmptyListContentProps } from "../EmptyListContent";

export type DataLoadingWrapperProps = HTMLAttributes<HTMLDivElement> &
  PropsWithChildren<{
    isLoading?: boolean;
    onRetry?: () => void;
    isError?: boolean;
    isFetching?: boolean;
    errorMessage?: string;
    animationSize?: LoadingAnimationProps["size"];
    customErrorContentProps?: EmptyListContentProps;
  }>;

export const DataLoadingWrapper = forwardRef<
  HTMLDivElement,
  DataLoadingWrapperProps
>(
  (
    {
      children,
      isLoading,
      isError,
      isFetching,
      errorMessage,
      onRetry,
      className,
      animationSize,
      customErrorContentProps,
      ...nativeProps
    },
    ref
  ) => {
    const defaultErrorMessage = "Can't load data";
    const $componentContent = useMemo(() => {
      if (isLoading) {
        return <LoadingAnimation size={animationSize || "lg"} />;
      }
      if (isError) {
        if (customErrorContentProps) {
          return (
            <div>
              <EmptyListContent
                {...{ onRefetch: onRetry, ...customErrorContentProps }}
              />
            </div>
          );
        }
        return (
          <div className="text-grey-800 text-center text-sm">
            {errorMessage || defaultErrorMessage}
            <br />
            {!!onRetry && (
              <InteractiveContainer
                as="span"
                className="text-primary-500 font-medium"
                onClick={onRetry}
              >
                Click to retry
              </InteractiveContainer>
            )}
          </div>
        );
      }
      return null;
    }, [
      animationSize,
      customErrorContentProps,
      errorMessage,
      isError,
      isLoading,
      onRetry,
    ]);

    if (!$componentContent) {
      return <>{children}</>;
    }
    return (
      <div
        ref={ref}
        {...{
          ...nativeProps,
          className: cx(
            {
              "flex justify-center items-center px-4 py-8 w-full h-full":
                !!$componentContent,
            },
            {
              relative: isFetching && !isLoading,
            },
            className
          ),
        }}
      >
        {$componentContent || children}
        {isFetching && !isLoading ? (
          <div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center bg-white bg-opacity-50 backdrop-blur-[0.5px]">
            <LoadingAnimation size={animationSize || "lg"} />
          </div>
        ) : null}
      </div>
    );
  }
);
