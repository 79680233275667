import {
  TaskPriorityDetails,
  useTaskPriorities,
} from "@jugl-web/domain-resources/tasks/hooks/useTaskPriorities";
import { TaskPriority } from "@jugl-web/rest-api/tasks";
import { ListBoxItem } from "@jugl-web/ui-components/cross-platform/ListBox";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { FC, useMemo } from "react";
import { TaskTableCellComponentProps } from "../../types";
import { TaskTableLayout } from "../TaskTableLayout";

export const PriorityCell: FC<TaskTableCellComponentProps> = ({
  task,
  isFutureTask,
  permissions,
  cellProps,
  onUpdate,
}) => {
  const { allPriorities, getPriorityDetailsById } = useTaskPriorities();

  const prioritiesAsListItems = useMemo<ListBoxItem<TaskPriorityDetails>[]>(
    () => allPriorities.map((p) => ({ id: p.id, value: p })),
    [allPriorities]
  );

  const priorityDetails = getPriorityDetailsById(task.priority);
  const hasPriority = task.priority !== TaskPriority.none;
  const isEditable = permissions.canEditPriority && !isFutureTask;
  const isValueHidden = isFutureTask;

  return (
    <ResourcePickerPopover
      placement="bottom"
      className="w-[375px]"
      renderTrigger={({ Trigger, triggerRef, isOpen }) => (
        <Trigger
          ref={triggerRef}
          as={TaskTableLayout.Cell}
          isFocused={isOpen}
          isHoverable
          isDisabled={!isEditable}
          {...cellProps}
        >
          {!isValueHidden && (
            <>
              {hasPriority ? (
                <div className="flex items-center gap-2">
                  <priorityDetails.Icon className="shrink-0" />
                  <TaskTableLayout.CellText>
                    {priorityDetails.shortLabel}
                  </TaskTableLayout.CellText>
                </div>
              ) : (
                <TaskTableLayout.AppearingAddValueCellText
                  isStatic={isOpen}
                  isHidden={!isEditable}
                />
              )}
            </>
          )}
        </Trigger>
      )}
      items={prioritiesAsListItems}
      selectionBehavior={{ mode: "single", canToggle: false }}
      defaultSelectedIds={[task.priority]}
      maxVisibleItems={5}
      itemSize="md"
      spaceBetweenItems="compact"
      renderLabel={(item) => item.value.shortLabel}
      renderStartIcon={(item) => <item.value.Icon />}
      onSelect={({ item, onClose }) => {
        onUpdate({ priority: item.value.id });
        onClose();
      }}
    />
  );
};
