import { useContext } from "react";
import useSendMessage from "@web-src/features/chats/hooks/useSendMessage";
import {
  ChatCallType,
  ChatMessagePayloadCallAction,
  ChatMessagePayloadPushType,
  ChatMessagePayloadType,
  ChatMessageType,
  ScenarioCallType,
} from "@web-src/features/chats/types";

import { getEntityUserDisplayName } from "@web-src/features/entities/utils";
import { v4 as uuid4 } from "uuid";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { useToast, useTranslations } from "@jugl-web/utils";
import { usePermission } from "react-use";
import { CallsContext } from "../providers";

export const useCallInvitation = () => {
  const sendMessage = useSendMessage();
  const { me } = useMe();
  const { activeCallProps, joinCall, setCallStage } = useContext(CallsContext);
  const { toast } = useToast({ variant: "web" });
  const { t } = useTranslations();
  const microphonePermission = usePermission({ name: "microphone" });
  const cameraPermission = usePermission({ name: "camera" });
  const call = async ({
    callType,
    userId,
    channelId = uuid4(),
    shouldJoinCall = true,
  }: {
    callType: keyof typeof ChatCallType;
    userId: string;
    channelId?: string;
    shouldJoinCall?: boolean;
  }) => {
    if (
      callType === "video" &&
      (cameraPermission === "denied" || microphonePermission === "denied")
    ) {
      toast(
        t({
          id: "call-conference.cant-start-video-call-without-permissions",
          defaultMessage:
            "Can't place a video call without video and microphone permissions",
        }),
        { variant: "error" }
      );
      return;
    }
    if (callType !== "video" && microphonePermission === "denied") {
      toast(
        t({
          id: "call-conference.cant-start-audio-call-without-microphone-permission",
          defaultMessage:
            "Can't place an audio call without microphone permission.",
        }),
        { variant: "error" }
      );
      return;
    }

    if (activeCallProps?.channel) {
      toast(
        t({
          id: "audio-call-component.cant-place-a-call-when-on-call",
          defaultMessage: "Can't place a call, when you are already in a call",
        }),
        { variant: "error" }
      );
      return;
    }
    const callText = {
      audio: "Outgoing Voice Call..",
      video: "Outgoing Video Call..",
    };
    sendMessage({
      to: userId,
      extraPayload: {
        body: callText[callType],
        call_type: callText[callType],
        call_action: ChatMessagePayloadCallAction.call_invite,
        call_channel: channelId,
        push_type: ChatMessagePayloadPushType.silent,
        sender_name: (me && getEntityUserDisplayName(me)) || undefined,
        title: "New Message",
        type: ChatMessagePayloadType.call,
      },
      type: ChatMessageType.call,
    });
    if (shouldJoinCall) {
      joinCall?.({
        channel: channelId,
        callType: callType as ChatCallType,
        type: ScenarioCallType.call,
        to: userId,
      });
      setCallStage?.("ringing");
    }
  };

  return {
    call,
  };
};
