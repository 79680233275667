import { PreviewTask, previewTasksAdapter } from "@jugl-web/rest-api/tasks";
import { useMemo } from "react";
import { TaskSorting } from "../../components/TaskSortingProvider";
import { INITIAL_FILTERS_STATE } from "../useTaskFiltersState";
import { useTaskSelectors } from "../useTaskSelectors";
import { useTasksQuery } from "../useTasksQuery";
import { UseTasksOptions } from "./types";
import {
  createAssigneesFilterMatcher,
  createCustomersFilterMatcher,
  createCustomFieldsFilterMatcher,
  createIsOverdueFilterMatcher,
  createLabelsFilterMatcher,
  createPrioritiesFilterMatcher,
  createSearchQueryFilterMatcher,
  createStatusesFilterMatcher,
  sortTasks,
  withFutureTasks,
} from "./utils";

export const useTasks = ({
  entityId,
  mode,
  searchQuery,
  filters = INITIAL_FILTERS_STATE,
  showFuture = false,
  forceShowCompleted = false,
  sorting = TaskSorting.lastUpdated,
}: UseTasksOptions) => {
  const { data } = useTasksQuery({ entityId, mode });

  const {
    createSelectTasksByDueDateInterval,
    createSelectOverdueTasks,
    createSelectNoDueDateTasks,
    createSelectTasksByLabelId,
    createSelectTasksByStatus,
    createSelectTasksByReporteeId,
    createSelectTasksByAssigneeId,
    createSelectTasksByCustomerId,
    createSelectTasksByPriority,
    createSelectTasksByCustomDropdownFieldValue,
  } = useTaskSelectors({ entityId });

  const allTasks = useMemo(() => {
    const regularTasks = previewTasksAdapter
      .getSelectors()
      .selectAll(data ?? previewTasksAdapter.getInitialState());

    if (showFuture) {
      return withFutureTasks(regularTasks);
    }

    return regularTasks;
  }, [data, showFuture]);

  const allSortedTasks = useMemo(
    () => sortTasks(allTasks, sorting),
    [allTasks, sorting]
  );

  const filteredTasks = useMemo(() => {
    const filterMatchers = [
      createSearchQueryFilterMatcher(searchQuery),
      createAssigneesFilterMatcher(filters.assignees),
      createCustomersFilterMatcher(filters.customers),
      createCustomFieldsFilterMatcher(filters.customFields),
      createIsOverdueFilterMatcher(filters.isOverdue),
      createLabelsFilterMatcher(filters.labels),
      createPrioritiesFilterMatcher(filters.priorities),
      createStatusesFilterMatcher(filters.statuses, forceShowCompleted),
    ];

    return allSortedTasks.reduce<PreviewTask[]>((acc, task) => {
      const matchesFilters = filterMatchers.every((fn) => fn(task));

      if (!matchesFilters) {
        return acc;
      }

      return [...acc, task];
    }, []);
  }, [allSortedTasks, filters, searchQuery, forceShowCompleted]);

  const selectTasksByDueDateInterval = useMemo(
    () => createSelectTasksByDueDateInterval(filteredTasks),
    [createSelectTasksByDueDateInterval, filteredTasks]
  );

  const selectOverdueTasks = useMemo(
    () => createSelectOverdueTasks(filteredTasks),
    [createSelectOverdueTasks, filteredTasks]
  );

  const selectNoDueDateTasks = useMemo(
    () => createSelectNoDueDateTasks(filteredTasks),
    [createSelectNoDueDateTasks, filteredTasks]
  );

  const selectTasksByLabelId = useMemo(
    () => createSelectTasksByLabelId(filteredTasks),
    [createSelectTasksByLabelId, filteredTasks]
  );

  const selectTasksByStatus = useMemo(
    () => createSelectTasksByStatus(filteredTasks),
    [createSelectTasksByStatus, filteredTasks]
  );

  const selectTasksByReporteeId = useMemo(
    () => createSelectTasksByReporteeId(filteredTasks),
    [createSelectTasksByReporteeId, filteredTasks]
  );

  const selectTasksByAssigneeId = useMemo(
    () => createSelectTasksByAssigneeId(filteredTasks),
    [createSelectTasksByAssigneeId, filteredTasks]
  );

  const selectTasksByCustomerId = useMemo(
    () => createSelectTasksByCustomerId(filteredTasks),
    [createSelectTasksByCustomerId, filteredTasks]
  );

  const selectTasksByPriority = useMemo(
    () => createSelectTasksByPriority(filteredTasks),
    [createSelectTasksByPriority, filteredTasks]
  );

  const selectTasksByCustomDropdownFieldValue = useMemo(
    () => createSelectTasksByCustomDropdownFieldValue(filteredTasks),
    [createSelectTasksByCustomDropdownFieldValue, filteredTasks]
  );

  return {
    tasks: filteredTasks,
    selectTasksByDueDateInterval,
    selectOverdueTasks,
    selectNoDueDateTasks,
    selectTasksByLabelId,
    selectTasksByStatus,
    selectTasksByPriority,
    selectTasksByReporteeId,
    selectTasksByAssigneeId,
    selectTasksByCustomerId,
    selectTasksByCustomDropdownFieldValue,
  };
};
