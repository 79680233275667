import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  HookOutOfContextError,
  useToast,
  useTranslations,
} from "@jugl-web/utils";
import {
  PhoneInputValue,
  PageLoaderFull,
} from "@jugl-web/ui-components/cross-platform";
import { useRequestOtpMutation } from "@web-src/features/auth/authApi";
import ReCAPTCHA from "react-google-recaptcha";
import { Portal } from "@headlessui/react";
import { environment } from "@web-src/environments/environment";
import { AuthStep, LoginMethod, PhoneMethod } from "../../types";

type LoginPageContextValue = {
  authStep: AuthStep;
  loginMethod: LoginMethod;
  phoneMethod: PhoneMethod;
  email: string;
  phone: PhoneInputValue;
  id: string;
  setAuthStep: (authStep: AuthStep) => void;
  setLoginMethod: (loginMethod: LoginMethod) => void;
  setPhoneMethod: (phoneMethod: PhoneMethod) => void;
  setEmail: (email: string) => void;
  setPhone: (phone: PhoneInputValue) => void;
  setId: (id: string) => void;
  requestOtpVerification: () => Promise<void>;
};

const LoginPageContext = createContext<LoginPageContextValue | null>(null);

export const LoginPageProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const recaptchaRef = React.useRef<ReCAPTCHA | null>(null);
  const [authStep, setAuthStep] = useState<AuthStep>(AuthStep.login);
  const [loginMethod, setLoginMethod] = useState<LoginMethod>(
    LoginMethod.phone
  );
  const [phoneMethod, setPhoneMethod] = useState<PhoneMethod>(PhoneMethod.sms);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState<PhoneInputValue>({
    code: "",
    iso: "",
    phone: "",
    isValid: false,
  });

  const [id, setId] = useState("");

  const { toast } = useToast({ variant: "web" });
  const { t } = useTranslations();

  const [requestOtp, { isLoading: otpIsLoading }] = useRequestOtpMutation();
  const requestOtpVerification = useCallback(async () => {
    const response = await requestOtp({
      country: phone.iso,
      country_isd_code: phone.code,
      mobile: loginMethod === LoginMethod.phone ? phone.phone : undefined,
      email: loginMethod === LoginMethod.email ? email : undefined,
      otp_via: loginMethod === LoginMethod.phone ? phoneMethod : undefined,
      grc_token:
        environment.isReCaptchaEnabled &&
        // NOTE: don't check captcha for test phone numbers
        !(phone.phone && /^000\d{4}000$/.test(phone.phone))
          ? await (async () => {
              recaptchaRef.current?.reset();
              const captchaValue = await recaptchaRef.current?.executeAsync();
              return captchaValue;
            })()
          : "",
    });
    if ("error" in response && response.error) {
      toast(
        t({
          id: "feedback.something-went-wrong",
          defaultMessage: "Something went wrong",
        }),
        {
          variant: "error",
        }
      );
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw response.error;
    }
    if ("data" in response && response.data) {
      setId(response.data.id);
    }
  }, [
    email,
    loginMethod,
    phone.code,
    phone.iso,
    phone.phone,
    phoneMethod,
    requestOtp,
    t,
    toast,
  ]);

  const value: LoginPageContextValue = useMemo(
    () => ({
      authStep,
      loginMethod,
      phoneMethod,
      email,
      phone,
      id,
      setAuthStep,
      setLoginMethod,
      setPhoneMethod,
      setEmail,
      setPhone,
      setId,
      requestOtpVerification,
    }),
    [
      authStep,
      loginMethod,
      phoneMethod,
      email,
      phone,
      id,
      setAuthStep,
      setLoginMethod,
      setPhoneMethod,
      setEmail,
      setPhone,
      setId,
      requestOtpVerification,
    ]
  );

  return (
    <LoginPageContext.Provider value={value}>
      <PageLoaderFull isActive={otpIsLoading} isTransparent />
      {environment.isReCaptchaEnabled && (
        <Portal>
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={environment.reCaptchaKey}
          />
        </Portal>
      )}
      {children}
    </LoginPageContext.Provider>
  );
};

export const useLoginPage = () => {
  const context = useContext(LoginPageContext);

  if (!context) {
    throw new HookOutOfContextError("useLoginPage", "LoginPageContext");
  }

  return context;
};
