import { useRestApiProvider } from "@jugl-web/rest-api";
import { TaskListMode } from "../types";

interface UseTasksQueryOptions {
  entityId: string;
  mode: TaskListMode;
}

export const useTasksQuery = ({ entityId, mode }: UseTasksQueryOptions) => {
  const { tasksApi } = useRestApiProvider();

  const tasksQuery = tasksApi.useGetTasksQuery(
    { entityId },
    { skip: mode !== "personal" }
  );

  const teamTasksQuery = tasksApi.useGetTeamTasksQuery(
    { entityId },
    { skip: mode !== "team", refetchOnMountOrArgChange: true }
  );

  const { data, isLoading, isError, refetch } =
    mode === "personal" ? tasksQuery : teamTasksQuery;

  return {
    data,
    isLoading,
    isError,
    refetch,
  };
};
