import React, { useMemo, useRef, useState } from "react";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Portal,
} from "@headlessui/react";
import { Virtuoso } from "react-virtuoso";
import classNames from "classnames";
import { cx, useTranslations } from "@jugl-web/utils";
import { useFloating, flip, shift, autoUpdate } from "@floating-ui/react";
import parsePhoneNumber, { CountryCode } from "libphonenumber-js";
import { Country } from "./types";
import { ReactComponent as ArrowIcon } from "./arrow-bottom.svg";
import { ReactComponent as SearchIcon } from "./search.svg";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const countries = require("countries-phone-masks");

export type PhoneInputValue = {
  phone: string;
  code: string;
  iso: string;
  isValid?: boolean;
};

export const validatePhoneNumber = (value: string) => {
  const [iso, code, number] = value.split(",");
  const country = countries.find((item: Country) => item.iso === iso);
  const mask = Array.isArray(country?.mask) ? country?.mask[0] : country?.mask;
  const parsedCountryMask = mask.replace(/[^#0-9]/g, "");
  const validNumberLength = `${country?.code}${parsedCountryMask}`;
  const enteredPhoneNumber = `${code}${number?.replace(/[^#0-9]/g, "")}`;
  return validNumberLength.length === enteredPhoneNumber.length;
};

export const PhoneInput: React.FC<{
  value?: { phone: string; code: string; iso: string };
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  listButtonClassName?: string;
  onChange: (value: PhoneInputValue) => void;
  onBlur?: () => void;
  onFocus?: () => void;
}> = ({
  value = { phone: "", code: "+91", iso: "IN" },
  className,
  inputClassName,
  listButtonClassName,
  onChange,
  onBlur,
  onFocus,
  placeholder,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [search, setSearch] = useState<string>("");
  const { t } = useTranslations();

  const { refs, floatingStyles } = useFloating({
    middleware: [flip({ crossAxis: true }), shift({ crossAxis: true })],
    whileElementsMounted: autoUpdate,
    placement: "right",
  });

  const handleCountrySelect = (e: string) => {
    const [iso, code] = e.split("-");
    const phoneNumber = parsePhoneNumber(`${value.phone}`, iso as CountryCode);
    onChange({
      ...value,
      isValid:
        /000\d\d\d\d000/g.test(value.phone) || phoneNumber?.isValid() || false,
      code,
      iso,
    });
  };
  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const phone = e.target.value.includes("_")
      ? e.target.value
      : e.target.value.replace(/\D+/g, "");
    const phoneNumber = parsePhoneNumber(
      `${value.code}${phone}`,
      value.iso as CountryCode
    );
    onChange({
      ...value,
      isValid: /000\d\d\d\d000/g.test(phone) || phoneNumber?.isValid() || false,
      phone,
    });
  };
  const preparedCountries: Country[] = useMemo(() => {
    const newArray = [...countries];
    const indexes: number[] = [];

    newArray.forEach((item, index) => {
      if (item.iso === "IN" || item.iso === "US" || item.iso === "SV") {
        indexes.push(index);
      }
    });

    indexes.forEach((index) => {
      const item = newArray.splice(index, 1)[0];
      newArray.unshift(item);
    });

    return newArray;
  }, []);
  const searchedCountries = useMemo(
    () =>
      preparedCountries.filter(({ name, code, iso }) =>
        `${name} ${iso} ${code}`
          .toLocaleLowerCase()
          .includes(search.toLowerCase())
      ),
    [preparedCountries, search]
  );
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  const handleSelect = () => {
    setTimeout(() => {
      if (inputRef?.current) {
        inputRef.current.focus();
      }
    }, 0);
  };

  return (
    <div className="relative flex items-center gap-2">
      <Listbox
        value={`${value.iso}-${value.code}`}
        onChange={handleCountrySelect}
      >
        <ListboxButton
          className={cx(
            "flex cursor-pointer items-center border-0 bg-transparent py-4 pl-4 text-base font-medium",
            listButtonClassName
          )}
          onClick={handleSelect}
          ref={refs.setReference}
        >
          {value.code} <ArrowIcon className={classNames("ml-3")} />
        </ListboxButton>
        {/* TODO: check position and height of list  */}
        <Portal>
          <ListboxOptions
            className={cx(
              "z-[9999] flex h-[450px] w-[375px] flex-col overflow-x-auto rounded-2xl bg-white px-4 pt-6 shadow-[0px_4px_16px_rgba(0,0,0,0.16)] focus:outline-none",
              className
            )}
            ref={refs.setFloating}
            style={{ ...floatingStyles }}
          >
            <div className="flex items-center justify-between">
              <span className="font-semibold text-[#383838]">
                {t({
                  id: "phone-input-component.select-country",
                  defaultMessage: "Select Country",
                })}
              </span>
            </div>
            <div className="bg-dark-100 my-4 h-px w-full" />
            <div className="bg-grey-100 flex items-center rounded-lg py-2.5 px-4">
              <SearchIcon />
              <input
                onKeyDown={(e) => e.stopPropagation()}
                value={search}
                onChange={handleSearchChange}
                className="placeholder:text-grey ml-2 w-full border-none bg-transparent text-sm focus:shadow-none focus:outline-none focus:ring-0"
                placeholder={t({
                  id: "common.search-with-ellipsis",
                  defaultMessage: "Search...",
                })}
                ref={inputRef}
              />
            </div>
            <Virtuoso
              style={{
                flexGrow: "1",
                width: "100%",
              }}
              data={searchedCountries}
              // eslint-disable-next-line react/no-unstable-nested-components
              itemContent={(index, { name, code, iso, flag }: Country) => (
                <>
                  <ListboxOption
                    value={`${iso}-${code}`}
                    className={({ active }) =>
                      cx(
                        `relative mb-2 cursor-pointer select-none rounded-lg ${
                          active && "bg-primary-50"
                        }`,
                        {
                          "bg-primary-50": active,
                          "mt-4": index === 0,
                        }
                      )
                    }
                  >
                    <div className="text-dark flex items-center justify-between px-4 py-3">
                      <div className="flex items-center">
                        <img
                          src={flag}
                          alt={name}
                          className="mr-[10px] w-[28px]"
                        />
                        <span>
                          {name} ({iso})
                        </span>
                      </div>
                      <span>{code}</span>
                    </div>
                  </ListboxOption>
                  {index === 2 && !search && (
                    <div className="bg-dark-100 my-4 h-px w-full" />
                  )}
                </>
              )}
            />
          </ListboxOptions>
        </Portal>
      </Listbox>
      <div className="bg-grey-300 h-[30px] w-[1px]" />
      <input
        value={value.phone}
        onChange={handlePhoneChange}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        className={cx(
          "flex-1 rounded-[10px] border-none py-3 text-base font-medium focus:outline-none",
          inputClassName
        )}
      />
    </div>
  );
};

export default PhoneInput;
