import { OrderListItemDto } from "./OrderListItemDto";
import { OrderListItemModel } from "./OrderListItemModel";

export const transformOrderListItemDtoToModel = (
  dto: OrderListItemDto
): OrderListItemModel => ({
  ...dto,
  formName: dto.title,
  taskName: dto.name,
});
