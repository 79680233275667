import { v4 } from "uuid";
import mime from "mime";

export const FILE_NAME_PREFIX = "recording";
const FILE_MIME_TYPE = "audio/webm";

export const createRecordingFile = (fileBits: BlobPart[]) => {
  const hash = v4();
  const extension = mime.getExtension(FILE_MIME_TYPE);

  const fileName = `${FILE_NAME_PREFIX}-${hash}.${extension}`;

  return new File(fileBits, fileName, { type: FILE_MIME_TYPE });
};
