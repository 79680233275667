import { useRestApiProvider } from "@jugl-web/rest-api";
import { TaskListMode } from "../types";

interface UseTasksQueryOptions {
  entityId: string;
  mode: TaskListMode;
  customerId?: string;
}

export const useTasksQuery = ({
  entityId,
  mode,
  customerId,
}: UseTasksQueryOptions) => {
  const { tasksApi } = useRestApiProvider();

  const tasksQuery = tasksApi.useGetTasksQuery(
    { entityId },
    { skip: mode !== "personal" }
  );

  const teamTasksQuery = tasksApi.useGetTeamTasksQuery(
    { entityId },
    { skip: mode !== "team", refetchOnMountOrArgChange: true }
  );

  const customerTasksQuery = tasksApi.useGetCustomerTasksQuery(
    { entityId, customerId },
    { refetchOnMountOrArgChange: true, skip: !customerId }
  );

  const { data, isLoading, isError, refetch } = (() => {
    if (customerId) {
      return customerTasksQuery;
    }
    if (mode === "personal") {
      return tasksQuery;
    }
    return teamTasksQuery;
  })();

  return {
    data,
    isLoading,
    isError,
    refetch,
  };
};
