import { FormField } from "@jugl-web/rest-api";
import { FieldValues } from "react-hook-form";

export const processNewCustomerData = (
  formFieldsData: FormField[],
  data: FieldValues
) => {
  const dataKeys = Object.keys(data);
  return dataKeys
    .filter((key: string) => {
      const fieldData = formFieldsData.find(
        (formField) => formField.id === key
      );
      return fieldData;
    })
    .map((key: string) => {
      const fieldData = formFieldsData.find(
        (formField) => formField.id === key
      );
      if (fieldData?.type === "mobile" && data[key]) {
        const [, , number] = data[key].split(",");
        if (!number) {
          return { field_id: key, value: null };
        }
      }
      return {
        field_id: key,
        value: data[key]?.trim() || null,
      };
    });
};
