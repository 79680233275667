import { ScreenTransitionWrapper } from "@jugl-web/ui-components/cross-platform/ScreenTransitionWrapper";
import { BottomCenteredDrawer } from "@jugl-web/ui-components/web/BottomCenteredDrawer";
import { DRAWER_HEADER_HEIGHT_PX } from "@jugl-web/ui-components/web/DrawerHeader";
import { useTranslations } from "@jugl-web/utils";
import {
  Screen,
  ScreenTransitionManagerProvider,
  useScreenTransitionManager,
} from "@jugl-web/utils/utils/ScreenTransitionManager";
import React, { useEffect, useMemo } from "react";
import { NewCustomDropdownFieldValuesScreen } from "@jugl-web/domain-resources/tasks/components/NewCustomDropdownFieldValuesScreen";
import { NewCustomFieldScreen } from "@jugl-web/domain-resources/tasks/components/NewCustomFieldScreen";
import { TaskCustomFieldType } from "@jugl-web/rest-api/tasks";

export type CustomTaskFieldsDialogScreenToParametersMap = {
  newCustomField: {
    initialFieldName?: string;
    initialFieldType?: TaskCustomFieldType;
    initialIsShownInCard?: boolean;
  };
  newCustomDropdownFieldValues: {
    fieldName: string;
    isShownInCard: boolean;
  };
};

export const InnerCustomTaskFieldsDialog: React.FC<
  CustomTaskFieldsDialogProps
> = ({ entityId, isOpen, onClose, initialScreen }) => {
  const { screen, transitionTo, renderContent, transitionRequest$ } =
    useScreenTransitionManager<CustomTaskFieldsDialogScreenToParametersMap>();

  const { t } = useTranslations();

  const content = useMemo<JSX.Element>(
    () =>
      renderContent({
        newCustomField: (parameters) => (
          <NewCustomFieldScreen
            entityId={entityId}
            onAfterCreateTaskField={onClose}
            onCancel={onClose}
            {...parameters}
          />
        ),
        newCustomDropdownFieldValues: (parameters) => (
          <NewCustomDropdownFieldValuesScreen
            entityId={entityId}
            onAfterCreateTaskField={onClose}
            {...parameters}
          />
        ),
      }),
    [entityId, renderContent, onClose]
  );

  const handleSafeClose = () => {
    if (transitionRequest$.observed) {
      transitionRequest$.next({ resolve: onClose });
    } else {
      onClose();
    }
  };

  const handleDrawerTransitionEnd = () => {
    if (!isOpen) {
      transitionTo(initialScreen, { force: true });
    }
  };

  useEffect(() => {
    transitionTo(initialScreen, { force: true });
  }, [initialScreen, transitionTo]);

  return (
    <BottomCenteredDrawer
      isOpen={isOpen}
      header={{
        breadcrumbs: [
          {
            id: "newCustomField",
            title: t({
              id: "tasks-page.new-custom-field",
              defaultMessage: "New custom field",
            }),
          },
        ],
        type: "breadcrumbs",
      }}
      onClose={handleSafeClose}
      onTransitionEnd={handleDrawerTransitionEnd}
    >
      <ScreenTransitionWrapper
        screenName={screen.name}
        style={{ height: `calc(100% - ${DRAWER_HEADER_HEIGHT_PX}px)` }}
      >
        {content}
      </ScreenTransitionWrapper>
    </BottomCenteredDrawer>
  );
};

interface CustomTaskFieldsDialogProps {
  isOpen: boolean;
  entityId: string;
  initialScreen: Screen<CustomTaskFieldsDialogScreenToParametersMap>;
  onClose: () => void;
}

export const CustomTaskFieldsDialog: React.FC<CustomTaskFieldsDialogProps> = (
  props
) => {
  const { initialScreen } = props;

  return (
    <ScreenTransitionManagerProvider initialScreen={initialScreen}>
      <InnerCustomTaskFieldsDialog {...props} />
    </ScreenTransitionManagerProvider>
  );
};
