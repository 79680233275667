import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import { useSearchInput } from "@jugl-web/utils/hooks";
import { FC } from "react";

export interface TopBarSearchInputProps {
  initialSearchQuery: string;
  onSearch: (searchQuery: string) => void;
  onClose: () => void;
}

export const TopBarSearchInput: FC<TopBarSearchInputProps> = ({
  initialSearchQuery,
  onSearch,
  onClose,
}) => {
  const { inputProps, reset } = useSearchInput({
    defaultValue: initialSearchQuery,
    onSearch,
  });

  const handleClear = () => {
    if (inputProps.value) {
      reset();
    } else {
      onClose();
    }
  };

  return (
    <SearchInput
      variant="filled"
      color="grey"
      autoFocus
      alwaysShowClear
      containerClassName="w-full"
      onClear={handleClear}
      {...inputProps}
    />
  );
};
