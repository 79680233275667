import { useFormattedCustomFieldValue } from "@jugl-web/domain-resources/tasks/hooks/useFormattedCustomFieldValue";
import { InternalTaskCustomField } from "@jugl-web/rest-api/tasks";
import { FC } from "react";
import { useTaskCustomFieldsDetails } from "@jugl-web/domain-resources/tasks/hooks/useTaskCustomFieldsDetails/useTaskCustomFieldsDetails";
import { TaskTableLayout } from "../TaskTableLayout";

interface CustomFieldCellContentProps {
  field: InternalTaskCustomField;
  rawValue: string | undefined;
  isEditable: boolean;
  isCellFocused: boolean;
  onClearValue: () => void;
}

export const CustomFieldCellContent: FC<CustomFieldCellContentProps> = ({
  field,
  rawValue,
  isEditable,
  isCellFocused,
  onClearValue,
}) => {
  const { customFieldTypeToDetails } = useTaskCustomFieldsDetails();
  const { formatCustomFieldValue } = useFormattedCustomFieldValue();

  const hasValue = !!rawValue;
  const Icon = customFieldTypeToDetails[field.type].primaryIcon;

  return (
    <>
      {hasValue ? (
        <div className="flex w-full items-center justify-between gap-2">
          <div className="flex items-center gap-2 overflow-hidden">
            <Icon className="text-grey-900 h-4 w-4 shrink-0" />
            <TaskTableLayout.CellText>
              {formatCustomFieldValue(field, rawValue)}
            </TaskTableLayout.CellText>
          </div>
          <TaskTableLayout.ClearCellValueButton
            isAppearing={!isCellFocused}
            isHidden={!isEditable}
            onClick={(event) => {
              event.stopPropagation();
              onClearValue();
            }}
          />
        </div>
      ) : (
        <TaskTableLayout.AppearingAddValueCellText
          isStatic={isCellFocused}
          isHidden={!isEditable}
          messageType={
            field.type === "text" || field.type === "number"
              ? "tap-to-enter"
              : "tap-to-add"
          }
        />
      )}
    </>
  );
};
