import { BaseTask, DetailedTask } from "@jugl-web/rest-api/tasks";
import { useMemo } from "react";

type RequiredTaskFields = Pick<
  BaseTask,
  "assignees" | "created_by" | "can_assignee_edit"
>;

type OptionalTaskFields = Partial<Pick<DetailedTask, "checklist">>;

export interface TaskPermissionsOptions {
  task: RequiredTaskFields & OptionalTaskFields;
  meId: string;
}

export const getTaskPermissions = (options: TaskPermissionsOptions) => {
  const { task, meId } = options;

  const isTaskCreator = task.created_by === meId;

  const isTaskAssignee = task.assignees.some(
    (assigneeId) => assigneeId === meId
  );

  // If the checklist property was not supplied, we assume the user is not a checklist assignee
  const isChecklistAssignee = task.checklist
    ? task.checklist.some((item) => item.user_id === meId)
    : false;

  const isTaskEditableByAssignee = task.can_assignee_edit;

  return {
    canEditAssignees:
      isTaskCreator || (isTaskAssignee && isTaskEditableByAssignee),

    canEditTitle: isTaskCreator || (isTaskAssignee && isTaskEditableByAssignee),

    canEditDescription:
      isTaskCreator || (isTaskAssignee && isTaskEditableByAssignee),

    canEditDueDate:
      isTaskCreator || (isTaskAssignee && isTaskEditableByAssignee),

    canEditPriority: isTaskCreator || isTaskAssignee,

    canEditLabel: isTaskCreator || isTaskAssignee,

    canEditStatus: isTaskCreator || isTaskAssignee,

    canEditCustomFields: isTaskCreator || isTaskAssignee,

    canManageChecklistItem: isTaskCreator || isTaskAssignee,

    canCompleteChecklistItem:
      isTaskCreator || isTaskAssignee || isChecklistAssignee,

    canEditCustomer: isTaskCreator || isTaskAssignee,

    canEditTimeSpentOnTask: isTaskCreator || isTaskAssignee,

    canManageComments: isTaskCreator || isTaskAssignee || isChecklistAssignee,

    canManageAttachments: isTaskCreator || isTaskAssignee,

    canEditRecurrence:
      isTaskCreator || (isTaskAssignee && isTaskEditableByAssignee),

    canManageAllowAssigneeToEditOption: isTaskCreator,

    canManageCompleteInSpecificOrderOption: isTaskCreator,

    canSendUpdateToCustomer: isTaskCreator || isTaskAssignee,

    canDeleteTask:
      isTaskCreator || (isTaskAssignee && isTaskEditableByAssignee),
    canArchiveTask:
      isTaskCreator || (isTaskAssignee && isTaskEditableByAssignee),
    canRestoreTask:
      isTaskCreator || (isTaskAssignee && isTaskEditableByAssignee),
  };
};

export type TaskPermissions = ReturnType<typeof getTaskPermissions>;

export const useTaskPermissions = (
  options: Partial<TaskPermissionsOptions>
): TaskPermissions => {
  const { task, meId } = options;

  return useMemo(() => {
    if (!task || !meId) {
      return {
        canEditAssignees: false,
        canEditTitle: false,
        canEditDescription: false,
        canEditDueDate: false,
        canEditPriority: false,
        canEditLabel: false,
        canEditStatus: false,
        canManageChecklistItem: false,
        canCompleteChecklistItem: false,
        canEditCustomer: false,
        canEditTimeSpentOnTask: false,
        canManageComments: false,
        canManageAttachments: false,
        canEditRecurrence: false,
        canManageAllowAssigneeToEditOption: false,
        canManageCompleteInSpecificOrderOption: false,
        canDeleteTask: false,
        canEditCustomFields: false,
        canSendUpdateToCustomer: false,
        canRestoreTask: false,
        canArchiveTask: false,
      };
    }

    return getTaskPermissions({ task, meId });
  }, [task, meId]);
};
