import { FC, useCallback, useState } from "react";
import {
  Button,
  DataLoadingWrapper,
  EmptyListContent,
  InteractiveContainer,
  Menu,
  PlainButton,
  TableGrid,
} from "@jugl-web/ui-components";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import Lottie from "react-lottie";
import { useToast, useTranslations } from "@jugl-web/utils";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { OrderListItemModel } from "@jugl-web/rest-api/orders/models/OrderListItem";
import { environment } from "@web-src/environments/environment";
import { OrdersTabsLayout } from "../components/OrdersTabsLayout";
import { ReactComponent as PlusIcon } from "./assets/plus.svg";
import noOrdersFoundAnimation from "./assets/no-orders-found-animation.json";
import { ReactComponent as MoreIcon } from "./assets/more.svg";
import { ReactComponent as CopyIcon } from "./assets/copy.svg";
import { ReactComponent as ShareIcon } from "./assets/share.svg";
import { ReactComponent as FillFormIcon } from "./assets/fill-form.svg";
// import { ReactComponent as EmbedIcon } from "./assets/embed.svg";
import { ReactComponent as EditIcon } from "./assets/edit.svg";
import { ReactComponent as DeleteIcon } from "./assets/delete.svg";
import { DeleteOrderFormAlert } from "./components/DeleteOrderFormAlert";

export const OrderFormsPage: FC = () => {
  const { t } = useTranslations();
  const { navigateToPage } = useNavigation();
  const { ordersApi } = useRestApiProvider();
  const { entity } = useEntitySelectedProvider();
  const { toast } = useToast({ variant: "web" });
  const { data, isLoading, refetch, isError, isFetching } =
    ordersApi.useGetOrdersQuery({
      entityId: entity.id,
    });
  const [deletedOrderForm, setDeletedOrderForm] =
    useState<OrderListItemModel>();

  const handleCopyToClipboard = useCallback(
    (content: string, type: "link" | "code") => {
      navigator.clipboard.writeText(content).then(() => {
        toast(
          type === "link"
            ? t({
                id: "feedback.link-copied-to-clipboard",
                defaultMessage: "Link was copied to clipboard",
              })
            : t({
                id: "feedback.embed-code-copied-to-clipboard",
                defaultMessage: "Embed code was copied to clipboard",
              })
        );
      });
    },
    [toast, t]
  );

  return (
    <>
      <OrdersTabsLayout
        selectedTab="order-forms"
        rightContent={
          <Button
            onClick={() => navigateToPage("orderFormCreate")}
            iconEnd={<PlusIcon />}
            className="h-10"
          >
            {t({
              id: "order-forms-page.create-form",
              defaultMessage: "Create Form",
            })}
          </Button>
        }
      >
        <DataLoadingWrapper
          onRetry={refetch}
          isError={isError}
          isLoading={isLoading}
          isFetching={isFetching}
        >
          {data?.data.length ? (
            <div className="px-8 py-10">
              <TableGrid
                unstyled
                data={data.data}
                className="rounded-xl border border-solid border-[#EEF2F5]"
                headerCellClassName="px-4 py-3 text-xs text-dark-700 font-normal leading-[140%] border-0 border-r border-solid border-[#EEF2F5]"
                cellClassName="px-4 text-dark-800 font-normal flex items-center text-sm py-3 border-0 border-t border-r border-solid border-[#EEF2F5] break-all leading-[140%] tracking-[0.14px] hover:bg-grey-200"
                rowHoverClassName="bg-grey-100"
                columns={[
                  {
                    title: t({
                      id: "order-forms-page.form-name",
                      defaultMessage: "Form name",
                    }),
                    grow: true,
                    className: "font-secondary font-medium text-base",
                    content: ({ formName }) => <>{formName}</>,
                  },
                  {
                    title: t({
                      id: "order-forms-page.task-name",
                      defaultMessage: "Task name",
                    }),
                    grow: true,
                    content: ({ taskName }) => <>{taskName}</>,
                  },
                  {
                    title: t({
                      id: "order-forms-page.form-id-order-quantity",
                      defaultMessage: "Form ID & Order quantity",
                    }),
                    grow: true,
                    content: ({ counter, prefix }) => (
                      <>{`${prefix} - ${counter}`}</>
                    ),
                  },
                  {
                    title: t({
                      id: "order-forms-page.share-link-directly",
                      defaultMessage: "Share link directly",
                    }),
                    content: (dataItem) => {
                      const link = `${environment.guestUrl}/order/${entity.id}/${dataItem.id}`;
                      return (
                        <InteractiveContainer
                          className="flex h-full w-full items-center gap-2 px-4 py-3"
                          onClick={() => handleCopyToClipboard(link, "link")}
                        >
                          <CopyIcon className="shrink-0" />
                          <span className="truncate whitespace-nowrap">
                            {link}
                          </span>
                        </InteractiveContainer>
                      );
                    },
                    className: "w-[220px] p-0",
                  },
                  // {
                  //   title: t({
                  //     id: "order-forms-page.share embed code",
                  //     defaultMessage: "Share embed code",
                  //   }),
                  //   content: (
                  //     <InteractiveContainer
                  //       className="flex h-full w-full items-center gap-2 px-4 py-3"
                  //       onClick={() =>
                  //         handleCopyToClipboard(
                  //           "<div class=”jugl-embed-container”><lirjvaoejdcoojdf9wuae pvaskZV",
                  //           "code"
                  //         )
                  //       }
                  //     >
                  //       <CopyIcon className="shrink-0" />
                  //       <span className="truncate whitespace-nowrap">
                  //         {
                  //           "<div class=”jugl-embed-container”><lirjvaoejdcoojdf9wuae pvaskZV"
                  //         }
                  //       </span>
                  //     </InteractiveContainer>
                  //   ),
                  //   className: "w-[220px] p-0",
                  // },
                  {
                    title: t({
                      id: "order-forms-page.settings",
                      defaultMessage: "Settings",
                    }),
                    className: "flex justify-center w-full border-r-0",
                    headerClassName: "border-r-0",
                    content: (orderForm) => (
                      <Menu
                        placement="bottom-end"
                        autoClose
                        renderTrigger={({ Trigger, triggerRef }) => (
                          <Trigger
                            as={PlainButton}
                            ref={triggerRef}
                            className="mt-1"
                          >
                            <MoreIcon />
                          </Trigger>
                        )}
                        className="py-2"
                        sections={[
                          [
                            {
                              id: "fill-form-manually",
                              label: t({
                                id: "order-forms-page.fill-form-manually",
                                defaultMessage: "Fill Form Manually",
                              }),
                              icon: <FillFormIcon />,
                              onSelect: () =>
                                window.open(
                                  `${environment.guestUrl}/order/${entity.id}/${orderForm.id}`,
                                  "_blank"
                                ),
                            },
                            {
                              id: "edit-form",
                              label: t({
                                id: "order-forms-page.edit-form",
                                defaultMessage: "Edit Form",
                              }),
                              icon: <EditIcon />,
                              onSelect: () =>
                                navigateToPage("orderFormEdit", {
                                  formId: orderForm.id,
                                }),
                            },
                            {
                              id: "share-link-directly",
                              label: t({
                                id: "order-forms-page.share-link-directly",
                                defaultMessage: "Share link directly",
                              }),
                              icon: <ShareIcon />,
                              onSelect: () => {
                                handleCopyToClipboard(
                                  `${environment.guestUrl}/order/${entity.id}/${orderForm.id}`,
                                  "link"
                                );
                              },
                            },
                            // {
                            //   id: "share-embed-code",
                            //   label: t({
                            //     id: "order-forms-page.share-embed-code",
                            //     defaultMessage: "Share embed code",
                            //   }),
                            //   icon: <EmbedIcon />,
                            //   onSelect: () => {
                            //     handleCopyToClipboard(
                            //       "<div class=”jugl-embed-container”><lirjvaoejdcoojdf9wuae pvaskZV",
                            //       "code"
                            //     );
                            //   },
                            // },
                            {
                              id: "delete-form",
                              label: t({
                                id: "order-forms-page.delete-form",
                                defaultMessage: "Delete Form",
                              }),
                              onSelect: () => setDeletedOrderForm(orderForm),
                              icon: <DeleteIcon />,
                            },
                          ],
                        ]}
                      />
                    ),
                  },
                ]}
              />
            </div>
          ) : (
            <EmptyListContent
              type="custom"
              className="w-[380px]"
              customImg={
                <Lottie
                  options={{ animationData: noOrdersFoundAnimation }}
                  width={262}
                  height={174}
                />
              }
              customTitle={t({
                id: "order-forms-page.take-orders-with-form-builder",
                defaultMessage: "Take orders easily with Form Builder ✨",
              })}
              customSubtitle={t({
                id: "order-forms-page.collect-work-requests",
                defaultMessage:
                  "Collect work requests from Customers using Jugl Order Form builder",
              })}
              customButton={{
                text: t({
                  id: "order-forms-page.create-form",
                  defaultMessage: "Create Form",
                }),
                iconEnd: <PlusIcon />,
                fullWidth: false,
                className: "h-10",
                onClick: () => navigateToPage("orderFormCreate"),
              }}
            />
          )}
        </DataLoadingWrapper>
      </OrdersTabsLayout>
      <DeleteOrderFormAlert
        isOpen={!!deletedOrderForm}
        orderForm={deletedOrderForm}
        onClose={() => setDeletedOrderForm(undefined)}
      />
    </>
  );
};
