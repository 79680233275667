import { DEFAULT_DEAL_MIRROR_PLAN_NAME } from "../../utils";
import {
  SubscriptionInfoPlanAddonItem,
  SubscriptionInfoSeatsAddonItem,
} from "../common-types/SubscriptionInfoAddonItem";
import { SubscriptionInfoDto } from "./SubscriptionInfoDto";
import { SubscriptionInfoModel } from "./SubscriptionInfoModel";

export const transformSubscriptionInfoDtoToModel = (
  dto: SubscriptionInfoDto
): SubscriptionInfoModel => {
  const planItem = dto.items.find((item) => item.metadata.type === "plan") as
    | SubscriptionInfoPlanAddonItem
    | undefined;
  const seatsAddonItem = dto.items.find(
    (item) => item.metadata.type === "addon_seats"
  ) as SubscriptionInfoSeatsAddonItem | undefined;
  const customerNotes = dto.customer?.invoice_settings?.custom_fields?.find(
    (field) => field.name.toLocaleLowerCase() === "notes"
  );
  return {
    entityId: dto.gist.entity_id,
    card: dto.cards[0],
    customerAddress: dto.customer?.address || null,
    customerName: dto.customer?.name || "",
    customerNotes: customerNotes?.value || "",
    isTrial: dto.status === "trialing",
    status: dto.status,
    validFrom: dto.valid_from,
    planTill: dto.gist.plan_till,
    graceTill: dto.gist.grace_till,
    isInGrace: dto.gist.is_in_grace,
    isInPlan: dto.gist.is_in_plan,
    isInTrial: dto.gist.plan === "trial",
    seatsLicensed: dto.license?.seats,
    seatsPlanDefault: (dto.license?.seats || 0) - (seatsAddonItem?.qty || 0),
    seatsUtilized: dto.license?.seats_utilized || 0,
    planIsActive: dto.gist.is_in_grace || dto.gist.is_in_plan,
    planId: dto.plan_id,
    availableModules: dto.license?.modules || null,
    planInfo: planItem
      ? {
          priceId: dto.plan_id,
          name:
            dto.license?.from === "deal_mirror"
              ? DEFAULT_DEAL_MIRROR_PLAN_NAME
              : planItem.nickname || "",
          type: planItem.metadata.plan_type,
          currency: planItem.currency,
          price: planItem.amount,
          interval: planItem.interval,
        }
      : undefined,
    additionalSeats: seatsAddonItem
      ? {
          price: seatsAddonItem.amount,
          priceId: seatsAddonItem.id,
          purchased: seatsAddonItem.qty,
          currency: seatsAddonItem.currency,
        }
      : undefined,
    balance: dto.customer?.balance || 0,
    actionsAllowed:
      dto.gist.is_in_plan ||
      (dto.gist.is_in_grace && dto.gist.plan !== "trial"),
    socketAllowed:
      dto.gist.is_in_plan ||
      (dto.gist.is_in_grace && dto.gist.plan !== "trial"),
    isDealMirror: dto.license?.from === "deal_mirror",
  };
};
