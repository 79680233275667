import { DescriptionBox } from "@jugl-web/domain-resources/tasks/components/DescriptionBox";
import { TaskExtraPropertiesPanel } from "@jugl-web/domain-resources/tasks/components/TaskExtraPropertiesPanel";
import { TaskPropertiesPanel } from "@jugl-web/domain-resources/tasks/components/TaskPropertiesPanel";
import { TaskPermissions } from "@jugl-web/domain-resources/tasks/hooks/useTaskPermissions";
import { DetailedTask, TaskDefaultStatus } from "@jugl-web/rest-api/tasks";
import { useTimeZone } from "@web-src/modules/settings/providers/TimeZoneProvider";
import { isoToLocalDate } from "@web-src/utils/helper";
import { FC, useState } from "react";
import { useTranslations } from "@jugl-web/utils";
import { UserRole } from "@jugl-web/rest-api";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { TASK_ORDER_EMAIL_ID } from "@jugl-web/utils/consts";
import { ReactComponent as AddCustomerIcon } from "./assets/add-customer.svg";

interface TaskDetailsPropertiesPanelProps {
  entityId: string;
  task: DetailedTask;
  permissions: TaskPermissions;
  updateCurrentTask: (updatedTask: Partial<DetailedTask>) => Promise<void>;
  onAttachmentsClick: () => void;
  onAddNewCustomer?: () => void;
}

export const TaskDetailsPropertiesPanel: FC<
  TaskDetailsPropertiesPanelProps
> = ({
  entityId,
  task,
  permissions,
  updateCurrentTask,
  onAttachmentsClick,
  onAddNewCustomer,
}) => {
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const { timeZone } = useTimeZone();
  const { me } = useMe();

  const { t } = useTranslations();
  return (
    <div className="mt-4 w-4/5 max-w-[700px]">
      {(task.desc || isEditingDescription) && (
        <DescriptionBox
          description={task.desc || ""}
          isEditing={isEditingDescription}
          classes={{ editingBox: "mb-8", descriptionText: "mb-8" }}
          onChange={(value) => updateCurrentTask({ desc: value })}
          onStartEditing={() => setIsEditingDescription(true)}
          onFinishEditing={() => {
            setIsEditingDescription(false);
          }}
        />
      )}
      <TaskPropertiesPanel
        entityId={entityId}
        config={{
          email: task.custom_fields?.[TASK_ORDER_EMAIL_ID],
          description: {
            isHidden:
              isEditingDescription ||
              !!task.desc ||
              !permissions.canEditDescription,
            onClick: () => setIsEditingDescription(true),
          },
          status: {
            statusId: task.status,
            isReadonly: !permissions.canEditStatus,
            onChange: (value) => updateCurrentTask({ status: value }),
          },
          dueDate: {
            state: task.due_at
              ? {
                  date: isoToLocalDate(task.due_at),
                  reminderExecutionTime: task.reminder
                    ? isoToLocalDate(task.reminder.next_execution)
                    : undefined,
                }
              : null,
            displayAs: "date",
            withReminder: task.status !== TaskDefaultStatus.completed,
            isReadonly: !permissions.canEditDueDate,
            onChange: (state) => {
              if (!state) {
                updateCurrentTask({ due_at: null, reminder: null });
                return;
              }

              updateCurrentTask({
                due_at: state.date.toISOString(),
                reminder: state.reminderExecutionTime
                  ? {
                      next_execution: state.reminderExecutionTime.toISOString(),
                    }
                  : null,
              });
            },
          },
          assignees: {
            assigneeIds: task.assignees,
            isReadonly: !permissions.canEditAssignees,
            onChange: (assigneeIds) =>
              updateCurrentTask({ assignees: assigneeIds }),
            isHidden: task.is_self,
          },
          priority: {
            priority: task.priority,
            isReadonly: !permissions.canEditPriority,
            onChange: (priority) => updateCurrentTask({ priority }),
          },
          label: {
            labelId: task.label_id,
            isReadonly: !permissions.canEditLabel,
            onChange: (labelId) => updateCurrentTask({ label_id: labelId }),
          },
          customer: {
            customerId: task.cust_id,
            isReadonly: !permissions.canEditCustomer,
            shouldWarnBeforeUpdate: true,
            addButton:
              me?.role === UserRole.admin
                ? {
                    label: t({
                      id: "tasks-page.add-new-customer",
                      defaultMessage: "Add customer",
                    }),
                    onClick: () => onAddNewCustomer?.(),
                    startIcon: <AddCustomerIcon />,
                  }
                : undefined,
            onChange: async (customer) =>
              updateCurrentTask({
                cust_id: customer?.id || null,
              }),
            isHidden: task.is_self,
          },
          timeSpent: {
            duration: task.duration,
            isReadonly: !permissions.canEditTimeSpentOnTask,
            onChange: (duration) => updateCurrentTask({ duration }),
          },
          attachments: {
            count: task.attachments.length,
            onClick: onAttachmentsClick,
          },
          recurrence: {
            state: task.recurrence,
            userTimezone: timeZone.name,
            shouldWarnBeforeRemoving: true,
            isReadonly: !permissions.canEditRecurrence,
            onChange: (recurrence) => updateCurrentTask({ recurrence }),
          },
          customFields: {
            onFieldChange: (fieldId, rawValue) =>
              updateCurrentTask({
                custom_fields: {
                  ...task.custom_fields,
                  [fieldId]: rawValue || "",
                },
              }),
            valuesById: task.custom_fields || {},
            isReadonly: !permissions.canEditCustomFields,
          },
        }}
      />
      <TaskExtraPropertiesPanel
        entityId={entityId}
        config={{
          privateTask: {
            isHidden: !task.is_self,
            type: "presentational",
          },
          allowAssigneesEdit: {
            isChecked: task.can_assignee_edit,
            isHidden:
              !permissions.canManageAllowAssigneeToEditOption || task.is_self,
            onChange: (isChecked) =>
              updateCurrentTask({ can_assignee_edit: isChecked }),
          },
          completeChecklistInOrder: {
            isChecked: task.has_chklist_chk,
            isHidden: !permissions.canManageCompleteInSpecificOrderOption,
            onChange: (isChecked) =>
              updateCurrentTask({ has_chklist_chk: isChecked }),
          },
        }}
        className="mt-8"
      />
    </div>
  );
};
