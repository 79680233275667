import {
  TITLE_MAX_LENGTH,
  taskNameValidator,
} from "@jugl-web/domain-resources/tasks";
import { TaskPropertiesPanel } from "@jugl-web/domain-resources/tasks/components/TaskPropertiesPanel";
import { useCreateTask } from "@jugl-web/domain-resources/tasks/hooks/useCreateTask";
import {
  TaskFormState,
  useTaskFormState,
} from "@jugl-web/domain-resources/tasks/hooks/useTaskFormState";
import { DetailedTask } from "@jugl-web/rest-api/tasks";
import {
  Button,
  Input,
  Popover,
  PopoverProps,
} from "@jugl-web/ui-components/cross-platform";
import { TaskPropertyButton } from "@jugl-web/ui-components/cross-platform/tasks/TaskPropertyButton";
import { useTranslations } from "@jugl-web/utils";
import {
  SupportedTaskPropertiesPanelConfigOverrides,
  useTasksPageContext,
} from "@web-src/features/tasks/TasksPageContext";
import { FC, FormEvent } from "react";
import { ReactComponent as CloseIcon } from "./assets/close.svg";
import { ReactComponent as PlusIcon } from "./assets/plus.svg";

export interface NewTaskPopoverProps
  extends Pick<PopoverProps, "renderTrigger" | "placement" | "isDisabled"> {
  entityId: string;
  initialState?: Partial<TaskFormState>;
  taskPropertiesPanelConfigOverrides?: SupportedTaskPropertiesPanelConfigOverrides;
  hasBackdrop?: boolean;
  /**
   * Can be used to perform additional actions before creating a task,
   * e.g. sending analytics events
   */
  onBeforeCreateTask?: () => void;
  /**
   * Can be used to perform additional actions after creating a task,
   * e.g. completing an onboarding step
   */
  onAfterCreateTask?: (task: DetailedTask) => void;
}

export const NewTaskPopover: FC<NewTaskPopoverProps> = ({
  entityId,
  initialState,
  taskPropertiesPanelConfigOverrides,
  hasBackdrop = false,
  onBeforeCreateTask,
  onAfterCreateTask,
  ...popoverProps
}) => {
  const { formState, updateFormState, resetFormState, isDirty } =
    useTaskFormState(initialState);

  const {
    taskListMode,
    openNewTaskDialog,
    customerContext,
    navigateToTaskDetailsPage,
  } = useTasksPageContext();

  const { createTask, showTaskCreationToast, isLoading } = useCreateTask({
    entityId,
    isInTeamTasksContext: taskListMode === "team",
    isInCustomerTasksContext: !!customerContext,
    onNavigate: (taskId) => navigateToTaskDetailsPage(taskId),
  });

  const { t } = useTranslations();

  const handleSubmit = async (
    event: FormEvent<HTMLFormElement>,
    closePopover: () => void
  ) => {
    event.preventDefault();
    onBeforeCreateTask?.();

    try {
      const createdTask = await createTask(formState);
      showTaskCreationToast(createdTask.id);
      onAfterCreateTask?.(createdTask);
      closePopover();
    } catch {
      // Do nothing
    }
  };

  return (
    <Popover
      className="min-w-[300px] p-4"
      hasBackdrop={hasBackdrop}
      onOverlayClickCapture={(event) => {
        if (isDirty()) {
          event.stopPropagation();
        }
      }}
      onUnmount={() => resetFormState(initialState)}
      {...popoverProps}
    >
      {({ onClose }) => (
        <form
          className="flex flex-col gap-4"
          onSubmit={(event) => handleSubmit(event, onClose)}
        >
          <Input
            autoFocus
            required
            className="text-dark placeholder:text-grey-background h-[27px] px-0 text-sm font-medium"
            value={formState.title}
            placeholder={t({ id: "common.title", defaultMessage: "Title" })}
            onChange={(e) => updateFormState("title", e.target.value)}
            maxLength={TITLE_MAX_LENGTH}
          />

          <div className="flex gap-2.5">
            <TaskPropertiesPanel
              entityId={entityId}
              config={{
                dueDate: {
                  state: formState.dueDate,
                  isLabelHidden: true,
                  displayAs: "date",
                  withReminder: true,
                  onChange: (state) => updateFormState("dueDate", state),
                  ...taskPropertiesPanelConfigOverrides?.dueDate,
                },
                assignees: {
                  onlyReportees: taskListMode === "team",
                  isLabelHidden: true,
                  onChange: (assigneeIds) =>
                    updateFormState("assigneeIds", assigneeIds),
                  assigneeIds: formState.assigneeIds,
                  ...taskPropertiesPanelConfigOverrides?.assignees,
                },
              }}
            />
            <TaskPropertyButton
              hint={t({
                id: "tasks-page.add-more-details",
                defaultMessage: "Add more details",
              })}
              className="flex h-8 w-8 justify-center"
              startIcon={<PlusIcon />}
              onClick={() =>
                openNewTaskDialog({
                  initialFormState: formState,
                  taskPropertiesPanelConfigOverrides: {
                    ...taskPropertiesPanelConfigOverrides,
                    assignees: {
                      isReadonly: !!initialState?.assigneeIds?.length,
                    },
                  },
                })
              }
            />
          </div>
          <div className="flex items-center gap-3">
            <Button
              type="submit"
              fullWidth
              size="small"
              isDisabled={isLoading || !taskNameValidator(formState.title)}
            >
              {t({ id: "common.create", defaultMessage: "Create" })}
            </Button>
            <TaskPropertyButton
              startIcon={<CloseIcon />}
              onClick={onClose}
              className="flex h-8 w-8 justify-center rounded-lg"
            />
          </div>
        </form>
      )}
    </Popover>
  );
};
