import { InternalTaskCustomField } from "@jugl-web/rest-api/tasks";
import { ListBoxItem, Popover } from "@jugl-web/ui-components/cross-platform";
import {
  TaskPropertyButton,
  TaskPropertyButtonProps,
} from "@jugl-web/ui-components/cross-platform/tasks/TaskPropertyButton";
import { ResourcePickerDrawer } from "@jugl-web/ui-components/mobile/ResourcePickerDrawer";
import { useAppVariant, useTranslations } from "@jugl-web/utils";
import { FC, useMemo, useState } from "react";
import { useTaskCustomFieldsDetails } from "../../../../hooks/useTaskCustomFieldsDetails";
import { ReactComponent as MoreIcon } from "../../assets/more.svg";
import { TaskCustomFieldPropertyButton } from "../TaskCustomFieldPropertyButton";

interface MoreInfoFieldProps {
  customFields: InternalTaskCustomField[];
  onStartEditingField: (fieldId: string) => void;
}

export const MoreInfoField: FC<MoreInfoFieldProps> = ({
  customFields,
  onStartEditingField,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { customFieldTypeToDetails } = useTaskCustomFieldsDetails();

  const { isMobile } = useAppVariant();
  const { t } = useTranslations();

  const customFieldsAsListItems = useMemo<
    ListBoxItem<InternalTaskCustomField>[]
  >(
    () => customFields.map((field) => ({ id: field.id, value: field })),
    [customFields]
  );

  const commonButtonProps: TaskPropertyButtonProps = {
    startIcon: <MoreIcon />,
  };

  const title = t({
    id: "task-properties-panel-component.more-info-field-title",
    defaultMessage: "Add more task info",
  });

  if (isMobile) {
    return (
      <>
        <TaskPropertyButton
          onClick={() => setIsDialogOpen(true)}
          {...commonButtonProps}
        />
        <ResourcePickerDrawer
          isOpen={isDialogOpen}
          title={title}
          items={customFieldsAsListItems}
          selectionBehavior={{ mode: "single", canToggle: false }}
          maxVisibleItems={8}
          itemSize="md"
          spaceBetweenItems="normal"
          renderLabel={(item) => item.value.name}
          renderStartIcon={(item) => {
            const Icon = customFieldTypeToDetails[item.value.type].primaryIcon;
            return <Icon className="text-grey h-5 w-5" />;
          }}
          onSelect={({ item }) => {
            onStartEditingField(item.id);
            setIsDialogOpen(false);
          }}
          onClose={() => setIsDialogOpen(false)}
        />
      </>
    );
  }

  return (
    <Popover
      placement="bottom-start"
      className="w-[375px] p-6"
      renderTrigger={({ Trigger, triggerRef }) => (
        <Trigger
          ref={triggerRef}
          as={TaskPropertyButton}
          {...commonButtonProps}
        />
      )}
    >
      {({ onClose }) => (
        <>
          <div className="mb-[13px]">
            <span className="text-base font-semibold text-[#383838]">
              {title}
            </span>
          </div>
          <div className="flex flex-wrap items-center gap-2">
            {customFields.map((field) => (
              <TaskCustomFieldPropertyButton
                key={field.id}
                field={field}
                rawValue=""
                onClick={() => {
                  onStartEditingField(field.id);
                  onClose();
                }}
              />
            ))}
          </div>
        </>
      )}
    </Popover>
  );
};
