import { TaskViewsProvider } from "@jugl-web/domain-resources/tasks";
import { TaskFilteringProvider } from "@jugl-web/domain-resources/tasks/components/TaskFilteringProvider";
import { TaskSortingProvider } from "@jugl-web/domain-resources/tasks/components/TaskSortingProvider";
import { HeaderBreadcrumbsItem } from "@jugl-web/ui-components/web/HeaderBreadcrumbs";
import { useTranslations } from "@jugl-web/utils";
import { TaskListModeButton } from "@web-src/components/TaskListModeButton";
import { TasksPageLayout } from "@web-src/components/TasksPageLayout";
import { TasksContent } from "@web-src/features/tasks/TasksContent";
import { TasksControlBar } from "@web-src/features/tasks/TasksControlBar";
import {
  TasksPageContextProvider,
  useTasksPageContext,
} from "@web-src/features/tasks/TasksPageContext";
import { useMarkModuleAsRead } from "@web-src/hooks/useMarkModuleAsRead";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { useMemo } from "react";
import { DataLoadingWrapper } from "@jugl-web/ui-components";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { getCustomerDetails } from "@jugl-web/domain-resources/customers/utils";

const InnerTasks = () => {
  const { taskListMode, customerContext, setTaskListMode } =
    useTasksPageContext();
  const { entityId } = useEntitySelectedProvider();
  const { customersApi } = useRestApiProvider();
  const { customerId } = useParams();

  const { isLoading, isFetching, isError, refetch } =
    customersApi.useCustomerQuery(
      customerId
        ? {
            entityId,
            customerId,
          }
        : skipToken
    );

  const { generateUrl } = useNavigation();
  const { t } = useTranslations();

  const breadcrumbs = useMemo<HeaderBreadcrumbsItem[]>(() => {
    const breadcrumbItems: HeaderBreadcrumbsItem[] = [];

    if (customerContext) {
      breadcrumbItems.push({
        title: t({
          id: "tasks-page.customers",
          defaultMessage: "Customers",
        }),
        href: generateUrl("customersCustomers"),
      });

      if (customerContext.customer) {
        breadcrumbItems.push({
          title: getCustomerDetails(customerContext.customer).fullName,
          href: generateUrl("customersTasks", {
            customerId: customerContext.customer.id,
          }),
        });
      }
    } else {
      breadcrumbItems.push({
        title: t({
          id: "tasks-page.tasks",
          defaultMessage: "Tasks",
        }),
        href: generateUrl("tasksTasks"),
      });
    }

    return breadcrumbItems;
  }, [customerContext, generateUrl, t]);

  useMarkModuleAsRead(entityId, "task", !!customerContext);

  return (
    <DataLoadingWrapper
      onRetry={refetch}
      isError={isError}
      isLoading={isLoading}
      isFetching={isFetching}
    >
      <TaskFilteringProvider entityId={entityId}>
        <TaskSortingProvider>
          <TaskViewsProvider
            entityId={entityId}
            isCustomerMode={!!customerContext}
          >
            <TasksPageLayout
              breadcrumbs={breadcrumbs}
              topBarRightContent={
                !customerContext ? (
                  <TaskListModeButton
                    isTeamTasks={taskListMode === "team"}
                    onChange={() =>
                      setTaskListMode(
                        taskListMode === "team" ? "personal" : "team"
                      )
                    }
                  />
                ) : null
              }
              hideNotifications={!!customerContext}
            >
              <TasksControlBar />
              <TasksContent />
            </TasksPageLayout>
          </TaskViewsProvider>
        </TaskSortingProvider>
      </TaskFilteringProvider>
    </DataLoadingWrapper>
  );
};

export const Tasks = () => (
  <TasksPageContextProvider>
    <InnerTasks />
  </TasksPageContextProvider>
);
