import { Popover } from "@jugl-web/ui-components/cross-platform";
import { DatePicker } from "@jugl-web/ui-components/cross-platform/DatePicker";
import { assert } from "@jugl-web/utils";
import { isValidDate } from "@jugl-web/utils/date-time/isValidDate";
import { FC } from "react";
import { CustomSpecificFieldCellProps } from "../../types";
import { CustomFieldCellContent } from "../CustomFieldCellContent";
import { TaskTableLayout } from "../TaskTableLayout";

export const CustomDateFieldCell: FC<CustomSpecificFieldCellProps> = ({
  field,
  rawValue,
  isEditable,
  isValueHidden,
  cellProps,
  onFieldUpdate,
}) => {
  assert(field.type === "date", "Field should be of type date");

  const initialDate = (() => {
    if (rawValue && isValidDate(rawValue)) {
      return new Date(rawValue);
    }

    return undefined;
  })();

  return (
    <Popover
      placement="bottom"
      renderTrigger={({ Trigger, triggerRef, isOpen, onClose }) => (
        <Trigger
          ref={triggerRef}
          as={TaskTableLayout.Cell}
          isFocused={isOpen}
          isHoverable
          isDisabled={!isEditable}
          {...cellProps}
        >
          {!isValueHidden && (
            <CustomFieldCellContent
              field={field}
              rawValue={rawValue}
              isEditable={isEditable}
              isCellFocused={isOpen}
              onClearValue={() => {
                onFieldUpdate("");
                onClose();
              }}
            />
          )}
        </Trigger>
      )}
      className="w-[375px] px-4 py-6"
    >
      {({ onClose }) => (
        <DatePicker
          initialDate={initialDate}
          onDaySelect={(date) => {
            onFieldUpdate(date.toISOString());
            onClose();
          }}
        />
      )}
    </Popover>
  );
};
