import { Text } from "@jugl-web/ui-components/cross-platform/Text";
import { cx, useTranslations } from "@jugl-web/utils";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Radio,
  RadioGroup,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from "@headlessui/react";
import VirtualBackgroundExtension from "agora-extension-virtual-background";
import AgoraRTC, { ICameraVideoTrack } from "agora-rtc-react";
import { ReactComponent as ChevronDown } from "./assets/chevron-down.svg";
import { ReactComponent as CloseIcon } from "./assets/close.svg";
import { ReactComponent as MicIcon } from "./assets/mic-on-settings.svg";
import { ReactComponent as ImageBgIcon } from "./assets/settings-image-bg.svg";
import { ReactComponent as CameraIcon } from "./assets/video-on-settings.svg";
import { ReactComponent as NoSymbolIcon } from "./assets/noeffects.svg";
import { ReactComponent as BlurIcon } from "./assets/blur.svg";
import { CallsContext } from "../../../../../../providers";
import { useCallsSettings } from "../../../../../../hooks";

type Resolutions = "240p_1" | "480p_1" | "720p_1";

const virtualBackgroundExtension = new VirtualBackgroundExtension();
AgoraRTC.registerExtensions([virtualBackgroundExtension]);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const processor = virtualBackgroundExtension.createProcessor();
processor.init("../../assets/agora-wasm.wasm");
processor.setOptions({ type: "blur", blurDegree: 2 });
processor.enable();

const ActiveCallDeviceSelector: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const { t } = useTranslations();
  const { getCallsSettings, setCallsSettings } = useCallsSettings();

  const cameraResolutionPresets = [
    {
      id: "720p_1",
      name: t({
        id: "call-control-panel-select-definition-720p",
        defaultMessage: "High Definition (720p)",
      }),
    },
    {
      id: "420p_1",
      name: t({
        id: "call-control-panel-select-definition-480p",
        defaultMessage: "Standard Definition (480p)",
      }),
    },
    {
      id: "240p_1",
      name: t({
        id: "call-control-panel-select-definition-240p",
        defaultMessage: "Low Definition (240p)",
      }),
    },
  ];
  const { audioDevices, videoDevices, activeCall, playbackDevices } =
    useContext(CallsContext);
  const [selectedPanel, setSelectedPanel] = useState<number>(0);
  const [selectedAudio, setSelectedAudio] = useState<string>();
  const [selectedPlayback, setSelectedPlayback] = useState<string>();
  const [selectedVideo, setSelectedVideo] = useState<string>();
  const [selectedResolution, setSelectedResolution] = useState<Resolutions>();
  const [storedSettings, setStoredSettings] = useState<{
    camera_id: string;
    camera_resolution: Resolutions;
    microphone_id: string;
    playback_id: string;
  }>(getCallsSettings());
  const [cameraSettings, setCameraSettings] = useState<string>(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    activeCall?.videoProcessor?.enabled ? "blur" : "none"
  );
  const localSettingsVideoTrack = useRef<ICameraVideoTrack>();
  const videoImageBgInput: React.MutableRefObject<HTMLInputElement | null> =
    useRef(null);
  useEffect(() => {
    if (selectedAudio && activeCall?.audioEnabled) {
      activeCall?.localAudioTrack?.setDevice(selectedAudio);
    }
    if (selectedVideo && activeCall?.videoEnabled) {
      activeCall?.localVideoTrack?.setDevice(selectedVideo);
      activeCall?.localVideoTrack?.setEncoderConfiguration(
        selectedResolution || "480p_1"
      );
    }
  }, [
    activeCall?.videoEnabled,
    activeCall?.localVideoTrack,
    selectedVideo,
    selectedAudio,
    activeCall?.audioEnabled,
    activeCall?.localAudioTrack,
    selectedPlayback,
    selectedResolution,
  ]);

  useEffect(() => {
    if (storedSettings) {
      const isCameraPresent = videoDevices?.find(
        (camera) => camera.deviceId === storedSettings?.camera_id
      );
      const isMicrophonePresent = audioDevices?.find(
        (mic) => mic.deviceId === storedSettings?.microphone_id
      );
      const isPlaybackPresent = playbackDevices?.find(
        (device) => device.deviceId === storedSettings?.playback_id
      );
      setSelectedResolution(storedSettings.camera_resolution || "480p_1");
      if (isCameraPresent) {
        setSelectedVideo(storedSettings.camera_id);
      }
      if (isMicrophonePresent) {
        setSelectedAudio(storedSettings.microphone_id);
      }
      if (isPlaybackPresent) {
        setSelectedPlayback(storedSettings.playback_id);
      }
    }
  }, [audioDevices, playbackDevices, storedSettings, videoDevices]);

  useEffect(() => () => localSettingsVideoTrack?.current?.close(), []);
  useEffect(() => {
    (async () => {
      if (videoDevices && isOpen && !localSettingsVideoTrack.current) {
        const settingsVideoTrack = await AgoraRTC.createCameraVideoTrack({
          encoderConfig: "720p",
        });
        localSettingsVideoTrack.current = settingsVideoTrack;
      }
      if (selectedPanel === 1 && localSettingsVideoTrack.current) {
        if (selectedVideo) {
          localSettingsVideoTrack?.current?.setDevice(selectedVideo);
        }
        localSettingsVideoTrack?.current?.play("settingslocalplayer", {
          fit: "cover",
        });

        localSettingsVideoTrack.current
          ?.pipe(processor)
          .pipe(localSettingsVideoTrack.current?.processorDestination);
      }
      if (!isOpen || selectedPanel !== 1) {
        await localSettingsVideoTrack.current?.close();
        localSettingsVideoTrack.current = undefined;
      }
    })();
  }, [
    selectedPanel,
    videoDevices,
    isOpen,
    localSettingsVideoTrack,
    selectedVideo,
  ]);

  const loadImage = async (url: string) => {
    const bgImage = new Image();
    bgImage.src = url;
    bgImage.crossOrigin = "anonymous";
    await bgImage.decode();
    return bgImage;
  };

  const handleVideoBackgroundImage = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    const file = e && e?.target?.files?.length && e.target.files[0];
    const blobURL = (file && URL.createObjectURL(file)) || "";
    const image = await loadImage(blobURL);
    const options = { type: "img", source: image };
    await processor.setOptions(options);
    await activeCall?.videoProcessor?.setOptions(options);
    processor.enable();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    activeCall?.videoProcessor?.enable();
    if (videoImageBgInput && videoImageBgInput.current) {
      videoImageBgInput.current.value = "";
    }
  };
  const handleBackgroundBlur = () => {
    const options = { type: "blur", blurDegree: 2 };
    processor.setOptions({ type: "blur", blurDegree: 2 });
    activeCall?.videoProcessor?.setOptions(options);
    processor.enable();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    activeCall?.videoProcessor?.enable();
  };
  const handleNoVideoFilters = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    activeCall?.videoProcessor?.disable();
    processor.disable();
  };
  const handleCameraSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    activeCall?.localVideoTrack?.setDevice(e.target.value);
    localSettingsVideoTrack?.current?.setDevice(e.target.value);
    const settingsNewState = { ...storedSettings, camera_id: e.target.value };
    setSelectedVideo(e.target.value);
    setStoredSettings(settingsNewState);
    setCallsSettings(settingsNewState);
  };

  const handleResolutionSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value as Resolutions;
    activeCall?.localVideoTrack?.setEncoderConfiguration(value);
    localSettingsVideoTrack?.current?.setEncoderConfiguration(value);
    const settingsNewState = { ...storedSettings, camera_resolution: value };
    setSelectedResolution(value);
    setStoredSettings(settingsNewState);
    setCallsSettings(settingsNewState);
  };

  const handleMicrophoneSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    activeCall?.localAudioTrack?.setDevice(e.target.value);
    setSelectedAudio(e.target.value);
    const settingsNewState = {
      ...storedSettings,
      microphone_id: e.target.value,
    };
    setStoredSettings(settingsNewState);
    setCallsSettings(settingsNewState);
  };
  const handlePlaybackSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedPlayback(e.target.value);
    const settingsNewState = {
      ...storedSettings,
      playback_id: e.target.value,
    };
    setStoredSettings(settingsNewState);
    setCallsSettings(settingsNewState);
  };
  return (
    <div
      className={cx(
        "relative flex h-[535px] w-[700px] flex-col rounded-xl bg-white",
        {
          hidden: !isOpen,
        }
      )}
    >
      <Text
        variant="h4"
        className="text-dark border-grey w-full border-b-[1px] border-l-0 border-r-0 border-t-0 border-solid border-opacity-10 pl-8 pb-4 font-[600] shadow-lg"
      >
        {t({
          id: "call-control-panel-settings",
          defaultMessage: "Settings",
        })}
      </Text>
      <TabGroup selectedIndex={selectedPanel} onChange={setSelectedPanel}>
        <div className="flex w-full flex-row gap-6">
          <div className="border-grey mt-2 min-h-[300px] border-r-[1px] border-l-0 border-b-0 border-t-0 border-solid border-opacity-10 px-8">
            <TabList>
              <Tab className="ui-selected:bg-primary-50 flex w-[124px] cursor-pointer flex-row items-center justify-start gap-2 rounded-lg border-transparent bg-transparent px-2 py-1">
                <MicIcon className="fill-primary" />
                <Text variant="body3">
                  {t({
                    id: "call-control-panel-audio",
                    defaultMessage: "Audio",
                  })}
                </Text>
              </Tab>

              <Tab className="ui-selected:bg-primary-50 mt-4 flex w-[124px] cursor-pointer flex-row items-center justify-start gap-2 rounded-lg border-transparent bg-transparent px-2 py-1">
                <CameraIcon className="fill-primary" />
                <Text variant="body3">
                  {t({
                    id: "call-control-panel-video",
                    defaultMessage: "Video",
                  })}
                </Text>
              </Tab>
            </TabList>
          </div>
          <div className="absolute right-7 top-7">
            <CloseIcon onClick={onClose} className="cursor-pointer" />
          </div>
          <TabPanels className="overflow-hidden">
            <TabPanel className="min-w-max">
              {audioDevices && (
                <div className="flex max-w-full flex-row items-center">
                  <div className="border-grey-400 relative w-[400px] overflow-hidden border border-t-0 border-l-0 border-r-0 border-solid py-2 pr-8">
                    <label
                      htmlFor="id_audio"
                      className="relative flex flex-col gap-2"
                    >
                      <span
                        className="text-primary pl-2 text-xs font-[500] uppercase"
                        style={{ fontVariant: "all-small-caps" }}
                      >
                        {t({
                          id: "call-control-panel-microphone",
                          defaultMessage: "Microphone",
                        })}
                      </span>
                      <select
                        className="text-dark w-[400px] cursor-pointer appearance-none text-ellipsis border-none bg-transparent py-1 pr-12 pl-3 text-base font-medium leading-tight focus:outline-none"
                        title="audio"
                        id="id_audio"
                        value={selectedAudio || audioDevices[0].deviceId}
                        onChange={handleMicrophoneSelect}
                      >
                        <option disabled selected={!selectedAudio}>
                          {t({
                            id: "call-control-panel-select-audio-input",
                            defaultMessage: "Select audio input",
                          })}
                        </option>

                        {audioDevices.map((item) => (
                          <option key={item.deviceId} value={item.deviceId}>
                            {item.label} {item.label}
                          </option>
                        ))}
                      </select>
                    </label>
                    <ChevronDown className="fill-grey absolute bottom-2 right-0 mr-1 cursor-pointer" />
                  </div>
                </div>
              )}
              {playbackDevices && (
                <div className="mt-10 flex max-w-full flex-row items-center">
                  <div className="border-grey-400 relative w-[400px] overflow-hidden border border-t-0 border-l-0 border-r-0 border-solid py-2 pr-8">
                    <label
                      htmlFor="id_playback"
                      className="relative flex flex-col gap-2"
                    >
                      <span
                        className="text-primary pl-2 text-xs font-[500] uppercase"
                        style={{ fontVariant: "all-small-caps" }}
                      >
                        {t({
                          id: "call-control-panel-speaker",
                          defaultMessage: "Speaker",
                        })}
                      </span>
                      <select
                        className="text-dark w-[400px] cursor-pointer appearance-none text-ellipsis border-none bg-transparent py-1 pr-12 pl-3 text-base font-medium leading-tight focus:outline-none"
                        title="audio"
                        id="playback"
                        value={selectedPlayback || playbackDevices[0]?.deviceId}
                        onChange={handlePlaybackSelect}
                      >
                        <option disabled selected={!selectedAudio}>
                          {t({
                            id: "call-control-panel-select-audio-playback",
                            defaultMessage: "Select audio playback",
                          })}
                        </option>

                        {playbackDevices.map((item) => (
                          <option key={item.deviceId} value={item.deviceId}>
                            {item.label} {item.label}
                          </option>
                        ))}
                      </select>
                    </label>
                    <ChevronDown className="fill-grey absolute bottom-2 right-0 mr-1 cursor-pointer" />
                  </div>
                </div>
              )}
            </TabPanel>
            <TabPanel className="min-w-max pr-16">
              {videoDevices && (
                <div className="mb-8 flex flex-row items-center">
                  <div className="border-grey-400 relative w-[400px] border border-t-0 border-l-0 border-r-0 border-solid py-2">
                    <label htmlFor="id_video" className="flex flex-col gap-2">
                      <span
                        className="text-primary pl-2 text-xs uppercase"
                        style={{ fontVariant: "all-small-caps" }}
                      >
                        {t({
                          id: "call-control-panel-camera",
                          defaultMessage: "Camera",
                        })}
                      </span>
                      <select
                        className="text-dark w-[400px] cursor-pointer appearance-none overflow-hidden text-ellipsis border-none bg-transparent py-1 px-2 pl-3 text-base font-medium leading-tight focus:outline-none"
                        title="video"
                        id="id_video"
                        value={selectedVideo}
                        onChange={handleCameraSelect}
                      >
                        <option disabled selected={!selectedVideo}>
                          {t({
                            id: "call-control-panel-select-video-input",
                            defaultMessage: "Select video input",
                          })}
                        </option>
                        {videoDevices.map((item) => (
                          <option key={item.deviceId} value={item.deviceId}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                    </label>
                    <ChevronDown className="fill-grey absolute bottom-2 right-0 mr-1 cursor-pointer" />
                  </div>
                </div>
              )}
              {videoDevices && (
                <div className="mb-8 flex flex-row items-center">
                  <div className="border-grey-400 relative w-[400px] border border-t-0 border-l-0 border-r-0 border-solid py-2">
                    <label htmlFor="id_video" className="flex flex-col gap-2">
                      <span
                        className="text-primary pl-2 text-xs uppercase"
                        style={{ fontVariant: "all-small-caps" }}
                      >
                        {t({
                          id: "call-control-panel-send-video-resolution",
                          defaultMessage: "Send resolution (maxiumum)",
                        })}
                      </span>
                      <select
                        className="text-dark w-[400px] cursor-pointer appearance-none overflow-hidden text-ellipsis border-none bg-transparent py-1 px-2 pl-3 text-base font-medium leading-tight focus:outline-none"
                        title="video"
                        id="id_video"
                        value={
                          selectedResolution || cameraResolutionPresets[1].id
                        }
                        onChange={handleResolutionSelect}
                      >
                        <option disabled selected={!selectedVideo}>
                          {t({
                            id: "call-control-panel-select-video-resolution",
                            defaultMessage: "Select send resolution",
                          })}
                        </option>
                        {cameraResolutionPresets.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </label>
                    <ChevronDown className="fill-grey absolute bottom-2 right-0 mr-1 cursor-pointer" />
                  </div>
                </div>
              )}
              <div className="mb-4 flex flex-row gap-4">
                <div
                  className="h-[180px] w-[338px] rounded-full border-2"
                  id="settingslocalplayer"
                />
                <RadioGroup
                  value={cameraSettings}
                  onChange={setCameraSettings}
                  className="flex flex-col justify-center gap-4"
                >
                  <Radio value="none" onClick={handleNoVideoFilters}>
                    <span className="ui-checked:bg-grey-100 bg-grey-100 border-grey-200 ui-checked:border-grey-400 stroke-grey flex h-[36px] w-[46px] cursor-pointer place-content-center items-center rounded-lg border-[1px] border-solid">
                      <NoSymbolIcon />
                    </span>
                  </Radio>
                  {!true && (
                    <Radio value="image-background">
                      <span className="ui-checked:bg-primary-50 bg-grey-100 ui-checked:border-solid border-primary ui-checked:stroke-primary stroke-grey relative flex cursor-pointer rounded-lg border-[1px] py-3 px-4">
                        <input
                          type="file"
                          accept="image/*"
                          className="absolute top-0 bottom-0 right-0 left-0 z-20 h-[36px] w-[46px] opacity-0"
                          onChange={handleVideoBackgroundImage}
                          ref={videoImageBgInput}
                        />
                        <ImageBgIcon />
                      </span>
                    </Radio>
                  )}
                  <Radio value="blur" onClick={handleBackgroundBlur}>
                    <span className="ui-checked:bg-grey-100 bg-grey-100 border-grey-200 ui-checked:border-grey-400 flex h-[36px] w-[46px] cursor-pointer place-content-center items-center rounded-lg border-[1px] border-solid">
                      <BlurIcon />
                    </span>
                  </Radio>
                </RadioGroup>
              </div>
            </TabPanel>
          </TabPanels>
        </div>
      </TabGroup>
    </div>
  );
};

export default ActiveCallDeviceSelector;
