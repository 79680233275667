import {
  taskNameValidator,
  TITLE_MAX_LENGTH,
} from "@jugl-web/domain-resources/tasks";
import { TaskPropertiesPanel } from "@jugl-web/domain-resources/tasks/components/TaskPropertiesPanel";
import { useCreateTask } from "@jugl-web/domain-resources/tasks/hooks/useCreateTask";
import { useTaskFormState } from "@jugl-web/domain-resources/tasks/hooks/useTaskFormState";
import { TaskViewColumn } from "@jugl-web/domain-resources/tasks/hooks/useTasksViewColumns";
import {
  Alert,
  Button,
  Input,
  PlainButton,
  TaskPropertyButton,
} from "@jugl-web/ui-components/cross-platform";
import { assert, ClickAwayListener, useTranslations } from "@jugl-web/utils";
import { useTasksPageContext } from "@web-src/features/tasks/TasksPageContext";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { useOnboarding } from "@web-src/modules/preferences/providers";
import { FC, useEffect, useState } from "react";
import { ReactComponent as CrossIcon } from "./assets/cross.svg";
import { ReactComponent as PlusIcon } from "./assets/plus.svg";

interface NewTaskColumnCardProps {
  column: TaskViewColumn;
  onClose: () => void;
}

export const NewTaskColumnCard: FC<NewTaskColumnCardProps> = ({
  column,
  onClose,
}) => {
  const { createTaskOptions } = column;
  assert(!!createTaskOptions, "Create task options are required");

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const { taskListMode, openNewTaskDialog } = useTasksPageContext();
  const { formState, updateFormState, resetFormState } = useTaskFormState();
  const { entityId } = useEntitySelectedProvider();

  const { t } = useTranslations();

  const { navigateToPage } = useNavigation();
  const { isOnboardingActive, completeOnboardingStep } = useOnboarding();

  const { createTask, showTaskCreationToast, isLoading } = useCreateTask({
    entityId,
    isInTeamTasksContext: taskListMode === "team",
    onNavigate: (taskId) => navigateToPage("tasksDetails", { taskId }),
  });

  const handleSubmit = async () => {
    try {
      const createdTask = await createTask(formState);
      showTaskCreationToast(createdTask.id);
      if (isOnboardingActive) {
        completeOnboardingStep("task");
      }
      onClose();
    } catch {
      // Do nothing
    }
  };

  const handleShouldSkipClick = (clickedElement: Node) =>
    // Checking if the clicked element is inside the portal so we won't get discard alert when we are using popovers inside task column card
    document
      .getElementById("headlessui-portal-root")
      ?.contains(clickedElement) ?? false;

  useEffect(() => {
    resetFormState(createTaskOptions.initialState);
  }, [createTaskOptions.initialState, resetFormState]);

  return (
    <ClickAwayListener
      onClickAway={() => setIsConfirmDialogOpen(true)}
      shouldSkipClick={handleShouldSkipClick}
    >
      <Alert
        isCloseButtonVisible={false}
        isOpen={isConfirmDialogOpen}
        onRequestClose={() => setIsConfirmDialogOpen(false)}
        title={t({
          id: "tasks-page.discard-changes",
          defaultMessage: "Discard Changes",
        })}
        content={t({
          id: "tasks-page.discard-changes-description",
          defaultMessage: "If you discard, entered info will not be saved",
        })}
        buttons={[
          {
            text: t({
              id: "common.cancel",
              defaultMessage: "Cancel",
            }),
            color: "grey",
            onClick: () => {
              setIsConfirmDialogOpen(false);
            },
          },
          {
            text: t({
              id: "common.discard",
              defaultMessage: "Discard",
            }),
            color: "primary",
            onClick: () => {
              onClose();
            },
          },
        ]}
      />
      <div className="shadow-task-card flex flex-col gap-4 rounded-lg bg-white p-4">
        <Input
          autoFocus
          required
          className="text-dark placeholder:text-grey-background px-0 text-sm font-medium"
          value={formState.title}
          placeholder={t({ id: "common.title", defaultMessage: "Title" })}
          onChange={(e) => updateFormState("title", e.target.value)}
          maxLength={TITLE_MAX_LENGTH}
        />
        <div className="flex gap-2.5">
          <TaskPropertiesPanel
            entityId={entityId}
            config={{
              dueDate: createTaskOptions?.fields.dueDate
                ? {
                    state: formState.dueDate,
                    isLabelHidden: true,
                    displayAs: "date",
                    withReminder: true,
                    onChange: (state) => updateFormState("dueDate", state),
                  }
                : undefined,
              assignees: {
                assigneeIds: formState.assigneeIds,
                isLabelHidden: true,
                onChange: (assigneeIds) =>
                  updateFormState("assigneeIds", assigneeIds),
                isHidden: !createTaskOptions?.fields.assignee,
              },
            }}
          />
          <TaskPropertyButton
            hint={t({
              id: "tasks-page.add-more-details",
              defaultMessage: "Add more details",
            })}
            className="flex h-8 w-8 justify-center"
            startIcon={<PlusIcon />}
            onClick={() => {
              openNewTaskDialog({
                initialFormState: formState,
                taskPropertiesPanelConfigOverrides: {
                  assignees: {
                    isReadonly: !createTaskOptions.fields.assignee,
                  },
                  dueDate: {
                    isDateEditable: !!createTaskOptions.fields.dueDate,
                  },
                },
              });
              onClose();
            }}
          />
        </div>
        <div className="mt-2 flex items-center gap-3">
          <Button
            fullWidth
            size="small"
            isDisabled={isLoading || !taskNameValidator(formState.title)}
            onClick={handleSubmit}
          >
            {t({ id: "common.create", defaultMessage: "Create" })}
          </Button>
          <PlainButton
            onClick={() => setIsConfirmDialogOpen(true)}
            className="bg-grey-200 flex h-8 w-8 items-center justify-center rounded-md p-1 hover:brightness-95"
          >
            <CrossIcon />
          </PlainButton>
        </div>
      </div>
    </ClickAwayListener>
  );
};
