import { BaseTask, TaskDefaultStatus } from "@jugl-web/rest-api/tasks";
import { isoToLocalDate } from "@jugl-web/utils/date-time";
import add from "date-fns/add";
import differenceInDays from "date-fns/differenceInDays";
import endOfDay from "date-fns/endOfDay";
import isAfter from "date-fns/isAfter";
import truncate from "lodash/truncate";
import addDays from "date-fns/addDays";
import getHours from "date-fns/getHours";
import getMinutes from "date-fns/getMinutes";
import getSeconds from "date-fns/getSeconds";
import set from "date-fns/set";
import { TEMPLATE_FOLDER_PRESETS } from "./consts";

const MAX_TASK_NAME_LENGTH = 100;

export const getDueDateBasedOnDays = (dueDateInDays: number) =>
  endOfDay(add(new Date(), { days: dueDateInDays }));

export const getDueDateInDays = (dueDate: Date) =>
  differenceInDays(endOfDay(dueDate), endOfDay(new Date()));

export const getDueDateBasedOnDaysAndTime = (date: string, days: number) => {
  const time = isoToLocalDate(date);
  const hours = getHours(time);
  const minutes = getMinutes(time);
  const seconds = getSeconds(time);

  const newDate = addDays(new Date(), days);

  return set(newDate, { hours, minutes, seconds });
};

export interface TransformedActivity {
  custom_fields?: {
    name: string;
    value: string;
    isRemoved?: boolean;
  };
}

export const shortenTaskTitle = (
  title: string,
  maxLength: number = MAX_TASK_NAME_LENGTH
) =>
  truncate(title, {
    length: maxLength,
    omission: "…",
  });

export const isTaskOverdue = <TTask extends BaseTask>(task: TTask) =>
  !!(
    task.due_at &&
    isAfter(new Date(), isoToLocalDate(task.due_at)) &&
    task.status !== TaskDefaultStatus.completed
  );

export const getRandomFolderPreset = () =>
  TEMPLATE_FOLDER_PRESETS[
    Math.floor(Math.random() * TEMPLATE_FOLDER_PRESETS.length)
  ];
