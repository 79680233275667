import {
  Button,
  InteractiveContainer,
  MobileDrawer,
  Popover,
} from "@jugl-web/ui-components";
import React, { useRef, useState } from "react";
import {
  DatePicker,
  DatePickerHandle,
} from "@jugl-web/ui-components/cross-platform/DatePicker";
import { ExpectedTaskCustomDropdownFieldValue } from "@jugl-web/rest-api/tasks";
import {
  cx,
  useAppVariant,
  useAutoResizedTextarea,
  useTranslations,
} from "@jugl-web/utils";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import format from "date-fns/format";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import isBefore from "date-fns/isBefore";
import { DueTimeInput } from "../../../tasks/components/DueDatePicker/components/DueTimeInput";
import { ReactComponent as ArrowIcon } from "./assets/arrow.svg";

const MAX_TEXT_INPUT_LENGTH = 500;

export type CustomFieldInputProps = {
  title: string;
  isRequired: boolean;
  isGuestApp?: boolean;
  isFieldRequiredError?: boolean;
  isFieldInvalidError?: boolean;
  field:
    | {
        type: "text" | "number";
        value: string;
        onChange: (value: string) => void;
      }
    | {
        type: "date";
        value?: Date;
        onChange: (value: Date | null) => void;
      }
    | {
        type: "dropdown";
        items: ExpectedTaskCustomDropdownFieldValue[];
        selectedId: string;
        onChange: (item: string) => void;
      };
};

export const CustomFieldInput: React.FC<CustomFieldInputProps> = ({
  title,
  isRequired,
  field,
  isFieldRequiredError,
  isFieldInvalidError,
  isGuestApp,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const datePickerRef = useRef<DatePickerHandle>(null);
  const { textareaProps } = useAutoResizedTextarea(textareaRef);
  const { dateLocale } = useLanguage();
  const { t } = useTranslations();
  const { isMobile } = useAppVariant();
  const [isDateDrawerOpen, setIsDateDrawerOpen] = useState(false);

  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    field.type === "date" ? field.value : undefined
  );

  const handleDaySelect = (date: Date) => {
    setSelectedDate((previousDate) => {
      if (previousDate) {
        const now = new Date();
        const updatedDate = new Date(date);

        updatedDate.setHours(
          previousDate.getHours(),
          previousDate.getMinutes()
        );

        if (!isBefore(updatedDate, now)) {
          return updatedDate;
        }
      }

      return date;
    });
  };

  const containerClasses = cx(
    "bg-grey-200 rounded-lg p-3",
    (isFieldRequiredError || isFieldInvalidError) && "bg-tertiary-50"
  );

  const label = (
    <span className="font-secondary text-[10px] leading-[14px] text-[#828282]">
      {title}
      {isRequired && <span className="text-gradients-danger"> *</span>}
    </span>
  );

  const requiredErrorMessage = (
    <span className="text-gradients-danger mt-2 text-xs">
      {t({
        id: "order-form-create-page.is-required-field",
        defaultMessage: "This is a required field",
      })}
    </span>
  );

  const invalidErrorMessage = (
    <span className="text-gradients-danger mt-2 text-xs">
      {t({
        id: "order-form-create-page.invalid-value",
        defaultMessage: "Invalid value",
      })}
    </span>
  );

  if (field.type === "text") {
    return (
      <div>
        <InteractiveContainer
          className={containerClasses}
          onClick={() => textareaRef.current?.focus()}
        >
          <div className="flex w-full flex-col">
            {label}
            <textarea
              ref={textareaRef}
              rows={1}
              placeholder={t({
                id: "common.enter",
                defaultMessage: "Enter",
              })}
              maxLength={MAX_TEXT_INPUT_LENGTH}
              className="font-secondary text-dark placeholder:font-secondary placeholder:text-dark w-full resize-none border-none bg-transparent p-0 text-xs leading-[140%] outline-none placeholder:text-xs placeholder:leading-[140%]"
              value={field.value}
              onChange={(e) => field.onChange(e.target.value.trimStart())}
              {...textareaProps}
            />
          </div>
        </InteractiveContainer>
        {field.value.length > MAX_TEXT_INPUT_LENGTH - 30 && (
          <span className="text-gradients-danger mt-2 text-xs">
            {`${field.value.length}/${MAX_TEXT_INPUT_LENGTH}`}
          </span>
        )}
        {isFieldInvalidError && invalidErrorMessage}
        {isFieldRequiredError && requiredErrorMessage}
      </div>
    );
  }

  if (field.type === "date") {
    const $button = (onClick?: () => void) => (
      <InteractiveContainer className={containerClasses} onClick={onClick}>
        <div className="flex flex-col">
          {label}
          <span className="font-secondary text-dark text-xs leading-[140%]">
            {field.value
              ? format(field.value, "d MMMM, yyyy, h:mm a", {
                  locale: dateLocale,
                })
              : t({
                  id: "common.select",
                  defaultMessage: "Select",
                })}
          </span>
        </div>
      </InteractiveContainer>
    );

    const $content = (onClose: () => void) => (
      <div className="mx-auto flex w-[350px] flex-col gap-8 p-4">
        <DatePicker
          ref={datePickerRef}
          initialDate={selectedDate}
          dateTransformation="endOfDay"
          onDaySelect={handleDaySelect}
        />
        <div className="flex flex-col gap-2">
          <DueTimeInput
            selectedDate={selectedDate || null}
            onDateChange={setSelectedDate}
          />
        </div>
        <div className="flex items-center gap-3">
          <Button
            fullWidth
            color="grey"
            className="h-10"
            onClick={() => {
              datePickerRef.current?.reset();
              setSelectedDate(undefined);
            }}
          >
            {t({ id: "common.clear", defaultMessage: "Clear" })}
          </Button>
          <Button
            fullWidth
            className="h-10"
            onClick={() => {
              field.onChange(selectedDate || null);
              onClose();
            }}
          >
            {t({ id: "common.save", defaultMessage: "Save" })}
          </Button>
        </div>
      </div>
    );

    if (isMobile) {
      return (
        <>
          {$button(() => setIsDateDrawerOpen(true))}
          <MobileDrawer
            isOpen={isDateDrawerOpen}
            animateHeight
            className="overflow-y-auto"
            onClose={() => setIsDateDrawerOpen(false)}
          >
            <MobileDrawer.Content>
              {$content(() => setIsDateDrawerOpen(false))}
            </MobileDrawer.Content>
          </MobileDrawer>
        </>
      );
    }
    return (
      <div>
        <Popover
          placement="right"
          className="z-[2000]"
          floatingMiddleware={isGuestApp ? [] : undefined}
          renderTrigger={({ Trigger, triggerRef }) => (
            <Trigger ref={triggerRef} as={InteractiveContainer}>
              {$button()}
            </Trigger>
          )}
        >
          {({ onClose }) => $content(onClose)}
        </Popover>
        {isFieldRequiredError && requiredErrorMessage}
      </div>
    );
  }

  if (field.type === "number") {
    return (
      <div>
        <InteractiveContainer
          className={containerClasses}
          onClick={() => inputRef.current?.focus()}
        >
          <div className="flex w-full flex-col">
            {label}
            <input
              ref={inputRef}
              type="number"
              value={field.value}
              onKeyDown={(e) => e.key === "e" && e.preventDefault()}
              placeholder={t({
                id: "common.enter",
                defaultMessage: "Enter",
              })}
              className="font-secondary text-dark placeholder:font-secondary placeholder:text-dark w-full resize-none border-none bg-transparent p-0 text-xs leading-[140%] outline-none [appearance:textfield] placeholder:text-xs placeholder:leading-[140%] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
              onChange={(e) => field.onChange(e.target.value)}
            />
          </div>
        </InteractiveContainer>
        {isFieldRequiredError && requiredErrorMessage}
      </div>
    );
  }

  if (field.type === "dropdown") {
    return (
      <div>
        <ResourcePickerPopover
          placement="bottom-start"
          className="z-[2003]"
          renderTrigger={({ Trigger, triggerRef, isOpen }) => (
            <Trigger
              ref={triggerRef}
              as={InteractiveContainer}
              className={cx(
                "bg-grey-200 flex items-center justify-between gap-1.5 rounded-lg p-3",
                isFieldRequiredError && "bg-tertiary-50"
              )}
            >
              <div className="flex flex-col">
                {label}
                <span className="font-secondary text-dark text-xs leading-[140%]">
                  {field.items.find((item) => item.id === field.selectedId)
                    ?.value ||
                    t({
                      id: "common.select",
                      defaultMessage: "Select",
                    })}
                </span>
              </div>
              <ArrowIcon
                className={cx("transition-transform", isOpen && "rotate-180")}
              />
            </Trigger>
          )}
          onSubmit={undefined}
          onSelect={({ item, onClose }) => {
            field.onChange(item.id);
            onClose();
          }}
          items={field.items.map(({ id, value }) => ({
            id,
            value,
          }))}
          selectionBehavior={{ canToggle: false, mode: "single" }}
          defaultSelectedIds={field.selectedId ? [field.selectedId] : undefined}
          renderLabel={(item) => item.value}
          maxVisibleItems={5}
          hasSearch
        />
        {isFieldRequiredError && requiredErrorMessage}
      </div>
    );
  }

  return null;
};
