import { cx } from "@jugl-web/utils";
import { ElementType, FC, ReactNode } from "react";

export interface DetailBoxProps {
  label: string;
  children?: ReactNode;
  endSlot?: ReactNode;
  className?: string;
  isDisabled?: boolean;
  isDanger?: boolean;
  onClick?: () => void;
}

export const DetailBox: FC<DetailBoxProps> = ({
  label,
  children,
  endSlot,
  className,
  isDisabled,
  isDanger,
  onClick,
  ...props
}) => {
  const isInteractive = !!onClick && !isDisabled;
  const Component: ElementType = isInteractive ? "button" : "div";

  return (
    <Component
      className={cx(
        "flex h-max w-full flex-col items-start gap-[1px] overflow-hidden rounded-lg border-none bg-white p-0 text-left transition-all duration-300 hover:drop-shadow-[0_1px_2px_rgba(0,0,0,0.16)]",
        isInteractive && "cursor-pointer",
        isDisabled && "pointer-events-none",
        className
      )}
      onClick={isInteractive ? onClick : undefined}
      {...props}
    >
      <div
        className={cx(
          "flex h-8 w-full items-center justify-between px-4",
          isDanger ? "bg-tertiary-50" : "bg-gray-100"
        )}
      >
        <span className="text-dark-600 truncate whitespace-nowrap text-xs">
          {label}
        </span>
        {endSlot}
      </div>
      {children && (
        <div
          className={cx(
            "h-8 w-full cursor-pointer transition-all duration-300",
            isDanger
              ? "bg-tertiary-100 hover:bg-[rgba(229,83,83,0.24)]"
              : "bg-[#F2F5F7] hover:bg-[#EEF2F4]"
          )}
        >
          {children}
        </div>
      )}
    </Component>
  );
};
