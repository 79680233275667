import React, { useEffect, useState, useMemo } from "react";
import { cx, useTranslations } from "@jugl-web/utils";
import useEntity from "@web-src/features/app/hooks/useEntity";
import useNavigateToChat from "@web-src/features/chats/hooks/useNavigateToChat";
import { useCallInvitation } from "@web-src/modules/conference/pages/ConferencePage/hooks/useCallInvitation";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { SidebarDrawer } from "@jugl-web/ui-components/web";
import {
  InteractiveContainer,
  LoadingAnimation,
} from "@jugl-web/ui-components/cross-platform";
import PersonalInfoItem from "./PersonalInfoItem";
import { ReactComponent as MessageIcon } from "./assets/message.svg";
import { ReactComponent as CallIcon } from "./assets/call.svg";
import { ReactComponent as PersonIcon } from "./assets/person.svg";
import { ReactComponent as LocationIcon } from "./assets/user-location-line.svg";
import { ReactComponent as LocationPin } from "./assets/location-pin.svg";
import { ReactComponent as Education } from "./assets/education.svg";

export const PeopleInfoSidebar: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  userId: string;
}> = ({ isOpen, onClose, userId }) => {
  const { t } = useTranslations();
  const navigateToChat = useNavigateToChat();
  const { entity } = useEntity();
  const [isDept, setIsDept] = useState<boolean>(false);
  const { usersApi } = useRestApiProvider();

  const { call } = useCallInvitation();

  const { data: profile, isLoading } = usersApi.useGetUserProfileQuery(
    { params: { user_id: userId } },
    { skip: !userId || !entity?.id, refetchOnMountOrArgChange: true }
  );
  const usernameLabel = `${profile?.general.first_name || ""} ${
    profile?.general.last_name || ""
  }`;

  const personalInfoItems = useMemo(() => {
    const personalElements = [
      {
        icon: <Education />,
        primaryText: t({
          id: "people-info-sidebar.education",
          defaultMessage: "Education",
        }),
        educations: profile?.educations,
        secondaryText: "",
      },
      {
        icon: <LocationPin />,
        primaryText: t({
          id: "people-info-sidebar.current-city",
          defaultMessage: "Current City",
        }),
        secondaryText: "",
        contentText:
          profile?.locations[0] &&
          `${profile?.locations[0]?.city}, ${profile?.locations[0]?.state}, ${profile?.locations[0]?.country}`,
        data: profile?.locations,
      },
      {
        icon: <LocationIcon />,
        primaryText: t({
          id: "people-info-sidebar.birth-place",
          defaultMessage: "Native/Birth Place",
        }),
        secondaryText: "",
        contentText:
          profile?.native?.city &&
          `${profile?.native?.city}, ${profile?.native?.state}, ${profile?.native?.country}`,
        hideBorder: true,
      },
    ];
    return personalElements.map((element) => {
      if (element.educations && !element?.educations.length) {
        return null;
      }
      if (!element.contentText && !element.educations) {
        return null;
      }
      return <PersonalInfoItem key={element.primaryText} {...element} />;
    });
  }, [
    t,
    profile?.educations,
    profile?.locations,
    profile?.native?.city,
    profile?.native?.country,
    profile?.native?.state,
  ]);
  const noPersonalInfoItems = useMemo(
    () => personalInfoItems.every((element) => element === null),
    [personalInfoItems]
  );
  useEffect(() => {
    if (profile?.dept) {
      setIsDept(true);
    }
  }, [profile?.dept]);
  const handleCall = (callType: "audio" | "video") => {
    call({ callType, userId });
    onClose();
  };
  if (!profile && !isLoading) {
    return (
      <SidebarDrawer
        isOpen
        onClose={onClose}
        hasBackdrop
        title={t({
          id: "people-info-sidebar.member-info",
          defaultMessage: "Member Info",
        })}
      >
        <SidebarDrawer.Content className="flex items-center justify-center font-bold">
          {t({
            id: "people-info-sidebar.profile-not-found",
            defaultMessage: "Profile not found",
          })}
        </SidebarDrawer.Content>
      </SidebarDrawer>
    );
  }
  if (isLoading) {
    return (
      <SidebarDrawer
        isOpen
        onClose={onClose}
        hasBackdrop
        title={t({
          id: "people-info-sidebar.member-info",
          defaultMessage: "Member Info",
        })}
      >
        <SidebarDrawer.Content className="flex items-center justify-center font-bold">
          <LoadingAnimation />
        </SidebarDrawer.Content>
      </SidebarDrawer>
    );
  }
  return (
    <SidebarDrawer
      isOpen={isOpen}
      onClose={onClose}
      hasBackdrop
      title={t({
        id: "people-info-sidebar.member-info",
        defaultMessage: "Member Info",
      })}
    >
      <SidebarDrawer.Content>
        <div className="flex flex-col justify-between">
          <div className="flex flex-col items-center justify-center p-2.5 px-8 pt-10 pb-2">
            {!profile?.general.img && (
              <div className="flex h-[320px] w-[416px] flex-col items-center justify-center rounded-3xl bg-[linear-gradient(180deg,#CECED3_0%,#ECEFF0_69.27%)]">
                <div className="bg-grey-100 mb-3 flex h-12 w-12 items-center justify-center rounded-full">
                  <PersonIcon />
                </div>
                <span className="text-2xl font-bold uppercase leading-5 text-[#333333]">
                  {usernameLabel}
                </span>
              </div>
            )}
            {profile?.general.img && (
              <img
                className="h-60 w-[336px] overflow-hidden rounded-3xl object-cover"
                src={profile?.general.img}
                alt=""
              />
            )}
            <div className="relative top-[-25px] flex justify-center gap-2.5">
              <InteractiveContainer
                onClick={() => {
                  navigateToChat(userId);
                  onClose();
                }}
                className="bg-primary flex h-[50px] w-[50px] items-center justify-center rounded-full"
              >
                <MessageIcon />
              </InteractiveContainer>
              <>
                <InteractiveContainer
                  onClick={() => handleCall("audio")}
                  className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-white"
                >
                  <CallIcon />
                </InteractiveContainer>
              </>
            </div>
            <span className="text-dark text-center text-[22px] font-semibold">
              {usernameLabel}
            </span>
            <div
              className={cx(
                "mt-2.5 flex items-center justify-center rounded-2xl  border border-solid px-2 py-1 text-sm font-semibold uppercase",
                isDept
                  ? "border-primary-100 text-primary bg-primary-50"
                  : "border-grey text-grey bg-grey-200"
              )}
            >
              {profile?.dept?.title ||
                t({
                  id: "common.no-department",
                  defaultMessage: "No department",
                })}
            </div>
          </div>
          <div className="border-y-[10px] border-solid border-[#F7F7F7]">
            {!noPersonalInfoItems && (
              <div className="px-8 pt-6">
                <span className="text-dark text-sm font-bold uppercase leading-[18px]">
                  {t({
                    id: "common.personal",
                    defaultMessage: "Personal",
                  })}
                </span>
                <div className="flex w-full flex-col items-start pt-1">
                  {personalInfoItems}
                </div>
              </div>
            )}
          </div>
        </div>
      </SidebarDrawer.Content>
    </SidebarDrawer>
  );
};
