import { Alert } from "@jugl-web/ui-components";
import { useToast, useTranslations } from "@jugl-web/utils";
import React, { useCallback } from "react";
import Lottie from "react-lottie";
import { OrderListItemModel } from "@jugl-web/rest-api/orders/models/OrderListItem";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import alertAnimation from "./assets/alert-animation.json";

export const DeleteOrderFormAlert: React.FC<{
  isOpen: boolean;
  orderForm?: OrderListItemModel;
  onClose: () => void;
}> = ({ isOpen, orderForm, onClose }) => {
  const { toast } = useToast({ variant: "web" });
  const { t } = useTranslations();
  const { ordersApi } = useRestApiProvider();
  const [deleteOrderForm, { isLoading }] =
    ordersApi.useDeleteOrderFormMutation();
  const { entityId } = useEntitySelectedProvider();

  const handleDeleteOrderForm = useCallback(async () => {
    if (!orderForm) return;
    const response = await deleteOrderForm({
      entityId,
      formId: orderForm.id,
    });
    if ("data" in response) {
      toast(
        t({
          id: "order-forms-page.form-was-deleted",
          defaultMessage: "Form was deleted",
        })
      );
      onClose();
      return;
    }
    if ("error" in response) {
      toast(
        t({
          id: "feedback.failed-to-delete-order-form",
          defaultMessage: "Failed to delete order form",
        }),
        { variant: "error" }
      );
    }
  }, [onClose, t, entityId, orderForm, deleteOrderForm, toast]);

  return (
    <Alert
      isOpen={isOpen}
      onRequestClose={onClose}
      img={
        <Lottie
          options={{ animationData: alertAnimation }}
          height={70}
          width={70}
        />
      }
      title={t({
        id: "common.confirm-action",
        defaultMessage: "Confirm action",
      })}
      content={t(
        {
          id: "order-forms-page.delete-order-form-confirmation",
          defaultMessage:
            "If you delete form {orderFormName} Clients will no longer be able to submit it",
        },
        {
          orderFormName: (
            <span className="text-primary-800 font-medium">
              {orderForm?.formName}
            </span>
          ),
        }
      )}
      buttons={[
        {
          text: t({
            id: "common.cancel",
            defaultMessage: "Cancel",
          }),
          role: "close",
        },
        {
          text: t({
            id: "common.delete",
            defaultMessage: "Delete",
          }),
          color: "tertiary",
          isDisabled: isLoading,
          onClick: handleDeleteOrderForm,
        },
      ]}
    />
  );
};
