import { InternalTaskCustomField } from "@jugl-web/rest-api/tasks";
import { assert, cx, useResizeObserver } from "@jugl-web/utils";
import partition from "lodash/partition";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTaskFields } from "../../hooks/useTaskFields";
import { AssigneesField } from "./components/AssigneesField";
import { AttachmentsField } from "./components/AttachmentsField";
import { CustomField } from "./components/CustomField";
import { CustomerField } from "./components/CustomerField";
import { DescriptionField } from "./components/DescriptionField";
import { DueDateField } from "./components/DueDateField";
import { LabelField } from "./components/LabelField";
import { MoreInfoField } from "./components/MoreInfoField";
import { PriorityField } from "./components/PriorityField";
import { StatusField } from "./components/StatusField";
import { TimeSpentField } from "./components/TimeSpentField";
import { RecurrenceField } from "./components/RecurrenceField";
import { TaskPropertiesPanelConfig } from "./types";
import { getCustomFieldButtonId } from "./utils";

interface TaskPropertiesPanelProps {
  entityId: string;
  config: TaskPropertiesPanelConfig;
  className?: string;
}

export const TaskPropertiesPanel: FC<TaskPropertiesPanelProps> = ({
  entityId,
  config,
  className,
}) => {
  const [isContainerEmpty, setIsContainerEmpty] = useState(false);

  const { isLoading: areTaskFieldsLoading, isError: hasTaskFieldsError } =
    useTaskFields({ entityId });

  const { ref } = useResizeObserver({
    onResize: useCallback(
      (entry) => setIsContainerEmpty(entry.borderBoxSize[0].blockSize === 0),
      []
    ),
  });

  const [editingCustomFieldId, setEditingCustomFieldId] = useState<
    string | null
  >(null);

  const { customFields } = useTaskFields({ entityId });

  const isCustomFieldInitialized = useCallback(
    (field: InternalTaskCustomField) => {
      const isEditing = editingCustomFieldId === field.id;

      if (isEditing) {
        return true;
      }

      const rawValue = config.customFields?.valuesById[field.id];
      const hasRawValue = !!rawValue;

      if (hasRawValue && field.type === "dropdown") {
        assert(!!field.values, "Dropdown field must have values");

        const hasMatchingOption = field.values.some(
          (option) => option.id === rawValue
        );

        return hasMatchingOption;
      }

      return hasRawValue;
    },
    [config.customFields?.valuesById, editingCustomFieldId]
  );

  const [initializedCustomFields, uninitializedCustomFields] = useMemo(
    () => partition(customFields, isCustomFieldInitialized),
    [customFields, isCustomFieldInitialized]
  );

  useEffect(() => {
    if (editingCustomFieldId) {
      // Wait a bit to ensure the button is rendered
      window.setTimeout(() => {
        const editingCustomFieldButton = document.getElementById(
          getCustomFieldButtonId(editingCustomFieldId)
        );

        editingCustomFieldButton?.focus();
        editingCustomFieldButton?.click();
      }, 10);
    }
  }, [editingCustomFieldId]);

  if (areTaskFieldsLoading || hasTaskFieldsError) {
    return (
      <div
        className={cx("bg-grey-200 h-8 animate-pulse rounded-lg", className)}
      />
    );
  }

  return (
    <div
      ref={ref}
      className={cx(
        "animate-fade-in flex flex-wrap items-center gap-2.5",
        !isContainerEmpty && className
      )}
    >
      {config.description && <DescriptionField {...config.description} />}
      {config.status && <StatusField entityId={entityId} {...config.status} />}
      {config.dueDate && <DueDateField {...config.dueDate} />}
      {config.assignees && (
        <AssigneesField entityId={entityId} {...config.assignees} />
      )}
      {config.label && <LabelField entityId={entityId} {...config.label} />}
      {config.priority && <PriorityField {...config.priority} />}
      {config.customer && (
        <CustomerField entityId={entityId} {...config.customer} />
      )}
      {config.timeSpent && <TimeSpentField {...config.timeSpent} />}
      {config.attachments && <AttachmentsField {...config.attachments} />}
      {config.recurrence && <RecurrenceField {...config.recurrence} />}
      {initializedCustomFields.map((field) => {
        const rawValue = config.customFields?.valuesById[field.id] || "";

        return (
          <CustomField
            key={field.id}
            field={field}
            rawValue={rawValue}
            isReadonly={config.customFields?.isReadonly}
            onChange={(value) =>
              config.customFields?.onFieldChange?.(field.id, value)
            }
            onFinishEditing={() => setEditingCustomFieldId(null)}
          />
        );
      })}
      {config.customFields &&
        !config.customFields.isReadonly &&
        uninitializedCustomFields.length > 0 && (
          <MoreInfoField
            customFields={uninitializedCustomFields}
            onStartEditingField={setEditingCustomFieldId}
          />
        )}
    </div>
  );
};
