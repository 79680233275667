import { useTaskFiltering } from "@jugl-web/domain-resources/tasks/components/TaskFilteringProvider";
import { useTaskSorting } from "@jugl-web/domain-resources/tasks/components/TaskSortingProvider";
import { useTasks } from "@jugl-web/domain-resources/tasks/hooks/useTasks";
import { PreviewTask } from "@jugl-web/rest-api/tasks";
import { useTasksPageContext } from "@web-src/features/tasks/TasksPageContext";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import {
  eachDayOfInterval,
  endOfDay,
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import { FC } from "react";
import { useWeekdays } from "../../hooks/useWeekdays";
import { CalendarMonthDayCell } from "../CalendarMonthDayCell";
import { CalendarViewLayout } from "../CalendarViewLayout";

export const CalendarMonthView: FC = () => {
  const { taskCalendarDate, taskListMode, openFutureTaskDialog } =
    useTasksPageContext();

  const { searchQuery, filters } = useTaskFiltering();
  const { sorting } = useTaskSorting();

  const { entityId } = useEntitySelectedProvider();

  const { navigateToPage } = useNavigation();

  const { selectTasksByDueDateInterval } = useTasks({
    entityId,
    mode: taskListMode,
    searchQuery,
    filters,
    sorting,
    showFuture: true,
    forceShowCompleted: true,
  });

  const weekdays = useWeekdays();

  const startDate = startOfWeek(startOfMonth(taskCalendarDate), {
    weekStartsOn: 1,
  });
  const endDate = endOfWeek(endOfMonth(taskCalendarDate), { weekStartsOn: 1 });
  const calendarDays = eachDayOfInterval({ start: startDate, end: endDate });

  const handleTaskClick = (task: PreviewTask) => {
    if (task._future_task) {
      openFutureTaskDialog(task);
      return;
    }

    navigateToPage("tasksDetails", { taskId: task.id });
  };

  return (
    <div className="animate-fade-in flex w-full flex-col">
      <div className="mb-1 grid shrink-0 grid-cols-7 gap-0.5">
        {weekdays.map((weekday) => (
          <CalendarViewLayout.ColumnHeader key={weekday.label}>
            <span className="font-secondary text-sm">{weekday.label}</span>
          </CalendarViewLayout.ColumnHeader>
        ))}
      </div>
      <div className="grid min-h-0 flex-1 auto-rows-fr grid-cols-7 gap-0.5">
        {calendarDays.map((day, index) => (
          <CalendarMonthDayCell
            key={day.toISOString()}
            cellIndex={index}
            cellDate={day}
            calendarDate={taskCalendarDate}
            tasks={selectTasksByDueDateInterval(day, endOfDay(day))}
            onTaskClick={handleTaskClick}
          />
        ))}
      </div>
    </div>
  );
};
