import { RefObject, useCallback, useEffect } from "react";

export const useAutoResizedTextarea = (ref: RefObject<HTMLTextAreaElement>) => {
  const resize = useCallback(() => {
    if (ref.current) {
      const computedStyle = window.getComputedStyle(ref.current);
      const borderTop = parseFloat(computedStyle.borderTopWidth);
      const borderBottom = parseFloat(computedStyle.borderBottomWidth);
      ref.current.style.height = "auto";
      ref.current.style.height = `${
        ref.current.scrollHeight + borderTop + borderBottom
      }px`;
    }
  }, [ref]);

  // Resizes textarea on mount in case it is initially filled with value.
  // Timeout is for a convenience to cover a case when data initialization
  // doesn't happen on the "first tick" (e.g. when happens in `useEffect`)
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      resize();
    }, 10);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [resize]);

  return {
    resize,
    textareaProps: { onInput: resize },
  };
};
