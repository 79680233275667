import React, { useContext, useEffect, useMemo, useState } from "react";
import { IAgoraRTCRemoteUser, useRemoteUserTrack } from "agora-rtc-react";
import useDebounce from "@web-src/hooks/useDebounce";
import { CallsContext } from "../../../../providers/Calls";
import UserCallWindow from "../UserCallWindow";
import { useCallsSettings } from "../../../../hooks";

const RemoteUserCallWindow: React.FC<{
  user: IAgoraRTCRemoteUser;
  minimized?: boolean;
  isCall?: boolean;
  duration?: string;
  containerClassName?: string;
  profileContainerClassName?: string;
  showAvatarOnly?: boolean;
  hide?: boolean;
  screenSharingId?: string;
  isFullscreen?: boolean;
  gridAvatarSize?: number;
}> = ({
  user,
  minimized,
  isCall,
  duration,
  containerClassName,
  profileContainerClassName,
  showAvatarOnly,
  hide,
  screenSharingId,
  isFullscreen,
  gridAvatarSize,
}) => {
  const { client, playbackDevices } = useContext(CallsContext);
  const { getCallsSettings } = useCallsSettings();
  const [isSpeaking, setIsSpeaking] = useState(false);
  const id = useMemo(() => (Math.random() * 1e16).toFixed(), []);
  const storedSettings = getCallsSettings();
  const debouncedIsSpeaking = useDebounce(isSpeaking, 1000);

  const videoTrack = useRemoteUserTrack(user, "video");
  const audioTrack = useRemoteUserTrack(user, "audio");

  useEffect(() => {
    const userInfoUpdateCallback = (
      uid: string,
      msg:
        | "mute-audio"
        | "mute-video"
        | "enable-local-video"
        | "unmute-audio"
        | "unmute-video"
        | "disable-local-video"
    ) => {
      if (uid !== user.uid) {
        return;
      }
      switch (msg) {
        case "enable-local-video":
        case "unmute-video":
          break;
        case "disable-local-video":
        case "mute-video":
          videoTrack.track?.stop();
          break;
        case "unmute-audio":
          break;
        case "mute-audio":
          audioTrack.track?.stop();
          break;
        default:
      }
    };

    client?.on("user-info-updated", userInfoUpdateCallback);
    return () => {
      client?.off("user-info-updated", userInfoUpdateCallback);
    };
  }, [audioTrack.track, client, id, user, videoTrack.track]);

  useEffect(() => {
    // TODO: optimize
    if (videoTrack && !videoTrack.track?.isPlaying) {
      videoTrack.track?.play(id, { fit: "contain" });
    }
  }, [id, minimized, user.uid, videoTrack]);

  useEffect(() => {
    // TODO: optimize
    const callsSettings = storedSettings;
    const isPlaybackPresent = playbackDevices?.find(
      (device) => device.deviceId === callsSettings?.playback_id
    );
    if (callsSettings && isPlaybackPresent) {
      audioTrack.track?.setPlaybackDevice(callsSettings.playback_id);
    }
    if (audioTrack && !audioTrack.track?.isPlaying) {
      audioTrack.track?.play();
    }
  }, [audioTrack, id, playbackDevices, storedSettings, user.uid]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newVolumeLevel = audioTrack.track?.getVolumeLevel() || 0;
      const newTalkingState = newVolumeLevel > 0.25;
      if (isSpeaking !== newTalkingState) setIsSpeaking(newTalkingState);
    }, 150);

    return () => clearInterval(intervalId);
  }, [user, isSpeaking, audioTrack.track]);

  useEffect(
    () => () => {
      videoTrack.track?.stop();
      audioTrack.track?.stop();
    },
    [audioTrack.track, user.uid, videoTrack.track]
  );

  if (hide) {
    return null;
  }
  return (
    <UserCallWindow
      isSpeaking={debouncedIsSpeaking}
      userId={user.uid}
      isAudioMuted={!audioTrack.track}
      isVideoMuted={!videoTrack.track}
      playerId={id}
      isCall={isCall}
      duration={duration}
      containerClassName={containerClassName}
      profileContainerClassName={profileContainerClassName}
      showAvatarOnly={showAvatarOnly}
      minimized={minimized}
      screenSharingId={screenSharingId}
      isFullscreen={isFullscreen || false}
      gridAvatarSize={gridAvatarSize}
    />
  );
};

export default RemoteUserCallWindow;
