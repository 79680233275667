import { Avatar } from "@jugl-web/ui-components/cross-platform/Avatar";
import { cx, isoToLocalDate } from "@jugl-web/utils";
import format from "date-fns/format";
import { FC, useMemo } from "react";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import { useUserGeneralProfile } from "../../../users/hooks/useUserGeneralProfile";

export interface TaskCreatorProps {
  entityId: string;
  userId: string;
  createdAt: string;
  className?: string;
}

export const TaskCreator: FC<TaskCreatorProps> = ({
  entityId,
  userId,
  createdAt,
  className,
}) => {
  const { safeProfile } = useUserGeneralProfile({ entityId, userId });
  const { dateLocale } = useLanguage();

  const [createdAtTime, createdAtDate] = useMemo(
    () => [
      format(isoToLocalDate(createdAt), "h:mm a", { locale: dateLocale }),
      format(isoToLocalDate(createdAt), "MMM d, yy", { locale: dateLocale }),
    ],
    [createdAt, dateLocale]
  );

  if (userId === "00000000-0000-0000-0000-000000000000") {
    return (
      <div className="inline-block rounded bg-[#F2F2F4] px-2 py-1 text-xs text-[#828282]">
        Created by order form
      </div>
    );
  }

  return (
    <div className={cx("flex items-center gap-2", className)}>
      <Avatar
        size="xs"
        username={safeProfile.displayName}
        imageUrl={safeProfile.avatar}
      />
      <span className="text-dark text-xs">{safeProfile.displayName}</span>
      <span className="text-grey-background text-xs">
        {createdAtTime} | {createdAtDate}
      </span>
    </div>
  );
};
