import { DealMirrorPromoCodeInfo } from "../models/common-types/PromoCodeInfo";

export const getDealMirrorPromoCodePrice = (
  promoCode: DealMirrorPromoCodeInfo,
  currency: string
) => {
  const priceDigits =
    currency in promoCode.price
      ? promoCode.price[currency as keyof typeof promoCode.price]
      : promoCode.price.usd;
  if (!priceDigits) {
    return null;
  }
  return priceDigits * 100;
};
