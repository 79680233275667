import { PreviewTask, TaskDefaultStatus } from "@jugl-web/rest-api/tasks";
import {
  PILL_BASE_BG_COLOR,
  PILL_BASE_TEXT_COLOR,
} from "@jugl-web/ui-components/cross-platform/tasks/TaskCardPill";
import { convertMillisecondsToTimeComponents } from "@jugl-web/ui-components/cross-platform/tasks/TimeSpentPicker/utils";
import { useTranslations } from "@jugl-web/utils";
import { isoToLocalDate } from "@jugl-web/utils/date-time/isoToLocalDate";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import { hexColorWithTransparency } from "@jugl-web/utils/utils/hexColorWithTransparency";
import { useCallback } from "react";
import { useTaskFields } from "../../hooks/useTaskFields";
import { useTaskStatuses } from "../../hooks/useTaskStatuses";
import { useTaskUnreadIndicator } from "../../hooks/useTaskUnreadIndicator";
import { isTaskOverdue } from "../../utils";
import { TaskCardField, TaskCardProps } from "./TaskCard";
import { adaptCustomFieldsToTaskCardFields } from "./utils";

type AdaptedTaskCardProps = Omit<
  TaskCardProps,
  "highlightedText" | "onClick" | "className"
>;

interface UseTaskCardOptions {
  entityId: string;
  customerId?: string;
}

export const useTaskCardAdapter = ({
  entityId,
  customerId,
}: UseTaskCardOptions) => {
  const { getCustomFieldById, getLabelById } = useTaskFields({ entityId });
  const { getStatusDetailsById } = useTaskStatuses({ entityId });
  const { isTaskUnread } = useTaskUnreadIndicator({ entityId, customerId });
  const { t } = useTranslations();
  const { dateLocale } = useLanguage();

  const getTaskCardProps = useCallback(
    (
      task: PreviewTask,
      overrides?: Partial<AdaptedTaskCardProps>
    ): AdaptedTaskCardProps => {
      const statusDetails = getStatusDetailsById(task.status);

      const statusField: TaskCardField = {
        id: "status",
        text: t(
          {
            id: "tasks-page.status-with-label",
            defaultMessage: "Status: {label}",
          },
          { label: statusDetails.label }
        ),
        textColor: PILL_BASE_TEXT_COLOR,
        backgroundColor: PILL_BASE_BG_COLOR,
      };

      const fields: TaskCardField[] = [statusField];

      if (task.label_id) {
        const taskLabel = getLabelById(task.label_id);

        if (taskLabel) {
          const labelField: TaskCardField = {
            id: "label",
            text: t(
              {
                id: "tasks-page.label-with-text",
                defaultMessage: "Label: {text}",
              },
              { text: taskLabel.text }
            ),
            textColor: taskLabel.color,
            backgroundColor: hexColorWithTransparency(taskLabel.color, 12),
          };

          fields.push(labelField);
        }
      }

      fields.push(
        ...adaptCustomFieldsToTaskCardFields(
          task.custom_fields,
          getCustomFieldById,
          dateLocale
        )
      );

      const isFutureTask = !!task._future_task;
      return {
        taskId: task.id,
        orderId: task.order_id || undefined,
        isUnread: isFutureTask ? false : isTaskUnread(task.id),
        title: task.name || "",
        dueDate: task.due_at ? isoToLocalDate(task.due_at) : null,
        isOverdue: isTaskOverdue(task),
        isCompleted: task.status === TaskDefaultStatus.completed,
        checklist: {
          completedItemsCount: task.chklist_completed,
          totalItemsCount: task.chklist_total,
          isOverdue: task.chklist_overdue,
        },
        timeSpent: convertMillisecondsToTimeComponents(task.duration),
        fields,
        recurrence: task.recurrence
          ? {
              isFutureTask,
              isPaused: task.recurrence.enabled === false,
            }
          : undefined,

        ...overrides,
      };
    },
    [
      getCustomFieldById,
      getLabelById,
      getStatusDetailsById,
      isTaskUnread,
      t,
      dateLocale,
    ]
  );

  return { getTaskCardProps };
};
