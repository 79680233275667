import React from "react";
import { getFileNameFromUrl } from "@jugl-web/utils/utils/files";
import { ReactComponent as FileIcon } from "./icons/file.svg";

export interface FileItemProps {
  url: string;
  onFileClick?: () => void;
}

const FileItem: React.FC<FileItemProps> = ({ url, onFileClick }) => {
  const name = getFileNameFromUrl(url);

  return (
    <div
      className="bg-primary-50 border-primary-500/50 flex items-center gap-[10px] rounded-[10px] border-[1px] border-solid p-[10px]"
      onClick={onFileClick}
    >
      <div className="flex items-center justify-center rounded-full bg-white p-2">
        <FileIcon />
      </div>
      <div className="truncate pr-5">
        <a
          href={url}
          target="_blank"
          rel="noreferrer"
          className="text-primary-500 no-underline"
        >
          {name}
        </a>
      </div>
    </div>
  );
};

export default FileItem;
