import { cx } from "@jugl-web/utils";
import { ReactNode } from "react";
import { Range, getTrackBackground } from "react-range";

export interface SliderProps {
  value: number;
  min: number;
  max: number;
  step: number;
  isDisabled?: boolean;
  className?: string;
  onChange: (value: number) => void;
  renderTooltipValue?: (value: number) => ReactNode;
}

export const Slider = ({
  value,
  min,
  max,
  step,
  isDisabled,
  className,
  onChange,
  renderTooltipValue,
}: SliderProps) => {
  const isTooltipShown = !isDisabled && !!renderTooltipValue;

  const handleChange = (values: number[]) => {
    if (!isDisabled) {
      onChange(values[0]);
    }
  };

  return (
    <div className={className}>
      <Range
        values={[value]}
        min={min}
        max={max}
        step={step}
        onChange={handleChange}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            className={cx("h-1 w-full rounded-lg", isDisabled && "bg-grey-500")}
            style={
              !isDisabled
                ? {
                    ...props.style,
                    background: getTrackBackground({
                      values: [value],
                      colors: ["#2196F3", "#C5C5CA"],
                      min,
                      max,
                    }),
                  }
                : undefined
            }
          >
            {children}
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            className={cx(
              "relative h-[23px] w-[23px] rounded-full border-[3px] border-solid border-white outline-none",
              isDisabled ? "bg-grey-500" : "bg-primary"
            )}
            {...props}
            style={{
              ...props.style,
              cursor: isDisabled ? "not-allowed" : props.style.cursor,
            }}
          >
            {isTooltipShown && (
              <div className="bg-primary-50 absolute left-1/2 -top-[11px] w-max -translate-x-1/2 -translate-y-full rounded-lg py-[6px] px-2">
                <span className="text-primary-700 text-xs font-medium leading-[14px]">
                  {renderTooltipValue(value)}
                </span>
                <div className="border-t-primary-50 absolute top-full left-1/2 -ml-[7px] border-[7px] border-solid border-r-transparent border-b-transparent border-l-transparent" />
              </div>
            )}
          </div>
        )}
      />
      <div className="align-items mt-[9px] flex justify-between">
        <span className="text-grey text-xs leading-[14px]">{min}</span>
        <span className="text-grey text-xs leading-[14px]">{max}</span>
      </div>
    </div>
  );
};
