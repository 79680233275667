import { useEffect, useRef, EffectCallback, DependencyList } from "react";

export const useUpdateEffect = (
  effect: EffectCallback,
  dependencies: DependencyList
) => {
  const isMountedRef = useRef(false);

  useEffect(() => {
    if (!isMountedRef.current) {
      isMountedRef.current = true;
      return undefined;
    }

    return effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};
