import { taskChecklistNameValidator } from "@jugl-web/domain-resources/tasks";
import { Checkbox } from "@jugl-web/ui-components/cross-platform";
import { cx, useTranslations } from "@jugl-web/utils";
import { Linkify } from "@jugl-web/utils/utils/Linkify";
import { Mentionify } from "@jugl-web/utils/utils/Mentionify";
import { FC, useLayoutEffect, useRef, useState } from "react";
import { ChecklistTableCellComponentProps } from "../../types";
import { ChecklistItemNumber } from "../ChecklistItemNumber";
import { TaskTableLayout } from "../TaskTableLayout";
import { TextCellEditor, TextCellEditorHandle } from "../TextCellEditor";

export const ChecklistItemNameCell: FC<ChecklistTableCellComponentProps> = ({
  item,
  index,
  specificOrderCompletionState,
  mentions,
  permissions,
  isFutureTask,
  cellProps,
  onUpdate,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const $textCellEditorRef = useRef<TextCellEditorHandle | null>(null);

  const { t } = useTranslations();

  const handleCellClick = () => {
    if (!isEditing) {
      setIsEditing(true);
      return;
    }

    $textCellEditorRef.current?.focus();
  };

  const handleSubmit = (richText: string) => {
    onUpdate({ name: richText });
    setIsEditing(false);
  };

  useLayoutEffect(() => {
    if (isEditing) {
      $textCellEditorRef.current?.setContent(item.name);
    }
  }, [isEditing, item.name]);

  return (
    <TaskTableLayout.Cell
      isFocused={isEditing}
      isHoverable
      isDisabled={!permissions.canManageChecklistItem || isFutureTask}
      className={cx("gap-2 overflow-hidden", isEditing && "cursor-text")}
      onClick={handleCellClick}
      {...cellProps}
    >
      {!isFutureTask && (
        <Checkbox
          isChecked={item.is_completed}
          isDisabled={
            specificOrderCompletionState.isDisabled ||
            !permissions.canCompleteChecklistItem
          }
          className="mr-0.5 h-[18px] w-[18px]"
          onClick={(event) => event.stopPropagation()}
          onChange={() => onUpdate({ is_completed: !item.is_completed })}
        />
      )}
      <ChecklistItemNumber
        index={index}
        isHighlighted={specificOrderCompletionState.isCurrent}
      />
      {isEditing ? (
        <TextCellEditor
          ref={$textCellEditorRef}
          mentions={mentions}
          placeholder={t({
            id: "form-controls.task-checklist.placeholder",
            defaultMessage: "Enter checklist title",
          })}
          validator={taskChecklistNameValidator}
          onSubmit={handleSubmit}
          onCancel={() => setIsEditing(false)}
        />
      ) : (
        <TaskTableLayout.CellText
          isFaded={
            isFutureTask
              ? false
              : item.is_completed || specificOrderCompletionState.isDisabled
          }
          isCrossedOut={isFutureTask ? false : item.is_completed}
        >
          <Linkify>
            <Mentionify>{item.name}</Mentionify>
          </Linkify>
        </TaskTableLayout.CellText>
      )}
    </TaskTableLayout.Cell>
  );
};
