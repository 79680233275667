import { Radio, RadioGroup } from "@headlessui/react";
import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import { TextField } from "@jugl-web/ui-components/cross-platform/TextField";
import { assert, cx, useTranslations } from "@jugl-web/utils";
import { FC, FormEvent, useState } from "react";
import { TASK_LABEL_COLORS } from "../../consts";
import { useTaskFields } from "../../hooks/useTaskFields";
import { taskLabelValidator } from "../../validators";
import { ManageTaskLabelFormProps } from "./types";

export const ManageTaskLabelForm: FC<ManageTaskLabelFormProps> = ({
  entityId,
  editingLabel,
  nameInputRef,
  onClose,
}) => {
  const isEditing = !!editingLabel;
  const { t } = useTranslations();

  const {
    createLabel,
    updateLabel,
    deleteLabel,
    isCreatingLabel,
    isUpdatingLabel,
    isDeletingLabel,
  } = useTaskFields({ entityId });

  const [name, setName] = useState(isEditing ? editingLabel.text : "");

  const [activeColorIndex, setActiveColorIndex] = useState(() => {
    if (isEditing) {
      const index = TASK_LABEL_COLORS.findIndex(
        (color) => color === editingLabel.color
      );

      if (index > -1) {
        return index;
      }
    }

    return 0;
  });

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Needed to stop bubbling the submit event up to the parent form
    event.stopPropagation();

    if (isEditing) {
      const response = await updateLabel({
        ...editingLabel,
        text: name,
        color: TASK_LABEL_COLORS[activeColorIndex],
      });

      if ("data" in response) {
        onClose();
      }

      return;
    }

    try {
      await createLabel({
        text: name,
        color: TASK_LABEL_COLORS[activeColorIndex],
      });
      onClose();
    } catch {
      // do nothing
    }
  };

  const handleDelete = async () => {
    assert(isEditing);

    const response = await deleteLabel(editingLabel.id);

    if (!("data" in response)) {
      return;
    }

    onClose();
  };

  const isLoading = isCreatingLabel || isUpdatingLabel || isDeletingLabel;

  return (
    <form onSubmit={handleSubmit}>
      <div className="px-4">
        <TextField
          variant="underline"
          ref={nameInputRef}
          isRequired
          isFullWidth
          value={name}
          onChange={(event) => setName(event.target.value)}
          label={t({
            id: "form-controls.name.label",
            defaultMessage: "Name",
          })}
          placeholder={t({
            id: "form-controls.label-name.placeholder",
            defaultMessage: "Enter label name",
          })}
        />
      </div>
      <RadioGroup
        as="div"
        value={activeColorIndex}
        onChange={setActiveColorIndex}
        className="jugl__hidden-scrollbar mt-2 mb-4 flex items-center gap-4 overflow-x-auto p-4"
      >
        {TASK_LABEL_COLORS.map((color, index) => {
          const isActive = index === activeColorIndex;

          return (
            <Radio
              key={+index}
              value={index}
              className={cx(
                "h-[38px] w-[38px] shrink-0 cursor-pointer rounded-full outline-none",
                "ring-2 ring-offset-2 ring-offset-white",
                isActive ? "ring-[#38393A]" : "ring-[#E8E8E8]"
              )}
              style={{ backgroundColor: color }}
            />
          );
        })}
      </RadioGroup>
      <div className="flex items-center gap-4 px-4">
        {isEditing && (
          <Button
            variant="outlined"
            isDisabled={isLoading}
            uppercase
            fullWidth
            onClick={handleDelete}
          >
            {t({
              id: "tasks-page.delete-label",
              defaultMessage: "Delete label",
            })}
          </Button>
        )}
        <Button
          type="submit"
          isDisabled={!taskLabelValidator(name) || isLoading}
          uppercase
          fullWidth
        >
          {isEditing
            ? t({
                id: "common.save-changes",
                defaultMessage: "Save changes",
              })
            : t({
                id: "tasks-page.create-label",
                defaultMessage: "Create label",
              })}
        </Button>
      </div>
    </form>
  );
};
