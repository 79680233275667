import React, { useState } from "react";
import { cx } from "@jugl-web/utils";

export const HoveredComponent = ({
  className,
  children,
  hoverComponent,
}: {
  className?: string;
  children: React.ReactNode;
  hoverComponent: React.ReactNode;
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={cx("relative", className)}
    >
      {children}
      {isHovered && hoverComponent}
    </div>
  );
};
