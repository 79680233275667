import { useRestApiProvider } from "@jugl-web/rest-api";
import { DetailedTask } from "@jugl-web/rest-api/tasks";
import { useAppVariant, useToast, useTranslations } from "@jugl-web/utils";
import { useCallback } from "react";
import { taskChecklistItemAdapters } from "../components/TaskChecklist";
import { TaskFormState } from "./useTaskFormState";

interface UseCreateTaskOptions {
  entityId: string;
  isInTeamTasksContext?: boolean;
  isInCustomerTasksContext?: boolean;
  onNavigate: (taskId: string) => void;
}

export type CreateTaskFn = (formState: TaskFormState) => Promise<DetailedTask>;

export const useCreateTask = ({
  entityId,
  isInTeamTasksContext,
  isInCustomerTasksContext,
  onNavigate,
}: UseCreateTaskOptions) => {
  const { tasksApi } = useRestApiProvider();
  const [createTaskMutation, { isLoading }] = tasksApi.useCreateTaskMutation();

  const { variant } = useAppVariant();
  const { toast, closeToast } = useToast({ variant });

  const { t } = useTranslations();

  const showTaskCreationToast = useCallback(
    (taskId: string, customMessage?: string) => {
      const toastId = toast(
        customMessage ||
          t({
            id: "feedback.task-created-tap-to-open",
            defaultMessage: "Task has been created. Tap to open it",
          }),
        {
          SnackbarProps: {
            style: {
              cursor: "pointer",
              userSelect: "none",
            },
            onClick: () => {
              onNavigate(taskId);
              closeToast(toastId);
            },
          },
        }
      );
    },
    [closeToast, onNavigate, t, toast]
  );

  const createTask = useCallback<CreateTaskFn>(
    (formState) =>
      new Promise<DetailedTask>((resolve, reject) => {
        createTaskMutation({
          entityId,
          task: {
            name: formState.title,
            due_at: formState.dueDate
              ? formState.dueDate.date.toISOString()
              : null,
            priority: formState.priority,
            checklist: formState.checklistItems.map(
              taskChecklistItemAdapters.toBackendModel
            ),
            reminder: formState.dueDate?.reminderExecutionTime
              ? {
                  next_execution:
                    formState.dueDate.reminderExecutionTime.toISOString(),
                }
              : null,
            desc: formState.description,
            assignees: formState.assigneeIds,
            label_id: formState.labelId,
            status: formState.statusId,
            cust_id: formState.customer?.id || null,
            can_assignee_edit: formState.allowAssigneesEdit,
            custom_fields: formState.customFields,
            has_chklist_chk: formState.completeChecklistInOrder,
            is_self: formState.isPrivate,
            recurrence: formState.recurrence,
          },
          isInTeamTasksContext,
          isInCustomerTasksContext,
        }).then((response) => {
          if ("data" in response) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        });
      }),
    [
      createTaskMutation,
      entityId,
      isInTeamTasksContext,
      isInCustomerTasksContext,
    ]
  );

  return {
    createTask,
    showTaskCreationToast,
    isLoading,
  };
};
