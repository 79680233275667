import { LiveUpdateEvent } from "@jugl-web/domain-resources/live-updates";
import { TaskLiveUpdatesProvider } from "@jugl-web/domain-resources/tasks/components/TaskLiveUpdatesProvider";
import { cx, usePrevious } from "@jugl-web/utils";
import { LiveUpdatesListener } from "@web-src/components/LiveUpdatesListener";
import { useApi } from "@web-src/features/api/useApi";
import { selectIsSideBarVisible } from "@web-src/features/app/appSlice";
import { HomeSideBar } from "@web-src/features/app/components/HomeSideBar";
import PrivateRoute from "@web-src/features/app/components/PrivateRoute";
import PublicRoute from "@web-src/features/app/components/PublicRoute";
import { PageRoute, TabRoute } from "@web-src/features/app/types";
import {
  useClaimOauthTokenMutation,
  useUpdateTokenMutation,
} from "@web-src/features/auth/authApi";
import {
  proceedLogin,
  selectAuthState,
  selectUserId,
} from "@web-src/features/auth/authSlice";
import ActiveCallWrapper from "@web-src/modules/conference/pages/ConferencePage/components/ActiveCallWrapper";
import { useSnackbar } from "notistack";
import React, {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useMatch,
} from "react-router-dom";
import { useNetworkState } from "react-use";
import { Subject } from "rxjs";
import { ChatBot } from "@web-src/features/chats/components/ChatBot";
import { UnreadIndicatorsProvider } from "@web-src/components/UnreadIndicatorsProvider";
import IncomingCallBanner from "@web-src/components/IncomingCallBanner";
import { useAppDispatch } from "@web-src/store";
import { useFCM } from "@web-src/modules/notifications/providers/FCMProvider";
import { useEntityProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { isAppInDevMode } from "@web-src/modules/common/utils/isAppInDevMode";
import { rootNavigationPageConfig } from "@web-src/modules/navigation/root-config";
import { getEntityPagePath } from "@web-src/modules/navigation/utils/getEntityPagePath";
import { DownloadMobileAppCTA } from "@web-src/modules/common/components/DownloadMobileAppCTA";
import { WelcomeToEntityAlert } from "@web-src/modules/entities/components/WelcomeToEntityAlert";

const DebugSubscriptionPage = lazy(
  () => import("@web-src/modules/debug/pages/DebugSubscriptionPage")
);
const DebugUserSettingsPage = lazy(
  () => import("@web-src/modules/debug/pages/DebugUserSettingsPage")
);
const DebugMobileAppPage = lazy(
  () => import("@web-src/modules/debug/pages/DebugMobileAppPage")
);

// TODO: to remove
const TokenLogin: React.FC = () => {
  const dispatch = useAppDispatch();
  const [message, setMessage] = useState<string>("Logging in...");
  const [claimOAuthToken] = useClaimOauthTokenMutation();
  const login = useCallback(async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const tkn = urlParams.get("tkn");
    if (!tkn) {
      setMessage("No token in get parameters");
      return;
    }
    try {
      const response = await claimOAuthToken({ tkn });
      if (!("data" in response) || !response.data) {
        setMessage("Error");
        return;
      }
      const { token, refreshToken } = response.data;
      if (!token) {
        setMessage("No x-auth-joiint return");
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dispatch(proceedLogin({ token, refreshToken: refreshToken! }));
    } catch (error) {
      try {
        setMessage(String(error));
      } catch {
        setMessage("Unknown error");
      }
    }
  }, [claimOAuthToken, dispatch]);

  useEffect(() => {
    login();
  }, [login]);

  return <div>{message}</div>;
};
// END TODO: to remove

const TransitionRouter = function TransitionRouter() {
  const location = useLocation();
  return (
    <Suspense>
      <Routes location={location} key={location.pathname}>
        {Object.values(rootNavigationPageConfig).map((item, index) => (
          <Route
            key={+index}
            path={
              item.isPrivate && !item.isEntityOptional
                ? getEntityPagePath(item.path)
                : item.path
            }
            element={
              item.isPrivate ? (
                <PrivateRoute
                  component={item.element}
                  entityRequired={!item.isEntityOptional}
                  profileRequired={!item.isProfileOptional}
                  activeSubscriptionRequired={
                    !item.isActiveSubscriptionOptional
                  }
                  adminRequired={item.isAdminRequired}
                  subscriptionModuleRequired={item.subscriptionModuleRequired}
                />
              ) : (
                <PublicRoute component={item.element} />
              )
            }
          />
        ))}

        <Route
          path="/jugl-callback"
          element={<PublicRoute component={<TokenLogin />} />}
        />
        {isAppInDevMode() && (
          <>
            <Route
              path="/:entityId/debug/subscription"
              element={<PrivateRoute component={<DebugSubscriptionPage />} />}
            />
            <Route
              path="/:entityId/debug/user-settings"
              element={<PrivateRoute component={<DebugUserSettingsPage />} />}
            />
            <Route
              path="/:entityId/debug/mobile-app"
              element={<PrivateRoute component={<DebugMobileAppPage />} />}
            />
          </>
        )}
        <Route path="*" element={<Navigate to={`/${PageRoute.login}`} />} />
      </Routes>
    </Suspense>
  );
};

const liveUpdateEvents$ = new Subject<LiveUpdateEvent>();

const App: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { isAuthenticated } = useSelector(selectAuthState);
  const meId = useSelector(selectUserId);
  const { showWriteRestrictedAlert, subscriptionInfo, entity } =
    useEntityProvider();

  useApi({
    onError: (error) => {
      if (!subscriptionInfo?.planIsActive) {
        return;
      }
      if (error.data?.error === "write_restricted") {
        showWriteRestrictedAlert();
        return;
      }
      enqueueSnackbar(error.message, {
        variant: "error",
        preventDuplicate: error.preventDuplicate,
      });
    },
  });
  const isSideBarVisible = useSelector(selectIsSideBarVisible);

  const [updateToken] = useUpdateTokenMutation();
  const { token } = useFCM();

  useEffect(() => {
    if (!token || !isAuthenticated) {
      return;
    }
    updateToken(token);
  }, [updateToken, token, isAuthenticated]);

  const { online } = useNetworkState();
  const previousOnline = usePrevious(online);
  useEffect(() => {
    // make sure app will be reloaded after user wakes up
    const wakeupWorker = new Worker("detect-wakeup.js");
    wakeupWorker.onmessage = (ev) => {
      if (ev?.data === "app:wakeup") {
        window.location.reload();
      }
    };
  }, []);
  useEffect(() => {
    if (previousOnline !== undefined && !previousOnline && online) {
      window.location.reload();
    }
  }, [online, previousOnline]);

  const $authenticatedContent = useMemo(
    () =>
      entity && isSideBarVisible && subscriptionInfo?.planIsActive ? (
        <>
          <ActiveCallWrapper />
          <div className="fixed top-0 left-0 flex  h-[100vh] w-[100vw] min-w-0">
            <HomeSideBar />
            <div className="h-full w-full min-w-0 grow overflow-y-auto">
              <TransitionRouter />
            </div>
          </div>
          <WelcomeToEntityAlert />
        </>
      ) : (
        <TransitionRouter />
      ),
    [entity, isSideBarVisible, subscriptionInfo?.planIsActive]
  );

  const taskDetailsPageMatch = useMatch(
    `/:entityId/${TabRoute.tasks}/details/:taskId`
  );

  const isInTaskDetailsView = !!taskDetailsPageMatch;

  return (
    <>
      {!online && (
        <div className="bg-tertiary-500 fixed top-10 left-[50%] z-50 -translate-x-[50%] rounded py-5 px-12 text-center font-bold text-white">
          No internet connection, please reconnect to use Jugl
        </div>
      )}
      <DownloadMobileAppCTA />
      <div className={cx({ "opacity-50": !online })}>
        {isAuthenticated ? (
          <>
            <IncomingCallBanner />
            <LiveUpdatesListener events$={liveUpdateEvents$} />
            <UnreadIndicatorsProvider>
              <TaskLiveUpdatesProvider
                events$={liveUpdateEvents$}
                meId={meId}
                isInTaskDetailsView={isInTaskDetailsView}
              >
                {$authenticatedContent}
              </TaskLiveUpdatesProvider>
            </UnreadIndicatorsProvider>
            <ChatBot />
          </>
        ) : (
          <TransitionRouter />
        )}
      </div>
    </>
  );
};

export default App;
