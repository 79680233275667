import { useTranslations } from "@jugl-web/utils";
import { TabsLayout } from "@web-src/modules/common/components/TabsLayout";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { FC, PropsWithChildren } from "react";

type TabId = "order-forms" | "order-services";

export const OrdersTabsLayout: FC<
  PropsWithChildren<{ selectedTab: TabId; rightContent?: React.ReactNode }>
> = ({ children, selectedTab, rightContent }) => {
  const { navigateToPage } = useNavigation();
  const { t } = useTranslations();

  return (
    <TabsLayout
      breadcrumbProps={{
        items: [
          {
            title: t({
              id: "orders-tabs-layout-component.order-forms",
              defaultMessage: "Order forms",
            }),
          },
        ],
      }}
      rightContent={rightContent}
      selectedTab={selectedTab}
      tabs={[
        {
          id: "order-forms",
          title: t({
            id: "orders-tabs-layout-component.order-forms",
            defaultMessage: "Order forms",
          }),
          onClick: () => navigateToPage("orderForms"),
        },
        {
          id: "order-services",
          title: t({
            id: "orders-tabs-layout-component.order-services",
            defaultMessage: "Order services",
          }),
          onClick: () => navigateToPage("orderServices"),
        },
      ]}
    >
      {children}
    </TabsLayout>
  );
};
