import { FC, useMemo } from "react";
import { Alert } from "@jugl-web/ui-components/cross-platform";
import {
  DEFAULT_DEAL_MIRROR_PLAN_NAME,
  SubscriptionInfoModel,
} from "@jugl-web/rest-api";
import Lottie from "react-lottie";
import basicPlanActivatedAnimation from "./assets/basic-plan-activated-animation.json";
import planActivatedAnimation from "./assets/plan-activated-animation.json";

export type SubscriptionUpdatedAlertProps = {
  isOpen: boolean;
  subscriptionInfo: SubscriptionInfoModel;
  onRequestClose: () => void;
};

export const SubscriptionUpdatedAlert: FC<SubscriptionUpdatedAlertProps> = ({
  isOpen,
  subscriptionInfo,
  onRequestClose,
}) => {
  const { icon, title, content } = useMemo<{
    title: string;
    icon: unknown;
    content?: string;
  }>(() => {
    if (subscriptionInfo.isDealMirror) {
      return {
        title: DEFAULT_DEAL_MIRROR_PLAN_NAME,
        content: `Lifetime plan for ${subscriptionInfo.seatsLicensed} members with all Basic features 🤩`,
        icon: basicPlanActivatedAnimation,
      };
    }
    if (subscriptionInfo.planInfo?.type === "basic") {
      return {
        title: `${subscriptionInfo.planInfo.name} Activated`,
        content: `Workspace can handle small Team of ${subscriptionInfo.seatsLicensed} People 😍`,
        icon: basicPlanActivatedAnimation,
      };
    }
    return {
      title: `${subscriptionInfo.planInfo?.name || "Jugl Plan"} Activated`,
      content: `Smart Team Management starts here 🚀`,
      icon: planActivatedAnimation,
    };
  }, [
    subscriptionInfo.isDealMirror,
    subscriptionInfo.planInfo?.name,
    subscriptionInfo.planInfo?.type,
    subscriptionInfo.seatsLicensed,
  ]);

  return (
    <Alert
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={title}
      content={content}
      img={
        <Lottie options={{ animationData: icon }} height={240} width={240} />
      }
      buttons={[{ text: "Okay", role: "close", color: "primary" }]}
    />
  );
};
