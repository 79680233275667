import { RtkEmptySplitApi } from "../../types";
import {
  EntityProp,
  EntityPropsApiTag,
  EntityPropType,
  getEntityPropsListTag,
} from "../entities";

export const addEntityPropsApi = (emptySplitApi: RtkEmptySplitApi) => {
  const apiWithTags = emptySplitApi.enhanceEndpoints({
    addTagTypes: [EntityPropsApiTag.entityPropsList],
  });

  const entityPropsApi = apiWithTags.injectEndpoints({
    overrideExisting: false,
    endpoints: (build) => ({
      entityPropsList: build.query<
        {
          id: string;
          entity_id: string;
          props: { data: EntityProp[] };
          type: EntityPropType;
        },
        { entityId: string; params: { type: EntityPropType } }
      >({
        query: ({ params, entityId }) => ({
          url: `/api/auth/entity/${entityId}/props`,
          params,
        }),
        providesTags: (e1, e2, { entityId, params: { type } }) => [
          getEntityPropsListTag(type, entityId),
        ],
      }),
      createEntityProp: build.mutation<
        void,
        {
          entityId: string;
          type: EntityPropType;
          propId?: string;
          data: { value: string };
        }
      >({
        query: ({ data: { value }, entityId, type, propId }) => ({
          url: `/api/auth/entity/${entityId}/props${
            propId ? `/${propId}` : ""
          }`,
          method: propId ? "PUT" : "POST",
          data: {
            type,
            props: propId
              ? [
                  {
                    value,
                  },
                ]
              : {
                  value: [value],
                },
          },
        }),
        invalidatesTags: (e1, e2, { entityId, type }) => [
          getEntityPropsListTag(type, entityId),
        ],
      }),
      updateEntityProp: build.mutation<
        void,
        {
          entityId: string;
          id: string;
          propId: string;
          type: EntityPropType;
          data: { value: string };
        }
      >({
        query: ({ data: { value }, type, entityId, propId, id }) => ({
          url: `/api/auth/entity/${entityId}/props/${propId}`,
          method: "PUT",
          data: {
            type,
            props: [{ id, value }],
          },
        }),
        invalidatesTags: (e1, e2, { entityId, type }) => [
          getEntityPropsListTag(type, entityId),
        ],
      }),
      deleteEntityProp: build.mutation<
        void,
        {
          entityId: string;
          propId: string;
          type: EntityPropType;
          ids: string[];
        }
      >({
        query: ({ propId, entityId, ids, type }) => ({
          url: `/api/auth/entity/${entityId}/props/${propId}`,
          method: "PUT",
          data: {
            type,
            props: ids.map((id) => ({ id })),
          },
        }),
        invalidatesTags: (e1, e2, { entityId, type }) => [
          getEntityPropsListTag(type, entityId),
        ],
      }),
    }),
  });

  const {
    useEntityPropsListQuery,
    useLazyEntityPropsListQuery,
    useCreateEntityPropMutation,
    useUpdateEntityPropMutation,
    useDeleteEntityPropMutation,
  } = entityPropsApi;
  return {
    api: entityPropsApi,
    useEntityPropsListQuery,
    useLazyEntityPropsListQuery,
    useCreateEntityPropMutation,
    useUpdateEntityPropMutation,
    useDeleteEntityPropMutation,
  };
};

export type EntityPropsApi = ReturnType<typeof addEntityPropsApi>;
