import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import { useSearchInput, useTranslations } from "@jugl-web/utils";
import React, { useMemo, useState } from "react";
import { PlainButton, Tooltip } from "@jugl-web/ui-components";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { Screen } from "@jugl-web/utils/utils/ScreenTransitionManager";
import { useTaskFields } from "@jugl-web/domain-resources/tasks/hooks/useTaskFields";
import { OrderFormComponent } from "@jugl-web/domain-resources/orders/components/OrderFormComponent";
import { OrderFormCustomField } from "@jugl-web/domain-resources/orders";
import {
  CustomTaskFieldsDialog,
  CustomTaskFieldsDialogScreenToParametersMap,
} from "./components/CustomTaskFieldsDialog";
import { ReactComponent as PlusIcon } from "./assets/plus.svg";

const initialManageFieldsDialogScreen: Screen<CustomTaskFieldsDialogScreenToParametersMap> =
  { name: "newCustomField" };

export const FormComponentSelectSection: React.FC<{
  customFields: OrderFormCustomField[];
}> = ({ customFields: selectedCustomFields }) => {
  const { t } = useTranslations();
  const { inputProps, reset, searchQuery } = useSearchInput();
  const { entity } = useEntity();
  const [isCustomTaskFieldsDialogOpen, setIsCustomTaskFieldsDialogOpen] =
    useState(false);
  const { customFields } = useTaskFields({ entityId: entity?.id });

  const filteredCustomFields = useMemo(
    () =>
      customFields
        .filter(
          (field) =>
            !selectedCustomFields.some(
              (selectedField) => selectedField.id === field.id
            )
        )
        .filter((field) =>
          field.name.toLowerCase().includes(searchQuery.toLowerCase())
        ),
    [selectedCustomFields, customFields, searchQuery]
  );

  return (
    <>
      <div className="flex h-full w-[280px] shrink-0 flex-col gap-7 rounded-t-2xl bg-white p-4">
        <div className="flex items-center justify-between">
          <span className="text-dark font-secondary ml-2 text-sm font-medium leading-4">
            {t({
              id: "order-form-create-page.select-inputs",
              defaultMessage: "Select Inputs",
            })}
          </span>
          <Tooltip
            renderTrigger={({ ref, props }) => (
              <PlainButton
                ref={ref}
                {...props}
                className="bg-primary-50 text-primary-800 hover:bg-primary flex h-7 w-7 items-center justify-center rounded-lg transition-colors hover:text-white"
                onClick={() => setIsCustomTaskFieldsDialogOpen(true)}
              >
                <PlusIcon />
              </PlainButton>
            )}
          >
            {t({
              id: "order-form-create-page.create-new-field",
              defaultMessage: "Create new field",
            })}
          </Tooltip>
        </div>
        <SearchInput
          variant="filled"
          color="grey"
          {...inputProps}
          onClear={reset}
        />
        {customFields.length === 0 ? (
          <span className="text-dark-700 font-secondary text-center text-xs">
            {t({
              id: "order-form-create-page.no-fields-created-yet",
              defaultMessage: "No fields created yet✨",
            })}
          </span>
        ) : filteredCustomFields.length === 0 ? (
          <span className="text-dark-700 font-secondary text-center text-xs">
            {t({
              id: "common.no-results",
              defaultMessage: "No results",
            })}
          </span>
        ) : (
          <div className="flex flex-col gap-[18px] overflow-y-auto">
            {filteredCustomFields.map(({ name, id, type }) => (
              <OrderFormComponent
                id={id}
                key={id}
                title={name}
                type={type}
                isCompact
              />
            ))}
          </div>
        )}
      </div>
      {entity?.id && (
        <CustomTaskFieldsDialog
          entityId={entity.id}
          isOpen={isCustomTaskFieldsDialogOpen}
          onClose={() => setIsCustomTaskFieldsDialogOpen(false)}
          initialScreen={initialManageFieldsDialogScreen}
        />
      )}
    </>
  );
};
