import { Button, Dialog, InteractiveContainer } from "@jugl-web/ui-components";
import { cx, useTranslations } from "@jugl-web/utils";
import React, { useRef, useState } from "react";

const ORDER_FORM_PREFIX_MAX_LENGTH = 6;
const ORDER_FORM_PREFIX_MIN_LENGTH = 2;

export const OrderFormPrefixField: React.FC<{
  orderFormPrefix: string;
  onChange: (prefix: string) => void;
}> = ({ orderFormPrefix, onChange }) => {
  const { t } = useTranslations();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [prefix, setPrefix] = useState(orderFormPrefix);

  return (
    <>
      <InteractiveContainer
        className="flex w-[340px] items-center justify-between rounded-2xl bg-white p-4"
        onClick={() => setIsDialogOpen(true)}
      >
        <div className="flex flex-col gap-1">
          <span className="text-dark font-secondary text-sm font-medium leading-[21px]">
            {t({
              id: "order-form-create-page.form-id",
              defaultMessage: "Form ID",
            })}
          </span>
          <span className="text-dark-700 font-secondary text-xs leading-[140%]">
            {t({
              id: "order-form-create-page.tap-to-edit",
              defaultMessage: "Tap to edit",
            })}
          </span>
        </div>
        <div className="bg-grey-200 text-dark flex h-12 w-[100px] items-center justify-center rounded-[10px]">
          {orderFormPrefix}
        </div>
      </InteractiveContainer>
      <Dialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        className="flex flex-col items-center py-10 px-6 text-center"
        initialFocus={inputRef}
        onTransitionEnd={() => {
          if (!isDialogOpen) {
            setPrefix(orderFormPrefix);
          }
        }}
      >
        <span className="text-dark font-secondary text-lg font-medium leading-[27px]">
          {t({
            id: "order-form-create-page.form-id",
            defaultMessage: "Form ID",
          })}
        </span>
        <span className="text-dark-800 mt-1 text-sm leading-[21px]">
          {t(
            {
              id: "order-form-create-page.unique-form-id-message",
              defaultMessage:
                "Create a unique Form ID. This will help to differentiate Order{br} Tasks that will be created from Clients Submitting the Form ✨",
            },
            { br: <br /> }
          )}
        </span>
        <div className="relative w-full">
          <input
            className="bg-grey-200 text-dark my-10 w-full rounded-[10px] border-none px-5 py-2.5 pr-14 font-[Roboto] text-base font-medium outline-none placeholder:font-medium placeholder:text-[#BDBDBD]"
            maxLength={ORDER_FORM_PREFIX_MAX_LENGTH}
            ref={inputRef}
            value={prefix}
            onChange={(e) => {
              const { value } = e.target;
              if (/^[a-zA-Z0-9]*$/.test(value)) {
                setPrefix(value.trim());
              }
            }}
            placeholder={t({
              id: "common.enter",
              defaultMessage: "Enter",
            })}
          />
          <span
            className={cx(
              "absolute top-1/2 right-4 -translate-y-1/2 text-xs font-medium",
              prefix.length === ORDER_FORM_PREFIX_MAX_LENGTH
                ? "text-gradients-danger"
                : "text-[#BDBDBD]"
            )}
          >
            {prefix.length}/6
          </span>
        </div>
        <div className="flex w-full gap-4 px-4">
          <Button color="grey" fullWidth onClick={() => setIsDialogOpen(false)}>
            {t({
              id: "common.cancel",
              defaultMessage: "Cancel",
            })}
          </Button>
          <Button
            fullWidth
            onClick={() => {
              onChange(prefix);
              setIsDialogOpen(false);
            }}
            isDisabled={prefix.length < ORDER_FORM_PREFIX_MIN_LENGTH}
          >
            {t({
              id: "order-form-create-page.proceed",
              defaultMessage: "Proceed",
            })}
          </Button>
        </div>
      </Dialog>
    </>
  );
};
