import {
  downloadBlobAsFile,
  useDownloadFile,
} from "@jugl-web/domain-resources/drive";
import { useFilePreview } from "@jugl-web/domain-resources/files/providers/FilePreviewProvider";
import {
  TaskAttachments,
  TaskAttachmentsHandle,
} from "@jugl-web/domain-resources/tasks/components/TaskAttachments";
import { TaskChecklistItemDeleteConfirmationDialog } from "@jugl-web/domain-resources/tasks/components/TaskChecklistItemDeleteConfirmationDialog";
import { TaskPermissions } from "@jugl-web/domain-resources/tasks/hooks/useTaskPermissions";
import { DetailedTask, TaskAttachment } from "@jugl-web/rest-api/tasks";
import { useToast, useTranslations } from "@jugl-web/utils";
import { useConfirmationDialogState } from "@jugl-web/utils/hooks/useConfirmationDialogState";
import { selectUserId } from "@web-src/features/auth/authSlice";
import { FC, useRef } from "react";
import { useSelector } from "react-redux";
import { TABLE_DIMENSIONS } from "../../consts";
import { NewTaskChecklistItemRow } from "../NewTaskChecklistItemRow";
import { TaskChecklistItemRow } from "../TaskChecklistItemRow";
import { TaskTableLayout } from "../TaskTableLayout";

export interface TaskDetailsRowsProps {
  task: DetailedTask;
  permissions: TaskPermissions;
  isFutureTask: boolean;
  entityId: string;
  showEmptyContentIndicator?: boolean;
}

export const TaskDetailsRows: FC<TaskDetailsRowsProps> = ({
  task,
  permissions,
  isFutureTask,
  entityId,
  showEmptyContentIndicator,
}) => {
  const deleteConfirmationDialogState = useConfirmationDialogState();

  const $taskAttachmentsRef = useRef<TaskAttachmentsHandle | null>(null);

  const meId = useSelector(selectUserId);

  const { previewFile } = useFilePreview();
  const { downloadFile } = useDownloadFile();

  const { toast, closeToast } = useToast({ variant: "web" });
  const { t } = useTranslations();

  const handleDownloadAttachment = async (attachment: TaskAttachment) => {
    const downloadingToastId = toast(
      t({ id: "feedback.file-downloading", defaultMessage: "Downloading..." }),
      { variant: "info", persist: true }
    );

    const response = await downloadFile({
      entityId,
      id: attachment.id,
      fileName: attachment.name,
      fileSize: attachment.size,
      mimeType: attachment.content_type,
    });

    if ("data" in response) {
      downloadBlobAsFile(response.data, attachment.name);
    } else {
      toast(
        t(
          {
            id: "tasks-page.attachment-download-failed",
            defaultMessage: "Couldn't download file {fileName}",
          },
          { fileName: attachment.name }
        ),
        { variant: "error", style: { zIndex: 99999 } }
      );
    }

    closeToast(downloadingToastId);
  };

  const handlePreviewAttachment = (attachment: TaskAttachment) => {
    previewFile({
      url: attachment.stream_url,
      name: attachment.name,
      mimeType: attachment.content_type,
      onDownload: () => handleDownloadAttachment(attachment),
    });
  };

  return (
    <>
      {showEmptyContentIndicator &&
        task.checklist.length === 0 &&
        task.attachments.length === 0 && (
          <TaskTableLayout.Row isHoverable={false}>
            <TaskTableLayout.OutermostEmptyCell />
            <TaskTableLayout.Cell
              hasMarginLine
              className="flex items-center border-0 border-r-[2px] border-solid border-[#EEF2F5] px-4 py-[17.5px] text-sm text-[#828282]"
              width={TABLE_DIMENSIONS.TABLE_TASK_TITLE_COLUMN_WIDTH}
            >
              {t({
                id: "tasks-page.no-checklist-or-attachments-added",
                defaultMessage: "No Checklists or Attachments added",
              })}
            </TaskTableLayout.Cell>
          </TaskTableLayout.Row>
        )}
      {task.checklist.map((item, index) => (
        <TaskChecklistItemRow
          key={item.id}
          task={task}
          permissions={permissions}
          isFutureTask={isFutureTask}
          item={item}
          index={index}
          entityId={entityId}
          meId={meId || ""}
          onOpenDeleteConfirmationDialog={deleteConfirmationDialogState.open}
        />
      ))}
      {!isFutureTask && (
        <>
          {permissions.canManageChecklistItem && (
            <NewTaskChecklistItemRow
              task={task}
              entityId={entityId}
              meId={meId || ""}
            />
          )}
          {(task.attachments.length > 0 ||
            permissions.canManageAttachments) && (
            <TaskTableLayout.Row isHoverable={false}>
              <TaskTableLayout.OutermostEmptyCell />
              <TaskTableLayout.Cell
                hasMarginLine
                shouldGrow
                className="px-6 pt-6 pb-2"
              >
                <TaskAttachments
                  mode="uncontrolled"
                  ref={$taskAttachmentsRef}
                  attachments={task.attachments}
                  entityId={entityId}
                  className="jugl__custom-scrollbar-horizontal max-w-[1500px] flex-nowrap items-center gap-3 overflow-x-auto rounded-xl pb-2"
                  taskId={task.id}
                  isEditable={permissions.canManageAttachments}
                  onPreviewAttachment={handlePreviewAttachment}
                  onAddAttachmentTileClick={() =>
                    $taskAttachmentsRef.current?.openFilePicker()
                  }
                />
              </TaskTableLayout.Cell>
            </TaskTableLayout.Row>
          )}
        </>
      )}
      <TaskTableLayout.Row
        height={TABLE_DIMENSIONS.TABLE_TASK_SEPARATOR_ROW_HEIGHT}
        isHoverable={false}
      >
        <TaskTableLayout.Cell shouldGrow />
      </TaskTableLayout.Row>
      <TaskChecklistItemDeleteConfirmationDialog
        isOpen={deleteConfirmationDialogState.isOpen}
        onDelete={() => {
          deleteConfirmationDialogState.confirm({ closeOnceConfirmed: true });
        }}
        onClose={deleteConfirmationDialogState.close}
      />
    </>
  );
};
