import { useMultipleUserProfiles } from "@jugl-web/domain-resources/users/hooks/useMultipleUserProfiles";
import {
  WITHOUT_USER_ITEM_ID,
  useUserListBox,
} from "@jugl-web/domain-resources/users/hooks/useUserListBox";
import {
  AvatarMember,
  AvatarStack,
} from "@jugl-web/ui-components/cross-platform";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { cx, useTranslations } from "@jugl-web/utils";
import { FC, useCallback, useMemo } from "react";
import { ChecklistTableCellComponentProps } from "../../types";
import { TaskTableLayout } from "../TaskTableLayout";

export const ChecklistItemAssigneeCell: FC<
  ChecklistTableCellComponentProps
> = ({
  task,
  item,
  specificOrderCompletionState,
  entityId,
  permissions,
  isFutureTask,
  cellProps,
  onUpdate,
}) => {
  const { profiles, isLoading } = useMultipleUserProfiles({
    entityId,
    userIds: item.user_id ? [item.user_id] : [],
  });

  const { t } = useTranslations();

  const { getListBoxProps, clearSearchQuery } = useUserListBox({
    entityId,
    withoutUserItem: {
      label: t({
        id: "tasks-page.without-assignee",
        defaultMessage: "Without assignee",
      }),
    },
  });

  const profilesAsAvatarMembers = useMemo<AvatarMember[]>(
    () =>
      profiles.map((profile) => ({
        firstName: profile.firstName || "",
        lastName: profile.lastName || "",
        imageUrl: profile.avatar || null,
      })),
    [profiles]
  );

  const isEditable =
    !isFutureTask && permissions.canManageChecklistItem && !task.is_self;

  const getCellContent = useCallback(
    (isPopoverOpen: boolean) => {
      if (isLoading) {
        return <TaskTableLayout.CellContentSkeleton type="avatars" />;
      }

      const hasNoAssignee = !item.user_id;

      if (hasNoAssignee) {
        return (
          <TaskTableLayout.AppearingAddValueCellText
            isStatic={isPopoverOpen}
            isHidden={!isEditable}
          />
        );
      }

      return (
        <AvatarStack
          maxCount={6}
          size="sm"
          members={profilesAsAvatarMembers}
          shouldShowNameForSingleMember
          singleMemberLabelClassName={cx(
            "transition-colors",
            !isFutureTask &&
              (item.is_completed || specificOrderCompletionState.isDisabled) &&
              "text-grey-700"
          )}
        />
      );
    },
    [
      isLoading,
      item.user_id,
      item.is_completed,
      profilesAsAvatarMembers,
      isFutureTask,
      specificOrderCompletionState.isDisabled,
      isEditable,
    ]
  );

  return (
    <ResourcePickerPopover
      placement="bottom"
      className="w-[375px]"
      renderTrigger={({ Trigger, triggerRef, isOpen }) => (
        <Trigger
          ref={triggerRef}
          as={TaskTableLayout.Cell}
          isFocused={isOpen}
          isHoverable
          isDisabled={!isEditable}
          {...cellProps}
        >
          {getCellContent(isOpen)}
        </Trigger>
      )}
      {...getListBoxProps({
        selectionBehavior: { mode: "single", canToggle: false },
        defaultSelectedIds: item.user_id ? [item.user_id] : undefined,
        itemSize: "md",
        spaceBetweenItems: "normal",
        maxVisibleItems: 5,
      })}
      onSelect={({ item: selectedItem, onClose }) => {
        onUpdate({
          user_id:
            selectedItem.id === WITHOUT_USER_ITEM_ID ? null : selectedItem.id,
        });

        onClose();
      }}
      onUnmount={clearSearchQuery}
    />
  );
};
