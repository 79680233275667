import {
  getImageDimensionsByUrl,
  getVideoDimensionsByUrl,
} from "@jugl-web/utils";
import { MENTIONS_REGEXP } from "@jugl-web/utils/utils/mentions";
import { environment } from "@web-src/environments/environment";
import uniqueId from "lodash/uniqueId";
import {
  ChatHistoryItem,
  ChatMessage,
  ChatMessageBodyToken,
  ChatMessagePayloadAttachment,
  ChatMessagePayloadAttachmentType,
  ChatMessagePayloadType,
  ChatMessageType,
} from "./types";

export const messageToConversationLastMessage = (
  message: ChatHistoryItem | ChatMessage
) =>
  message.payload
    ? {
        msgId: message.msg_id,
        from: message.from,
        message: payloadToClearMessage(message),
        timestamp: message.timestamp,
        attachments: message.payload?.attachments,
        tokenizedMessage: tokenizeMessagePayload(message),
        deleted: message.deleted || false,
      }
    : undefined;

export const getMessageBodyStr = (message: ChatMessage) =>
  message.payload.body || message.payload?.attachments?.[0]?.caption;

export const getMessageChatId = (message: ChatMessage, selfUserId?: string) => {
  // TODO: re-check all cases
  if (message.type === ChatMessageType.chat) {
    return message.from === selfUserId ? message.to : message.from;
  }
  return message.payload?.group_id || message.to;
};

export const clearMessageBody = (body: string) => {
  let cleanBody = body;
  const parsedMentions = [...body.matchAll(MENTIONS_REGEXP)];
  parsedMentions.forEach((item) => {
    cleanBody = cleanBody.replace(item[0], item[2]);
  });
  return cleanBody;
};

export const tokenizeMessagePayload = (
  message: ChatMessage | ChatHistoryItem
): ChatMessageBodyToken[] => {
  const { payload } = message;
  if (payload?.type === ChatMessagePayloadType.group_info && payload.action) {
    const forUserId = payload?.action_for;
    const fromUserId = payload?.action_from;
    if (payload.action === "deleted") {
      return [
        { type: "userName", rawString: fromUserId, id: uniqueId() },
        {
          type: "string",
          humanizedString: "deleted the group",
          id: "2",
        },
      ];
    }
    if (payload.action === "removed") {
      return [
        { type: "userName", rawString: forUserId, id: uniqueId() },
        {
          type: "string",
          humanizedString: "has been removed from the group",
          id: "2",
        },
      ];
    }
    // TODO: re-check with backend/design
    if (payload?.action === "added") {
      if (forUserId === fromUserId) {
        return [
          { type: "userName", rawString: forUserId, id: uniqueId() },
          { type: "string", rawString: "created the group", id: uniqueId() },
        ];
      }
      return [
        { type: "userName", rawString: fromUserId, id: uniqueId() },
        { type: "string", humanizedString: "added", id: uniqueId() },
        { type: "userName", rawString: forUserId, id: uniqueId() },
        { type: "string", humanizedString: "to the group", id: uniqueId() },
      ];
    }
    if (payload?.action === "updated") {
      return [
        { type: "userName", rawString: fromUserId, id: uniqueId() },
        {
          type: "string",
          humanizedString: "updated the group",
          id: uniqueId(),
        },
      ];
    }
    if (payload?.action === "left") {
      return [
        { type: "userName", rawString: fromUserId, id: uniqueId() },
        { type: "string", humanizedString: "left the group", id: uniqueId() },
      ];
    }
    return [];
  }

  const messageBody = getChatMessageBody(message);
  if (!messageBody?.length) {
    return [];
  }
  return tokenizeMessageBody(messageBody);
};

export const tokenizeMessageBody = (messageBody: string) => {
  const isValidHttpUrl = (string: string) => {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  };

  const mentionsCleanBody = (body?: string) => {
    let cleanBody = body || "";
    const parsedMentions = [...(body || "").matchAll(MENTIONS_REGEXP)];
    parsedMentions.forEach((item) => {
      cleanBody = cleanBody.replace(item[0], item[0].replaceAll(" ", "&nbsp;"));
    });
    return cleanBody;
  };
  const lines = mentionsCleanBody(messageBody)?.split("\n");
  const tokens: ChatMessageBodyToken[] = [];
  lines.forEach((line, lineIdx) => {
    line.split(" ").forEach((item) => {
      if (isValidHttpUrl(item)) {
        tokens.push({
          type: "url",
          rawString: item,
          humanizedString: item,
          id: uniqueId(),
        });
        return;
      }
      if (item.match(MENTIONS_REGEXP)) {
        tokens.push({
          type: "mention",
          rawString: item,
          value: item.slice(item.indexOf("<") + 1, item.indexOf(">")),
          humanizedString: item
            .slice(item.indexOf("[") + 1, item.indexOf("]"))
            .replaceAll("&nbsp;", " "),
          id: uniqueId(),
        });
        return;
      }
      tokens.push({
        type: "string",
        rawString: item,
        humanizedString: item,
        id: uniqueId(),
      });
    });
    if (lineIdx !== lines.length - 1) {
      tokens.push({
        type: "string",
        rawString: "\n",
        humanizedString: "\n",
        id: uniqueId("line-"),
      });
    }
  });
  return tokens;
};

export const getChatMessageBody = (
  chatMessage: ChatMessage | ChatHistoryItem
) => {
  if (chatMessage.payload?.body) {
    return chatMessage.payload?.body;
  }
  return chatMessage.payload?.attachments?.[0]?.caption || "";
};

export const isChatMessageEmojiOnly = (chatMessage: ChatMessage) => {
  const stringToTest = getChatMessageBody(chatMessage).replace(/ /g, "");
  const emojiRegex =
    /^(?:(?:\p{RI}\p{RI}|\p{Emoji}(?:\p{Emoji_Modifier}|\u{FE0F}\u{20E3}?|[\u{E0020}-\u{E007E}]+\u{E007F})?(?:\u{200D}\p{Emoji}(?:\p{Emoji_Modifier}|\u{FE0F}\u{20E3}?|[\u{E0020}-\u{E007E}]+\u{E007F})?)*)|[\u{1f900}-\u{1f9ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}])+$/u;
  return emojiRegex.test(stringToTest) && Number.isNaN(Number(stringToTest));
};

export const payloadToClearMessage = (
  message: ChatMessage | ChatHistoryItem
) => {
  const messageBody = getChatMessageBody(message);
  if (messageBody) {
    const clearBody = clearMessageBody(messageBody);
    if (message.payload.type === "call") {
      if (message.payload.call_action === "call_un_hold") {
        return "Call resumed";
      }
      if (message.payload.call_action === "call_hold") {
        return "Call is on hold";
      }

      if (message.payload.call_action === "call_answered") {
        return "Call answered";
      }
      return clearBody.replace("Outgoing", "Incoming");
    }
    return clearBody;
  }
  const { payload } = message;
  if (payload?.conference) {
    return "Join video meeting";
  }
  if (payload?.attachments) {
    return payload.attachments?.[0]?.name
      ? payload.attachments?.[0]?.name
      : `${payload.attachments.length} attachment${
          payload.attachments.length > 1 ? "s" : ""
        }`;
  }
  // TODO: @critical re-check
  return "";
};

export const getAttachmentTypeByMimeType = (
  type: string
): ChatMessagePayloadAttachmentType => {
  if (type?.startsWith("image")) {
    return ChatMessagePayloadAttachmentType.image;
  }
  if (type?.startsWith("audio")) {
    return ChatMessagePayloadAttachmentType.audio;
  }
  if (type?.startsWith("video")) {
    return ChatMessagePayloadAttachmentType.video;
  }
  return ChatMessagePayloadAttachmentType.doc;
};

export const isMessagesDebugMode = () =>
  !environment.production && localStorage.getItem("jugl:chat-dev-mode");

export const filesToChatMessageAttachments = async (
  files: File[],
  attachmentType?: ChatMessagePayloadAttachmentType
): Promise<ChatMessagePayloadAttachment[]> => {
  const attachments = await Promise.all<ChatMessagePayloadAttachment>(
    files.map(
      (file) =>
        // eslint-disable-next-line no-async-promise-executor
        new Promise(async (resolve) => {
          let attachment: ChatMessagePayloadAttachment | undefined;
          if (file) {
            const attachmentUrl = URL.createObjectURL(file);
            attachment = {
              type: attachmentType || getAttachmentTypeByMimeType(file.type),
              name: file.name,
              size: file.size,
              mimetype: file.type,
              uid: "",
              url: URL.createObjectURL(file),
              _stream_url: "",
            };
            if (file.type.startsWith("image")) {
              const dimensions = await getImageDimensionsByUrl(attachmentUrl);
              attachment.width = dimensions.width;
              attachment.height = dimensions.height;
            } else if (file.type.startsWith("video")) {
              const dimensions = await getVideoDimensionsByUrl(attachmentUrl);
              attachment.width = dimensions.width;
              attachment.height = dimensions.height;
            }
          }
          if (attachment) {
            resolve(attachment);
          }
        })
    )
  );
  return attachments;
};
