import React, { useState } from "react";
import { useTranslations } from "@jugl-web/utils";
import { ResourceMainSettingsItem } from "@web-src/modules/common/components";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { useRestApiProvider } from "@jugl-web/rest-api";
import {
  useFileSelect,
  avatarFileSelectConfig,
} from "@jugl-web/domain-resources/files/providers/FileSelectProvider";
import { AVATAR_VERSION_STORAGE_KEY } from "@jugl-web/utils/storage";
import { AvatarSelectSize } from "@jugl-web/ui-components/web";
import { Alert, Avatar, Input } from "@jugl-web/ui-components/cross-platform";
import { useUserAuth } from "@web-src/modules/auth/providers/UserAuthProvider";
import { ReactComponent as BinIcon } from "./assets/bin.svg";
import { ReactComponent as WarningIcon } from "./assets/warning.svg";
import { UserAccountSettingsLayout } from "../../components/UserAccountSettingsLayout";
import { LoginMethodList } from "./components/LoginMethodList";

export const MainSettings: React.FC = () => {
  const { t } = useTranslations();
  const { me } = useMe();
  const [
    deleteUserConfirmationInputValue,
    setDeleteUserConfirmationInputValue,
  ] = useState<string>();

  const { usersApi } = useRestApiProvider();
  const [deleteMyAccount, { isLoading: isDeleteMyAccountLoading }] =
    usersApi.useDeleteMyAccountMutation();
  const { logout } = useUserAuth();
  const { selectFile } = useFileSelect(avatarFileSelectConfig);
  const [updateProfilePhoto] = usersApi.useUpdateUserProfilePictureMutation();
  const getAvatarVersion = () =>
    localStorage.getItem(AVATAR_VERSION_STORAGE_KEY) || "1";
  const [avatarVersion, setAvatarVersion] = useState(getAvatarVersion());
  const [
    isDeleteAccountConfirmationAlertOpen,
    setIsDeleteAccountConfirmationAlertOpen,
  ] = useState(false);

  const handleImageChange = (image?: File) => {
    updateProfilePhoto(image).finally(() => {
      if (image) {
        const newAvatarVersion = Math.random().toString();
        localStorage.setItem(AVATAR_VERSION_STORAGE_KEY, newAvatarVersion);
        setAvatarVersion(newAvatarVersion);
      }
    });
  };
  const handleDeleteMyAccount = async () => {
    const resp = await deleteMyAccount();
    if ("error" in resp && resp.error) {
      return;
    }
    logout(false);
  };
  if (!me) return null;

  const departmentName = me.spaces[0]?.info.title;
  const isDeleteButtonDisabled =
    deleteUserConfirmationInputValue?.toLowerCase() !==
      me.display_name.toLowerCase() || isDeleteMyAccountLoading;
  return (
    <UserAccountSettingsLayout selectedTab="main-settings">
      <div className="flex flex-col gap-10 px-8 py-12">
        <ResourceMainSettingsItem
          title={me.display_name}
          avatarSelectProps={{
            defaultSrc: me.profile?.img
              ? `${me.profile.img}?v=${avatarVersion}`
              : undefined,
            onSave: handleImageChange,
            size: AvatarSelectSize.xxl,
            showRemoveButton: true,
            externalSelectFile: selectFile,
          }}
          subTitle={
            departmentName ||
            t({
              id: "common.no-department",
              defaultMessage: "No department",
            })
          }
          menuItems={[
            {
              id: "delete-my-account",
              label: t({
                id: "settings-page.delete-my-account",
                defaultMessage: "Delete My Account",
              }),
              icon: <BinIcon />,
              onSelect: (_, close) => {
                setIsDeleteAccountConfirmationAlertOpen(true);
                close();
              },
            },
          ]}
        />
        <LoginMethodList />
      </div>
      <Alert
        isOpen={isDeleteAccountConfirmationAlertOpen}
        header={t({
          id: "common.confirm-action",
          defaultMessage: "Confirm action",
        })}
        isCloseButtonVisible
        onRequestClose={() => setIsDeleteAccountConfirmationAlertOpen(false)}
        img={
          <div className="relative">
            <Avatar username={me.display_name} size="5xl" />
            <div className="absolute bottom-0 left-[90px] h-[60px] w-[60px] rounded-full bg-white p-2.5">
              <WarningIcon />
            </div>
          </div>
        }
        title={t({
          id: "settings-page.delete-account",
          defaultMessage: "Delete Account",
        })}
        content={
          <div className="flex flex-col gap-8 text-center">
            <div>
              {t(
                {
                  id: "settings-page.delete-account-description",
                  defaultMessage:
                    "You are trying to delete your entire Account. By doing this, you will <fancyText>lose access</fancyText> to all Workspaces, that associated to this Account. This action cant be undone",
                },
                {
                  fancyText: (text: (string | JSX.Element)[]) => (
                    <span className="text-gradients-danger font-semibold">
                      {text}
                    </span>
                  ),
                }
              )}
            </div>
            <div>
              <Input
                placeholder={t({
                  id: "common.enter",
                  defaultMessage: "Enter",
                })}
                className="w-full"
                onChange={(e) =>
                  setDeleteUserConfirmationInputValue(e.target.value)
                }
              />
              <div className="text-gradients-danger mt-2 text-sm">
                {t({
                  id: "settings-page.delete-alert-input-info",
                  defaultMessage:
                    "To confirm Action, type the name of the Account Owner",
                })}
              </div>
            </div>
          </div>
        }
        buttons={[
          {
            text: t({
              id: "settings-page.delete-my-account",
              defaultMessage: "Delete My Account",
            }),
            color: "tertiary",
            isDisabled: isDeleteButtonDisabled,
            onClick: () => {
              setIsDeleteAccountConfirmationAlertOpen(false);
              handleDeleteMyAccount();
            },
          },
        ]}
      />
    </UserAccountSettingsLayout>
  );
};
