import { useRestApiProvider } from "@jugl-web/rest-api";
import { DetailedTask, UpdateTaskResponse } from "@jugl-web/rest-api/tasks";
import { useCallback, useMemo } from "react";

interface UseUpdateTaskFactoryOptions {
  entityId: string;
  isInTeamTasksContext?: boolean;
  customerId?: string;
}

export const useUpdateTaskFactory = ({
  entityId,
  isInTeamTasksContext,
  customerId,
}: UseUpdateTaskFactoryOptions) => {
  const { tasksApi } = useRestApiProvider();
  const [updateTaskMutation] = tasksApi.useUpdateTaskMutation();

  const updateTaskFactory = useCallback(
    (taskId: string) => (attributes: Partial<DetailedTask>) =>
      new Promise<UpdateTaskResponse>((resolve, reject) => {
        updateTaskMutation({
          entityId,
          taskId,
          updatedTask: attributes,
          isInTeamTasksContext,
          customerId,
        }).then((response) => {
          if ("data" in response) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        });
      }),
    [entityId, isInTeamTasksContext, updateTaskMutation, customerId]
  );

  return updateTaskFactory;
};

interface UseUpdateTaskOptions extends UseUpdateTaskFactoryOptions {
  taskId: string;
}

export const useUpdateTask = (options: UseUpdateTaskOptions) => {
  const updateTaskFactory = useUpdateTaskFactory(options);

  const updateTask = useMemo(
    () => updateTaskFactory(options.taskId),
    [options.taskId, updateTaskFactory]
  );

  return updateTask;
};
