import {
  InteractiveContainer,
  Popover,
} from "@jugl-web/ui-components/cross-platform";
import { DatePicker } from "@jugl-web/ui-components/cross-platform/DatePicker";
import { useFormattedDate } from "@jugl-web/utils/hooks/useFormattedDate";
import { FC } from "react";
import { ReactComponent as CalendarIcon } from "../../assets/calendar.svg";

interface EndAtDatePickerProps {
  endAtDate: Date;
  onChange: (date: Date) => void;
}

export const EndAtDatePicker: FC<EndAtDatePickerProps> = ({
  endAtDate,
  onChange,
}) => {
  const { localeAwareFormat } = useFormattedDate();

  return (
    <Popover
      placement="right"
      renderTrigger={({ Trigger, triggerRef }) => (
        <Trigger
          ref={triggerRef}
          as={InteractiveContainer}
          className="bg-grey-100 flex h-10 w-full items-center justify-between rounded-lg p-4 outline-none"
        >
          <span className="text-dark text-sm">
            {localeAwareFormat(endAtDate, "MMMM d, yyyy")}
          </span>
          <CalendarIcon />
        </Trigger>
      )}
    >
      {({ onClose }) => (
        <DatePicker
          initialDate={endAtDate}
          minDate={new Date()}
          className="w-[343px] p-4"
          onDaySelect={(date) => {
            onChange(date);
            onClose();
          }}
          onClose={onClose}
        />
      )}
    </Popover>
  );
};
