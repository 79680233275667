import { PaginatedResponse, useRestApiProvider } from "@jugl-web/rest-api";
import { TaskComment } from "@jugl-web/rest-api/tasks-comments";
import { assert, usePagination, usePrevious } from "@jugl-web/utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useCallback, useEffect } from "react";

// TODO: reduce when pagination added
const COMMENTS_PER_PAGE = 100;

export const useTasksComments = ({
  entityId,
  taskId,
  commentId,
}: {
  entityId?: string;
  noInitialLoad?: boolean;
  taskId: string;
  commentId?: string;
}) => {
  const {
    items,
    addItems,
    reset,
    updateItem,
    setLastPageState,
    lastPageState,
    deleteItem,
  } = usePagination<
    TaskComment,
    Pick<
      PaginatedResponse<unknown>,
      "page_number" | "page_size" | "total_entries" | "total_pages"
    >
  >(`tasks-comments:${taskId}${commentId ? `:${commentId}` : ""}`);

  const { tasksCommentsApi } = useRestApiProvider();

  const {
    data: taskCommentsResponse,
    isLoading,
    isFetching,
  } = tasksCommentsApi.useGetTaskCommentsQuery(
    entityId
      ? {
          entityId,
          taskId,
          repliesCommentId: commentId,
          params: { page_size: COMMENTS_PER_PAGE },
        }
      : skipToken
  );

  const [getTaskComment] = tasksCommentsApi.useLazyGetTaskCommentQuery();
  const [createCommentApi, { isLoading: createCommentIsLoading }] =
    tasksCommentsApi.useCreateTaskCommentMutation();
  const [editCommentApi, { isLoading: editCommentIsLoading }] =
    tasksCommentsApi.useEditCommentMutation();
  const [deleteCommentApi, { isLoading: deleteCommentIsLoading }] =
    tasksCommentsApi.useDeleteTaskCommentMutation();

  const previousIsFetching = usePrevious(isFetching);

  useEffect(() => {
    if (!taskCommentsResponse) {
      return;
    }

    if (!(isFetching === false && previousIsFetching === true)) {
      return;
    }

    const { data, ...lastPageStateToSet } = taskCommentsResponse;
    const dataToSet = data;

    reset();
    addItems(
      dataToSet.map((item) => ({
        id: item.comment_id || item.reply_id || "",
        data: item,
      })),
      "end"
    );

    setLastPageState(lastPageStateToSet);
  }, [
    addItems,
    reset,
    taskCommentsResponse,
    setLastPageState,
    isFetching,
    previousIsFetching,
  ]);

  const createComment = useCallback(
    async (data: Parameters<typeof createCommentApi>[0]["data"]) => {
      if (!entityId) {
        return null;
      }
      const response = await createCommentApi({
        entityId,
        taskId,
        data,
        replyToCommentId: commentId,
      });

      return response;
    },
    [commentId, createCommentApi, entityId, taskId]
  );

  const editComment = useCallback(
    async ({
      id,
      parentCommentId,
      data,
    }: {
      id: string;
      parentCommentId?: string;
      data: Parameters<typeof createCommentApi>[0]["data"];
    }) => {
      if (!entityId) {
        return null;
      }
      const response = await editCommentApi({
        entityId,
        taskId,
        commentId: id,
        parentCommentId,
        data,
      });

      return response;
    },
    [editCommentApi, entityId, taskId]
  );

  const deleteComment = useCallback(
    async (id: string) => {
      if (!entityId) {
        return null;
      }
      const response = await deleteCommentApi({
        commentId: id,
        entityId,
        taskId,
        parentCommentId: commentId,
      });

      return response;
    },
    [commentId, deleteCommentApi, entityId, taskId]
  );

  const fetchCommentById = useCallback(
    async (
      _commentId: string,
      _replyId?: string
    ): Promise<TaskComment | null> => {
      assert(!!entityId);

      const response = await getTaskComment({
        entityId,
        taskId,
        commentId: _commentId,
        replyId: _replyId,
      });

      if (response.data) {
        const [comment] = response.data.data;
        return comment;
      }

      return null;
    },
    [entityId, getTaskComment, taskId]
  );

  const fetchAndInsertCommentLocally = useCallback(
    async (_commentId: string, _replyId?: string) => {
      const comment = await fetchCommentById(_commentId, _replyId);
      const itemId = _replyId || _commentId;

      if (comment) {
        addItems([{ id: itemId, data: comment }], "start");

        if (lastPageState) {
          setLastPageState({
            ...lastPageState,
            total_entries: lastPageState.total_entries + 1,
          });
        }
      }
    },
    [addItems, fetchCommentById, lastPageState, setLastPageState]
  );

  const fetchAndUpdateCommentLocally = useCallback(
    async (_commentId: string, _replyId?: string) => {
      const comment = await fetchCommentById(_commentId, _replyId);
      const itemId = _replyId || _commentId;

      if (comment) {
        updateItem({ id: itemId, data: comment });
      }
    },
    [fetchCommentById, updateItem]
  );

  const deleteCommentLocally = useCallback(
    (_commentOrReplyId: string) => {
      deleteItem({ itemId: _commentOrReplyId });

      if (lastPageState) {
        setLastPageState({
          ...lastPageState,
          total_entries: lastPageState.total_entries - 1,
        });
      }
    },
    [deleteItem, lastPageState, setLastPageState]
  );

  return {
    items,
    isLoading,
    createCommentIsLoading,
    deleteCommentIsLoading,
    editCommentIsLoading,
    createComment,
    deleteComment,
    editComment,
    fetchAndInsertCommentLocally,
    fetchAndUpdateCommentLocally,
    deleteCommentLocally,
    totalCount: lastPageState?.total_entries,
  };
};
