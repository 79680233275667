import { cx } from "@jugl-web/utils";
import { forwardRef, HTMLAttributes, ReactNode } from "react";

export interface TaskCardPillProps extends HTMLAttributes<HTMLDivElement> {
  label: string;
  backgroundColor?: string;
  textColor?: string;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
}

export const PILL_BASE_BG_COLOR = "#F9F9F9";
export const PILL_BASE_TEXT_COLOR = "#40494D";

export const TaskCardPill = forwardRef<HTMLDivElement, TaskCardPillProps>(
  (
    {
      label,
      backgroundColor = PILL_BASE_BG_COLOR,
      textColor = PILL_BASE_TEXT_COLOR,
      startIcon,
      endIcon,
      className,
      ...props
    },
    ref
  ) => (
    <div
      ref={ref}
      className={cx(
        "flex items-center gap-1.5 rounded-md py-0.5 px-1.5",
        className
      )}
      style={{
        backgroundColor,
        color: textColor,
      }}
      title={label}
      {...props}
    >
      {startIcon && <div className="shrink-0 leading-[0]">{startIcon}</div>}
      <span className="leading-2 truncate text-xs">{label}</span>
      {endIcon && <div className="shrink-0 leading-[0]">{endIcon}</div>}
    </div>
  )
);
