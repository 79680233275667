import { FC } from "react";
import PeopleInfoSidebar from "@web-src/features/people/components/PeopleInfoSidebar";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { OnboardingProgressBar } from "@web-src/modules/preferences/components/OnboardingProgressBar";
import { useOnboarding } from "@web-src/modules/preferences/providers";
import { CPanelPageProvider, useCPanelPageContext } from "./CPanelPageProvider";
import { CPanelTable } from "./components/CPanelTable";
import { PageControlBar } from "./components/PageControlBar";
import { UserDocumentsSidebar } from "./components/UserDocumentsSidebar";
import { UserActivitySideBar } from "./components/UserActivitySideBar";
import { UserInvitationSidebar } from "./components/UserInvitationSidebar";
import { InvitationLinkAlert } from "./components/InvitationLinkAlert";
import { SettingsTabsLayout } from "../../components/SettingsTabsLayout/SettingsTabsLayout";

const CPanelPageContent: FC = () => {
  const { openedSideBar, openedAlert, setOpenedSideBar, setOpenedAlert } =
    useCPanelPageContext();
  const { subscriptionInfo } = useEntitySelectedProvider();
  const { isOnboardingActive } = useOnboarding();
  const isSubscriptionExpired = subscriptionInfo?.planIsActive === false;
  return (
    <SettingsTabsLayout
      selectedTab="members"
      rightContent={<PageControlBar />}
      isSubscriptionExpired={isSubscriptionExpired}
    >
      <div className="flex h-full flex-col">
        <CPanelTable />
        {isOnboardingActive && <OnboardingProgressBar />}
      </div>
      <InvitationLinkAlert
        isOpen={openedAlert?.alert === "copyInvitation"}
        onRequestClose={() => setOpenedAlert(null)}
      />
      <UserDocumentsSidebar
        isOpen={openedSideBar?.sideBar === "documents"}
        user={openedSideBar?.user}
        onRequestClose={() => setOpenedSideBar(null)}
      />
      <UserActivitySideBar
        isOpen={openedSideBar?.sideBar === "loginActivity"}
        user={openedSideBar?.user}
        onRequestClose={() => setOpenedSideBar(null)}
      />
      <UserInvitationSidebar
        isOpen={openedSideBar?.sideBar === "invite"}
        user={openedSideBar?.user}
        onRequestClose={() => setOpenedSideBar(null)}
      />
      {openedSideBar?.sideBar === "userProfile" && openedSideBar?.user?.id && (
        <PeopleInfoSidebar
          isOpen
          userId={openedSideBar.user.id}
          onClose={() => setOpenedSideBar(null)}
        />
      )}
    </SettingsTabsLayout>
  );
};

export const CPanelPage: FC = () => (
  <CPanelPageProvider>
    <CPanelPageContent />
  </CPanelPageProvider>
);
