import { Button } from "@jugl-web/ui-components";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslations } from "@jugl-web/utils";
import { CustomFieldInput, CustomFieldInputProps } from "../CustomFieldInput";
import { OrderFormCustomField, OrderFormValues } from "../../types";

export type OrderFormSubmissionValues = Pick<
  OrderFormValues,
  "title" | "description"
> & {
  fields: OrderFormCustomField[];
  image?: string;
};

export const OrderFormSubmission: React.FC<{
  values: OrderFormSubmissionValues;
  initialFieldsValues?: { [key: string]: string | Date };
  isSubmitted?: boolean;
  submittedMessage: string;
  onSubmit: (fieldsValues?: { [key: string]: string | Date }) => void;
  onReset?: () => void;
  resetButtonText?: string;
  isLoading?: boolean;
  isGuestApp?: boolean;
}> = ({
  values,
  initialFieldsValues,
  onSubmit,
  isSubmitted,
  submittedMessage,
  onReset,
  resetButtonText,
  isLoading,
  isGuestApp,
}) => {
  const { t } = useTranslations();
  const { description, image, title, fields } = values;

  const [isError, setIsError] = useState(false);
  const [fieldsValues, setFieldsValues] = useState(initialFieldsValues || {});

  const getCustomFieldInputProps = useCallback(
    (field: OrderFormCustomField) => {
      const handleChange = (value: string | Date) => {
        setFieldsValues((prevState) => ({
          ...prevState,
          [field.id]: value,
        }));
      };

      switch (field.type) {
        case "date":
          return {
            type: "date",
            value: fieldsValues[field.id],
            onChange: handleChange,
          };
        case "dropdown":
          return {
            items: field.values || [],
            selectedId: fieldsValues[field.id],
            onChange: handleChange,
            type: "dropdown",
          };
        case "text":
          return {
            value: fieldsValues[field.id] || "",
            onChange: handleChange,
            type: "text",
          };
        case "number":
          return {
            value: fieldsValues[field.id] || "",
            onChange: handleChange,
            type: "number",
          };
        default:
          throw Error("Unsupported custom field type");
      }
    },
    [fieldsValues]
  );

  const isFormValid = useMemo(
    () => !fields.some((field) => field.isRequired && !fieldsValues[field.id]),
    [fields, fieldsValues]
  );

  return (
    <div className="flex w-full flex-col items-center">
      {image && (
        <div className="h-[120px] w-full shrink-0 overflow-hidden rounded-lg">
          <img
            src={image}
            className="h-full w-full object-cover object-center"
            alt=""
          />
        </div>
      )}
      {!isSubmitted ? (
        <div className="mt-6 w-full px-8">
          <div className="text-dark font-secondary text-center text-2xl leading-7">
            {title}
          </div>
          {description && (
            <div className="font-secondary text-dark-600 text-center text-sm leading-[21px]">
              {description}
            </div>
          )}
          {fields.length > 0 && (
            <div className="mt-8 flex flex-col gap-6">
              {fields.map((field) => (
                <CustomFieldInput
                  key={field.id}
                  isRequired={field.isRequired}
                  title={field.name}
                  field={
                    getCustomFieldInputProps(
                      field
                    ) as CustomFieldInputProps["field"]
                  }
                  isError={
                    isError && field.isRequired && !fieldsValues[field.id]
                  }
                  isGuestApp={isGuestApp}
                />
              ))}
            </div>
          )}
        </div>
      ) : (
        <div className="mt-20 flex flex-col items-center text-center">
          <span className="text-dark font-secondary text-2xl leading-7">
            {t({
              id: "order-form-create-page.thank-you",
              defaultMessage: "Thank you!",
            })}
          </span>
          <span className="text-dark-600 font-secondary text-sm leading-[21px]">
            {submittedMessage}
          </span>
        </div>
      )}
      {(!isSubmitted || onReset) && (
        <Button
          isDisabled={isLoading}
          className="mx-auto mt-8 h-10 w-[240px] shrink-0"
          onClick={() => {
            if (isSubmitted) {
              setFieldsValues({});
              setIsError(false);
              onReset?.();
              return;
            }
            if (!isFormValid) {
              setIsError(true);
              return;
            }
            onSubmit(fieldsValues);
          }}
        >
          {!isSubmitted
            ? t({
                id: "common.submit",
                defaultMessage: "Submit",
              })
            : resetButtonText ||
              t({
                id: "common.add-another-answer",
                defaultMessage: "Add another answer",
              })}
        </Button>
      )}
    </div>
  );
};
