import { useRestApiProvider } from "@jugl-web/rest-api";
import { previewTasksAdapter } from "@jugl-web/rest-api/tasks";
import { useCallback, useMemo } from "react";

interface UseSelectTaskByIdOptions {
  entityId: string;
  customerId?: string;
}

export const useSelectTaskById = ({
  entityId,
  customerId,
}: UseSelectTaskByIdOptions) => {
  const { tasksApi } = useRestApiProvider();

  const { data: taskEntityState } = tasksApi.endpoints.getTasks.useQueryState({
    entityId,
  });

  const { data: teamTaskEntityState } =
    tasksApi.endpoints.getTeamTasks.useQueryState({
      entityId,
    });

  const { data: customerTaskEntityState } =
    tasksApi.endpoints.getCustomerTasks.useQueryState({
      entityId,
      customerId,
    });

  const adapterSelectors = useMemo(
    () => previewTasksAdapter.getSelectors(),
    []
  );

  // Searches for a task in both personal and team task collections
  const selectTaskById = useCallback(
    (taskId: string) => {
      const maybePersonalTask = adapterSelectors.selectById(
        taskEntityState ?? previewTasksAdapter.getInitialState(),
        taskId
      );

      if (maybePersonalTask) {
        return maybePersonalTask;
      }

      const maybeTeamTask = adapterSelectors.selectById(
        teamTaskEntityState ?? previewTasksAdapter.getInitialState(),
        taskId
      );

      if (maybeTeamTask) {
        return maybeTeamTask;
      }

      const maybeCustomerTask = adapterSelectors.selectById(
        customerTaskEntityState ?? previewTasksAdapter.getInitialState(),
        taskId
      );

      return maybeCustomerTask;
    },
    [
      adapterSelectors,
      taskEntityState,
      teamTaskEntityState,
      customerTaskEntityState,
    ]
  );

  return selectTaskById;
};
