import {
  TaskPropertyButton,
  TaskPropertyButtonProps,
} from "@jugl-web/ui-components/cross-platform/tasks/TaskPropertyButton";
import { MobileDrawer } from "@jugl-web/ui-components/mobile";
import { cx, useAppVariant, useTranslations } from "@jugl-web/utils";
import { useFormattedDate } from "@jugl-web/utils/hooks/useFormattedDate";
import { FC, useState } from "react";
import { getDueDateInDays } from "../../../../utils";
import { DueDatePicker, DueDatePickerProps } from "../../../DueDatePicker";
import { DueDatePickerPopover } from "../../../DueDatePickerPopover";
import { ReactComponent as DueDateIcon } from "../../assets/due-date.svg";
import { FieldComponentProps } from "../../types";

export const DueDateField: FC<FieldComponentProps<"dueDate">> = ({
  state,
  displayAs = "date",
  isReadonly,
  isLabelHidden,
  isDateEditable = true,
  withReminder = false,
  onChange,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const hasDueDate = !!state;
  const isFieldVisible = hasDueDate || !isReadonly;

  const { t } = useTranslations();
  const { formatDateTimeLabel } = useFormattedDate();

  const { isMobile } = useAppVariant();

  const label = (() => {
    if (!hasDueDate) {
      return isLabelHidden
        ? null
        : t({
            id: "task-templates-page.due-date",
            defaultMessage: "Due date",
          });
    }

    if (displayAs === "days") {
      return t(
        {
          id: "tasks-page.due-in-days",
          defaultMessage:
            "Due in: {days} {days, plural, one {day} other {days}}",
        },
        { days: getDueDateInDays(state.date) }
      );
    }

    const dueDateTimeLabel = formatDateTimeLabel(state.date);

    return isLabelHidden
      ? dueDateTimeLabel
      : t(
          { id: "tasks-page.due-to-date", defaultMessage: "Due to: {date}" },
          { date: dueDateTimeLabel }
        );
  })();

  if (!isFieldVisible) {
    return null;
  }

  const commonButtonProps: TaskPropertyButtonProps = {
    isDisabled: isReadonly,
    startIcon: <DueDateIcon className={cx(hasDueDate && "text-primary")} />,
    className: cx(hasDueDate && "bg-primary-50 text-primary-900"),
    children: label,
  };

  const commonDueDatePickerProps = {
    initialState: state,
    isDateEditable,
    withReminder,
  } satisfies Partial<DueDatePickerProps>;

  if (isMobile) {
    return (
      <>
        <TaskPropertyButton
          onClick={() => setIsDialogOpen(true)}
          {...commonButtonProps}
        />
        <MobileDrawer
          isOpen={isDialogOpen}
          animateHeight
          className="overflow-y-auto"
          onClose={() => setIsDialogOpen(false)}
        >
          <MobileDrawer.Content className="pt-4">
            <DueDatePicker
              {...commonDueDatePickerProps}
              onSubmit={(s) => onChange?.(s)}
              onClose={() => setIsDialogOpen(false)}
            />
          </MobileDrawer.Content>
        </MobileDrawer>
      </>
    );
  }

  return (
    <DueDatePickerPopover
      placement="bottom-start"
      renderTrigger={({ Trigger, triggerRef }) => (
        <Trigger
          ref={triggerRef}
          as={TaskPropertyButton}
          {...commonButtonProps}
        />
      )}
      {...commonDueDatePickerProps}
      onSubmit={(s) => onChange?.(s)}
    />
  );
};
